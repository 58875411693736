import { Card } from "@/component/shadcn/ui/card";
import type { HistoryResponse } from "@/interfaces/serverData.js";

import { Timestamp } from "@liveblocks/react-ui/primitives";
import { Comment as CommentPrimitive } from "@liveblocks/react-ui/primitives";
import clsx from "clsx";
import { type ComponentProps, Suspense } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Avatar } from "./Avatar";
import { MyUser } from "./User";

import { toHTML } from "slack-markdown";

import parse, { domToReact } from "html-react-parser";

import { formatUsernames } from "@/utilities/methods";
import rehypeRaw from "rehype-raw";

/**
 * Custom comment component.
 */

interface CommentProps extends ComponentProps<"div"> {
    comment: HistoryResponse;
    source: string;
}

export function Comment({
    comment,
    className,
    source,
    ...props
}: CommentProps) {
    if (!comment.content) {
        return null;
    }

    const commentDate = (comment: HistoryResponse): string | Date => {
        if (comment.timestamp) {
            try {
                const date = new Date(comment.timestamp);
                if (Number.isNaN(date.getTime())) {
                    throw new Error("Invalid date");
                }
                return date;
            } catch (err) {
                console.log(
                    `Could not convert comment's timestamp ${comment.timestamp} to a valid date, so using the original timestamp format. Error: ${err}`,
                );
                return new Date();
            }
        }
        return new Date();
    };
    const timestamp = commentDate(comment);

    const options = {
        // @ts-ignore
        replace: (domNode) => {
            if (domNode.attribs && domNode.name === "a") {
                return (
                    <CommentPrimitive.Link
                        href={domNode.attribs.href}
                        className="lb-comment-link"
                    >
                        {domToReact(domNode.children)}
                    </CommentPrimitive.Link>
                );
            }

            if (domNode.name === "code") {
                return (
                    <code className="text-balance">
                        {domToReact(domNode.children)}
                    </code>
                );
            }

            if (
                domNode.name === "span" &&
                domNode.attribs &&
                domNode.attribs.class === "s-mention s-user"
            ) {
                return (
                    <span className="text-iris10 hover:underline cursor-pointer">
                        {domToReact(domNode.children)}
                    </span>
                );
            }
        },
    };

    return (
        <Card className=" my-4 shadow-sm shadow-[#f3f4f6] color-[#f3f4f6] border">
            <div
                className={clsx(className, "lb-root lb-comment", "rounded-xl")}
                {...props}
            >
                <div className="lb-comment-header mb-0">
                    <div className="lb-comment-details ">
                        <Suspense
                            fallback={
                                <div className="relative aspect-square w-8 flex-none animate-pulse rounded-full bg-gray-100" />
                            }
                        >
                            <Avatar user={comment.user_data} />
                        </Suspense>
                        <div className="lb-comment-details-labels">
                            <MyUser
                                user={comment.user_data}
                                className="lb-comment-author text-sm font-semibold"
                            />
                            <Timestamp
                                date={timestamp}
                                className="lb-comment-date"
                            />
                        </div>
                    </div>
                </div>
                <div className="lb-comment:indent-content min-h-min text-balance">
                    {comment.source !== "Slack" ? (
                        <Markdown
                            className="text-sm font-normal lb-comment-content min-h-min text-balance"
                            remarkPlugins={[remarkGfm]}
                            rehypePlugins={[rehypeRaw]}
                            components={{
                                a(props) {
                                    const { href, children } = props;
                                    return (
                                        <CommentPrimitive.Link
                                            href={href}
                                            className="lb-comment-link"
                                        >
                                            {children}
                                        </CommentPrimitive.Link>
                                    );
                                },
                                code(props) {
                                    const { children } = props;
                                    return (
                                        <code className="text-balance">
                                            {children}
                                        </code>
                                    );
                                },
                                span(props) {
                                    const { children } = props;
                                    return (
                                        <span className="text-iris10 hover:underline cursor-pointer">
                                            {children}
                                        </span>
                                    );
                                },
                            }}
                        >
                            {formatUsernames(comment.content)}
                        </Markdown>
                    ) : (
                        <div className="text-sm font-normal lb-comment-content min-h-min text-balance">
                            {parse(
                                formatUsernames(toHTML(comment.content)),
                                options,
                            )}
                        </div>
                    )}
                </div>
            </div>
        </Card>
    );
}
