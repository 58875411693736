import { type FC, useEffect, useState } from "react";

import { Text } from "@radix-ui/themes";

type TypewriterProps = {
    text: string;
    speed?: number;
};

const useTypewriter = (text: string, speed = 15): string => {
    const [displayText, setDisplayText] = useState<string>("");

    useEffect(() => {
        let i = 0;
        const typingInterval = setInterval(() => {
            if (i < text.length) {
                setDisplayText(text.slice(0, i+4));
                i+=4;
            } else {
                clearInterval(typingInterval);
            }
        }, speed);

        return () => {
            clearInterval(typingInterval);
        };
    }, [text, speed]);

    return displayText;
};

export const Typewriter: FC<TypewriterProps> = ({ text, speed = 30 }) => {
    const displayText = useTypewriter(text, speed);

    return (
        <Text style={{ paddingLeft: 1, paddingBottom: 2 }} size="2">
            {displayText}
        </Text>
    );
};

export default Typewriter;
