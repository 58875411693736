export const queryToLabel: Record<string, string> = {
    id: "Id",
    title: "Title",
    created_at: "Created",
    bot_category: "Tag",
    source: "Source",
    topic: "Topics",
    ticket_status: "Status",
};

export const tags = [
    {
        color: "",
        label: "Bug",
        value: "Bug",
    },
    {
        color: "",
        label: "Feature",
        value: "Feature",
    },
    {
        color: "",
        label: "Query",
        value: "Query",
    },
    {
        color: "",
        label: "Unknown",
        value: "Unknown",
    },
];

export const sources = [
    {
        color: "",
        label: "Slack",
        value: "Slack",
    },
    {
        color: "",
        label: "Discord",
        value: "Discord",
    },
    {
        color: "",
        label: "Chat Widget",
        value: "ChatWidget",
    },
    {
        color: "",
        label: "GitHub Issues",
        value: "GitHubTicket",
    },
    {
        color: "",
        label: "Gmail",
        value: "Gmail",
    },
    {
        color: "",
        label: "Web",
        value: "Web",
    },
];

export const broadcasts = [
    {
        label: "Slack",
        value: "Slack",
    },
    {
        label: "Discord",
        value: "Discord",
    },
];

export const statuses = [
    {
        color: "",
        label: "Breaching",
        value: "Breaching",
    },
    {
        color: "",
        label: "Needs Response",
        value: "NeedsResponse",
    },
    {
        color: "",
        label: "New",
        value: "New",
    },
    {
        color: "",
        label: "Open",
        value: "Open",
    },
    {
        color: "",
        label: "Closed",
        value: "Closed",
    },
];

export type RowState = {
    tagSelected: string;
    topicsSelected: string[];
    statusSelected: string;
};

export enum HeaderType {
    Status = "Status",
}
