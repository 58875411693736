"use client";

import { LexicalComposer } from "@lexical/react/LexicalComposer";

import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";

import { ContentEditable } from "@lexical/react/LexicalContentEditable";

import { HeadingNode, QuoteNode } from "@lexical/rich-text";

import { ListItemNode, ListNode } from "@lexical/list";

import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";

import {
    LiveblocksPlugin,
    liveblocksConfig,
    useEditorStatus,
} from "@liveblocks/react-lexical";

import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { ExampleTheme } from "../../component/textEditor/Theme";
import Toolbar from "../../component/textEditor/Toolbar";
import { EmojiNode } from "../../component/textEditor/nodes/EmojiNode";
import { Loading } from "./Loading";

import "./editor.css";
import { AIResponsePlugin } from "@/component/textEditor/AIResponsePlugin";
import { useState } from "react";

// Wrap your initial config with `liveblocksConfig`
const initialConfig = liveblocksConfig({
    namespace: "liveblocks",
    nodes: [HeadingNode, QuoteNode, ListNode, ListItemNode, EmojiNode],
    onError: (error: unknown) => {
        console.error(error);
        throw error;
    },
    theme: ExampleTheme,
});

interface CollaborativeEditorProps {
    getAIResponse?: () => void;
    aiResponse: string;
    aiResponseCompleted: number;
    aiResponseButton: boolean;
}

export default function CollaborativeEditor({
    getAIResponse = () => {},
    aiResponse,
    aiResponseCompleted,
    aiResponseButton,
}: CollaborativeEditorProps) {
    const status = useEditorStatus();
    const [isToggled, setIsToggled] = useState(false);
    return (
        <div className="relative min-h-screen flex flex-col">
            <LexicalComposer initialConfig={initialConfig}>
                <LiveblocksPlugin>
                    {status === "not-loaded" || status === "loading" ? (
                        <Loading />
                    ) : (
                        <div className="relative flex flex-row justify-between w-full gap-[50px]">
                            <div className="focus-within:shadow-md focus-within:outline-0.5 focus-within:outline-offset-0  relative text-sm flex flex-col flex-1 pb-4 pr-4 border rounded-lg shadow-sm  transition-all min-h-[500px]">
                                <Toolbar />
                                <AIResponsePlugin
                                    className="right-2"
                                    isToggled={isToggled ?? false}
                                    setIsToggled={setIsToggled}
                                    aiResponseButton={aiResponseButton}
                                    ai_response={aiResponse}
                                    getAIResponse={getAIResponse}
                                    aiResponseCompleted={aiResponseCompleted}
                                />
                                <RichTextPlugin
                                    contentEditable={
                                        <ContentEditable className="min-h-[500px] pl-4 pt-4 focus:outline-purple-600" />
                                    }
                                    placeholder={<div />}
                                    ErrorBoundary={LexicalErrorBoundary}
                                />
                                <ListPlugin />
                            </div>
                        </div>
                    )}
                </LiveblocksPlugin>
            </LexicalComposer>
        </div>
    );
}
