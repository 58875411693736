import Clock from "@/component/Timer";

import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	BreadcrumbList,
	BreadcrumbSeparator,
} from "@/component/shadcn/ui/breadcrumb";
import { Button } from "@/component/shadcn/ui/button";
import {
	ResizableHandle,
	ResizablePanel,
	ResizablePanelGroup,
} from "@/component/shadcn/ui/resizable";
import {
	TooltipContent,
	Tooltip as TooltipRoot,
	TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";

import { TooltipProvider } from "@/component/shadcn/ui/tooltip";
import { DataList } from "@radix-ui/themes";

import type {
	ExternalIssues,
	HistoryResponse,
	Ticket,
} from "@/interfaces/serverData";
import { integrationBackEndDataMappingToSvg } from "@/pages/Admin/Integrations/constant";
import { arraysAreEqual } from "@/utilities/methods";
import { RoomProvider } from "@liveblocks/react/suspense";
import { useAuthInfo } from "@propelauth/react";
import { CalendarIcon, CopyIcon, Link2Icon } from "@radix-ui/react-icons";
import { Callout, Flex, Heading, Skeleton, Text } from "@radix-ui/themes";
import { Separator } from "@radix-ui/themes";
import type {
	QueryObserverResult,
	RefetchOptions,
} from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SlackSvg } from "../images/integrations/slack.svg";
import { AssigneeDropdown } from "./AssigneeDropdown";
import CustomerProfile from "./CustomerProfile";
import { ExternalIssuesSection } from "./ExternalIssuesSection";
import InsightsSection from "./InsightsSection";
import InternalNotes from "./InternalNote";
import Room from "./Room";
import { TicketStatusDropdown } from "./TicketStatusDropdown";
import { TicketTopicsDropdown } from "./TicketTopicsDropdown";
function processTitle(ticket: Ticket): string {
	// Older tickets don't have a generated title, just use the query
	if (ticket.title == null) {
		return ticket.query;
	} else if (ticket.title.startsWith('"') && ticket.title.endsWith('"')) {
		// If the generated title is surrounded by quotes, remove the quotes
		return ticket.title.slice(1, -1);
	} else {
		return ticket.title;
	}
}

const areEqual = (prevProps: TicketProps, nextProps: TicketProps) => {
	return (
		arraysAreEqual(prevProps.threadData, nextProps.threadData) &&
		prevProps.refetchTicketData === nextProps.refetchTicketData &&
		prevProps.refetchThreadData === nextProps.refetchThreadData &&
		prevProps.loadingThreadState === nextProps.loadingThreadState &&
		prevProps.identifier === nextProps.identifier &&
		prevProps.analytics === nextProps.analytics &&
		arraysAreEqual(
			prevProps.external_issues ?? [],
			nextProps.external_issues ?? [],
		) &&
		prevProps.userID === nextProps.userID &&
		prevProps.ai_response === nextProps.ai_response &&
		prevProps.fromInbox === nextProps.fromInbox
	);
};

interface TicketProps {
	threadData: HistoryResponse[];
	refetchTicketData: (
		options?: RefetchOptions,
	) => Promise<QueryObserverResult<Ticket | null, Error>>;
	refetchThreadData: (
		options?: RefetchOptions,
	) => Promise<QueryObserverResult<HistoryResponse[], Error>>;
	loadingThreadState: number;
	identifier: string;
	analytics: Ticket;
	external_issues: ExternalIssues[] | null;
	userID: string;
	ai_response: string;
	fromInbox: boolean;
}

function TicketDisplay({
	threadData,
	refetchTicketData,
	refetchThreadData,
	loadingThreadState,
	identifier,
	analytics,
	external_issues,
	userID,
	ai_response,
	fromInbox,
}: TicketProps) {
	const authInfo = useAuthInfo();
	const [ticketThread, setTicketThread] = useState<HistoryResponse[]>([]);

	useEffect(() => {
		if (threadData && threadData.length === 0) {
			const message: HistoryResponse[] = [
				{
					id: analytics.id,
					content: analytics.query,
					source: analytics.source ?? "",
					timestamp: analytics.created_at,
					type: "Message",
					metadata: "",
					user_data: {
						name: "Anonymous",
					},
				},
			];
			setTicketThread(message);
		} else {
			setTicketThread(threadData);
		}
	}, [threadData]);
	const [update, setUpdate] = useState<boolean>(false);
	const [tooltipOpen, setTooltipOpen] = useState<boolean>(false); // State for tooltip visibility

	const [isHovered, setIsHovered] = useState(false);
	const [animate, setAnimate] = useState<string | null>(null);

	const triggerAnimation = useCallback((animationName: string): void => {
		setAnimate(animationName);
		setTimeout(() => setAnimate(null), 200); // Reset after animation duration
	}, []);

	const copyIdentifier = useCallback((): void => {
		navigator.clipboard.writeText(identifier);
		triggerAnimation("scale-on-click");
		setTooltipOpen(false);
	}, [identifier]);

	const copyLink = useCallback((): void => {
		navigator.clipboard.writeText(
			`${process.env.REACT_APP_CALLBACK_URL}issue/${identifier}`,
		);
		triggerAnimation("scale-on-click");
		setTooltipOpen(false);
	}, [identifier]);

	const loremIpsum = "Lorem ipsum dolor sit amet, consectetur adipiscing elit.";

	const source: string = analytics.source ?? "";
	const SourceSvgImage = useMemo(
		() => integrationBackEndDataMappingToSvg.get(source) ?? SlackSvg,
		[source],
	);
	const created_date = useMemo(() => {
		try {
			const d = new Date(analytics.created_at);
			return d.toLocaleDateString();
		} catch (err) {
			console.log(
				`Could not convert ticket's created at timestamp ${analytics.created_at} to a valid date, so using the original timestamp format. Error: ${err}`,
			);
			return analytics.created_at;
		}
	}, [analytics.created_at]);

	const [orgId, setOrgId] = useState<string>("");
	const navigate = useNavigate();

	useEffect(() => {
		const orgIds = authInfo.orgHelper?.getOrgIds();
		if (orgIds === undefined || orgIds.length !== 1) {
			navigate("/*");
			return;
		}
		setOrgId(orgIds[0]);
	}, [authInfo.orgHelper, navigate]);

	const minDataListWidth = "80px";

	const sidebarLabels = "font-medium text-xs text-gray9";
	return (
		<div className="overflow-hidden min-h-screen">
			<div className="h-screen overflow-hidden">
				<TooltipProvider delayDuration={0}>
					<ResizablePanelGroup
						direction="horizontal"
						onLayout={(sizes: number[]) => {
							document.cookie = `react-resizable-panels:layout=${JSON.stringify(
								sizes,
							)}`;
						}}
						className="h-fit items-stretch"
					>
						<ResizablePanel
							defaultSize={window.innerWidth * 0.75}
							key="ticketInfo"
							className="mt-4"
						>
							<div className="flex flex-row items-center pl-4">
								<Breadcrumb>
									<BreadcrumbList className="flex items-center pl-2">
										<BreadcrumbItem>
											<BreadcrumbLink href={fromInbox ? "/inbox" : "/issues"}>
												{fromInbox ? "Inbox" : "Issues"}
											</BreadcrumbLink>
										</BreadcrumbItem>
										<BreadcrumbSeparator className="mx-2" />
										<TooltipProvider>
											<TooltipRoot
												open={tooltipOpen}
												onOpenChange={setTooltipOpen}
											>
												<TooltipTrigger asChild>
													<BreadcrumbItem
														onMouseEnter={() => setTooltipOpen(true)}
													>
														<BreadcrumbLink className="hover:underline">
															{identifier}
														</BreadcrumbLink>
													</BreadcrumbItem>
												</TooltipTrigger>
												<TooltipContent className="bg-white text-black p-4 rounded shadow-lg">
													<div className="flex flex-col gap-2">
														<Button
															size="sm"
															variant="ghost"
															onClick={copyIdentifier}
															className="flex items-center gap-2 text-black"
														>
															<CopyIcon className="h-4 w-4" /> Copy Identifier
														</Button>
														<Button
															size="sm"
															variant="ghost"
															onClick={copyLink}
															className="flex items-center gap-2 text-black"
														>
															<Link2Icon className="h-4 w-4" /> Copy Link
														</Button>
													</div>
												</TooltipContent>
											</TooltipRoot>
										</TooltipProvider>
									</BreadcrumbList>
								</Breadcrumb>
							</div>
							<Separator size="4" className="mt-5 mb-8" />
							<Flex
								align="start"
								direction="column"
								justify={"start"}
								className="pr-8 pl-28"
							>
								<Heading
									size="5"
									weight="bold"
									align="left"
									className="pt-1 pb-6"
								>
									{processTitle(analytics)}
								</Heading>

								<div className="font-medium pb-2 text-md">Activity</div>
								{loadingThreadState === 0 && (
									<div>
										<Skeleton>
											<Text>
												{[...Array(6)].map((_, index) => (
													// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
													<Text key={index}>{loremIpsum}</Text>
												))}
											</Text>
										</Skeleton>
									</div>
								)}

								{loadingThreadState === 2 && (
									<Callout.Root
										size="1"
										variant="outline"
										color="red"
										className="pt-4 w-[50%]"
									>
										<Callout.Text>
											Sorry, something's wrong with loading the thread! Please
											notify us at support@askassembly.app.
										</Callout.Text>
									</Callout.Root>
								)}

								{loadingThreadState === 1 && ticketThread && (
									<RoomProvider id={`${orgId}:ticket`}>
										<Room
											threadData={ticketThread}
											source={source}
											source_specific_id={analytics.source_specific_id}
											classNameComposer={"sticky bottom-0 z-10"}
											update={update}
											setUpdate={setUpdate}
											title={analytics.title}
											ai_response={ai_response}
											refetchThreadData={refetchThreadData}
											showComposer={true}
											url={analytics.url}
											classNameThread="mb-52"
											className="h-screen"
										/>
									</RoomProvider>
								)}
							</Flex>
						</ResizablePanel>
						<ResizableHandle className="bg-sidebarBorder w-px" />
						<ResizablePanel
							defaultSize={window.innerWidth * 0.25}
							key="ticketMetadata"
							className="mt-4 mr-4 flex flex-col h-full"
						>
							<div className="ml-4 text-sm font-medium">Properties</div>
							<Separator size="4" className="mt-5" />
							<div className="pt-8 ml-4 flex flex-1 flex-col pb-5 gap-3 rounded-md pr-6 overflow-y-auto overflow-visible">
								<DataList.Root size="1" trim={"both"}>
									{analytics.breaching !== "" &&
										analytics.breaching !== null &&
										analytics.breaching !== undefined && (
											<DataList.Item align="center">
												<DataList.Label
													minWidth={`${minDataListWidth}`}
													className="text-xs"
												>
													SLA
												</DataList.Label>
												<DataList.Value>
													<Clock
														targetTime={analytics.breaching}
														variant="outline"
													/>
												</DataList.Value>
											</DataList.Item>
										)}
									<DataList.Item align="center">
										<DataList.Label
											minWidth={`${minDataListWidth}`}
											className="text-xs"
										>
											Owner
										</DataList.Label>
										<DataList.Value>
											<AssigneeDropdown
												ticket={analytics}
												userID={userID}
												refetchThreadData={refetchThreadData}
											/>
										</DataList.Value>
									</DataList.Item>
									<DataList.Item align="center">
										<DataList.Label
											minWidth={`${minDataListWidth}`}
											className="text-xs"
										>
											Status
										</DataList.Label>
										<DataList.Value>
											<TicketStatusDropdown
												ticket={analytics}
												userID={userID}
												refetchThreadData={refetchThreadData}
											/>
										</DataList.Value>
									</DataList.Item>
									<DataList.Item align="center">
										<DataList.Label
											minWidth={`${minDataListWidth}`}
											className="text-xs"
										>
											Source
										</DataList.Label>
										<DataList.Value>
											<div className="flex items-center text-xs">
												<SourceSvgImage
													style={{
														width: "20px",
														height: "30px",
													}}
													justify="start"
												/>
												<span className="px-1">
													<a
														href={analytics.url}
														target="_blank"
														rel="noopener noreferrer"
														className="text-iris9 hover:text-iris11 hover:underline"
													>
														{analytics.channel_name.trim() !== ""
															? analytics.channel_name
															: analytics.source}
													</a>
												</span>
											</div>
										</DataList.Value>
									</DataList.Item>
									<DataList.Item align="center">
										<DataList.Label
											minWidth={`${minDataListWidth}`}
											className="text-xs"
										>
											Date
										</DataList.Label>
										<DataList.Value>
											<div className="flex flex-col gap-1 ">
												<div className="text-xs flex items-center">
													<CalendarIcon
														style={{
															width: "20px",
															height: "30px",
														}}
													/>
													<span className="px-1">{created_date}</span>
												</div>
											</div>
										</DataList.Value>
									</DataList.Item>
								</DataList.Root>

								<div>
									<div className={sidebarLabels}>Labels</div>
									<div className="flex flex-row flex-wrap">
										<TicketTopicsDropdown
											ticket={analytics}
											userID={userID}
											refetchThreadData={refetchThreadData}
										/>
									</div>
								</div>
								{source !== "ChatWidget" && (
									<>
										<Separator
											size="4"
											className="border border-sidebarBorder"
										/>

										<CustomerProfile
											userInfo={
												threadData.length > 0 ? threadData[0].user_data : null
											} // User info of first message in the ticket
											source={source}
											sidebarLabels={sidebarLabels}
										/>
									</>
								)}

								<Separator size="4" className="border border-sidebarBorder" />

								<ExternalIssuesSection
									ticket={analytics}
									userID={userID}
									sidebarLabels={sidebarLabels}
								/>

								<Separator size="4" className="border border-sidebarBorder" />

								<InsightsSection
									issue={analytics}
									refetchTicketData={refetchTicketData}
									sidebarLabels={sidebarLabels}
								/>

								<Separator size="4" className="border border-sidebarBorder" />

								<InternalNotes
									ticketId={analytics.id}
									internalNote={analytics.internal_note}
									source={source}
									userId={userID}
									sidebarLabels={sidebarLabels}
								/>
								<div className="pb-10" />
							</div>
						</ResizablePanel>
					</ResizablePanelGroup>
				</TooltipProvider>
			</div>
		</div>
	);
}

export default memo(TicketDisplay, areEqual);
