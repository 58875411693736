import type { UserResponse } from "@/interfaces/serverData";
import clsx from "clsx";
import type { ComponentProps } from "react";

interface UserProps extends ComponentProps<"span"> {
    user: UserResponse;
}

export function MyUser({ user, className, ...props }: UserProps) {
    function getUserName(user: UserResponse) {
        return user.name ?? user.username ?? "Anonymous";
    }
    return (
        <span className={clsx(className, "")} {...props}>
            {getUserName(user)}
        </span>
    );
}
