import { Card, CardContent } from "@/component/shadcn/ui/card";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { GeneralNotificationSettings } from "@/interfaces/serverData";
import {
    BellIcon,
    CheckCircledIcon,
    CrossCircledIcon,
} from "@radix-ui/react-icons";
import * as Toast from "@radix-ui/react-toast";
import {
    Box,
    Button,
    Flex,
    HoverCard,
    Skeleton,
    Switch,
    Text,
} from "@radix-ui/themes";

import { useEffect, useRef, useState } from "react";
import { ReactComponent as SlackSvg } from "../../images/integrations/slack.svg";
import { useSearch } from "../SearchContext";
import SlackAlerts from "./SlackAlerts";

const NotificationPreferences: React.FC = () => {
    const { searchBarFilters, loading } = useSearch(); // using this for checking if slack integration is active
    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const [newNeedsResponse, setNewNeedsResponse] = useState<boolean>(false);
    const [updatedNeedsResponse, setUpdatedNeedsResponse] =
        useState<boolean>(false);
    const [slaBreach, setSlaBreach] = useState<boolean>(true);
    const [slaNotification, setSlaNotification] = useState<boolean>(true);

    const [toastText, setToastText] = useState<string>("Updated Settings!");
    const [open, setOpen] = useState<boolean>(false);
    const [ToastSymbol, setToastSymbol] =
        useState<React.ElementType>(CheckCircledIcon);

    const [slackModalOpen, setSlackModalOpen] = useState<boolean>(false);

    const [isSlackIntegrationEnabled, setIsSlackIntegrationEnabled] =
        useState<boolean>(false);

    const api = useApi();

    useEffect(() => {
        if (loading) {
            if (searchBarFilters.get("Slack")) {
                setIsSlackIntegrationEnabled(true);
            } else {
                setIsSlackIntegrationEnabled(false);
            }
        }
    }, [searchBarFilters, loading]);

    useEffect(() => {
        // Fetch general notifications settings for "Needs Response"
        api.get(`${URLS.serverUrl}${API.getTeamAlerts}`, {
            headers: { "Content-Type": "application/json" },
        })
            .then((generalRes) => {
                if (generalRes.status === 200) {
                    const data = generalRes.data
                        .data as GeneralNotificationSettings; // change this
                    console.log("General Notifications Response:", data);
                    setNewNeedsResponse(data.new_needs_response);
                    setUpdatedNeedsResponse(data.updated_needs_response);
                    setSlaBreach(data.sla_breaching_notification);
                    setSlaNotification(data.sla_notification);
                }
            })
            .catch((error) => {
                console.error(
                    "Error fetching general notification settings:",
                    error,
                );
            });
    }, [api]);

    const timerRef = useRef(0);

    const handleSavePreferences = async () => {
        const requestData: GeneralNotificationSettings = {
            new_needs_response: newNeedsResponse,
            updated_needs_response: updatedNeedsResponse,
            sla_breaching_notification: slaBreach,
            sla_notification: slaNotification,
        };
        api.post(`${URLS.serverUrl}${API.saveTeamAlerts}`, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status !== 200) {
                    setToastText(
                        "Oops! Something's wrong. Please try again at a later time.",
                    );
                    setToastSymbol(CrossCircledIcon);
                } else {
                    setToastText("Updated Settings!");
                }
            })
            .catch((res) => {
                setToastText(
                    "Oops! Something's wrong. Please try again at a later time.",
                );
                setToastSymbol(CrossCircledIcon);
            })
            .finally(() => {
                setOpen(false);
                window.clearTimeout(timerRef.current);
                timerRef.current = window.setTimeout(() => {
                    console.log("setting open to true");
                    setOpen(true);
                }, 100);
            });
    };

    return (
        <div>
            <Box mt={"5%"} ml={"28%"} mr={"28%"}>
                <h2 className="text-2xl font-semibold">Alerts</h2>
                <p className="pb-10 text-sm text-gray11">
                    Manage your notification settings
                </p>

                <Flex direction="column" gap="4">
                    <Card className="pt-6 pb-6 px-6">
                        <CardContent>
                            <div className="flex flex-col gap-3">
                                <div className="flex flex-row items-center gap-1">
                                    <BellIcon />
                                    <h2 className="text-md font-medium">
                                        General Notifications
                                    </h2>
                                </div>

                                <Flex
                                    align="center"
                                    justify="between"
                                    className="px-2"
                                >
                                    <Flex direction="column">
                                        <h2 className="text-sm font-medium">
                                            New Needs Response
                                        </h2>
                                        <h4 className="text-xs">
                                            Send a notification when a new
                                            ticket is created
                                        </h4>
                                    </Flex>
                                    <Switch
                                        checked={newNeedsResponse}
                                        // disabled={loading}
                                        onCheckedChange={(checked: boolean) => {
                                            setNewNeedsResponse(checked);
                                        }}
                                    />
                                </Flex>

                                <Flex
                                    align="center"
                                    justify="between"
                                    className="px-2"
                                >
                                    <Flex direction="column">
                                        <h2 className="text-sm font-medium">
                                            Updated Needs Response
                                        </h2>
                                        <h4 className="text-xs">
                                            Send a notification when a ticket is
                                            newly assigned the status "Needs
                                            Response"
                                        </h4>
                                    </Flex>
                                    <Switch
                                        checked={updatedNeedsResponse}
                                        // disabled={loading}
                                        onCheckedChange={(checked: boolean) => {
                                            setUpdatedNeedsResponse(checked);
                                        }}
                                    />
                                </Flex>
                                <Flex
                                    align="center"
                                    justify="between"
                                    className="px-2"
                                >
                                    <Flex direction="column">
                                        <h2 className="text-sm font-medium">
                                            SLA Breach
                                        </h2>
                                        <h4 className="text-xs">
                                            Send a notification when an SLA is
                                            breached.
                                        </h4>
                                    </Flex>
                                    <Switch
                                        checked={slaBreach}
                                        // disabled={loading}
                                        onCheckedChange={(checked: boolean) => {
                                            setSlaBreach(checked);
                                        }}
                                    />
                                </Flex>
                                <Flex
                                    align="center"
                                    justify="between"
                                    className="px-2"
                                >
                                    <Flex direction="column">
                                        <h2 className="text-sm font-medium">
                                            SLA Notification
                                        </h2>
                                        <h4 className="text-xs">
                                            Send a notification in Slack when a
                                            ticket crosses the SLA notification
                                            time.
                                        </h4>
                                    </Flex>
                                    <Switch
                                        checked={slaNotification}
                                        // disabled={loading}
                                        onCheckedChange={(checked: boolean) => {
                                            setSlaNotification(checked);
                                        }}
                                    />
                                </Flex>
                                <Button
                                    className="iris text-white mt-4 self-end justify-self-end"
                                    onClick={handleSavePreferences}
                                >
                                    Save Preferences
                                </Button>
                            </div>
                        </CardContent>
                    </Card>

                    {!loading ? (
                        <Skeleton>
                            <Text>
                                {[...Array(2)].map((_, index) => (
                                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                    <Text key={index}>{loremIpsum}</Text>
                                ))}
                            </Text>
                        </Skeleton>
                    ) : isSlackIntegrationEnabled ? (
                        <SlackAlerts
                            setToastOpen={setOpen}
                            setToastText={setToastText}
                            setToastSymbol={setToastSymbol}
                            isOpen={slackModalOpen}
                            onOpenChange={setSlackModalOpen}
                            timerRef={timerRef}
                        />
                    ) : (
                        <HoverCard.Root>
                            <HoverCard.Trigger>
                                <Card className="px-1">
                                    <CardContent className="py-3 bg-gray-200">
                                        <div className="flex flex-col gap-6">
                                            <div className="flex flex-row items-center justify-between">
                                                <div className="flex flex-row items-center gap-4">
                                                    <SlackSvg className="max-h-5 max-w-5 grayscale" />
                                                    <h2 className="text-md font-medium text-gray-500">
                                                        Slack Notifications
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            </HoverCard.Trigger>
                            <HoverCard.Content>
                                <Text>Connect Slack to enable</Text>
                            </HoverCard.Content>
                        </HoverCard.Root>
                    )}
                </Flex>
                <Toast.Provider swipeDirection="right">
                    <Toast.Root
                        className="ToastRoot"
                        open={open}
                        onOpenChange={setOpen}
                    >
                        <Toast.Title className="ToastTitle">
                            <Flex direction={"row"} align={"center"} gap="2">
                                <ToastSymbol color="green" />
                                {toastText}
                            </Flex>
                        </Toast.Title>
                    </Toast.Root>
                    <Toast.Viewport className="ToastViewport" />
                </Toast.Provider>
            </Box>
        </div>
    );
};

export default NotificationPreferences;
