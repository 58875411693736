import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ASSEMBLY_COOKIES, pages } from "../constant";
import CustomPopup from "../design/Popup";
import { clearAllCookies, getCookie } from "../utilities/CookieManagement";

import { HamburgerMenuIcon } from "@radix-ui/react-icons";

import { Button, DropdownMenu } from "@radix-ui/themes";
import { useLogoutFunction } from "@propelauth/react";

export default function MenuDropdownComponent({ state = pages.login }) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isDataDeletable, setIsDataDeletable] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        let adminTemp = false;
        const adminCookie =
            getCookie(ASSEMBLY_COOKIES.admin) !== ""
                ? getCookie(ASSEMBLY_COOKIES.admin)
                : "false";
        if (adminCookie === "true") {
            adminTemp = true;
            setIsAdmin(adminTemp); // state doesn't auto update for request data in time (since its async)
        }

        switch (state) {
            case pages.login:
                break;
            case pages.index:
                setIsLoggedIn(true);
                break;
            case pages.search:
                setIsLoggedIn(true);
                setIsDataDeletable(true);
                break;
            default:
            // Default case
        }
    }, [state]);

    const navigate = useNavigate();
    const logout = useLogoutFunction();

    const handleExternalLink = (url: string) => {
        window.open(url, "_blank");
    };

    const onLogout = async () => {
        await logout(false);
        clearAllCookies();
        navigate("/");
    };

    const adminIntegrationsPage = () => {
        navigate("/admin/manage_integrations");
    };

    const adminQueriesPage = () => {
        navigate("/issues");
    };

    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const deleteMessage =
        "Reach out to support@askassembly.app, and we'll get this started for you!";

    const handleConfirmDelete = () => {
        setOpenDeletePopup(false);
        clearAllCookies();
    };

    const handleCancelDelete = () => {
        setOpenDeletePopup(false);
    };

    const handleAdminLogin = () => {
        navigate("/admin_login");
    };

    return (
        <div>
            <DropdownMenu.Root>
                <DropdownMenu.Trigger>
                    <Button variant="soft" size="3">
                        <HamburgerMenuIcon />
                    </Button>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content>
                    {isLoggedIn && (
                        <DropdownMenu.Item onClick={() => onLogout()}>
                            Logout
                        </DropdownMenu.Item>
                    )}
                    {isLoggedIn && isDataDeletable && isAdmin && (
                        <DropdownMenu.Item
                            onClick={() => adminIntegrationsPage()}
                        >
                            Manage Integrations
                        </DropdownMenu.Item>
                    )}
                    {isLoggedIn && isDataDeletable && isAdmin && (
                        <DropdownMenu.Item onClick={() => adminQueriesPage()}>
                            Manage Queries
                        </DropdownMenu.Item>
                    )}
                    {!isLoggedIn && (
                        <DropdownMenu.Item onClick={() => handleAdminLogin()}>
                            Login as Admin
                        </DropdownMenu.Item>
                    )}
                </DropdownMenu.Content>
            </DropdownMenu.Root>

            <CustomPopup
                open={openDeletePopup}
                message={deleteMessage}
                onDeleteConfirm={handleConfirmDelete}
                onCancel={handleCancelDelete}
            />
        </div>
    );
}
