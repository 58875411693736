import { pages } from "@/constant";
import { integrationMappingToSvg } from "@/pages/Admin/Integrations/constant";
import { SvgIcon } from "@mui/material";
import {
    DrawingPinIcon,
    FrameIcon,
    MagicWandIcon,
    MagnifyingGlassIcon,
    QuestionMarkIcon,
    RocketIcon,
    SpeakerLoudIcon,
    TriangleDownIcon,
    TriangleRightIcon,
} from "@radix-ui/react-icons";
import { NavigationMenu } from "@radix-ui/react-navigation-menu";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../shadcn/ui/button";
import {
    NavigationMenuItem,
    NavigationMenuList,
} from "../shadcn/ui/navigation-menu";
import BallPopover from "./BallPopover";
import FilterComponent from "./FilterComponent";
import MenuDropdownComponent from "./MenuDropdownComponent";

import { useAuthInfo } from "@propelauth/react";

export type FilterMetadata = {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    granularFilters: Map<any, boolean>;
    enabled: boolean;
};

export const NAVBAR_ITEMS = {
    integrations: "integrations",
    search: "search",
    queries: "issues",
    inbox: "inbox",
    analytics: "analytics",
    insights: "insights",
    announcements: "announcements",
};

interface SideBarProps {
    organizationName: string;
    filters?: Map<string, FilterMetadata>;
    activeButton: string;
    setActiveButton: (newTerm: string) => void;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    handleToggle?: (type: string, value: any) => () => void;
    isAdmin: boolean;
    width: number;
}

export const drawerWidth = "250px";

export default function SideBarComponent(props: SideBarProps) {
    const sidebarRef = useRef<HTMLDivElement | null>(null);
    const [selectedItem, setSelectedItem] = useState(props.activeButton);
    const [searchDropdownOpen, setSearchDropdownOpen] = useState(
        props.activeButton === NAVBAR_ITEMS.search,
    );
    const [collapsed, setCollapsed] = useState(props.width < 11);

    function setDropdowns(buttonName: string) {
        if (buttonName === NAVBAR_ITEMS.queries) {
            setSearchDropdownOpen(false);
        } else if (buttonName === NAVBAR_ITEMS.search) {
            setSearchDropdownOpen(!searchDropdownOpen);
        } else {
            setSearchDropdownOpen(false);
        }
    }

    const handleMenuClick = (buttonName: string) => {
        // You can call the respective function here
        props.setActiveButton(buttonName);
        setSelectedItem(buttonName);
        setDropdowns(buttonName);
        if (buttonName === NAVBAR_ITEMS.integrations) {
            adminIntegrationsPage();
        } else if (buttonName === NAVBAR_ITEMS.queries) {
            adminQueriesPage();
        } else if (buttonName === NAVBAR_ITEMS.inbox) {
            adminInboxPage();
        } else if (buttonName === NAVBAR_ITEMS.analytics) {
            adminAnalyticsPage();
        } else if (buttonName === NAVBAR_ITEMS.search) {
            searchPage();
        } else if (buttonName === NAVBAR_ITEMS.insights) {
            AdminInsightsPage();
        } else if (buttonName === NAVBAR_ITEMS.announcements) {
            navigate("/announcements");
        }
    };

    const authInfo = useAuthInfo();

    const [orgId, setOrgId] = useState<string>("");

    useEffect(() => {
        const orgIds = authInfo.orgHelper?.getOrgIds();
        if (orgIds === undefined || orgIds.length !== 1) {
            return;
        }
        setOrgId(orgIds[0]);
    }, [authInfo.orgHelper]);

    useEffect(() => {
        setCollapsed(props.width < 190);
    }, [props.width]);

    useEffect(() => {
        const path = window.location.pathname;
        if (path.includes("/inbox")) {
            setSelectedItem(NAVBAR_ITEMS.inbox);
        }
    }, [window.location.pathname]);

    const navigate = useNavigate();

    const adminIntegrationsPage = () => {
        navigate("/admin/manage_integrations");
    };

    const adminQueriesPage = () => {
        navigate("/issues");
    };

    const adminInboxPage = () => {
        navigate("/inbox");
    };

    const adminAnalyticsPage = () => {
        navigate("/analytics");
    };

    const AdminInsightsPage = () => {
        navigate("/insights");
    };

    const searchPage = () => {
        navigate("/search");
    };

    return (
        <div
            className="relative transition-all duration-300"
            style={{ minWidth: "40px", width: `${props.width}` }}
            ref={sidebarRef}
        >
            <MenuDropdownComponent
                organizationName={props.organizationName}
                state={pages.search}
                collapsed={collapsed}
            />
            <NavigationMenu className="flex flex-col space-y-2">
                <div
                    className={`text-xs px-5 font-bold text-gray-500 ${
                        collapsed ? "hidden" : "block"
                    }`}
                >
                    Personal
                </div>
                <NavigationMenuList className="flex flex-col items-center w-full">
                    <NavigationMenuItem className="w-full">
                        <div className="w-full px-3">
                            <Button
                                variant="ghost"
                                size="sm"
                                onClick={() =>
                                    handleMenuClick(NAVBAR_ITEMS.inbox)
                                }
                                className={`flex justify-center items-center text-left w-full box-border ${
                                    selectedItem === NAVBAR_ITEMS.inbox
                                        ? "bg-secondary text-gray-950"
                                        : "text-gray-700 hover:text-gray-950 hover:bg-gray-100"
                                }`}
                            >
                                <DrawingPinIcon className="w-4 h-4" />
                                <div
                                    className={`flex-1 ml-2 ${
                                        collapsed ? "hidden" : "block"
                                    }`}
                                >
                                    <div className="flex items-center">
                                        Inbox
                                    </div>
                                </div>
                            </Button>
                        </div>
                    </NavigationMenuItem>
                </NavigationMenuList>
                <div
                    className={`text-xs pt-3 px-5 font-bold text-gray-500 ${
                        collapsed ? "hidden" : "block"
                    }`}
                >
                    Team
                </div>
                <NavigationMenuList className="flex flex-col items-center w-full">
                    {props.isAdmin && (
                        <NavigationMenuItem className="w-full">
                            <div className="w-full px-3">
                                <Button
                                    variant="ghost"
                                    size="sm"
                                    onClick={() =>
                                        handleMenuClick(
                                            NAVBAR_ITEMS.integrations,
                                        )
                                    }
                                    className={`flex justify-center items-center text-left w-full box-border ${
                                        selectedItem ===
                                        NAVBAR_ITEMS.integrations
                                            ? "bg-secondary text-gray-950"
                                            : "text-gray-700 hover:text-gray-950 hover:bg-gray-100"
                                    }`}
                                >
                                    <FrameIcon className="w-4 h-4" />
                                    <div
                                        className={`flex-1 ml-2 ${
                                            collapsed ? "hidden" : "block"
                                        }`}
                                    >
                                        Manage Integrations
                                    </div>
                                </Button>
                            </div>
                        </NavigationMenuItem>
                    )}
                    <NavigationMenuItem className="w-full">
                        <div className="w-full px-3">
                            <Button
                                variant="ghost"
                                size="sm"
                                onClick={() =>
                                    handleMenuClick(NAVBAR_ITEMS.queries)
                                }
                                className={`flex justify-center items-center text-left w-full box-border ${
                                    selectedItem === NAVBAR_ITEMS.queries
                                        ? "bg-secondary text-gray-950"
                                        : "text-gray-700 hover:text-gray-950 hover:bg-gray-100"
                                }`}
                            >
                                <QuestionMarkIcon className="w-4 h-4" />
                                <div
                                    className={`flex-1 ml-2 ${
                                        collapsed ? "hidden" : "block"
                                    }`}
                                >
                                    <div className="flex items-center">
                                        Issues
                                    </div>
                                </div>
                            </Button>
                        </div>
                    </NavigationMenuItem>
                    <NavigationMenuItem className="w-full">
                        <div className="w-full px-3">
                            <Button
                                variant="ghost"
                                size="sm"
                                onClick={() =>
                                    handleMenuClick(NAVBAR_ITEMS.analytics)
                                }
                                className={`flex justify-center items-center text-left w-full box-border ${
                                    selectedItem === NAVBAR_ITEMS.analytics
                                        ? "bg-secondary text-gray-950"
                                        : "text-gray-700 hover:text-gray-950 hover:bg-gray-100"
                                }`}
                            >
                                <MagicWandIcon className="w-4 h-4" />
                                <div
                                    className={`flex-1 ml-2 ${
                                        collapsed ? "hidden" : "block"
                                    }`}
                                >
                                    Analytics
                                </div>
                            </Button>
                        </div>
                    </NavigationMenuItem>
                    <NavigationMenuItem className="w-full">
                        <div className="w-full px-3">
                            <Button
                                variant="ghost"
                                size="sm"
                                onClick={() =>
                                    handleMenuClick(NAVBAR_ITEMS.insights)
                                }
                                className={`flex justify-center items-center text-left w-full box-border ${
                                    selectedItem === NAVBAR_ITEMS.insights
                                        ? "bg-secondary text-gray-950"
                                        : "text-gray-700 hover:text-gray-950 hover:bg-gray-100"
                                }`}
                            >
                                <RocketIcon className="w-4 h-4" />
                                <div
                                    className={`flex-1 ml-2 ${
                                        collapsed ? "hidden" : "block"
                                    }`}
                                >
                                    Product Insights
                                </div>
                            </Button>
                        </div>
                    </NavigationMenuItem>

                    <NavigationMenuItem className="w-full">
                        <div className="w-full px-3">
                            <Button
                                variant="ghost"
                                size="sm"
                                onClick={() =>
                                    handleMenuClick(NAVBAR_ITEMS.announcements)
                                }
                                className={`flex justify-center items-center text-left w-full box-border ${
                                    selectedItem === NAVBAR_ITEMS.announcements
                                        ? "bg-secondary text-gray-950"
                                        : "text-gray-700 hover:text-gray-950 hover:bg-gray-100"
                                }`}
                            >
                                <SpeakerLoudIcon className="w-4 h-4" />
                                <div
                                    className={`flex-1 ml-2 ${
                                        collapsed ? "hidden" : "block"
                                    }`}
                                >
                                    Announcements
                                </div>
                            </Button>
                        </div>
                    </NavigationMenuItem>

                    <NavigationMenuItem className="w-full">
                        <div className="w-full px-3">
                            <Button
                                variant="ghost"
                                size="sm"
                                onClick={() =>
                                    handleMenuClick(NAVBAR_ITEMS.search)
                                }
                                className={`flex justify-center items-center text-left w-full box-border ${
                                    selectedItem === NAVBAR_ITEMS.search
                                        ? "bg-secondary text-gray-950"
                                        : "text-gray-700 hover:text-gray-950 hover:bg-gray-100"
                                }`}
                            >
                                <MagnifyingGlassIcon className="w-4 h-4" />
                                <div
                                    className={`flex-1 ml-2 ${
                                        collapsed ? "hidden" : "block"
                                    }`}
                                >
                                    <div className="flex items-center">
                                        Search
                                        {searchDropdownOpen ? (
                                            <TriangleDownIcon className="text-gray-600 ml-0.5 w-3 h-3" />
                                        ) : (
                                            <TriangleRightIcon className="text-gray-600 ml-0.5 w-3 h-3" />
                                        )}
                                    </div>
                                </div>
                            </Button>
                        </div>
                    </NavigationMenuItem>
                    <div
                        className={`w-full px-3 pt-1 ${
                            collapsed || !searchDropdownOpen
                                ? "hidden"
                                : "block"
                        }`}
                    >
                        {props.filters &&
                            Array.from(props.filters.entries()).map(
                                ([key, value]: [string, FilterMetadata]) => {
                                    const svgIcon =
                                        integrationMappingToSvg.get(key); // item type

                                    if (!svgIcon) {
                                        return null;
                                    }
                                    return (
                                        <FilterComponent
                                            items={value.granularFilters}
                                            name={key}
                                            key={key}
                                            // biome-ignore lint/style/noNonNullAssertion: <explanation>
                                            handleToggle={props.handleToggle!} // MUST pass handle toggle if we pass in something to filters.
                                            selected={value.enabled}
                                            svg={
                                                <SvgIcon
                                                    component={svgIcon}
                                                    inheritViewBox
                                                    style={{
                                                        transform: "scale(0.8)",
                                                        width: "20px",
                                                        height: "20px",
                                                    }}
                                                />
                                            }
                                        />
                                    );
                                },
                            )}
                    </div>
                </NavigationMenuList>
            </NavigationMenu>
            <div className="fixed bottom-0 left-0 p-4">
                <BallPopover />
            </div>
        </div>
    );
}
