import { useAuthInfo } from "@propelauth/react";
import axios from "axios";
import type { AxiosError } from "axios";
import { useEffect, useRef } from "react";
import { useDialog } from "../DialogContext";
import { URLS } from "../constant";

const api = axios.create({
    baseURL: URLS.serverUrl,
    withCredentials: true,
});

const handleUnauthorizedError = (error: AxiosError, openDialog: () => void) => {
    if (error.response && error.response.status === 401) {
        openDialog();
    }
    return Promise.reject(error);
};

export const useApi = () => {
    const { openDialog } = useDialog();
    const authInfo = useAuthInfo();
    const authInfoRef = useRef(authInfo);

    useEffect(() => {
        authInfoRef.current = authInfo;
    }, [authInfo]);

    api.interceptors.request.use(
        (config) => {
            if (authInfoRef.current.accessToken) {
                config.headers.Authorization = `Bearer ${authInfoRef.current.accessToken}`;
            }
            config.headers["Content-Type"] = "application/json";
            return config;
        },
        (error) => Promise.reject(error),
    );

    api.interceptors.response.use(
        (response) => response,
        (error) => handleUnauthorizedError(error, openDialog),
    );

    return api;
};

export const UseApi = () => {
    const { openDialog } = useDialog();
    const { accessToken } = useAuthInfo();

    api.interceptors.request.use(
        (config) => {
            if (accessToken) {
                config.headers.Authorization = `Bearer ${accessToken}`;
            }
            config.headers["Content-Type"] = "application/json";
            return config;
        },
        (error) => Promise.reject(error),
    );

    api.interceptors.response.use(
        (response) => response,
        (error) => handleUnauthorizedError(error, openDialog),
    );

    return api;
};
