import { CreateIssuePopup } from "@/IssuesTable/CreateIssuePopup";
import { Button } from "@/component/shadcn/ui/button";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";

import { API, URLS } from "@/constant";

import { useApi } from "@/interfaces/api";

import type {
    GetTopicsResponse,
    GetUserResponse,
    QueriesWithPaginationResponse,
} from "@/interfaces/serverData";

import { ViewHorizontalIcon, ViewVerticalIcon } from "@radix-ui/react-icons";
import { Box, Callout, Flex, Heading, Skeleton, Text } from "@radix-ui/themes";

import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { memo, useEffect, useState } from "react";
import AdminProductFeedbackPage from "./AdminProductFeedbackPage";

import IssuesList from "@/IssuesTable/IssuesList";

const areEqual = (
    prevProps: AdminQueryPageProps,
    nextProps: AdminQueryPageProps,
) => {
    return (
        prevProps.userID === nextProps.userID &&
        prevProps.fromInbox === nextProps.fromInbox &&
        prevProps.tableView === nextProps.tableView
    );
};

interface AdminQueryPageProps {
    userID: string;
    fromInbox: boolean;
    tableView: boolean;
}

const AdminQueriesPage: React.FC<AdminQueryPageProps> = ({
    userID,
    fromInbox,
    tableView,
}) => {
    const api = useApi();
    const queryClient = useQueryClient();
    const [topics, setTopics] = useState<
        { color: string; label: string; value: string }[]
    >([]);
    const [topicsMap, setTopicsMap] = useState(new Map());
    const [users, setUsers] = useState<GetUserResponse[]>([]);
    const [loadingState, setLoadingState] = useState<number>(0); // 0: loading, 1: loaded, 2: error
    const [loadingTopicsState, setLoadingTopicsState] = useState<number>(0);
    const [loadingUsersState, setLoadingUsersState] = useState<number>(0);
    const [toggleTable, setToggleTable] = useState<boolean>(tableView); // true if table, false if kanban board

    const fetchQueries = async ({
        pageParam = 0,
    }: { pageParam?: number }): Promise<QueriesWithPaginationResponse> => {
        try {
            const response = await api.get(
                URLS.serverUrl + API.queriesWithPagination,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    params: {
                        limit: 1000,
                        offset: pageParam,
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            setLoadingState(2);
            return { data: [], has_next_page: false, next_cursor: 0 };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return { data: [], has_next_page: false, next_cursor: 0 };
        }
    };

    const {
        data,
        error,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        status,
    } = useInfiniteQuery({
        queryKey: ["queries"],
        queryFn: fetchQueries,
        getNextPageParam: (lastPage) => {
            if (lastPage?.has_next_page) {
                return lastPage.next_cursor;
            }
            return undefined; // No more pages
        },
        initialPageParam: 0,
        refetchInterval: 30000,
        refetchOnWindowFocus: true,
    });
    const combinedData =
        data && Array.isArray(data.pages)
            ? data.pages
                  .filter((page) => page !== null && page !== undefined)
                  .flatMap((page) =>
                      Array.isArray(page.data)
                          ? page.data.filter(
                                (item) => item !== null && item !== undefined,
                            )
                          : [],
                  ) // Filter out null or undefined items in page.data
            : [];

    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    // Fetch all the data
    useEffect(() => {
        if (hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

    // Grab the list of topics for the org
    useEffect(() => {
        api.get(URLS.serverUrl + API.getTopics, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    const topics: GetTopicsResponse[] = res.data.data;

                    const newTopics: {
                        color: string;
                        label: string;
                        value: string;
                    }[] = [];

                    const map = new Map<string, GetTopicsResponse>();

                    for (const topic of topics) {
                        newTopics.push({
                            color: topic.color ?? "#9B9EF0",
                            label: topic.topic_name,
                            value: topic.topic_name,
                        });
                        map.set(topic.topic_name, topic);
                    }
                    setTopicsMap(map);
                    setTopics(newTopics);
                    setLoadingTopicsState(1);
                }
            })
            .catch(() => {
                console.log("Did not grab topics from db successfully");
            });
    }, [api]);

    // Grab the users for the org
    useEffect(() => {
        api.post(URLS.serverUrl + API.getAllUsers, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                setUsers(res.data.data);
                setLoadingUsersState(1);
            } else {
                console.log("Call to grab users failed");
            }
        });
    }, [api]);

    useEffect(() => {
        if (data) {
            setLoadingState(1);
        }
    }, [data]);

    return (
        <div>
            <Flex direction="column" align="center" justify="center">
                <Box
                    height="100%" // Ensure it takes full height of its container
                    width="98%"
                    mt="6"
                >
                    <Flex
                        align="start"
                        direction="column"
                        justify={"start"}
                        className="px-3"
                    >
                        <Heading
                            size="5"
                            align="left"
                            className="flex items-center gap-1"
                        >
                            {fromInbox ? "Inbox" : "Issues"}
                        </Heading>

                        <Text mb="10px" size="2">
                            {fromInbox
                                ? "Track and manage issues assigned to me"
                                : "Manage the issues that Assembly identifies and creates."}
                        </Text>
                    </Flex>
                    <div className="flex items-center absolute top-4 right-4 gap-1.5 my-4">
                        {!fromInbox && (
                            <Button
                                type="button"
                                variant="ghost"
                                className="hover:bg-muted px-2"
                                onClick={() => {
                                    setToggleTable((prev) => !prev);
                                }}
                            >
                                <TooltipProvider>
                                    {toggleTable ? (
                                        <Tooltip>
                                            <TooltipTrigger asChild>
                                                <ViewVerticalIcon />
                                            </TooltipTrigger>
                                            <TooltipContent className="bg-[#5B5BD6]">
                                                <p>Kanban View</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip>
                                            <TooltipTrigger asChild>
                                                <ViewHorizontalIcon />
                                            </TooltipTrigger>
                                            <TooltipContent className="bg-[#5B5BD6]">
                                                <p>List View</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    )}
                                </TooltipProvider>
                            </Button>
                        )}
                        <CreateIssuePopup
                            topics={topics}
                            userID={userID}
                            users={users}
                            queryClient={queryClient}
                        />
                    </div>
                    <div className="h-full flex-1 flex-col space-y-8 p-8 md:flex">
                        {loadingState === 0 && (
                            <div>
                                <Skeleton>
                                    <Text>
                                        {[...Array(6)].map((_, index) => (
                                            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                            <Text key={index}>
                                                {loremIpsum}
                                            </Text>
                                        ))}
                                    </Text>
                                </Skeleton>
                            </div>
                        )}
                        {loadingState === 1 &&
                            loadingTopicsState === 1 &&
                            loadingUsersState === 1 &&
                            data &&
                            (toggleTable || fromInbox) && (
                                <>
                                    <IssuesList
                                        issues={
                                            fromInbox
                                                ? combinedData.filter(
                                                      (issue) =>
                                                          issue.assignee_user_id ===
                                                          userID,
                                                  )
                                                : combinedData
                                        }
                                        topics={topics}
                                        topicsMap={topicsMap}
                                        userID={userID}
                                        users={users}
                                        fromInbox={fromInbox}
                                    />
                                </>
                            )}
                        {loadingState === 1 &&
                            data &&
                            !toggleTable &&
                            !fromInbox && (
                                <>
                                    <AdminProductFeedbackPage
                                        issues={combinedData}
                                        users={users}
                                    />
                                </>
                            )}
                        {loadingState === 2 && (
                            <Callout.Root
                                size="1"
                                variant="outline"
                                color="red"
                            >
                                <Callout.Text>
                                    Sorry, something's wrong! Please notify us
                                    at support@askassembly.app.
                                </Callout.Text>
                            </Callout.Root>
                        )}
                    </div>
                </Box>
            </Flex>
        </div>
    );
};

export default memo(AdminQueriesPage, areEqual);
