import { Autocomplete, TextField } from "@mui/material";
import { CheckCircledIcon, LoopIcon } from "@radix-ui/react-icons";
import type React from "react";

import { useState } from "react";

interface Option {
    disabled: boolean;
    label: string;
}
interface DropdownProps {
    dropdownStyle?: React.CSSProperties;
    items: Option[];
    onItemClick: (selectedItems: string[]) => void;
    menuText: string;
}

const MultiSelectSearchableDropdown: React.FC<DropdownProps> = ({
    dropdownStyle,
    items,
    onItemClick,
    menuText,
}) => {
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

    const sortedItems = items.sort((a, b) => a.label.localeCompare(b.label));
    const selectAllOption: Option = {
        disabled: false,
        label: "Select All",
    };
    return (
        <Autocomplete
            multiple
            value={selectedOptions.map((label) => {
                const option: Option = { disabled: true, label: label };
                return option;
            })}
            options={[selectAllOption, ...sortedItems]}
            onChange={(_: any, newValue: Option[]) => {
                const selectedLabels = newValue.map((option) => option.label);
                if (selectedLabels.includes("Select All")) {
                    const allNonSelectedOptions = sortedItems
                        .filter((option) => !option.disabled)
                        .map((option) => option.label);
                    setSelectedOptions(allNonSelectedOptions);
                    onItemClick(allNonSelectedOptions);
                } else {
                    setSelectedOptions(selectedLabels);
                    onItemClick(selectedLabels);
                }
            }}
            getOptionDisabled={(option) => option.disabled === true}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={menuText}
                    placeholder="Select..."
                />
            )}
            renderOption={(props, option) => (
                <li {...props}>
                    {option.disabled && (
                        <CheckCircledIcon
                            style={{ color: "green", marginRight: 10 }}
                        />
                    )}
                    {option.label === "Select All" && (
                        <LoopIcon
                            style={{ color: "bg-[#5B5BD6]", marginRight: 10 }}
                        />
                    )}
                    {option.label}
                </li>
            )}
            style={{ width: 500, ...dropdownStyle }}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) =>
                option.label === value.label
            }
        />
    );
};

export default MultiSelectSearchableDropdown;
