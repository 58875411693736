import type React from "react";
import { useCallback, useState } from "react";
import styled from "styled-components";
const ToggleContainer = styled.div`
  display: flex;
  background: #2d333b;
  border-radius: 4px;
  margin: 6px;
  position: relative;
  height: 2rem; // Fixed height for the container
  width: fit-content; // Fit the content width
`;

const ActiveIndicator = styled.div<{ isActive: boolean }>`
  position: absolute;
  left: ${(props) => (props.isActive ? "45%" : "0")};
  top: 0;
  bottom: 0; // Added to stretch the indicator vertically
  height: 100%; // Stretches the height to fill the parent
  width: ${(props) => (props.isActive ? "55%" : "45%")};
  background: #ffffff;
  border-radius: 5px;
  transition: left 0.3s ease;
  z-index: 0; // Ensure the indicator is behind the text
`;

const ToggleOption = styled.button<{ isActive: boolean }>`
  flex: 1;
  display: flex; // Use flexbox for centering
  align-items: center; // Center vertically
  justify-content: center; // Center horizontally
  border: none;
  background: transparent;
  color: ${(props) => (props.isActive ? "#2d333b" : "#ffffff")};
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
  height: 100%; // Make the buttons fill the ToggleContainer height
  z-index: 1;

  &:focus {
    outline: none;
  }
`;
type Props = {
    onToggle: (isBlameOn: boolean) => void;
};

const CodeBlameToggle: React.FC<Props> = ({ onToggle }) => {
    const [isBlameOn, setIsBlameOn] = useState(false);

    const handleToggle = useCallback(() => {
        const newIsBlameOn = !isBlameOn;
        setIsBlameOn(newIsBlameOn);
        onToggle(newIsBlameOn);
    }, [isBlameOn, onToggle]);

    return (
        <ToggleContainer>
            <ActiveIndicator isActive={isBlameOn} />
            <ToggleOption isActive={!isBlameOn} onClick={handleToggle}>
                Code
            </ToggleOption>
            <ToggleOption isActive={isBlameOn} onClick={handleToggle}>
                Blame
            </ToggleOption>
        </ToggleContainer>
    );
};
export default CodeBlameToggle;
