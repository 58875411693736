import { useEffect, useState } from "react";
import type { SearchBlockProps } from "../../interfaces/SearchBlock";
import type { NodeResponse } from "../../interfaces/serverData";
import Typewriter from "../TypeWriterComponent";

import { MagicWandIcon } from "@radix-ui/react-icons";
import { Box, Button, Card, Flex, Heading } from "@radix-ui/themes";

import { integrationBackEndDataMappingToSvg } from "../../pages/Admin/Integrations/constant";

import { getNodesText } from "./cardUtils";

function IntegrationsLookupComponent(props: SearchBlockProps) {
    const [nodes, setNodes] = useState<NodeResponse[]>([]);

    useEffect(() => {
        if (props.nodes && Object.values(props.nodes)) {
            const allNodes: NodeResponse[] = [];
            // biome-ignore lint/complexity/noForEach: <explanation>
            Object.values(props.nodes)?.forEach((node: NodeResponse | null) => {
                if (node !== null) {
                    allNodes.push(node);
                }
            });

            setNodes(allNodes);
        }
    }, [props.nodes]);

    function openLink(link: string) {
        window.open(link, "_blank");
    }

    return (
        <Box p={"4"} style={{ width: "900px", margin: "auto" }}>
            <Card size="3">
                <Flex direction={"column"} gap="2">
                    {props.bestAnswer && (
                        <Box
                            style={{
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                                paddingBottom: "5px",
                            }}
                        >
                            <MagicWandIcon />
                            <Heading
                                size="3"
                                style={{ paddingLeft: "1%" }}
                                color="iris"
                            >
                                Best Answer
                            </Heading>
                        </Box>
                    )}
                    <Typewriter text={props.summary} />

                    <Box
                        style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                            paddingTop: "10px",
                        }}
                    >
                        <Flex gap="4" wrap="wrap">
                            {nodes.length > 0 && (
                                <Box
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    <Flex gap="4" wrap={"wrap"}>
                                        {nodes.map(
                                            (
                                                n: NodeResponse,
                                                index: number,
                                            ) => {
                                                const SvgIcon =
                                                    integrationBackEndDataMappingToSvg.get(
                                                        n.kind,
                                                    ); // item type

                                                if (!SvgIcon) {
                                                    return null;
                                                }
                                                return (
                                                    <Button
                                                        size="2"
                                                        variant="soft"
                                                        onClick={() => {
                                                            openLink(
                                                                n.url ?? "",
                                                            );
                                                        }}
                                                    >
                                                        <SvgIcon
                                                            style={{
                                                                width: "20px",
                                                                height: "20px",
                                                            }}
                                                        />
                                                        {getNodesText(n)}
                                                    </Button>
                                                );
                                            },
                                        )}
                                    </Flex>
                                </Box>
                            )}
                        </Flex>
                    </Box>
                </Flex>
            </Card>
        </Box>
    );
}

export default IntegrationsLookupComponent;
