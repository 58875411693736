import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    Insight,
    InsightFilters,
    InsightsResponse,
    InsightsWithPaginationResponse,
    TopicCount,
    TopicInfo,
} from "@/interfaces/serverData";
import { saveAIGeneratedInsight } from "@/utilities/methods";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";

// A Hook for grabbing insights related data
export function useInsightsData(userID: string) {
    const api = useApi();
    const [loadingState, setLoadingState] = useState<number>(0); // 0: loading, 1: loaded, 2: error
    const [aiInsights, setAiInsights] = useState<Insight[]>([]);
    const [generatedInsights, setGeneratedInsights] = useState<Insight[]>([]);
    const [topTopics, setTopTopics] = useState<TopicCount[]>([]);
    const [currentTopicIndex, setCurrentTopicIndex] = useState<number>(0);
    const [insightStates, setInsightStates] = useState<Map<string, Insight>>(
        new Map(),
    );
    const [insightsFetched, setInsightsFetched] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const fetchInitiatedRef = useRef<boolean>(false);
    const queryClient = useQueryClient();

    const fetchInsights = async ({
        pageParam = 0,
    }: { pageParam?: number }): Promise<InsightsWithPaginationResponse> => {
        try {
            const response = await api.get(
                URLS.serverUrl + API.getInsightsWithPagination,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    params: {
                        limit: 1000,
                        offset: pageParam,
                    },
                },
            );
            if (response.status === 200) {
                setInsightsFetched(true);
                return response.data.data;
            }
            return { data: [], has_next_page: false, next_cursor: 0 };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return { data: [], has_next_page: false, next_cursor: 0 };
        }
    };

    const {
        data,
        error,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        status,
    } = useInfiniteQuery({
        queryKey: ["insights"],
        queryFn: fetchInsights,
        getNextPageParam: (lastPage) => {
            if (lastPage?.has_next_page) {
                return lastPage.next_cursor;
            }
            return undefined; // No more pages
        },
        initialPageParam: 0,
        refetchInterval: 30000,
        refetchOnWindowFocus: true,
    });
    const combinedData =
        data && Array.isArray(data.pages)
            ? data.pages
                  .filter((page) => page !== null && page !== undefined)
                  .flatMap((page) =>
                      Array.isArray(page.data)
                          ? page.data.filter(
                                (item) => item !== null && item !== undefined,
                            )
                          : [],
                  ) // Filter out null or undefined items in page.data
            : [];
    // Fetch all the data
    useEffect(() => {
        if (hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

    const updateInsightState = (id: string, newState: Partial<Insight>) => {
        setInsightStates((prevState) => {
            const currentState = prevState.get(id) || {
                id: "",
                created_at: "",
                updated_at: "",
                title: "",
                description: "",
                user_id: "",
                status: "New",
                related_issues: [],
                count: 0,
                metadata: "",
                external_issues: [],
                announcements: []
            };
            const updatedState = { ...currentState, ...newState };
            return new Map(prevState).set(id, updatedState);
        });
    };

    const handleSaveAIGeneratedInsight = async (insight: Insight) => {
        try {
            const result = await saveAIGeneratedInsight(
                insight,
                queryClient,
                userID,
                api,
            );
            const newInsightID = result.id;
            const updatedData = queryClient.getQueryData<InsightsResponse>([
                "insights",
            ]);
            const combinedData = updatedData
                ? updatedData.pages
                      .filter((page) => page !== null && page !== undefined)
                      .flatMap((page) =>
                          Array.isArray(page.data)
                              ? page.data.filter(
                                    (item) =>
                                        item !== null && item !== undefined,
                                )
                              : [],
                      )
                : [];

            const newInsightStates = new Map<string, Insight>();
            for (const insight of combinedData) {
                newInsightStates.set(insight.id, insight);
            }
            setInsightStates(newInsightStates);
            if (newInsightID) {
                const newSelectedInsight = newInsightStates.get(newInsightID);
                if (newSelectedInsight) {
                    setAiInsights((prevAiInsights) =>
                        prevAiInsights.filter(
                            (insight) =>
                                insight.title !== newSelectedInsight.title && insight.description != newSelectedInsight.description,
                        ),
                    );
                    setGeneratedInsights((prevGeneratedInsights) =>
                        prevGeneratedInsights.filter(
                            (insight) =>
                                insight.title !== newSelectedInsight.title && insight.description != newSelectedInsight.description,
                        ),
                    );
                }
            }
        } catch (error) {
            console.error("Error saving and refetching insights", error);
        }
    };

    useEffect(() => {
        if (data) {
            const newInsightStates = new Map<string, Insight>();
            for (const insight of combinedData) {
                newInsightStates.set(insight.id, insight);
            }
            setInsightStates(newInsightStates);
            setLoadingState(1);
        }
    }, [data]);

    useEffect(() => {
        api.post(URLS.serverUrl + API.determineTopTopics)
            .then((res) => {
                if (res.status === 200) {
                    const response: TopicCount[] = res.data.data;
                    if (response.length === 0) {
                        setLoadingState(1);
                    }
                    setTopTopics(response);
                } else {
                    setLoadingState(2);
                }
            })
            .catch(() => {
                console.log("System is down.");
                setLoadingState(2);
            });
    }, [api]);

    const fetchProcessedResult = async (insight_filters: InsightFilters) => {
        try {
            const res = await api.post(
                URLS.serverUrl + API.determineTopInsights,
                {
                    filters: insight_filters,
                    saved_insights: combinedData,
                },
            );
            if (res.status === 200) {
                const response: Insight[] = res.data.data;
                if (response != null) {
                    setAiInsights((prevAiInsights) => {
                        const newAiInsights = [...prevAiInsights, ...response];
                        newAiInsights.sort((a, b) => b.count - a.count);
                        return newAiInsights;
                    });
                }
            } else {
                console.error(
                    `Error fetching processed top questions response with filters ${insight_filters}`,
                );
            }
        } catch (error) {
            console.error(
                `Error fetching processed top questions response with filters ${insight_filters}: `,
                error,
            );
        }
    };

    useEffect(() => {
        const fetchInsightsForTopic = async (topic: TopicInfo) => {
            setIsLoading(true);
            const now = new Date();
            const oldestDate = new Date();
            oldestDate.setDate(now.getDate() - 90);
            
            const insight_filters: InsightFilters = {
                topics: [topic],
                exclude_existing_ri: true,
                oldest_updated: oldestDate.toISOString(),
            };
    
            await fetchProcessedResult(insight_filters);
            setIsLoading(false)
        };

        const fetchInsightsNoTopic = async () => {
            setIsLoading(true);
            const now = new Date();
            const oldestDate = new Date();
            oldestDate.setDate(now.getDate() - 90);
            
            const insight_filters: InsightFilters = {
                exclude_existing_ri: true,
                oldest_updated: oldestDate.toISOString(),
            };
    
            await fetchProcessedResult(insight_filters);
            setIsLoading(false)
        };
    
        if (insightsFetched && topTopics.length > 0 && currentTopicIndex < topTopics.length) {
            console.log('iin if case')
            if (currentTopicIndex < 3) {
                // Fetch insights for the first three topics
                fetchInsightsForTopic(topTopics[currentTopicIndex].topic);
                setCurrentTopicIndex((prevIndex) => prevIndex + 1);
            } else if (aiInsights.length < 20 && currentTopicIndex < topTopics.length && !isLoading) {
                // Fetch more insights if we have less than 20 AI insights
                fetchInsightsForTopic(topTopics[currentTopicIndex].topic);
                setCurrentTopicIndex((prevIndex) => prevIndex + 1);
            }
        } else if (insightsFetched && topTopics.length == 0 && currentTopicIndex == 0) {
            fetchInsightsNoTopic()
            setCurrentTopicIndex((prevIndex) => prevIndex + 1);
        }
    }, [aiInsights, insightsFetched, currentTopicIndex]);

    return {
        loadingState,
        aiInsights,
        topTopics,
        insightStates,
        handleSaveAIGeneratedInsight,
        updateInsightState,
        queryClient,
        generatedInsights,
        setGeneratedInsights,
    };
}
