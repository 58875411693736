import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import * as Toggle from "@radix-ui/react-toggle";

import { $createParagraphNode, $createTextNode, $getRoot } from "lexical";

import SparklesIcon from "../../images/icons8-sparkles-48.png";

import ShinyButton from "@/component/shadcn/magicui/shiny-button";
import { loadingTypes } from "@/constant";
import { useEffect, useState } from "react";

interface AIResponsePluginProps {
	className?: string;
	getAIResponse?: () => void;
	ai_response: string;
	isToggled: boolean;
	setIsToggled: React.Dispatch<React.SetStateAction<boolean>>;
	loaded?: boolean;
	aiResponseButton?: boolean;
	setIsSendDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
	setKey?: React.Dispatch<React.SetStateAction<number>>;
	aiResponseCompleted?: number;
}

export function AIResponsePlugin({
	className,
	getAIResponse = () => {},
	ai_response,
	isToggled,
	loaded,
	setIsToggled,
	aiResponseButton,
	setIsSendDisabled = () => {},
	setKey = () => {},
	aiResponseCompleted = loadingTypes.none,
}: AIResponsePluginProps) {
	const [editor] = useLexicalComposerContext();

	const [aiResponse, setAIResponse] = useState<string>(ai_response);

	const updateEditor = () => {
		editor.update(() => {
			const content_used = aiResponse ?? "";
			const root = $getRoot();
			if (isToggled) {
				for (const paragraph of root.getChildren()) {
					const textNode = paragraph.getTextContent();

					if (textNode === aiResponse) {
						paragraph.remove(false);
						break; // Exit the loop once the specific text is found and removed
					}
				}
			} else {
				const paragraph = $createParagraphNode();
				const text = $createTextNode(content_used?.trim());
				paragraph.append(text);
				root.append(paragraph);
				root.selectEnd();
			}

			setIsSendDisabled((content_used?.trim().length ?? -1) > 0);
			setKey((prevKey) => prevKey + 1);
			setIsToggled(!isToggled);
		});
	};

	useEffect(() => {
		setAIResponse(ai_response);
		if (aiResponseCompleted === loadingTypes.loaded) {
			updateEditor();
		}
	}, [aiResponseCompleted, ai_response]);
	return (
		<div>
			<div
				className={`${className} absolute top-0 right-0 mt-2 mr-1 ${aiResponseButton ? "hidden" : ""}`}
			>
				<Toggle.Root
					aria-label="Toggle italic"
					className="flex items-center justify-center rounded text-base leading-4"
					onClick={() => {
						editor.update(async () => {
							if (aiResponse === "") {
								await getAIResponse();
							} else {
								updateEditor();
							}
						});
					}}
					hidden={aiResponseButton}
					asChild
				>
					<div>
						<ShinyButton
							text={
								<div className="flex flex-row gap-2 items-center">
									<p>Ask AI</p>
									<img src={SparklesIcon} alt="" className="h-5 w-5" />
								</div>
							}
							className={`text-[#5e6ad2] pr-2 pl-2 text-xs outline outline-1 outline-iris8 flex py-1 flex-wrap justify-start data-[state=on]:bg-[#eceefb] ${isToggled ? "bg-[#eceefb]" : "bg-white"}`}
						/>
					</div>
				</Toggle.Root>
			</div>
		</div>
	);
}
