import { loadingTypes } from "@/constant";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { OrgInfoResponse } from "@/interfaces/serverData";
import { Box, Button, Callout, Flex, Skeleton, Text } from "@radix-ui/themes";
import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as GmailSvg } from "../../../images/integrations/gmail.svg";
import type { metadata } from "../IntegrationsManagementPage";
import IntegrationHeader from "./IntegrationHeader";

const GoogleIntegration = () => {
    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const [refreshDisabled, setRefreshDisabled] = useState<boolean>(true);

    const [additionalMetadata, setAdditionalMetadata] = useState<metadata>({
        scopes: [],
        indexedOn: "",
    });

    const [loadingState, setLoadingState] = useState<number>(
        loadingTypes.loading,
    );
    const api = useApi();

    const [integrationEnabled, setIntegrationEnabled] =
        useState<boolean>(false);

    useEffect(() => {
        const requestData = {
            types: ["Google"],
        };
        api.post(URLS.serverUrl + API.getItemsByOrgID, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                const temp: metadata = { scopes: [], indexedOn: "" };

                if (res.status === 200) {
                    if (res.data.data) {
                        const orgInfo: OrgInfoResponse = res.data.data;

                        if (orgInfo.Google) {
                            setIntegrationEnabled(true);
                        }
                        if (orgInfo.Google?.scopes) {
                            for (const repo of orgInfo.Google.scopes) {
                                temp.scopes.push(repo.name);
                            }

                            if (orgInfo.Google.date) {
                                const date: Date = parseISO(
                                    orgInfo.Google.date,
                                );
                                const humanReadableDate = format(
                                    date,
                                    "MMMM dd, yyyy hh:mm a",
                                );
                                temp.indexedOn = humanReadableDate;

                                const currentTime: Date = new Date();

                                const difference: number =
                                    currentTime.getTime() - date.getTime();
                                const differenceInHours: number =
                                    difference / (1000 * 60 * 60);

                                const hasNotBeen24Hours: boolean =
                                    differenceInHours <= 24;
                                setRefreshDisabled(hasNotBeen24Hours);
                            }
                        }

                        setAdditionalMetadata(temp);
                        setLoadingState(1);
                    }
                } else {
                    console.log("failed to get results");
                    setLoadingState(2);
                }
            })
            .catch((res) => {
                console.log("failed to get repository");
                setLoadingState(2);
            });
    }, [api]);

    const navigate = useNavigate();

    const googleContinue = () => {
        const client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID;
        const redirect_url = `${process.env.REACT_APP_CALLBACK_URL}admin/integrations/google`;
        const google_url = `https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.send&access_type=offline&include_granted_scopes=true&response_type=code&state=state_parameter_passthrough_value&redirect_uri=${redirect_url}&client_id=${client_id}&approval_prompt=force`;
        window.open(google_url, "_self");
    };

    return (
        <div className="bg-gray-500">
            <Box mt={"5%"} ml={"28%"} mr={"28%"}>
                <Flex direction={"column"} align={"start"} gap="6">
                    <IntegrationHeader
                        integrationType="Google"
                        description="Let Assembly ingest emails sent to your customer support email address"
                        SvgIcon={GmailSvg}
                    />
                    <Flex style={{ width: "100%", justifyContent: "flex-end" }}>
                        {loadingState === loadingTypes.loaded &&
                        !integrationEnabled ? (
                            <Button onClick={googleContinue}>Enable</Button>
                        ) : (
                            <Button disabled={true}>Enabled</Button>
                        )}
                    </Flex>
                </Flex>
                {loadingState === loadingTypes.loading && (
                    <Skeleton>
                        <Text>
                            {[...Array(2)].map((_, index) => (
                                <Text key={null}>{loremIpsum}</Text>
                            ))}
                        </Text>
                    </Skeleton>
                )}
                {loadingState === loadingTypes.error && (
                    <Callout.Root size="1" variant="outline" color="red">
                        <Callout.Text>
                            Sorry, something's wrong! Please notify us at
                            support@askassembly.app.
                        </Callout.Text>
                    </Callout.Root>
                )}
            </Box>
        </div>
    );
};

export default GoogleIntegration;
