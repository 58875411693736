import { Chip, styled } from "@mui/material";
import { assembly_purple, grey } from "../colors";

export const ErrorChip = styled(Chip)({
    color: "red",
    border: "1px solid red",
    marginRight: "5px",
    marginLeft: "5px",
    backgroundColor: "white",
    fontSize: "0.875rem",
    fontWeight: "400",
    "& .MuiChip-icon": {
        color: "inherit",
    },
});

export const SearchBarChip = styled(Chip)({
    borderRadius: "8px 0 0 8px",
    color: "white",
    height: "2.75rem",
    border: `1px solid ${assembly_purple.regularPurple}`,
    backgroundColor: grey[800],
    fontSize: "1rem",
    fontWeight: "400",
    "& .MuiChip-icon": {
        color: "inherit",
    },
});
