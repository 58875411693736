import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import type React from "react";

interface PopupProps {
    open: boolean;
    message: string;
    onDeleteConfirm: () => void;
    onCancel: () => void;
}

const CustomPopup: React.FC<PopupProps> = ({
    open,
    message,
    onDeleteConfirm,
    onCancel,
}) => {
    return (
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Data Deletion"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
};

export default CustomPopup;
