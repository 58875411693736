import type React from "react";
import type { ReactNode } from "react";
import { cloneElement, useRef } from "react";
import { useEffect, useState } from "react";
import type { FilterMetadata } from "../component/SideBar/SideBarComponent";
import SideBarComponent from "../component/SideBar/SideBarComponent";

import {
    ResizableHandle,
    ResizablePanel,
    ResizablePanelGroup,
} from "@/component/shadcn/ui/resizable";
import { NAVBAR_ITEMS } from "../component/SideBar/SideBarComponent";
import { useSearch } from "./SearchContext";

import WorkspaceSideBarComponent, {
    NAVBAR_PREFERENCES_ITEMS,
} from "@/component/SideBar/WorkspaceSideBarComponent";
interface LayoutProps {
    children: ReactNode;
    navBarState: string;
    isAdmin: boolean;
    organizationName: string;
    isWorkspace?: boolean;
    withScroll?: boolean; // default is true
}

const HomePage: React.FC<LayoutProps> = ({
    children,
    navBarState,
    isAdmin,
    organizationName,
    isWorkspace,
    withScroll,
}) => {
    const { searchBarFilters, setSearchBarFilters } = useSearch();

    // Show the selected state if there is one. Otherwise show inbox page for admins and search for non admins
    const [activeButton, setActiveButton] = useState<string>(
        isWorkspace
            ? NAVBAR_PREFERENCES_ITEMS.profile
            : navBarState
              ? navBarState
              : NAVBAR_ITEMS.inbox,
    );

    const MIN_PANEL_WIDTH = 4;
    const [sidebarWidth, setSidebarWidth] = useState<number>(
        Math.max(window.innerWidth * 0.14, MIN_PANEL_WIDTH),
    );

    const sidebarRef = useRef<HTMLDivElement | null>(null);
    const overflowStyle = withScroll === false ? {} : { overflow: "scroll" };

    const handleNavMenu = (menuItem: string) => {
        setActiveButton(menuItem);
    };

    const handleResize = (width: number) => {
        setSidebarWidth(
            Math.max(sidebarRef.current?.offsetWidth ?? width, MIN_PANEL_WIDTH),
        );
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const handleResize = () => {
            if (sidebarRef.current) {
                setSidebarWidth(
                    Math.max(sidebarRef.current.offsetWidth, MIN_PANEL_WIDTH),
                );
            }
        };
        const resizeObserver = new ResizeObserver(handleResize);
        if (sidebarRef.current) {
            resizeObserver.observe(sidebarRef.current);
        }
        handleResize();
        return () => {
            resizeObserver.disconnect();
        };
    }, [sidebarRef.current]);

    useEffect(() => {
        setActiveButton(navBarState);
    }, [navBarState]);

    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const handleToggle = (type: string, value: any) => () => {
        const temp = new Map(searchBarFilters);
        const metadata = temp.get(type);

        temp.forEach((metadata: FilterMetadata, key: string) => {
            if (key !== type) {
                metadata.enabled = false;

                metadata.granularFilters.forEach(
                    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                    (enabled: boolean, key: any) => {
                        metadata.granularFilters.set(key, false);
                    },
                );
            }
        });

        if (
            type === value &&
            metadata !== undefined &&
            metadata.enabled === false
        ) {
            metadata.enabled = !metadata.enabled;
            temp.set(type, metadata);
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
            metadata.granularFilters.forEach((enabled: boolean, key: any) => {
                metadata?.granularFilters.set(key, metadata?.enabled);
            });
        } else if (type !== value && metadata !== undefined) {
            const filters = metadata.granularFilters;
            // biome-ignore lint/style/noNonNullAssertion: <explanation>
            const granularEnabled = filters.get(value)!;
            filters?.set(value, !granularEnabled);
            metadata.granularFilters = filters;
            temp.set(type, metadata);

            metadata.enabled = true;
        }
        setSearchBarFilters(temp);
    };

    return (
        <div className="h-screen flex overflow-hidden">
            <ResizablePanelGroup direction={"horizontal"}>
                <ResizablePanel
                    defaultSize={sidebarWidth}
                    className="bg-muted"
                    onResize={handleResize}
                    minSize={MIN_PANEL_WIDTH}
                >
                    <div ref={sidebarRef}>
                        {isWorkspace ? (
                            <WorkspaceSideBarComponent
                                activeButton={activeButton}
                                setActiveButton={handleNavMenu}
                                organizationName={organizationName}
                                isAdmin={isAdmin}
                                width={sidebarWidth}
                            />
                        ) : activeButton === NAVBAR_ITEMS.search ? (
                            <SideBarComponent
                                activeButton={activeButton}
                                setActiveButton={handleNavMenu}
                                organizationName={organizationName}
                                filters={searchBarFilters}
                                handleToggle={handleToggle}
                                isAdmin={isAdmin}
                                width={sidebarWidth}
                            />
                        ) : (
                            <SideBarComponent
                                activeButton={activeButton}
                                setActiveButton={handleNavMenu}
                                organizationName={organizationName}
                                isAdmin={isAdmin}
                                width={sidebarWidth}
                            />
                        )}
                    </div>
                </ResizablePanel>
                <ResizableHandle className="bg-sidebarBorder w-px" />
                <ResizablePanel
                    defaultSize={window.innerWidth * 0.86}
                    style={{ ...overflowStyle }}
                >
                    <main>
                        {cloneElement(children as React.ReactElement, {
                            searchBarFilters,
                            setSearchBarFilters,
                            isAdmin,
                        })}
                    </main>
                </ResizablePanel>
            </ResizablePanelGroup>
        </div>
    );
};

export default HomePage;
