import {
    ChatBubbleIcon,
    CheckCircledIcon,
    CrossCircledIcon,
    ExclamationTriangleIcon,
    InfoCircledIcon,
    LightningBoltIcon,
    QuestionMarkCircledIcon,
} from "@radix-ui/react-icons";
import {
    Box,
    Card,
    Flex,
    Grid,
    Heading,
    Skeleton,
    Text,
} from "@radix-ui/themes";
import { useEffect, useState } from "react";
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { URLS } from "../../constant";
import { API } from "../../constant";
import { useApi } from "../../interfaces/api";
import type {
    AnalyticsResponse,
    QueriesWithPaginationResponse,
} from "../../interfaces/serverData";
import { useInfiniteQuery } from "@tanstack/react-query";

type BadgeColor =
    | "gray"
    | "gold"
    | "bronze"
    | "brown"
    | "yellow"
    | "amber"
    | "orange"
    | "tomato"
    | "red"
    | "ruby"
    | "crimson"
    | "pink"
    | "plum"
    | "purple"
    | "violet"
    | "iris"
    | "indigo"
    | "blue"
    | "cyan"
    | "teal"
    | "jade"
    | "green"
    | "grass"
    | "lime"
    | "mint"
    | "sky";

const AdminAnalyticsPage = () => {
    const api = useApi();

    function getColor(input: string): BadgeColor {
        switch (input) {
            case "Query":
                return "green";
            case "Feature":
                return "blue";
            case "Bug":
                return "red";
            default:
                return "gray";
        }
    }

    function getIcon(input: string) {
        switch (input) {
            case "Query":
                return (
                    <QuestionMarkCircledIcon
                        color={getColor(input)}
                        style={{
                            marginTop: "5px",
                            marginLeft: "5px",
                            transform: "scale(1.4)",
                        }}
                    />
                );
            case "Feature":
                return (
                    <LightningBoltIcon
                        color={getColor(input)}
                        style={{
                            marginTop: "5px",
                            marginLeft: "5px",
                            transform: "scale(1.4)",
                        }}
                    />
                );
            case "Bug":
                return (
                    <ExclamationTriangleIcon
                        color={getColor(input)}
                        style={{
                            marginTop: "5px",
                            marginLeft: "5px",
                            transform: "scale(1.4)",
                        }}
                    />
                );
            default:
                return (
                    <InfoCircledIcon
                        color={getColor(input)}
                        style={{
                            marginTop: "5px",
                            marginLeft: "5px",
                            transform: "scale(1.4)",
                        }}
                    />
                );
        }
    }

    interface GroupedDataItem {
        date: string;
        count: number;
    }
    const [incomingData, setIncomingData] = useState<AnalyticsResponse>();
    const [chartData, setChartData] = useState<GroupedDataItem[]>();
    const [loadingState, setLoadingState] = useState<number>(0); // 0: loading, 1: loaded, 2: error
    const [chartLoadingState, setChartLoadingState] = useState<number>(0); // 0: loading, 1: loaded, 2: error

    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    useEffect(() => {
        api.get(URLS.serverUrl + API.analytics, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    setIncomingData(res.data.data);
                    setLoadingState(1);
                } else {
                    setLoadingState(2);
                }
            })
            .catch((res) => {
                console.log("System is down.");
                setLoadingState(2);
            });
    }, [api]);

    const convertTimestampToDate = (timestamp: string): string => {
        try {
            const date = new Date(timestamp);
            return date.toISOString().split("T")[0]; // Format as 'YYYY-MM-DD'
        } catch (err) {
            console.log(
                `Could not convert timestamp ${timestamp} to date ISOString, so using original timestamp format. Error: ${err}`,
            );
            return timestamp;
        }
    };

    const fetchQueries = async ({
        pageParam = 0,
    }: { pageParam?: number }): Promise<QueriesWithPaginationResponse> => {
        try {
            const response = await api.get(
                URLS.serverUrl + API.queriesWithPagination,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    params: {
                        limit: 1000,
                        offset: pageParam,
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            setLoadingState(2);
            return { data: [], has_next_page: false, next_cursor: 0 };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return { data: [], has_next_page: false, next_cursor: 0 };
        }
    };

    const {
        data,
        error,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        status,
    } = useInfiniteQuery({
        queryKey: ["queries"],
        queryFn: fetchQueries,
        getNextPageParam: (lastPage) => {
            if (lastPage.has_next_page) {
                return lastPage.next_cursor;
            }
            return undefined; // No more pages
        },
        initialPageParam: 0,
        refetchInterval: 30000,
        refetchOnWindowFocus: true,
    });
    const combinedData =
        data && Array.isArray(data.pages)
            ? data.pages
                  .filter((page) => page !== null && page !== undefined)
                  .flatMap((page) =>
                      Array.isArray(page.data)
                          ? page.data.filter(
                                (item) => item !== null && item !== undefined,
                            )
                          : [],
                  ) // Filter out null or undefined items in page.data
            : [];

    // Fetch all the data
    useEffect(() => {
        if (hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

    useEffect(() => {
        // Function to calculate chart data
        const calculateChartData = () => {
            const parsedData = combinedData.map((item) => ({
                date: convertTimestampToDate(item.created_at),
            }));

            const groupDataByDate = (
                data: { date: string }[],
            ): { [key: string]: GroupedDataItem } => {
                return data.reduce(
                    (acc, curr) => {
                        const date = curr.date;
                        if (!acc[date]) {
                            acc[date] = { date: date, count: 0 };
                        }
                        acc[date].count += 1; // Increment count for each occurrence
                        return acc;
                    },
                    {} as { [key: string]: GroupedDataItem },
                );
            };

            const groupedDataArray: GroupedDataItem[] = Object.values(
                groupDataByDate(parsedData),
            );

            setChartData(groupedDataArray);
            setChartLoadingState(1); // Indicate that data is loaded and processed
        };

        if (status === "success") {
            calculateChartData();
        } else {
            console.log("System is down.");
            setChartLoadingState(2);
        }
    }, [combinedData, status]);

    return (
        <div className="bg-gray-500">
            <Box mt={"5%"} ml={"5%"} mr={"2%"}>
                <Flex direction="column" gap="9">
                    <Card
                        style={{
                            backgroundColor: "#EFF0F3",
                            flex: 1,
                            width: "100%",
                        }}
                    >
                        <Flex direction="column" gap="6">
                            <Heading mb="2" size="5">
                                Analytics
                            </Heading>
                            <Grid columns="6" gap="9">
                                <Card style={{ flex: 1 }}>
                                    <Flex
                                        direction="row"
                                        align="start"
                                        gap="2"
                                        justify="between"
                                    >
                                        <ChatBubbleIcon
                                            color="#5B5BD6"
                                            style={{
                                                marginTop: "5px",
                                                marginLeft: "5px",
                                                transform: "scale(1.4)",
                                            }}
                                        />
                                        <Flex
                                            direction="column"
                                            gap="1"
                                            align="end"
                                        >
                                            {loadingState === 0 && (
                                                <Skeleton>
                                                    <Text
                                                        size="5"
                                                        color="iris"
                                                        weight={"bold"}
                                                    >
                                                        000
                                                    </Text>
                                                </Skeleton>
                                            )}
                                            {loadingState === 1 && (
                                                <Text
                                                    size="5"
                                                    color="iris"
                                                    weight={"bold"}
                                                >
                                                    {incomingData?.count}
                                                </Text>
                                            )}
                                            <Text size="3" color="gray">
                                                Interactions
                                            </Text>
                                        </Flex>
                                    </Flex>
                                </Card>

                                <Card style={{ flex: 1 }}>
                                    <Flex
                                        direction="row"
                                        align="start"
                                        gap="2"
                                        width={"auto"}
                                        justify="between"
                                    >
                                        <CheckCircledIcon
                                            color="green"
                                            style={{
                                                marginTop: "5px",
                                                marginLeft: "5px",
                                                transform: "scale(1.4)",
                                            }}
                                        />
                                        <Flex
                                            direction="column"
                                            gap="1"
                                            align="end"
                                        >
                                            {loadingState === 0 && (
                                                <Skeleton>
                                                    <Text
                                                        size="5"
                                                        color="iris"
                                                        weight={"bold"}
                                                    >
                                                        000
                                                    </Text>
                                                </Skeleton>
                                            )}
                                            {loadingState === 1 && (
                                                <Text
                                                    size="5"
                                                    color="green"
                                                    weight={"bold"}
                                                >
                                                    {incomingData?.resolved}
                                                </Text>
                                            )}
                                            <Text size="3" color="gray">
                                                Resolved
                                            </Text>
                                        </Flex>
                                    </Flex>
                                </Card>

                                <Card style={{ flex: 1 }}>
                                    <Flex
                                        direction="row"
                                        align="start"
                                        gap="2"
                                        width={"auto"}
                                        justify="between"
                                    >
                                        <CrossCircledIcon
                                            color="red"
                                            style={{
                                                marginTop: "5px",
                                                marginLeft: "5px",
                                                transform: "scale(1.4)",
                                            }}
                                        />
                                        <Flex
                                            direction="column"
                                            gap="1"
                                            align="end"
                                        >
                                            {loadingState === 0 && (
                                                <Skeleton>
                                                    <Text
                                                        size="5"
                                                        weight={"bold"}
                                                    >
                                                        000
                                                    </Text>
                                                </Skeleton>
                                            )}
                                            {loadingState === 1 && (
                                                <Text
                                                    size="5"
                                                    color="red"
                                                    weight={"bold"}
                                                >
                                                    {incomingData?.escalated}
                                                </Text>
                                            )}
                                            <Text size="3" color="gray">
                                                Escalated
                                            </Text>
                                        </Flex>
                                    </Flex>
                                </Card>

                                {/* biome-ignore lint/correctness/noUnsafeOptionalChaining: <explanation> */}
                                {incomingData != null ? (
                                    Object.entries(
                                        incomingData?.categories,
                                    ).map(([key, val]) => (
                                        <Card style={{ flex: 1 }} key={key}>
                                            <Flex
                                                direction="row"
                                                align="start"
                                                gap="2"
                                                width={"auto"}
                                                justify="between"
                                            >
                                                {getIcon(key)}
                                                <Flex
                                                    direction="column"
                                                    gap="1"
                                                    align="end"
                                                >
                                                    <Text
                                                        size="5"
                                                        color={getColor(key)}
                                                    >
                                                        {loadingState === 0 && (
                                                            <Skeleton>
                                                                "1"
                                                            </Skeleton>
                                                        )}
                                                        {loadingState === 1 &&
                                                            val}
                                                    </Text>
                                                    <Text size="3" color="gray">
                                                        {key}
                                                    </Text>
                                                </Flex>
                                            </Flex>
                                        </Card>
                                    ))
                                ) : (
                                    <></>
                                )}
                            </Grid>
                        </Flex>
                    </Card>

                    <Card style={{ gridColumn: "span 3", flex: 1 }}>
                        <Flex direction="column">
                            <Text size="3" weight="bold">
                                Chat Messages Submitted
                            </Text>
                            <Text size="3" color="gray">
                                View messages submitted over time.
                            </Text>
                            {chartLoadingState === 0 && (
                                <Skeleton>
                                    <Text size="5" color="iris" weight={"bold"}>
                                        {[...Array(6)].map((elem, index) => (
                                            <Text key={elem}>{loremIpsum}</Text>
                                        ))}
                                    </Text>
                                </Skeleton>
                            )}

                            {chartLoadingState === 1 && (
                                <ResponsiveContainer height={400}>
                                    <LineChart
                                        data={chartData}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="date" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Line
                                            type="monotone"
                                            dataKey="count"
                                            stroke="#5B5BD6"
                                            activeDot={{ r: 8 }}
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            )}
                        </Flex>
                    </Card>
                </Flex>
            </Box>
        </div>
    );
};

export default AdminAnalyticsPage;
