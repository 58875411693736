// api connections

export const URLS = {
	serverUrl: process.env.REACT_APP_SERVER_URL,
};

export const SERVERS = {
	github: "https://api.github.com/",
};

export const version_zero = "/api/v0/";
export const version_one = "/api/v1/";
export const authenticated_requests = `${version_one}auth/`;
export const API = {
	getAccessCode: `${authenticated_requests}get_access_code`,
	auth: `${authenticated_requests}auth`,
	index: `${authenticated_requests}index`,
	getItemsByOrgID: `${authenticated_requests}get_items_by_orgid`,
	update_cookie: `${authenticated_requests}update_cookie`,
	indexStatus: `${authenticated_requests}index_status`,
	getIntegrations: `${authenticated_requests}get_integrations`,
	authenticateIntegration: `${authenticated_requests}authenticate_integration`,
	indexIntegration: `${authenticated_requests}index_integration`,
	getScopes: `${authenticated_requests}get_scopes`,
	// TODO: can remove once pagination is verified with no issues
	queries: `${authenticated_requests}queries`,
	queriesWithPagination: `${authenticated_requests}queries_paginated`,
	// TODO: can remove once pagination is verified with no issues
	queriesWithFilters: `${authenticated_requests}queries_with_filters`,
	analytics: `${authenticated_requests}analytics`,

	saveBotSettingsV2: `${authenticated_requests}bot_settings`,
	getBotSettingsV2: `${authenticated_requests}bot_settings`,
	initializeBot: `${authenticated_requests}bot_settings`,

	saveTeamAlerts: `${authenticated_requests}team_alerts`,
	getTeamAlerts: `${authenticated_requests}team_alerts`,

	saveSLA: `${authenticated_requests}sla`,
	getSLAs: `${authenticated_requests}sla`,

	getWorkflows: `${authenticated_requests}workflows`,
	getWorkflow: `${authenticated_requests}workflows`,
	saveWorkflow: `${authenticated_requests}workflows`,
	updateWorkflow: `${authenticated_requests}workflows`,
	deleteWorkflow: `${authenticated_requests}workflows`,

	saveNotificationSettings: `${authenticated_requests}notifications`,
	getNotifications: `${authenticated_requests}notifications`,
	initializeNotification: `${authenticated_requests}notifications`,

	saveBotSettings: `${authenticated_requests}save_bot_settings`,
	getBotSettings: `${authenticated_requests}get_bot_settings`,
	whoAmI: `${authenticated_requests}whoami`,
	search: `${authenticated_requests}search_natural`,
	describe: `${authenticated_requests}describe`,
	updateConnection: `${authenticated_requests}update_connection`,
	getConnections: `${authenticated_requests}get_connections`,
	getTopics: `${authenticated_requests}get_topics`,
	addTopic: `${authenticated_requests}add_topic`,
	editTopic: `${authenticated_requests}edit_topic`,
	saveTicketTag: `${authenticated_requests}save_ticket_tag`,
	saveTicketTopics: `${authenticated_requests}save_ticket_topics`,
	saveTicketStatus: `${authenticated_requests}save_ticket_status`,
	saveTicket: `${authenticated_requests}ticket`,
	determineTopTopics: `${authenticated_requests}top_topics`,
	determineTopInsights: `${authenticated_requests}top_insights`,
	getTicketInfo: `${authenticated_requests}ticket`,
	getApiKeys: `${authenticated_requests}get_api_keys`,
	createTicket: `${authenticated_requests}ticket/create`,
	createTicketFromUI: `${authenticated_requests}ticket/create_ui`,
	getHistoryRecords: `${authenticated_requests}ticket/get_history_records`,
	publishMessage: `${authenticated_requests}ticket/publish_message`,
	publishExternalIssue: `${authenticated_requests}ticket/publish`,
	getOrg: `${authenticated_requests}get_org`,
	getUser: `${authenticated_requests}get_user`,
	getAllUsers: `${authenticated_requests}get_all_users`,
	changeUserRole: `${authenticated_requests}user_role`,
	saveInsight: `${authenticated_requests}save_insight`,
	getInsightsWithPagination: `${authenticated_requests}get_insights_paginated`,
	getInsightInfo: `${authenticated_requests}insight`,
	similarIssues: `${authenticated_requests}similar_issues`,
	announcement: `${authenticated_requests}announcement`,
	getAnnouncements: `${authenticated_requests}get_announcements`,
	createHistoricalTickets: `${authenticated_requests}historical_tickets`,
	getAIResponse: `${authenticated_requests}generate_ai_response`,

	saveCustomerGroup: `${authenticated_requests}customer_group`,
	getCustomerGroups: `${authenticated_requests}get_customer_groups`,
	getCustomerGroup: `${authenticated_requests}get_customer_group`,
};

export const EXTERNAL_LINKS = {
	privacy_policy: "https://www.askassembly.app/privacy-policy",
	contact: "https://www.askassembly.app/contact",
};

// cookie management

type CookieKeys = {
	[key: string]: string;
};

export const ASSEMBLY_COOKIES: CookieKeys = {
	logged_in: "logged_in",
	indexed: "indexed",
	login_client: "client",
	admin: "admin",
	jira_url: "jira_url",
	jira_reindex: "jira_reindex",
	slack_bot_enabled: "slack_bot_enabled",
	linear_reauth: "linear_reauth",
};

// page states
export const pages = {
	login: "login",
	index: "index",
	search: "search",
};

export const login_clients = {
	GITHUB_CLIENT: "GitHub",
	GITLAB_CLIENT: "GitLab",
};

export enum loadingTypes {
	loading = 0,
	loaded = 1,
	error = 2,
	none = 3,
}
