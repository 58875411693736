"use client";

import type { HistoryResponse } from "@/interfaces/serverData";
import { arraysAreEqual } from "@/utilities/methods";
import type {
    QueryObserverResult,
    RefetchOptions,
} from "@tanstack/react-query";
import { memo, useEffect, useRef } from "react";
import { Action } from "./Action";
import { Comment } from "./MyComment";
import { MyComposer } from "./MyComposer";

const areEqual = (prevProps: RoomProps, nextProps: RoomProps) => {
    return (
        prevProps.source === nextProps.source &&
        prevProps.source_specific_id === nextProps.source_specific_id &&
        prevProps.title === nextProps.title &&
        arraysAreEqual(
            prevProps.threadData ?? [],
            nextProps.threadData ?? [],
        ) &&
        prevProps.update === nextProps.update &&
        prevProps.setUpdate === nextProps.setUpdate &&
        prevProps.ai_response === nextProps.ai_response &&
        prevProps.refetchThreadData === nextProps.refetchThreadData
    );
};

interface RoomProps {
    source: string;
    classNameThread?: string;
    className?: string;
    classNameComposer?: string;
    title: string;
    source_specific_id: string;
    threadData: HistoryResponse[];
    update: boolean;
    setUpdate: React.Dispatch<React.SetStateAction<boolean>>;
    ai_response: string;
    refetchThreadData: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<HistoryResponse[], Error>>;
    showComposer: boolean;
    url?: string;
}

function Room({
    source,
    source_specific_id,
    classNameThread = "",
    className = "",
    classNameComposer = "",
    title,
    threadData,
    update,
    setUpdate,
    ai_response,
    refetchThreadData,
    showComposer,
    url,
}: RoomProps) {
    const list = ["Slack", "Discord", "Gmail", "GitHubTicket", "Web"];
    const renderComment = (comment: HistoryResponse) => {
        switch (comment.type) {
            case "Message":
                return (
                    <Comment
                        key={comment.id}
                        comment={comment}
                        source={source}
                    />
                );
            case "Action":
                return <Action key={comment.id} comment={comment} />;
            default:
                return null; // or you can handle other types if needed
        }
    };

    const scrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (scrollRef.current) {
            const element = scrollRef.current;
            element.scrollTop = element.scrollHeight;
        }
    }, [threadData, source_specific_id]);

    console.log("thread data is ", threadData);

    return (
        <div className={`w-full lb-root flex flex-col relative ${className}`}>
            <div
                ref={scrollRef}
                className={`overflow-y-auto flex-1 ${classNameThread}`}
            >
                {threadData.map((comment) => renderComment(comment))}
            </div>
            <div className={classNameComposer}>
                {showComposer &&
                    list.includes(source) &&
                    source_specific_id !== "" &&
                    source_specific_id !== undefined &&
                    source_specific_id != null && (
                        <MyComposer
                            source={source}
                            source_specific_id={source_specific_id}
                            update={update}
                            setUpdate={setUpdate}
                            title={title}
                            ai_response={ai_response}
                            refetchThreadData={refetchThreadData}
                        />
                    )}
                {url && (
                    <div className="py-2 pb-4 text-xs">
                        <a
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-iris9 hover:text-iris11 hover:underline"
                        >
                            Full Message Thread
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
}

export default memo(Room, areEqual);
