import { loadingTypes } from "@/constant";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { OrgInfoResponse } from "@/interfaces/serverData";
import { PlusIcon, ReloadIcon } from "@radix-ui/react-icons";
import {
    Badge,
    Box,
    Button,
    Callout,
    Card,
    Flex,
    Separator,
    Skeleton,
    Text,
} from "@radix-ui/themes";
import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LinearSvg } from "../../../images/integrations/linear1.svg";
import type { metadata } from "../IntegrationsManagementPage";
import IntegrationHeader from "./IntegrationHeader";

const LinearIntegration = () => {
    function handleReindex(integrationType: string, integrationUrl?: string) {
        if (integrationType === undefined || integrationType === "") {
            return;
        }

        const requestData = {
            type: integrationType,
            is_reindex: true,
        };

        api.post(URLS.serverUrl + API.indexIntegration, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then(async (res) => {
            if (res.status === 200) {
            } else {
                // todo - need to handle this case.
            }
        });
        navigate("/admin/integrations/reindex");
    }

    const addMoreOrganizations = () => {
        navigate("/admin/integrations/add/linear");
    };

    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const [refreshDisabled, setRefreshDisabled] = useState<boolean>(true);

    const [additionalMetadata, setAdditionalMetadata] = useState<metadata>({
        scopes: [],
        indexedOn: "",
    });

    const [loadingState, setLoadingState] = useState<number>(
        loadingTypes.loading,
    );
    const api = useApi();

    const [integrationEnabled, setIntegrationEnabled] =
        useState<boolean>(false);

    useEffect(() => {
        const requestData = {
            types: ["Linear"],
        };
        api.post(URLS.serverUrl + API.getItemsByOrgID, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                const temp: metadata = { scopes: [], indexedOn: "" };

                if (res.status === 200) {
                    if (res.data.data) {
                        const orgInfo: OrgInfoResponse = res.data.data;
                        if (orgInfo.Linear) {
                            setIntegrationEnabled(true);
                        }
                        if (orgInfo.Linear?.scopes) {
                            for (const repo of orgInfo.Linear.scopes) {
                                temp.scopes.push(repo.name);
                            }

                            if (orgInfo.Linear.date) {
                                const date: Date = parseISO(
                                    orgInfo.Linear.date,
                                );
                                const humanReadableDate = format(
                                    date,
                                    "MMMM dd, yyyy hh:mm a",
                                );
                                temp.indexedOn = humanReadableDate;

                                const currentTime: Date = new Date();

                                const difference: number =
                                    currentTime.getTime() - date.getTime();
                                const differenceInHours: number =
                                    difference / (1000 * 60 * 60);

                                const hasNotBeen24Hours: boolean =
                                    differenceInHours <= 24;
                                setRefreshDisabled(hasNotBeen24Hours);
                            }
                        }
                        console.log(temp.scopes?.length);
                        setAdditionalMetadata(temp);
                        setLoadingState(1);
                    }
                } else {
                    console.log("failed to get results");
                    setLoadingState(2);
                }
            })
            .catch((res) => {
                console.log("failed to get repository");
                setLoadingState(2);
            });
    }, [api]);

    const navigate = useNavigate();

    const linearContinue = () => {
        const client_id = process.env.REACT_APP_LINEAR_CLIENT_ID;
        const redirect_url = `${process.env.REACT_APP_CALLBACK_URL}admin/integrations/linear`;
        const state = process.env.REACT_APP_LINEAR_STATE;
        const linear_url = `https://linear.app/oauth/authorize?response_type=code&client_id=${client_id}&redirect_uri=${redirect_url}&state=${state}&scope=read,write,issues:create`;
        window.open(linear_url, "_self");
    };

    return (
        <div className="bg-gray-500">
            <Box mt={"5%"} ml={"28%"} mr={"28%"}>
                <Flex direction={"column"} align={"start"} gap="6">
                    <IntegrationHeader
                        integrationType="Linear"
                        description="Auto-generate or search through all Linear tickets from Slack or Discord"
                        SvgIcon={LinearSvg}
                    />
                    <Flex style={{ width: "100%", justifyContent: "flex-end" }}>
                        {loadingState === loadingTypes.loaded &&
                        !integrationEnabled ? (
                            <Button onClick={linearContinue}>Enable</Button>
                        ) : (
                            <Flex gap="2" direction="column">
                                <Button disabled={true}>Enabled</Button>
                                <Button
                                    size="1"
                                    onClick={linearContinue}
                                    mb={"20px"}
                                >
                                    <ReloadIcon /> Refresh Scopes
                                </Button>
                            </Flex>
                        )}
                    </Flex>
                </Flex>
                {loadingState === loadingTypes.loading && (
                    <Skeleton>
                        <Text>
                            {[...Array(2)].map((_, index) => (
                                <Text key={null}>{loremIpsum}</Text>
                            ))}
                        </Text>
                    </Skeleton>
                )}
                {loadingState === loadingTypes.error && (
                    <Callout.Root size="1" variant="outline" color="red">
                        <Callout.Text>
                            Sorry, something's wrong! Please notify us at
                            support@askassembly.app.
                        </Callout.Text>
                    </Callout.Root>
                )}
                {loadingState === loadingTypes.loaded && integrationEnabled && (
                    <Flex direction={"column"} gap="2" mb="2">
                        <Card
                            style={{
                                paddingRight: "0px",
                                paddingLeft: "0px",
                            }}
                        >
                            <Flex
                                direction={"column"}
                                gap="2"
                                mb="2"
                                justify={"between"}
                            >
                                <Flex
                                    direction={"row"}
                                    justify={"between"}
                                    align={"start"}
                                    style={{
                                        paddingLeft: "20px",
                                        paddingRight: "20px",
                                        paddingTop: "5px",
                                        paddingBottom: "5px",
                                    }}
                                >
                                    <Flex direction={"column"} maxWidth={"75%"}>
                                        <Text size={"2"}>
                                            Organizations Selected
                                        </Text>
                                        <Text size={"1"} color="gray">
                                            Add an organization to index through
                                            and add to your searchable knowledge
                                            base.
                                        </Text>
                                    </Flex>
                                    <Button
                                        variant="outline"
                                        size="1"
                                        onClick={addMoreOrganizations}
                                    >
                                        <PlusIcon width="15" height="15" /> Add
                                        More Organizations
                                    </Button>
                                </Flex>
                                {additionalMetadata.scopes?.length !== 0 && (
                                    <Separator
                                        size={"4"}
                                        mt="1"
                                        mb="1"
                                        style={{ width: "100%" }}
                                    />
                                )}
                                {additionalMetadata.scopes?.length !== 0 && (
                                    <Flex
                                        style={{
                                            paddingRight: "20px",
                                            paddingLeft: "20px",
                                        }}
                                        direction="row"
                                        gap="3"
                                        align="center"
                                        wrap="wrap"
                                    >
                                        {additionalMetadata.scopes.map(
                                            (item) => (
                                                <Badge key={item}>{item}</Badge>
                                            ),
                                        )}{" "}
                                    </Flex>
                                )}
                            </Flex>
                        </Card>
                        {additionalMetadata.indexedOn && (
                            <Flex
                                direction="column"
                                style={{
                                    justifyContent: "flex-end",
                                    alignItems: "end",
                                }}
                                gap="3"
                                mb="5"
                            >
                                <Flex direction={"row"}>
                                    <Text size={"1"}>
                                        Last Updated:{" "}
                                        {additionalMetadata.indexedOn}{" "}
                                    </Text>
                                </Flex>
                            </Flex>
                        )}
                    </Flex>
                )}
            </Box>
        </div>
    );
};

export default LinearIntegration;
