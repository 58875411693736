import {
    Alert,
    AlertDescription,
    AlertTitle,
} from "@/component/shadcn/ui/alert";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "@/component/shadcn/ui/alert-dialog";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { ReactComponent as SlackSvg } from "../../images/integrations/slack.svg";
import { ReactComponent as DiscordSvg } from "../../images/integrations/discord.svg";
import { ReactComponent as GoogleSvg } from "../../images/integrations/gmail.svg";
import { Button } from "@/component/shadcn/ui/button";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "@/component/shadcn/ui/dialog";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    GetTopicsResponse,
    GetUserResponse,
    OrgInfoResponse,
    ScopeResponse,
} from "@/interfaces/serverData";
import {
    AllSidesIcon,
    CheckCircledIcon,
    Cross2Icon,
    DiscordLogoIcon,
    DotsHorizontalIcon,
    ExclamationTriangleIcon,
    Pencil2Icon,
    PlusIcon,
} from "@radix-ui/react-icons";
import { Box, Flex } from "@radix-ui/themes";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import type React from "react";
import { useEffect, useState } from "react";
import WorkflowCard from "./WorkflowCard";
import WorkflowPopup from "./WorkflowPopup";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/component/shadcn/ui/table";
import { CheckIcon, MailIcon, SlackIcon, TrashIcon, XIcon } from "lucide-react";
import { intlFormatDistance } from "date-fns";

export interface Action {
    action: string;
    // biome-ignore lint/complexity/noBannedTypes: <explanation>
    metadata: Object;
}

export interface Workflow {
    id?: string;
    enabled?: boolean;
    name: string;
    when: string;
    if: {
        source: string;
        channels: ScopeResponse[];
        topic: string;
    };
    then: Action[];
    org_id?: string;
    last_triggered?: string;
    created_at?: string;
    updated_at?: string;
}

const Workflows: React.FC = () => {
    const [open, setOpen] = useState(false);
    const [selectedWorkflow, setSelectedWorkflow] = useState<Workflow | null>(
        null,
    );
    const [workflowName, setWorkflowName] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [validationErr, setValidationErr] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [toastText, setToastText] = useState<string>("Updated Settings!");
    const [ToastSymbol, setToastSymbol] =
        useState<React.ElementType>(CheckCircledIcon);

    const topicsQuery = useQuery<
        {
            color: string;
            label: string;
            value: string;
        }[]
    >({
        queryKey: ["topics"],
        queryFn: () =>
            api.get(`${URLS.serverUrl}${API.getTopics}`).then((res) => {
                const topics: GetTopicsResponse[] = res.data.data;

                const newTopics: {
                    color: string;
                    label: string;
                    value: string;
                }[] = [];

                const map = new Map<string, GetTopicsResponse>();

                for (const topic of topics) {
                    newTopics.push({
                        color: topic.color ?? "#9B9EF0",
                        label: topic.id,
                        value: topic.topic_name,
                    });
                    map.set(topic.topic_name, topic);
                }
                return newTopics;
            }),
    });

    const channelsQuery = useQuery<Map<string, ScopeResponse[]>>({
        queryKey: ["channels"],
        queryFn: async () => {
            const theMap = new Map<string, ScopeResponse[]>();

            // Fetching the orgInfo first
            const res = await api.post(
                URLS.serverUrl + API.getItemsByOrgID,
                { types: ["Slack", "Discord", "Google"] },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            );

            if (res.status === 200 && res.data.data) {
                const orgInfo: OrgInfoResponse = res.data.data;
                const apiPromises: Promise<void>[] = [];

                // Handle Slack scopes asynchronously
                if (orgInfo.Slack) {
                    const slackPromise = api
                        .post(
                            URLS.serverUrl + API.getScopes,
                            { type: "Slack" },
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                },
                            },
                        )
                        .then((res) => {
                            const dataItems: ScopeResponse[] = res.data.data;
                            theMap.set("Slack", dataItems);
                        });
                    apiPromises.push(slackPromise);
                }

                // Handle Discord scopes asynchronously
                if (orgInfo.Discord) {
                    const discordPromise = api
                        .post(
                            URLS.serverUrl + API.getScopes,
                            { type: "Discord" },
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                },
                            },
                        )
                        .then((res) => {
                            const dataItems: ScopeResponse[] = res.data.data;
                            theMap.set("Discord", dataItems);
                        });
                    apiPromises.push(discordPromise);
                }

                // Set Google scopes to an empty array
                if (orgInfo.Google) {
                    theMap.set("Google", []);
                }

                // Wait for all asynchronous calls (Slack and Discord) to complete
                await Promise.all(apiPromises);
            }

            return theMap;
        },
    });

    const api = useApi();
    const queryClient = useQueryClient();

    const {
        data: workflowsData,
        isLoading: isLoadingWorkflows,
        error: workflowsError,
    } = useQuery<Workflow[]>({
        queryKey: ["workflows"],
        queryFn: () =>
            api.get(`${URLS.serverUrl}${API.getWorkflows}`).then((res) => {
                const data: Workflow[] = res.data.data;
                return data;
            }),
    });

    const clearWorkflowDetails = () => {
        setSelectedSource(undefined);
        setSelectedChannels([]);
        setSelectedTopic(undefined);
        setSelectedWorkflow(null);
    };

    const addWorkflowMutation = useMutation({
        mutationFn: (newWorkflow: Workflow) =>
            api.post(`${URLS.serverUrl}${API.saveWorkflow}`, newWorkflow),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["workflows"] });
            setOpen(false);
        },
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        onError: (error: any) => {
            setErrorMsg(`Creation failed: ${error.message}`);
            setValidationErr(true);
        },
    });

    const updateWorkflowMutation = useMutation({
        mutationFn: (updatedWorkflow: Workflow) =>
            api.patch(
                `${URLS.serverUrl}${API.updateWorkflow}/${updatedWorkflow.id}`,
                updatedWorkflow,
            ),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["workflows"] });
            setOpen(false);
        },
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        onError: (error: any) => {
            setErrorMsg(`Update failed: ${error.message}`);
            setValidationErr(true);
        },
    });

    const toggleWorkflowMutation = useMutation({
        mutationFn: (workflow: Workflow) =>
            api.patch(
                `${URLS.serverUrl}${API.updateWorkflow}/${workflow.id}`,
                { enabled: !workflow.enabled },
            ),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["workflows"] });
        },
        onError: (error) => {
            console.error("Toggle workflow failed:", error.message);
            setValidationErr(true);
        }
    })

    const deleteWorkflowMutation = useMutation({
        mutationFn: (id: string) => {
            return api.delete(`${URLS.serverUrl}${API.deleteWorkflow}/${id}`); // Make sure this is the right endpoint and id is passed correctly
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["workflows"] });
            setDeleteDialogOpen(false);
        },
        onError: (error) => {
            console.error("Delete workflow failed:", error.message);
        },
    });

    const assignValuesQuery = useQuery<{ users: GetUserResponse[] }>({
        queryKey: ["assignValues"],
        queryFn: () =>
            api.post(`${URLS.serverUrl}${API.getAllUsers}`).then((res) => {
                const users: GetUserResponse[] = res.data.data;
                return { users };
            }),
    });

    const [supportedWhen, setSupportedWhen] = useState<string[]>([
        "Issue is Created",
    ]);
    const [supportedThen, setSupportedThen] = useState<string[]>([
        "Assign Issue To",
    ]);
    const [supportedThenValues, setSupportedThenValues] = useState<
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        Map<string, any[]>
    >(new Map());

    const [supportedFields, setSupportedFields] = useState<string[]>([
        "Source",
        "Channel",
        "Topics",
    ]);
    const [supportedValues, setSupportedValues] = useState<
        Map<string, string[]>
    >(new Map());

    // Function to fetch values for a specific field
    const fetchValuesForField = async (
        field: string,
    ): Promise<[string, string[]]> => {
        // Define URLs for each supported field
        const urlMap: Record<string, string> = {
            Source: "/api/source-values",
            Channel: "/api/channel-values",
            Topics: "/api/tag-values",
        };

        try {
            const { data } = await api.get(urlMap[field]); // Fetch values from the corresponding URL
            return [field, data]; // Return the field and its associated values
        } catch (error) {
            console.error(`Error fetching values for ${field}`, error);
            return [field, []]; // Return an empty array in case of error
        }
    };

    // Fetch the values for each field on component mount
    useEffect(() => {
        const fetchSupportedValues = async () => {
            // Fetch values for all fields in parallel
            const results = await Promise.all(
                supportedFields.map(fetchValuesForField),
            );

            // Create a new Map with the fetched results
            const newMap = new Map<string, string[]>();
            for (const [field, values] of results) {
                newMap.set(field, values);
            }

            // Update state with the new map
            setSupportedValues(newMap);
        };

        fetchSupportedValues();
    }, [supportedFields]);

    const [options, setOptions] = useState<ScopeResponse[]>([]);
    const [icon, setIcon] = useState<React.ElementType>();

    const [selectedSource, setSelectedSource] = useState<string | undefined>(
        undefined,
    );
    const [selectedChannels, setSelectedChannels] = useState<ScopeResponse[]>(
        [],
    );
    const [selectedTopic, setSelectedTopic] = useState<string | undefined>(
        undefined,
    );

    return (
        <div>
            <Box mt={"5%"} ml={"28%"} mr={"28%"}>
                <h2 className="text-2xl font-semibold">Workflows</h2>
                <p className=" text-sm text-gray11">
                    Manage your smart routing workflows
                </p>
                <div className="flex flex-col items-end gap-2">
                    <WorkflowPopup
                        workflow={undefined}
                        triggerElement={
                            <Button
                                onClick={() => {
                                    clearWorkflowDetails(); // Clear the state before creating a new workflow
                                }}
                                className="outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm mb-5"
                                size="sm"
                                variant="outline"
                            >
                                Add Workflow
                                <PlusIcon />
                            </Button>
                        }
                        selectedSource={selectedSource}
                        setSelectedSource={setSelectedSource}
                        options={channelsQuery.data ?? new Map()}
                        supportedFields={supportedFields}
                        setSupportedFields={setSupportedFields}
                        supportedValues={supportedValues}
                        selectedChannels={selectedChannels}
                        setSelectedChannels={setSelectedChannels}
                        selectedTopic={selectedTopic}
                        setSelectedTopic={setSelectedTopic}
                        addWorkflow={addWorkflowMutation}
                        updateWorkflow={updateWorkflowMutation}
                        icon={icon}
                        editing={false}
                        clearWorkflowDetails={clearWorkflowDetails}
                        supportedWhen={supportedWhen}
                        setSupportedWhen={setSupportedWhen}
                        supportedThen={supportedThen}
                        setSupportedThen={setSupportedThen}
                        supportedThenValues={assignValuesQuery}
                        topics={topicsQuery.data ?? []}
                    />
                </div>

                <Dialog
                    open={deleteDialogOpen}
                    onOpenChange={setDeleteDialogOpen}
                >
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>
                                Are you sure you want to delete this workflow?
                            </DialogTitle>
                        </DialogHeader>
                        <div className="flex justify-end gap-2 mt-4">
                            <Button
                                variant="destructive"
                                onClick={() =>
                                    selectedWorkflow &&
                                    deleteWorkflowMutation.mutate(
                                        // biome-ignore lint/style/noNonNullAssertion: <explanation>
                                        selectedWorkflow.id!,
                                    )
                                }
                            >
                                Delete
                            </Button>
                            <Button
                                variant="secondary"
                                onClick={() => setDeleteDialogOpen(false)}
                            >
                                Cancel
                            </Button>
                        </div>
                    </DialogContent>
                </Dialog>

                {isLoadingWorkflows && <div>Loading workflows...</div>}

                {workflowsError && (
                    <Alert variant="destructive">
                        <ExclamationTriangleIcon className="h-4 w-4" />
                        <AlertTitle>Error</AlertTitle>
                        <AlertDescription>
                            Failed to load workflows. Please try again later.
                        </AlertDescription>
                    </Alert>
                )}

                {!isLoadingWorkflows &&
                    !workflowsError &&
                    (!workflowsData || workflowsData.length === 0) && (
                        <p>
                            No workflows found. Create a new workflow to get
                            started.
                        </p>
                    )}

                {workflowsData && workflowsData?.length > 0 && (
                    <Table>
                        <TableHeader>
                            <TableRow key={"headerGroup"}>
                                <TableHead>Name</TableHead>
                                <TableHead>Source</TableHead>
                                <TableHead>Status</TableHead>
                                <TableHead>Actions</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {workflowsData.map((workflow) => (
                                <TableRow key={workflow.id}>
                                    <TableCell key={`${workflow.id}/name`}>
                                        {
                                            <>
                                                <Flex direction="column">
                                                    <p className="text-base">{workflow.name}</p>
                                                    <p className="text-sm text-slate-500">Last triggered {
                                                        workflow.last_triggered ?
                                                            intlFormatDistance(workflow.last_triggered, new Date()) :
                                                            "never"
                                                    }</p>
                                                </Flex>
                                            </>
                                        }
                                    </TableCell>
                                    <TableCell key={`${workflow.id}/source`}>
                                        {workflow.if.source && (
                                            <div className="flex items-center gap-2 size-6">
                                                {workflow.if.source === "Slack" && (<SlackSvg/>)}
                                                {workflow.if.source === "Discord" && (<DiscordSvg/>)}
                                                {workflow.if.source === "Google" && (<GoogleSvg/>)}
                                                {workflow.if.source === "*" && (<AllSidesIcon/>)}
                                            </div>
                                        )}
                                    </TableCell>
                                    <TableCell key={`${workflow.id}/trigger`}>
                                        {workflow.enabled != undefined && workflow.enabled ? (
                                            <div className="bg-green-500 rounded-full p-1 flex items-center justify-center w-[32px]">
                                                <CheckIcon className="text-white w-4 h-4" />
                                            </div>
                                        ) : (
                                            <div className="bg-red-500 rounded-full p-1 flex items-center justify-center w-[32px]">
                                                <XIcon className="text-white w-4 h-4" />
                                            </div>
                                        )}
                                    </TableCell>
                                    <TableCell key={`${workflow.id}/actions`}>
                                        <DropdownMenu>
                                            <DropdownMenuTrigger asChild>
                                                <Button
                                                    variant="ghost"
                                                    className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
                                                >
                                                    <DotsHorizontalIcon className="h-4 w-4" />
                                                </Button>
                                            </DropdownMenuTrigger>
                                            <DropdownMenuContent
                                                align="end"
                                                className="w-[150px] max-h-60 p-0 rounded-md overflow-y-auto"
                                                onClick={(event) => event.stopPropagation()}
                                            >
                                                <DropdownMenuItem asChild>
                                                    <AlertDialog
                                                        open={deleteDialogOpen}
                                                        onOpenChange={setDeleteDialogOpen}
                                                    >
                                                        <AlertDialogTrigger asChild>
                                                            <div className="h-7 px-2 m-1 hover:bg-muted cursor-pointer text-xs flex items-center gap-2">
                                                                <TrashIcon className="h-4 w-4" />
                                                                <p>Delete</p>
                                                            </div>
                                                        </AlertDialogTrigger>
                                                        <AlertDialogContent className="fixed inset-0 z-50 flex items-center justify-center p-5 bg-black bg-opacity-50">
                                                            <div className="bg-white shadow-lg rounded-md py-3 px-7 overflow-auto relative">
                                                                <div>
                                                                    <AlertDialogHeader className="pt-1 justify-left text-left items-left pb-7">
                                                                        <AlertDialogTitle>
                                                                            Are you absolutely sure?
                                                                        </AlertDialogTitle>
                                                                        <AlertDialogDescription>
                                                                            This action cannot be
                                                                            undone. This will
                                                                            permanently delete this
                                                                            workflow.
                                                                        </AlertDialogDescription>
                                                                    </AlertDialogHeader>
                                                                </div>
                                                                <AlertDialogFooter className="justify-end items-end pb-5 flex flex-row gap-4 ">
                                                                    <AlertDialogCancel>
                                                                        Cancel
                                                                    </AlertDialogCancel>
                                                                    <AlertDialogAction
                                                                        onClick={() => {
                                                                            deleteWorkflowMutation.mutate(workflow.id ?? "")
                                                                        }}
                                                                    >
                                                                        Delete
                                                                    </AlertDialogAction>
                                                                    <AlertDialogCancel className="shadow-none absolute top-0 right-2 px-2 py-2 ">
                                                                        <Cross2Icon />
                                                                    </AlertDialogCancel>
                                                                </AlertDialogFooter>
                                                            </div>
                                                        </AlertDialogContent>
                                                    </AlertDialog>
                                                </DropdownMenuItem>
                                                <DropdownMenuItem asChild>
                                                    <WorkflowPopup
                                                        workflow={workflow}
                                                        triggerElement={
                                                            <div className="h-7 px-2 m-1 hover:bg-muted cursor-pointer text-xs flex items-center gap-2">
                                                                <Pencil2Icon className="h-4 w-4" />
                                                                <p>Edit</p>
                                                            </div>
                                                        }
                                                        selectedSource={selectedSource}
                                                        setSelectedSource={setSelectedSource}
                                                        options={
                                                            channelsQuery.data ?? new Map()
                                                        }
                                                        supportedFields={supportedFields}
                                                        setSupportedFields={setSupportedFields}
                                                        supportedValues={supportedValues}
                                                        selectedChannels={selectedChannels}
                                                        setSelectedChannels={
                                                            setSelectedChannels
                                                        }
                                                        selectedTopic={selectedTopic}
                                                        setSelectedTopic={setSelectedTopic}
                                                        addWorkflow={addWorkflowMutation}
                                                        updateWorkflow={updateWorkflowMutation}
                                                        icon={icon}
                                                        editing={true}
                                                        clearWorkflowDetails={
                                                            clearWorkflowDetails
                                                        }
                                                        supportedWhen={supportedWhen}
                                                        setSupportedWhen={setSupportedWhen}
                                                        supportedThen={supportedThen}
                                                        setSupportedThen={setSupportedThen}
                                                        supportedThenValues={assignValuesQuery}
                                                        topics={topicsQuery.data ?? []}
                                                    />
                                                </DropdownMenuItem>
                                                <DropdownMenuItem asChild>
                                                    <div
                                                        onClick={() => {
                                                            toggleWorkflowMutation.mutate(workflow)
                                                        }}
                                                        className="h-7 px-2 m-1 hover:bg-muted cursor-pointer text-xs flex items-center gap-2"
                                                    >
                                                        {workflow.enabled ? <XIcon className="h-4 w-4" /> : <CheckIcon className="h-4 w-4" />}
                                                        <p className="text-xs">{workflow.enabled ? "Disable" : "Enable"}</p>
                                                    </div>
                                                </DropdownMenuItem>
                                            </DropdownMenuContent>
                                        </DropdownMenu>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </Box>
        </div>
    );
};

export default Workflows;
