import { Button } from "@/component/shadcn/ui/button";
import { Card, CardDescription } from "@/component/shadcn/ui/card";
import {
    Command,
    CommandGroup,
    CommandItem,
    CommandList,
} from "@/component/shadcn/ui/command";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/component/shadcn/ui/dialog";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { Input } from "@/component/shadcn/ui/input";
import { Label } from "@/component/shadcn/ui/label";
import { Textarea } from "@/component/shadcn/ui/textarea";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    InsightData,
    Query,
    SimilarIssuesResponse,
} from "@/interfaces/serverData";
import { CheckIcon, Cross2Icon } from "@radix-ui/react-icons";
import * as Toggle from "@radix-ui/react-toggle";
import type { QueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import SparklesIcon from "../images/icons8-sparkles-48.png";
import { Badge } from "@radix-ui/themes";

interface CreateInsightProps {
    userID: string;
    queryClient: QueryClient;
    issues: Query[];
}

export function CreateInsightPopup({
    userID,
    queryClient,
    issues,
}: CreateInsightProps) {
    const api = useApi();
    const ticket_identifier = issues[0]?.ticket_identifier ?? "ASM"
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [relatedIssues, setRelatedIssues] = useState<Map<string, Query>>(
        new Map(),
    );
    const [feedbackMessage, setFeedbackMessage] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredIssues, setFilteredIssues] = useState(issues);
    const [isGenerating, setIsGenerating] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    useEffect(() => {
        if (searchQuery.trim() === "") {
            // If the search query is empty, reset to the full list
            setFilteredIssues(issues);
        } else {
            // Filter the queries based on the search value
            setFilteredIssues(
                issues.filter((issue) =>
                    (issue.title ?? issue.query ?? "")
                        .toLowerCase()
                    || `${issue.ticket_identifier}-${issue.ticket_number ?? issue.number}`
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()),
                ),
            );
        }
    }, [issues]);

    function clearForm() {
        setTitle("");
        setDescription("");
        setRelatedIssues(new Map());
    }

    const handleSubmit = async (event: { preventDefault: () => void }) => {
        event.preventDefault(); // Prevent the default form submission behavior
        setFeedbackMessage("Loading");

        if (title.trim() === "" || description.trim() === "") {
            setFeedbackMessage("Missing Required Fields");
            return;
        }

        const requestData: InsightData = {
            title: title,
            description: description,
            user_id: userID,
            related_issues: Array.from(relatedIssues.keys()),
        };

        api.post(URLS.serverUrl + API.saveInsight, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                console.log(`Created insight ${res.data.data.id} successfully`);
                queryClient.refetchQueries({
                    queryKey: ["insights"],
                    exact: true,
                });
                clearForm();
                setFeedbackMessage("Insight created successfully!");
            } else {
                console.log("Call to update status failed");
                setFeedbackMessage(
                    "Failed to create insight. Please try again.",
                );
            }
        });
    };

    const toggleIssueSelection = (issue: Query) => {
        const newRelatedIssues = new Map<string, Query>(
            Array.from(relatedIssues.entries()),
        );
        if (newRelatedIssues.get(issue.id)) {
            // Delete the issue if it's already in related issues
            newRelatedIssues.delete(issue.id);
        } else {
            // Add the issue if it's not in related issues yet
            newRelatedIssues.set(issue.id, issue);
        }
        setRelatedIssues(newRelatedIssues);
    };

    const handleClick = (id: string) => {
        return () => {
            const url = `/issue/${id}`;
            window.open(url, "_blank");
        };
    };

    const handleSearchChange = (event: { target: { value: string } }) => {
        const searchVal = event.target.value.toLowerCase();
        setSearchQuery(searchVal);

        if (searchVal.trim() === "") {
            // If the search query is empty, reset to the full list
            setFilteredIssues(issues);
        } else {
            // Filter the queries based on the search value
            setFilteredIssues(
                issues.filter((issue) =>
                    (issue.title ?? issue.query ?? "")
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) 
                    || `${issue.ticket_identifier}-${issue.ticket_number ?? issue.number}`
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()),
                ),
            );
        }
    };

    const fetchSimilarIssues = async () => {
        setIsGenerating(() => true);
        setErrorMsg(() => "")
        try {
            const res = await api.post(URLS.serverUrl + API.similarIssues, {
                title: title,
                description: description,
            });
            if (res.status === 200) {
                const response: SimilarIssuesResponse = res.data.data;
                if (response.issues && response.issues.length != 0) {
                    const newRelatedIssues = new Map<string, Query>(
                        Array.from(relatedIssues.entries()),
                    );
                    // biome-ignore lint/complexity/noForEach: <explanation>
                    response.issues.forEach((query) => newRelatedIssues.set(query.id, query),
);
                    setRelatedIssues(newRelatedIssues);
                } else {
                    setErrorMsg("No issues found, please enter a more specific title and/or description then try again")
                }
            } else {
                console.log("Failed to fetch similar issues");
                setErrorMsg(`Please try again, call failed with status ${res.status}`)
            }
        } catch (error) {
            console.log("System is down.");
            setErrorMsg("Please try again, call failed")
        } finally {
            setIsGenerating(false);
        }
    };

    return (
        <Dialog>
            <DialogTrigger asChild>
                <Button
                    className="shadow-md outline outline-1 outline-slate-200 mb-1 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm"
                    size="sm"
                    variant="outline"
                >
                    Create Insight
                </Button>
            </DialogTrigger>
            <DialogContent className="inset-0 z-50 flex items-center justify-center p-5 bg-black bg-opacity-50 max-h-full overflow-auto">
                <div className="bg-white shadow-lg rounded-md p-7 w-1/2 overflow-auto relative">
                    <DialogHeader className="flex flex-col items-start w-full">
                        <DialogTitle>Create Insight</DialogTitle>
                        <DialogDescription>
                            Create your own insight and assign relevant issues.
                        </DialogDescription>
                    </DialogHeader>
                    <form
                        onSubmit={handleSubmit}
                        className="space-y-4 py-4 flex flex-col"
                    >
                        <div className="flex flex-col items-start gap-1">
                            <Label
                                htmlFor="title"
                                className="text-right font-semibold flex"
                            >
                                Title
                                <div className="text-destructive pl-0.5">*</div>
                            </Label>
                            <Input
                                id="title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                className="w-full"
                            />
                        </div>
                        <div className="flex flex-col items-start gap-1">
                            <Label
                                htmlFor="description"
                                className="text-right font-semibold flex"
                            >
                                Description
                                <div className="text-destructive pl-0.5">*</div>
                            </Label>
                            <Textarea
                                id="description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                className="w-full p-2 border rounded-md resize-none"
                                rows={3}
                            />
                        </div>
                        <div className="flex flex-col items-start gap-1">
                            <div className="flex items-center justify-between">
                                <Label
                                    htmlFor="related_issue"
                                    className="text-right font-semibold pr-1"
                                >
                                    Related Issues
                                </Label>

                                <TooltipProvider>
                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                            <Toggle.Root
                                                aria-label="Toggle italic"
                                                className={`px-1 py-1 hover:bg-iris4 flex items-center justify-center rounded bg-white text-base leading-4 ${
                                                    isGenerating
                                                        ? "bg-iris4"
                                                        : ""
                                                }`}
                                                onClick={fetchSimilarIssues}
                                            >
                                                <img
                                                    src={SparklesIcon}
                                                    alt=""
                                                    className="h-6 w-6"
                                                />
                                            </Toggle.Root>
                                        </TooltipTrigger>
                                        <TooltipContent className="bg-[#5B5BD6]">
                                            <p>Add Similar Issues</p>
                                        </TooltipContent>
                                    </Tooltip>
                                </TooltipProvider>
                            </div>
                            {isGenerating && <div className="flex items-center gap-2 px-0.5 pb-1 text-xs flex items-center rounded-lg w-full">
                                Adding issues related to the title and description...
                                <div className="w-3 h-3 border-2 border-t-4 border-primary border-dashed rounded-full animate-spin" />
                            </div>}
                            {!isGenerating && errorMsg != "" && <div className="flex items-center gap-2 px-0.5 pb-1 text-xs flex items-center rounded-lg w-full">
                                {errorMsg}
                            </div>}
                            <div className="w-full">
                            <DropdownMenu>
                                <DropdownMenuTrigger
                                    asChild
                                    className="hover:bg-muted max-w-full w-auto"
                                    type="button"
                                >
                                    <div className="flex flex-wrap w-full">
                                        <Button
                                            variant="ghost"
                                            className="flex flex-wrap justify-start p-0 min-w-0 max-w-full data-[state=open]:bg-muted overflow-hidden"
                                        >
                                            {relatedIssues.size > 0 ? (
                                                Array.from(
                                                    relatedIssues.keys(),
                                                ).map((id) => {
                                                    let ri = relatedIssues.get(id)
                                                    let org_specific_id = `${ri?.ticket_identifier ?? ticket_identifier}-${ri?.ticket_number ?? ri?.number}`
                                                    return (
                                                    <div
                                                        key={id}
                                                        className="p-1"
                                                    >
                                                        <Card className="rounded hover:bg-secondary max-w-full overflow-hidden" onClick={handleClick(org_specific_id)}>
                                                            <CardDescription className="p-1 text-xs w-full overflow-hidden whitespace-nowrap text-ellipsis">
                                                                {ri?.title ?? ri?.query}
                                                            </CardDescription>
                                                        </Card>
                                                    </div>
                                                )})
                                            ) : (
                                                <div className="p-1.5 border border-sidebarBorder rounded-md text-xs bg-secondary">
                                                    Search by title to find
                                                    related issues
                                                </div>
                                            )}
                                        </Button>
                                    </div>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent
                                    align="start"
                                    className="fixed w-[450px] max-h-60 p-0 bg-muted rounded-md shadow-lg"
                                >
                                    <div className="p-2">
                                        <input
                                            type="text"
                                            placeholder="Search..."
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                            className="w-full p-1.5 border rounded-md text-xs"
                                        />
                                    </div>
                                    <div className="max-h-52">
                                        <Command className="bg-muted">
                                            <CommandList>
                                                <CommandGroup>
                                                    {filteredIssues.length >
                                                    0 ? (
                                                        filteredIssues.map(
                                                            (query) => {
                                                                const isSelected =
                                                                    relatedIssues.has(
                                                                        query.id,
                                                                    );
                                                                return (
                                                                    <CommandItem
                                                                        key={
                                                                            query.id
                                                                        }
                                                                        onSelect={() =>
                                                                            toggleIssueSelection(
                                                                                query,
                                                                            )
                                                                        }
                                                                        className="py-1 hover:bg-secondary cursor-pointer text-xs"
                                                                    >
                                                                        <div
                                                                            className={`mr-2 flex h-3 w-3 items-center justify-center rounded-sm border border-primary ${
                                                                                isSelected
                                                                                    ? "bg-primary text-primary-foreground"
                                                                                    : "opacity-50"
                                                                            }`}
                                                                        >
                                                                            {isSelected && (
                                                                                <CheckIcon className="h-3 w-3" />
                                                                            )}
                                                                        </div>
                                                                        <Badge
                                                                            color="iris"
                                                                            size="1"
                                                                            radius="full"
                                                                            className="px-2 py-1 rounded-xl text-xs mx-1"
                                                                        >
                                                                            {`${query.ticket_identifier}-${query.ticket_number ?? query.number}`}
                                                                        </Badge>
                                                                        {query.title
                                                                            ? query.title
                                                                            : query.query}
                                                                    </CommandItem>
                                                                );
                                                            },
                                                        )
                                                    ) : (
                                                        <div className="p-2 text-xs text-gray-500">
                                                            No results found
                                                        </div>
                                                    )}
                                                </CommandGroup>
                                            </CommandList>
                                        </Command>
                                    </div>
                                </DropdownMenuContent>
                            </DropdownMenu>
                            </div>
                        </div>

                        <DialogFooter>
                            <div className="flex flex-col gap-1 mx-1">
                            <Button type="submit" className="w-12">Submit</Button>
                            {feedbackMessage && (
                                <p
                                    className={`mt-1 text-right italic text-xs ${
                                        feedbackMessage.includes("Failed") ||
                                        feedbackMessage.includes("Missing")
                                            ? "destructive"
                                            : "text-primary"
                                    }`}
                                >
                                    {feedbackMessage}
                                </p>
                            )}
                            </div>

                            <DialogClose asChild>
                                <Button
                                    type="button"
                                    variant="secondary"
                                    className="absolute top-4 right-4"
                                >
                                    <Cross2Icon />
                                </Button>
                            </DialogClose>
                        </DialogFooter>
                    </form>
                </div>
            </DialogContent>
        </Dialog>
    );
}
