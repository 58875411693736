const NotFound = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
            }}
        >
            Oops! Please navigate back, or try logging in again. If problem persists, please contact us at support@askassembly.app. 
        </div>
    );
};

export default NotFound;
