import TicketDisplay from "@/Ticket/TicketDisplay";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { HistoryResponse, Ticket } from "@/interfaces/serverData";
import { Box, Callout, Flex, Skeleton, Text } from "@radix-ui/themes";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

interface AdminTicketPageProps {
    identifier: string;
    userID: string;
    fromInbox: boolean;
}

const AdminTicketPage: React.FC<AdminTicketPageProps> = ({
    identifier,
    userID,
    fromInbox,
}) => {
    const api = useApi();
    const [loadingState, setLoadingState] = useState<number>(0); // 0: loading, 1: loaded, 2: error
    const [loadingThreadState, setLoadingThreadState] = useState<number>(0);
    const { data: ticketData, refetch: refetchTicketData } = useQuery({
        queryKey: ["ticket", identifier, userID],
        queryFn: () => fetchTicket(),
        refetchInterval: 10000, // refetch every 10 secs
        refetchOnWindowFocus: true,
    });
    const { data: threadData = [], refetch: refetchThreadData } = useQuery({
        queryKey: ["ticket_thread", identifier],
        queryFn: () => fetchTicketThread(),
        refetchInterval: 10000, // refetch every 10 secs
        refetchOnWindowFocus: true,
    });
    const [generateAIResponse, setGenerateAIResponse] =
        useState<boolean>(false);
    const [aiResponse, setAIResponse] = useState<string>("");

    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const fetchTicket = async (): Promise<Ticket | null> => {
        const response = await api.get(
            `${URLS.serverUrl}${API.getTicketInfo}/${identifier}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            },
        );
        if (response.status === 200) {
            const ticketData: Ticket = response.data.data;
            if (
                ticketData.ai_response === undefined ||
                ticketData.ai_response === ""
            ) {
                setGenerateAIResponse(true);
            } else {
                setAIResponse(ticketData.ai_response);
            }
            setLoadingState(1);
            return ticketData;
        } else {
            setLoadingState(2);
            return null;
        }
    };

    const fetchTicketThread = async (): Promise<HistoryResponse[]> => {
        const response = await api.post(
            URLS.serverUrl + API.getHistoryRecords,
            {
                identifier: identifier,
                parent_id: "",
                latest_timestamp: "",
            },
        );
        if (response.status === 200) {
            setLoadingThreadState(1);
            return response.data.data;
        } else {
            return [];
        }
    };

    type searchResponse = {
        id: string;
        org_id: string;
        kind: string;
    };

    type describeResponse = {
        summary: string;
        confidence: string;
    };

    useEffect(() => {
        // if this is true, generate ai response in background
        if (ticketData === undefined) {
            return;
        }
        if (
            ticketData?.ai_response !== undefined &&
            ticketData.ai_response !== ""
        ) {
            setAIResponse(ticketData.ai_response);
            return;
        }

        if (generateAIResponse && ticketData?.query) {
            const content = ticketData.query;
            const reqData = {
                query: content,
            };

            api.post(URLS.serverUrl + API.search, reqData, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            }).then(async (res) => {
                if (res.status === 200) {
                    const searchResp: searchResponse[] = res.data.data;

                    const objects = [];

                    if (searchResp.length === 0) {
                        return {
                            content: [
                                {
                                    type: "text",
                                    text: "Sorry, don't know how to answer your question. Try asking something else or contacting the support team!",
                                },
                            ],
                        };
                    }

                    for (const searchElem of searchResp) {
                        const elem = {
                            id: searchElem.id,
                            source: searchElem.kind,
                        };
                        objects.push(elem);
                    }
                    const describeReq = {
                        query: content,
                        objects: objects,
                    };

                    api.post(URLS.serverUrl + API.describe, describeReq, {
                        headers: {
                            "Content-Type": "application/json",
                            "Analytics-Id": ticketData.id,
                        },
                    }).then(async (describeData) => {
                        if (describeData.status === 200) {
                            const describeResp: describeResponse =
                                describeData.data.data;
                            setAIResponse(describeResp.summary);
                        }
                    });
                }
            });
        }
    }, [generateAIResponse, ticketData, api]);

    return (
        <div className="overflow-hidden">
            <Flex direction="column" align="center" justify="center">
                <Box
                    height="100%" // Ensure it takes full height of its container
                    width="100%"
                    pl="100"
                >
                    <div className="h-full flex-1 flex-col space-y-8 pb-8  md:flex ">
                        {loadingState === 0 && (
                            <div>
                                <Skeleton>
                                    <Text>
                                        {[...Array(6)].map((_, index) => (
                                            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                            <Text key={index}>
                                                {loremIpsum}
                                            </Text>
                                        ))}
                                    </Text>
                                </Skeleton>
                            </div>
                        )}
                        {loadingState === 1 && ticketData && (
                            <TicketDisplay
                                threadData={threadData}
                                refetchTicketData={refetchTicketData}
                                refetchThreadData={refetchThreadData}
                                loadingThreadState={loadingThreadState}
                                identifier={identifier}
                                analytics={ticketData}
                                external_issues={ticketData.external_issues}
                                userID={userID}
                                ai_response={aiResponse}
                                fromInbox={fromInbox}
                            />
                        )}
                        {loadingState === 2 && (
                            <Callout.Root
                                size="1"
                                variant="outline"
                                color="red"
                            >
                                <Callout.Text>
                                    Sorry, something's wrong! Please notify us
                                    at support@askassembly.app.
                                </Callout.Text>
                            </Callout.Root>
                        )}
                    </div>
                </Box>
            </Flex>
        </div>
    );
};
export default AdminTicketPage;
