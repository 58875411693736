import { Box, styled } from "@mui/material";

import { grey } from "../colors";
export const StyledBox = styled(Box)({
    backgroundColor: "white", // white background
    border: "1px solid #cccccc", // grey border
    borderRadius: "8px", // slightly rounded corners
    padding: "20px", // spacing inside the box
    maxWidth: "60rem", // maximum width of the box
    justifyContent: "center",
});

export const CaptionBox = styled(Box)({
    backgroundColor: "white", // white background
    borderRadius: "8px", // slightly rounded corners
    padding: "1%", // spacing inside the box
    paddingTop: "0.5%",
    paddingBottom: "0.5%",
    maxWidth: "60rem", // maximum width of the box
    justifyContent: "center",
    color: grey[800],
});
