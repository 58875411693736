import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Card, IconButton, Link, Typography, styled } from "@mui/material";
import clipboardCopy from "clipboard-copy";
import { useEffect, useState } from "react";
import type { CodeBlockProps } from "../interfaces/SearchBlock";
import CodeBlameToggle from "./CodeBlameToggle";

const StyledCard = styled(Card)({
    margin: "0.3rem",
    marginLeft: "5rem",
    marginRight: "5rem",
    padding: "0.3rem",
    marginBottom: "2rem",
    backgroundColor: "#2d333b", // GitHub dark theme background color
    color: "#c9d1d9", // GitHub font color for dark theme
    border: "1px solid #444c56", // border color for dark theme
    borderRadius: "6px",
});

const HorizontallyScrollingComponent = styled("div")({
    overflowX: "auto",
    paddingBottom: "3px",
    "::-webkit-scrollbar": {
        height: "6px",
        backgroundColor: "transparent",
    },
    "::-webkit-scrollbar-thumb": {
        backgroundColor: "#434d59",
        borderRadius: "50px",
    },
});

const RepoPath = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flex: 1,
    padding: theme.spacing(0, 1), // Add padding if needed
}));

const FilePath = styled(Typography)({
    fontSize: "0.85rem",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
});

const BranchInfo = styled(Typography)({
    fontSize: "0.85rem",
    textAlign: "right",
    whiteSpace: "nowrap",
});

const CodeLine = styled("div")(({ theme }) => ({
    display: "flex",
    padding: "0.2rem 0",
    maxWidth: "4rem",
    "&:hover": {
        backgroundColor: "#41484f", // hover effect similar to GitHub
    },
}));

const LineNumber = styled("span")({
    display: "inline-block",
    width: "2rem",
    color: "#8b949e", // GitHub line number color for dark theme
    textAlign: "right",
    padding: "0rem 1rem 0rem", // space between line number and code
    userSelect: "none",
    fontSize: "12px",
});

const CodeSnippet = styled("code")({
    letterSpacing: "-0.75px",
    fontFamily:
        'SFMono-Regular, Consolas, "Liberation Mono", Menlo, Courier, monospace',
    whiteSpace: "pre",
    fontSize: "12px",
});

const BlameSnippet = styled("code")({
    letterSpacing: "-1.5px",
    fontFamily:
        'SFMono-Regular, Consolas, "Liberation Mono", Menlo, Courier, monospace',
    whiteSpace: "pre",
    fontSize: "12px",
});

const HeaderWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    backgroundColor: "#434d59", //'#38404a',//'#2d333b', // Dark background for the header
    borderRadius: theme.shape.borderRadius, // Optional: if you want rounded corners
}));

function CodeSnippetComponent(props: CodeBlockProps) {
    const [language, setLanguage] = useState<string>(getLanguage());
    const [codeLines, setCodeLines] = useState<string[]>(
        props.codeBlock.split("\n"),
    );

    const [isCopied, setIsCopied] = useState(false);

    const [displayedFilePath, setDisplayedFilePath] = useState<string>(
        getDisplayedFilePath(),
    );

    const [displayedLines, setDisplayedLines] = useState<string[]>([]);
    const [showFullCode, setShowFullCode] = useState<boolean>(false);

    const [blame, setBlame] = useState(false);
    const handleToggle = (isBlameOn: boolean) => {
        setBlame(isBlameOn);
    };

    function getLanguage(): string {
        const myMap = new Map<string, string>([
            ["py", "python"],
            ["java", "java"],
            ["go", "go"],
            ["c", "c"],
            ["js", "javascript"],
            ["ts", "typescript"],
            ["jsx", "javascript"],
            ["tsx", "typescript"],
            ["cpp", "cpp"],
        ]);

        const filePathArr = props.filePath.split(".");
        const extension = filePathArr[filePathArr.length - 1];

        if (myMap.get(extension)) {
            // biome-ignore lint/style/noNonNullAssertion: <explanation>
            return myMap.get(extension)!;
        } else {
            return "python"; // default it to python at least
        }
    }

    function getDisplayedFilePath(): string {
        const firstSlashIndex = props.filePath.indexOf("/");
        const dividedResponse = props.filePath.substring(firstSlashIndex);
        if (dividedResponse.length > 55) {
            return dividedResponse.substring(0, 55) + "...";
        }
        return dividedResponse;
    }

    const copyToClipboard = () => {
        clipboardCopy(codeLines.join("\n"));
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 4000);
    };

    const toggleCodeDisplay = () => {
        setShowFullCode(!showFullCode);
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (showFullCode) {
            setDisplayedLines(codeLines);
        } else {
            setDisplayedLines(codeLines.slice(0, 5));
        }
    }, [showFullCode, props.codeBlock, codeLines]);

    function openGitLink() {
        // TODO - add here
    }

    const handleExternalLink = (url: string) => {
        window.open(url, "_blank");
    };

    const getMessage = (lineNumber: number) => {
        const message: string | undefined =
            props.githubblame?.[lineNumber]?.Message;

        const time: string | undefined =
            props.githubblame?.[lineNumber]?.CommittedDate;

        const datePadding = 12;
        let readableDate = " ".repeat(9);
        if (time !== undefined) {
            const date = new Date(time);
            readableDate = date.toLocaleDateString();
            readableDate = readableDate.padEnd(datePadding, " ");
        }

        const padEnding = 30;

        // ensure that message is always 10 characters.
        if (message === undefined) {
            return `${readableDate} ${" ".repeat(padEnding)}`;
        } else if (message.length > padEnding - 3) {
            return `${readableDate} ${message.substring(0, padEnding - 3)}...`;
        } else {
            return `${readableDate} ${message.padEnd(padEnding, " ")}`;
        }
    };

    const getCommitLink = (lineNumber: number) => {
        const commitId: string | undefined =
            props.githubblame?.[lineNumber]?.Id;
        if (commitId) {
            const org = `${props.owner}/${props.repo}`;
            const link = `https://github.com/${org}/commit/${commitId}`;
            return link;
        }
        return "";
    };

    return (
        <StyledCard>
            <HeaderWrapper>
                <CodeBlameToggle onToggle={handleToggle} />
                <RepoPath>
                    <FilePath>
                        <Link
                            color="inherit"
                            onClick={() => handleExternalLink(props.link)}
                            underline="hover"
                        >
                            {displayedFilePath}
                        </Link>
                    </FilePath>
                    <BranchInfo>
                        {language} · main
                        <IconButton sx={{ color: "#8b949e" }}>
                            {!showFullCode && (
                                <KeyboardArrowDownIcon
                                    fontSize="small"
                                    onClick={toggleCodeDisplay}
                                />
                            )}
                            {showFullCode && (
                                <KeyboardArrowUpIcon
                                    fontSize="small"
                                    onClick={toggleCodeDisplay}
                                />
                            )}
                        </IconButton>
                    </BranchInfo>
                </RepoPath>
            </HeaderWrapper>

            <HorizontallyScrollingComponent>
                {displayedLines.map((code, index) => (
                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                    <CodeLine key={index}>
                        {blame && (
                            <BlameSnippet sx={{ pr: 3 }}>
                                <Link
                                    color="inherit"
                                    onClick={() =>
                                        handleExternalLink(
                                            getCommitLink(
                                                index + props.startLine,
                                            ),
                                        )
                                    }
                                    underline="hover"
                                >
                                    {getMessage(index + props.startLine)}
                                </Link>
                            </BlameSnippet>
                        )}
                        <LineNumber>{index + props.startLine}</LineNumber>
                        <CodeSnippet className={`language-${language}`}>
                            {code}
                        </CodeSnippet>
                    </CodeLine>
                ))}
            </HorizontallyScrollingComponent>
        </StyledCard>
    );
}

export default CodeSnippetComponent;
