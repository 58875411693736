import { Card } from "@/component/shadcn/ui/card";
import type {
    GetTopicsResponse,
    Insight,
    Query,
    TopicCount,
} from "@/interfaces/serverData";
import { memo, useEffect, useState } from "react";
import SparklesIcon from "../images/icons8-sparkles-48.png";
import { Button } from "@/component/shadcn/ui/button";
import { CaretDownIcon, CaretUpIcon } from "@radix-ui/react-icons";
import { Badge, Box, Dialog } from "@radix-ui/themes";
import { InsightDisplay } from "./InsightDisplay";
import { arraysAreEqual } from "@/utilities/methods";

const areEqual = (prevProps: TopInsightsProps, nextProps: TopInsightsProps) => {
    return (
        arraysAreEqual(
            prevProps.suggestedInsights,
            nextProps.suggestedInsights,
        ) &&
        prevProps.issuesCount === nextProps.issuesCount &&
        prevProps.userID === nextProps.userID &&
        prevProps.handleSaveAIGeneratedInsight ===
            nextProps.handleSaveAIGeneratedInsight &&
        prevProps.updateInsightState === nextProps.updateInsightState &&
        prevProps.topicsMap === nextProps.topicsMap &&
        prevProps.setOnDashboard === nextProps.setOnDashboard &&
        prevProps.generatedIsOpen === nextProps.generatedIsOpen &&
        prevProps.setGeneratedIsOpen === nextProps.setGeneratedIsOpen
    );
};

interface SuggestedInsightCardProps {
    insight: Insight;
    handleSaveAIGeneratedInsight: (insight: Insight) => Promise<void>;
    issues: Query[];
    userID: string;
    topicsMap: Map<string, GetTopicsResponse>;
}

const SuggestedInsightCard = (props: SuggestedInsightCardProps) => {
    const [popUpHeight, setPopUpHeight] = useState<number>(
        window.innerHeight * 0.9,
    );
    // Update the pop upheight when the window resizes
    useEffect(() => {
        const handleResize = () => setPopUpHeight(window.innerHeight * 0.9);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Dialog.Root>
            <Dialog.Trigger>
                <Box>
                    <Card className="p-4 mb-3 bg-background bg-mutedhover:bg-muted rounded-sm">
                        <div className="flex flex-col gap-1 text-sm">
                            <div className="flex items-center gap-1 font-semibold text-[13px]">
                                {props.insight.title}
                                <Badge
                                    color="gray"
                                    size="1"
                                    radius="full"
                                    variant="soft"
                                    className="m-0.5 text-[11px]"
                                >
                                    <div className="flex flex-row items-center">
                                        <CaretUpIcon />
                                        <p className="pl-0.5">
                                            {props.insight.count}
                                        </p>
                                    </div>
                                </Badge>
                            </div>
                            <div className="text-xs text-muted-foreground line-clamp-2">
                                {props.insight.description}
                            </div>
                        </div>
                    </Card>
                </Box>
            </Dialog.Trigger>

            <Dialog.Content style={{ maxHeight: `${popUpHeight}px` }}>
                <Dialog.Description
                    size="2"
                    mb="4"
                    className="pt-3 w-full -mb-20"
                >
                    <InsightDisplay
                        insight={props.insight}
                        userID={props.userID}
                        insightSelectedIsSaved={false}
                        topicsMap={props.topicsMap}
                        handleSaveAIGeneratedInsight={
                            props.handleSaveAIGeneratedInsight
                        }
                    />
                </Dialog.Description>
            </Dialog.Content>
        </Dialog.Root>
    );
};

interface TopInsightsProps {
    suggestedInsights: Insight[];
    issuesCount: number;
    userID: string;
    handleSaveAIGeneratedInsight: (insight: Insight) => Promise<void>;
    updateInsightState: (id: string, newState: Partial<Insight>) => void;
    topicsMap: Map<string, GetTopicsResponse>;
    setOnDashboard: React.Dispatch<React.SetStateAction<boolean>>;
    generatedIsOpen: boolean;
    setGeneratedIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function TopInsights({
    suggestedInsights,
    issuesCount,
    userID,
    handleSaveAIGeneratedInsight,
    updateInsightState,
    topicsMap,
    setOnDashboard,
    generatedIsOpen,
    setGeneratedIsOpen,
}: TopInsightsProps) {
    return (
        <Card className="m-2 py-1 rounded-sm w-full flex flex-col relative">
            <div className="flex items-center justify-between">
                <div className="flex items-center gap-1 px-7 py-2 text-[15px] font-semibold">
                    Suggested AI Insights{" "}
                    <img
                        src={SparklesIcon}
                        alt=""
                        className="h-7 w-7 rounded p-1"
                    />
                </div>
                {generatedIsOpen ? (
                    <Button
                        variant="ghost"
                        className="text-xs p-1 absolute top-2 right-2"
                        onClick={() => setGeneratedIsOpen(false)}
                    >
                        <CaretUpIcon className="w-5 h-5" />
                    </Button>
                ) : (
                    <Button
                        variant="ghost"
                        className="text-xs p-1 absolute top-2 right-2"
                        onClick={() => setGeneratedIsOpen(true)}
                    >
                        <CaretDownIcon className="w-5 h-5" />
                    </Button>
                )}
            </div>
            {generatedIsOpen && suggestedInsights.length === 0 && 
                (issuesCount == 0 ? 
                    (
                        <div className="flex items-center gap-2 px-7 pb-1 text-xs flex items-center rounded-lg w-full">
                            No recent data available, please create issues or index past issues.
                        </div>
                    ): (
                        <div className="flex items-center gap-2 px-7 pb-1 text-xs flex items-center rounded-lg w-full">
                            Generating AI Insights{" "}
                            <div className="w-3 h-3 border-2 border-t-4 border-primary border-dashed rounded-full animate-spin" />
                        </div>
            ))} 
            {generatedIsOpen && (
                <div className="flex flex-col gap-1">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mx-6">
                        {suggestedInsights.length > 0 &&
                            suggestedInsights
                                .slice(0, 3) // Show top 3 AI generated insights
                                .map((insight) => (
                                    <SuggestedInsightCard
                                        insight={insight}
                                        key={insight.id}
                                        handleSaveAIGeneratedInsight={
                                            handleSaveAIGeneratedInsight
                                        }
                                        issues={[]}
                                        userID={userID}
                                        topicsMap={topicsMap}
                                    />
                                ))}
                    </div>
                    <div className="flex justify-end">
                        <Button
                            onClick={() => setOnDashboard(false)}
                            variant="ghost"
                            className="mx-2 -mt-1 pt-0 hover:bg-background text-gray-600 hover:text-gray-900 text-[12px]"
                        >
                            Generate More AI Insights...
                        </Button>
                    </div>
                </div>
            )}
        </Card>
    );
}

export default memo(TopInsights, areEqual);
