import { Grid, LinearProgress } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { pages } from "../../../constant";
import { StyledBox } from "../../../design/Box";
import NavBarComponent from "../../../sharedPages/NavBar";

import { Em, Flex, Text } from "@radix-ui/themes";

const ReIndexingPage = () => {
    const navigate = useNavigate();
    const navigateToIntegrations = useCallback(async () => {
        navigate("/admin/manage_integrations", { replace: true });
    }, [navigate]);

    const [startIndexing, setStartIndexing] = useState<boolean>(true);

    const [jobList, setJobList] = useState<string[]>([]);

    const fetchProgress = useCallback(async () => {
        await new Promise((resolve) => setTimeout(resolve, 30000));
        navigateToIntegrations();
    }, [navigateToIntegrations]);

    useEffect(() => {
        if (startIndexing) {
            fetchProgress();
        }
    }, [startIndexing, fetchProgress]);

    return (
        <div className="bg-gray-500">
            <NavBarComponent state={pages.search} />

            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{ minHeight: "80vh" }}
            >
                <StyledBox>
                    <Flex direction="column" gap="2">
                        <Text
                            size={{
                                sm: "4",
                                initial: "5",
                                md: "5",
                                xl: "6",
                            }}
                            weight="bold"
                        >
                            <b>
                                Please don't close this tab or refresh the page!
                            </b>
                        </Text>
                        <Text
                            size={{
                                sm: "3",
                                initial: "4",
                                md: "4",
                                xl: "4",
                            }}
                        >
                            <Em>Assembling...</Em>
                        </Text>
                        <Text
                            size={{
                                initial: "3",
                                sm: "2",
                                md: "3",
                                xl: "4",
                            }}
                            color="gray"
                            highContrast
                        >
                            This will take a few minutes, about as long as it
                            would take you to grab a cup of coffee!
                        </Text>
                        <LinearProgress />
                    </Flex>
                </StyledBox>
            </Grid>
        </div>
    );
};

export default ReIndexingPage;
