import Clock from "@/component/Timer";

import { Badge, DataList } from "@radix-ui/themes";

import { AssigneeDropdown } from "@/Ticket/AssigneeDropdown";
import CustomerProfile from "@/Ticket/CustomerProfile";
import { ExternalIssuesSection } from "@/Ticket/ExternalIssuesSection";
import InsightsSection from "@/Ticket/InsightsSection";
import InternalNotes from "@/Ticket/InternalNote";
import Room from "@/Ticket/Room";
import { TicketStatusDropdown } from "@/Ticket/TicketStatusDropdown";
import { TicketTopicsDropdown } from "@/Ticket/TicketTopicsDropdown";
import { Button } from "@/component/shadcn/ui/button";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";
import type { HistoryResponse, Ticket } from "@/interfaces/serverData";
import { integrationBackEndDataMappingToSvg } from "@/pages/Admin/Integrations/constant";
import { arraysAreEqual } from "@/utilities/methods";
import { RoomProvider } from "@liveblocks/react/suspense";
import { useAuthInfo } from "@propelauth/react";
import { CalendarIcon, OpenInNewWindowIcon } from "@radix-ui/react-icons";
import { Callout, Flex, Heading, Skeleton, Text } from "@radix-ui/themes";
import { Separator } from "@radix-ui/themes";
import type {
    QueryObserverResult,
    RefetchOptions,
} from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SlackSvg } from "../images/integrations/slack.svg";
function processTitle(ticket: Ticket): string {
    // Older tickets don't have a generated title, just use the query
    if (ticket.title == null) {
        return ticket.query;
    } else if (ticket.title.startsWith('"') && ticket.title.endsWith('"')) {
        // If the generated title is surrounded by quotes, remove the quotes
        return ticket.title.slice(1, -1);
    } else {
        return ticket.title;
    }
}

const areEqual = (prevProps: TicketProps, nextProps: TicketProps) => {
    return (
        arraysAreEqual(prevProps.threadData, nextProps.threadData) &&
        prevProps.refetchTicketData === nextProps.refetchTicketData &&
        prevProps.refetchThreadData === nextProps.refetchThreadData &&
        prevProps.loadingTicketState === nextProps.loadingTicketState &&
        prevProps.loadingThreadState === nextProps.loadingThreadState &&
        prevProps.analytics === nextProps.analytics &&
        prevProps.userID === nextProps.userID &&
        prevProps.ai_response === nextProps.ai_response
    );
};

interface TicketProps {
    analytics: Ticket;
    userID: string;
    ai_response: string;
    threadData: HistoryResponse[];
    loadingTicketState: number;
    loadingThreadState: number;
    refetchTicketData: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<Ticket | null, Error>>;
    refetchThreadData: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<HistoryResponse[], Error>>;
}

function TicketDisplay({
    analytics,
    userID,
    ai_response,
    threadData,
    loadingTicketState,
    loadingThreadState,
    refetchThreadData,
    refetchTicketData,
}: TicketProps) {
    const authInfo = useAuthInfo();
    const [ticketThread, setTicketThread] = useState<HistoryResponse[]>([]);

    const handleClick =
        (id: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
            const url = `/issue/${id}`;
            window.open(url, "_blank");
        };

    useEffect(() => {
        if (threadData && threadData.length === 0) {
            const message: HistoryResponse[] = [
                {
                    id: analytics.id,
                    content: analytics.query,
                    source: analytics.source ?? "",
                    timestamp: analytics.created_at,
                    type: "Message",
                    metadata: "",
                    user_data: {
                        name: "Anonymous",
                    },
                },
            ];
            setTicketThread(message);
        } else {
            setTicketThread(threadData);
        }
    }, [threadData]);
    const [update, setUpdate] = useState<boolean>(false);
    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.";

    const source: string = analytics.source ?? "";
    const SourceSvgImage = useMemo(
        () => integrationBackEndDataMappingToSvg.get(source) ?? SlackSvg,
        [source],
    );
    const created_date = useMemo(() => {
        try {
            const d = new Date(analytics.created_at);
            return d.toLocaleDateString();
        } catch (err) {
            console.log(
                `Could not convert ticket's created at timestamp ${analytics.created_at} to a valid date, so using the original timestamp format. Error: ${err}`,
            );
            return analytics.created_at;
        }
    }, [analytics.created_at]);

    const [orgId, setOrgId] = useState<string>("");
    const navigate = useNavigate();

    useEffect(() => {
        const orgIds = authInfo.orgHelper?.getOrgIds();
        if (orgIds === undefined || orgIds.length !== 1) {
            navigate("/*");
            return;
        }
        setOrgId(orgIds[0]);
    }, [authInfo.orgHelper, navigate]);

    const sidebarLabels = "ml-2 text-sm font-medium";
    return (
        <div className="w-full h-screen overflow-y-auto">
            {loadingTicketState === 0 && (
                <div>
                    <Skeleton>
                        <Text>
                            {[...Array(6)].map((_, index) => (
                                <Text
                                    key={
                                        // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                        index
                                    }
                                >
                                    {loremIpsum}
                                </Text>
                            ))}
                        </Text>
                    </Skeleton>
                </div>
            )}
            {loadingTicketState === 1 && (
                <Flex
                    align="start"
                    direction="column"
                    justify={"start"}
                    className="pl-5 pt-9 w-full"
                >
                    <Heading
                        size="4"
                        weight="bold"
                        align="left"
                        className="flex items-center gap-1 pb-6 ml-1"
                    >
                        <Badge
                            color="iris"
                            size="1"
                            radius="full"
                            className="px-2 py-1 rounded-xl text-sm mr-1"
                        >
                            {`${analytics.ticket_identifier}-${analytics.number}`}
                        </Badge>
                        {processTitle(analytics)}
                        <Button
                            type="button"
                            variant="ghost"
                            className="hover:bg-muted px-1"
                            onClick={handleClick(
                                `${analytics.ticket_identifier}-${analytics.number}`,
                            )}
                        >
                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <OpenInNewWindowIcon />
                                    </TooltipTrigger>
                                    <TooltipContent className="bg-[#5B5BD6]">
                                        <p>Open Issue</p>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                        </Button>
                    </Heading>

                    <div className="ml-2 flex flex-1 flex-col gap-3 rounded-md pr-6 overflow-y-auto overflow-visible">
                        <div className="text-sm font-medium">Properties</div>
                        <DataList.Root size="1">
                            {analytics.breaching !== "" &&
                                analytics.breaching !== null &&
                                analytics.breaching !== undefined && (
                                    <DataList.Item align="center">
                                        <DataList.Label className="text-xs w-4">
                                            SLA
                                        </DataList.Label>
                                        <DataList.Value>
                                            <Clock
                                                targetTime={analytics.breaching}
                                                variant="outline"
                                            />
                                        </DataList.Value>
                                    </DataList.Item>
                                )}
                            <DataList.Item align="center">
                                <DataList.Label className="text-xs w-4">
                                    Owner
                                </DataList.Label>
                                <DataList.Value>
                                    <AssigneeDropdown
                                        ticket={analytics}
                                        userID={userID}
                                        refetchThreadData={refetchThreadData}
                                    />
                                </DataList.Value>
                            </DataList.Item>
                            <DataList.Item align="center">
                                <DataList.Label className="text-xs w-4">
                                    Status
                                </DataList.Label>
                                <DataList.Value>
                                    <TicketStatusDropdown
                                        ticket={analytics}
                                        userID={userID}
                                        refetchThreadData={refetchThreadData}
                                    />
                                </DataList.Value>
                            </DataList.Item>
                            <DataList.Item align="center">
                                <DataList.Label className="text-xs w-4">
                                    Source
                                </DataList.Label>
                                <DataList.Value>
                                    <div className="flex items-center text-xs">
                                        <SourceSvgImage
                                            style={{
                                                width: "20px",
                                                height: "30px",
                                            }}
                                            justify="start"
                                        />
                                        <span className="px-1">
                                            <a
                                                href={analytics.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="text-iris9 hover:text-iris11 hover:underline"
                                            >
                                                {analytics.channel_name.trim() !==
                                                ""
                                                    ? analytics.channel_name
                                                    : analytics.source}
                                            </a>
                                        </span>
                                    </div>
                                </DataList.Value>
                            </DataList.Item>
                            <DataList.Item align="center">
                                <DataList.Label className="text-xs w-4">
                                    Date
                                </DataList.Label>
                                <DataList.Value>
                                    <div className="flex flex-col gap-1 ">
                                        <div className="text-xs flex items-center">
                                            <CalendarIcon
                                                style={{
                                                    width: "20px",
                                                    height: "30px",
                                                }}
                                            />
                                            <span className="px-1">
                                                {created_date}
                                            </span>
                                        </div>
                                    </div>
                                </DataList.Value>
                            </DataList.Item>
                        </DataList.Root>
                        <div className="font-medium text-xs text-gray9 -mb-2">
                            Labels
                        </div>
                        <div className="flex flex-row flex-wrap">
                            <TicketTopicsDropdown
                                ticket={analytics}
                                userID={userID}
                                refetchThreadData={refetchThreadData}
                            />
                        </div>
                        <Separator
                            size="4"
                            className="border border-sidebarBorder"
                        />
                        <div className="font-medium py-1 text-sm">Activity</div>
                        <div>
                            {loadingThreadState === 0 && (
                                <div>
                                    <Skeleton>
                                        <Text>
                                            {[...Array(6)].map((_, index) => (
                                                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                                <Text key={index}>
                                                    {loremIpsum}
                                                </Text>
                                            ))}
                                        </Text>
                                    </Skeleton>
                                </div>
                            )}

                            {loadingThreadState === 2 && (
                                <Callout.Root
                                    size="1"
                                    variant="outline"
                                    color="red"
                                    className="pt-4 w-[50%]"
                                >
                                    <Callout.Text>
                                        Sorry, something's wrong with loading
                                        the thread! Please notify us at
                                        support@askassembly.app.
                                    </Callout.Text>
                                </Callout.Root>
                            )}

                            {loadingThreadState === 1 && ticketThread && (
                                <RoomProvider id={`${orgId}:ticket`}>
                                    <Room
                                        threadData={ticketThread}
                                        source={source}
                                        source_specific_id={
                                            analytics.source_specific_id
                                        }
                                        update={update}
                                        setUpdate={setUpdate}
                                        title={analytics.title}
                                        ai_response={ai_response}
                                        refetchThreadData={refetchThreadData}
                                        showComposer={false}
                                        classNameThread="max-h-[300px]"
                                    />
                                </RoomProvider>
                            )}
                            <div className="py-2 text-xs">
                                <a
                                    href={analytics.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-iris9 hover:text-iris11 hover:underline"
                                >
                                    Full Message Thread
                                </a>
                            </div>
                        </div>
                        {source !== "ChatWidget" && (
                            <>
                                <Separator
                                    size="4"
                                    className="border border-sidebarBorder"
                                />

                                <CustomerProfile
                                    userInfo={
                                        threadData.length > 0
                                            ? threadData[0].user_data
                                            : null
                                    } // User info of first message in the ticket
                                    source={source}
                                    sidebarLabels={sidebarLabels}
                                />
                            </>
                        )}

                        <Separator
                            size="4"
                            className="border border-sidebarBorder"
                        />

                        <ExternalIssuesSection
                            ticket={analytics}
                            userID={userID}
                            sidebarLabels={sidebarLabels}
                        />

                        <Separator
                            size="4"
                            className="border border-sidebarBorder"
                        />

                        <InsightsSection
                            issue={analytics}
                            refetchTicketData={refetchTicketData}
                            sidebarLabels={sidebarLabels}
                        />

                        <Separator
                            size="4"
                            className="border border-sidebarBorder"
                        />

                        <InternalNotes
                            ticketId={analytics.id}
                            internalNote={analytics.internal_note}
                            source={source}
                            userId={userID}
                            sidebarLabels={sidebarLabels}
                        />
                        <div className="h-[100px]" />
                    </div>
                </Flex>
            )}
            {loadingTicketState === 2 && (
                <Callout.Root
                    size="1"
                    variant="outline"
                    color="red"
                    className="pt-4 w-[50%]"
                >
                    <Callout.Text>
                        Sorry, something's wrong with loading the thread! Please
                        notify us at support@askassembly.app.
                    </Callout.Text>
                </Callout.Root>
            )}
        </div>
    );
}

export default memo(TicketDisplay, areEqual);
