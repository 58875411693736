import Cookies from "js-cookie";
import { ASSEMBLY_COOKIES } from "../constant";

export function isLoggedIn(): boolean {
    const isLoggedIn = getCookie(ASSEMBLY_COOKIES.logged_in);
    return isLoggedIn === "true";
}

export function isIndexed(): boolean {
    const isIndexed = getCookie(ASSEMBLY_COOKIES.indexed);
    return isIndexed === "true";
}

export function isAdmin(): boolean {
    const isAdmin = getCookie(ASSEMBLY_COOKIES.admin);
    return isAdmin === "true";
}

export function clearCookie(cookieName: string): void {
    Cookies.remove(cookieName);
}

export function getCookie(cookieName: string): string | undefined {
    return Cookies.get(cookieName);
}

export function setCookie(cookieName: string, value: string) {
    // expires in 60 days.
    const expiryInfo: Cookies.CookieAttributes = {
        expires: 60,
        secure: true,
        sameSite: "strict",
    };
    Cookies.set(cookieName, value, expiryInfo);
}

export function clearAllCookies(): void {
    // biome-ignore lint/complexity/noForEach: <explanation>
    Object.keys(ASSEMBLY_COOKIES).forEach((key) => {
        Cookies.remove(ASSEMBLY_COOKIES[key]);
    });
}
