import { Box, Callout, Flex, Skeleton } from "@radix-ui/themes";
import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { useApi } from "../../interfaces/api";

import { API, URLS } from "@/constant";

import type { Announcement, ScopeResponse } from "@/interfaces/serverData";

import { integrationBackEndDataMappingToSvg } from "../Admin/Integrations/constant";

import { loadingTypes } from "@/constant";

import { AnnouncementsNewDraft } from "./AnnouncementsNewDraft";

// https://codesandbox.io/p/sandbox/markdown-to-slack-converter-iphgn?file=%2Fsrc%2Findex.js%3A9%2C49

export interface BroadcastInfo {
    selectedBroadcast: string;
    icon: React.ElementType | undefined;
    options: ScopeResponse[];
    selectedChannels: ScopeResponse[];
    filters: Map<string, Set<string>>;
}

export const AnnouncementsEditingPage = () => {
    const { id } = useParams<{ id: string }>();
    const api = useApi();
    const [initialAnnouncement, setInitialAnnouncement] =
        useState<Announcement>();
    const [loading, setLoading] = useState<loadingTypes>(loadingTypes.loading);

    useEffect(() => {
        setLoading(loadingTypes.loading);
        api.get(`${URLS.serverUrl}${API.announcement}/${id}`, {
            headers: { Accept: "application/json" },
        })
            .then((res) => {
                if (res.status === 200) {
                    const announcementRes = res.data.data;
                    setInitialAnnouncement(announcementRes);
                    setLoading(loadingTypes.loaded);
                } else {
                    setLoading(loadingTypes.error);
                }
            })
            .catch((error) => {
                console.error("Error checking Slack integration:", error);
                setLoading(loadingTypes.error);
            });
    }, [api, id]);

    const [sourceMetadata, setSourceMetadata] = useState<BroadcastInfo[]>([]);

    const [customerGroups, setCustomerGroups] = useState<ScopeResponse[]>([]);

    const [sourceMetadataLoading, setSourceMetadataLoading] =
        useState<loadingTypes>(loadingTypes.loading);

    useEffect(() => {
        const fetchData = async () => {
            if (!initialAnnouncement) {
                return;
            }

            if (
                initialAnnouncement.metadata === "" ||
                initialAnnouncement.metadata === undefined
            ) {
                setSourceMetadataLoading(loadingTypes.loaded);
                return;
            }

            setCustomerGroups(initialAnnouncement.customer_groups ?? []);

            try {
                const data = JSON.parse(initialAnnouncement.metadata);
                const infos: BroadcastInfo[] = await Promise.all(
                    Object.entries(data).map(
                        async ([serviceName, serviceData]: [
                            string,
                            ScopeResponse[],
                        ]) => {
                            const icon =
                                integrationBackEndDataMappingToSvg.get(
                                    serviceName,
                                );
                            const response = await api.get(
                                `${URLS.serverUrl}${API.getBotSettingsV2}/${serviceName}`,
                                {
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                },
                            );

                            const options: ScopeResponse[] =
                                response.status === 200
                                    ? response.data.data?.asm_ticket_channels ||
                                      []
                                    : [];
                            const selectedChannels: ScopeResponse[] =
                                serviceData;
                            const filters: Map<string, Set<string>> = new Map();

                            return {
                                selectedBroadcast: serviceName,
                                icon,
                                options,
                                selectedChannels,
                                filters,
                            };
                        },
                    ),
                );

                setSourceMetadata(infos);
            } catch (error) {
                setSourceMetadataLoading(loadingTypes.error);
                console.error("Error fetching or parsing data:", error);
            } finally {
                console.log("loader set to loaded");
                setSourceMetadataLoading(loadingTypes.loaded);
            }
        };

        fetchData();
    }, [initialAnnouncement, api, id]);

    if (loading === loadingTypes.loading) {
        return (
            <Flex direction="column" align="center" justify="center">
                <Box
                    mt="7"
                    height="100%" // Ensure it takes full height of its container
                >
                    <Skeleton>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Pellentesque felis tellus, efficitur id convallis a,
                        viverra eget libero. Nam magna erat, fringilla sed
                        commodo sed, aliquet nec magna.
                    </Skeleton>
                </Box>
            </Flex>
        );
    }

    if (loading === loadingTypes.error) {
        return (
            <Flex direction="column" align="center" justify="center">
                <Box
                    mt="7"
                    height="100%" // Ensure it takes full height of its container
                >
                    <Callout.Root size="1" variant="outline" color="red">
                        <Callout.Text>
                            This announcement doesn't exist. Navigate back and
                            try again. Please notify us at
                            support@askassembly.app if the problem persists.
                        </Callout.Text>
                    </Callout.Root>
                </Box>
            </Flex>
        );
    }

    if (
        initialAnnouncement &&
        loading === loadingTypes.loaded &&
        sourceMetadataLoading === loadingTypes.loaded
    ) {
        return (
            <AnnouncementsNewDraft
                id={id}
                announcement={initialAnnouncement}
                sourceFields={sourceMetadata}
                customerGroups={customerGroups}
            />
        );
    }

    return (
        <Flex direction="column" align="center" justify="center">
            <Box
                mt="7"
                height="100%" // Ensure it takes full height of its container
            >
                <Skeleton>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Pellentesque felis tellus, efficitur id convallis a, viverra
                    eget libero. Nam magna erat, fringilla sed commodo sed,
                    aliquet nec magna.
                </Skeleton>
            </Box>
        </Flex>
    );
};
