import { Button } from "@/component/shadcn/ui/button";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { Announcement, Insight, Ticket } from "@/interfaces/serverData";
import {
    AnnouncementsState,
    AnnouncementsType,
} from "@/pages/Announcements/constants";
import type {
    QueryObserverResult,
    RefetchOptions,
} from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

function extractParts(url: string) {
    try {
        const urlObj = new URL(url);
        const pathParts = urlObj.pathname.split("/").filter((part) => part);
        if (url.toLowerCase().includes("linear")) {
            return pathParts.length > 2 ? pathParts[2] : null;
        } else if (url.toLowerCase().includes("github")) {
            return pathParts.length > 3
                ? `${pathParts[1]} ${pathParts[3]}`
                : null;
        } else if (url.toLowerCase().includes("atlassian")) {
            return pathParts.length > 1 ? pathParts[1] : null;
        }
    } catch (error) {
        console.error("Invalid URL or error parsing URL:", error);
        return null;
    }
}

// A ticket or an insight should be inputted
interface AnnouncementSectionProps {
    ticket?: Ticket;
    refetchTicketData?: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<Ticket | null, Error>>;
    insight?: Insight;
    updateInsightState?: (newState: Partial<Insight>) => void;
    userID: string;
    sidebarLabels: string;
}

export const AnnouncementsSection: React.FC<AnnouncementSectionProps> = ({
    ticket,
    refetchTicketData,
    insight,
    updateInsightState,
    userID,
    sidebarLabels,
}) => {
    const api = useApi();
    // TODO: input ExternalIssues[] once all formats can grab the values form the backend
    const announcements: Announcement[] = insight?.announcements ?? [];

    const navigate = useNavigate();
    const createAnnouncement = async () => {
        const requestData = {
            status: AnnouncementsState.draft,
            id: "",
            insight_id: insight?.id,
            announcement_type: AnnouncementsType.replyInThread,
        };

        api.post(URLS.serverUrl + API.announcement, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                const id = res.data.data.id;
                navigate(`/announcements/new/${id}`, {
                    state: { announcement: res.data.data },
                });
            } else {
                console.log("could not create a draft announcement");
            }
        });
    };

    return (
        <div>
            <div className="flex flex-row justify-between items-center w-full pb-2">
                <div className={sidebarLabels}>Announcements</div>
            </div>

            <Button
                variant="secondary"
                className="text-xs px-2 py-1 m-2"
                onClick={createAnnouncement}
            >
                Create Announcement
            </Button>
        </div>
    );
};
