import { tags } from "@/IssuesTable/constants";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { Query, Ticket, TicketTag } from "@/interfaces/serverData";
import { getColorLight } from "@/utilities/methods";
import { ComponentBooleanIcon, TriangleDownIcon } from "@radix-ui/react-icons";
import { Badge } from "@radix-ui/themes";
import { useState } from "react";

interface TicketTagDropdownProps<TData> {
    ticket: Ticket;
    userID: string;
}

export function TicketTagDropdown<TData extends Query>({
    ticket,
    userID,
}: TicketTagDropdownProps<TData>) {
    const api = useApi();
    const [tagSelected, setTagSelected] = useState<string>(ticket.bot_category);

    function saveTag(tag: string) {
        const requestData: TicketTag = {
            id: ticket.id,
            tag: tag,
            source: "Web",
            user_id: userID,
        };
        api.patch(URLS.serverUrl + API.saveTicket, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                setTagSelected(tag);
                console.log(`Updated tag to ${tag} successfully`);
            } else {
                console.log("Call to update tag failed");
            }
        });
    }

    return (
        <DropdownMenu>
            <DropdownMenuTrigger
                asChild
                className="hover:opacity-70 transition-opacity duration-300"
            >
                <Badge
                    color={"gray"}
                    size="2"
                    radius="full"
                    variant="outline"
                    className="m-1 hover:opacity-70 transition-opacity duration-300"
                >
                    <div className="flex flex-row items-center">
                        <ComponentBooleanIcon
                            color={getColorLight(tagSelected)}
                        />
                        <p className="pl-0.5">{tagSelected}</p>
                    </div>
                    <TriangleDownIcon className="h-3 w-3" />
                </Badge>
            </DropdownMenuTrigger>
            <DropdownMenuContent
                side="bottom"
                align="end"
                className="w-[140px] p-2"
            >
                {tags.map((tag) => (
                    <DropdownMenuItem
                        key={tag.value}
                        className="py-1 hover:bg-muted cursor-pointer flex items-center"
                        onSelect={() => saveTag(tag.value)}
                    >
                        <div className="flex flex-row gap-3 items-center">
                            <ComponentBooleanIcon
                                color={getColorLight(tag.value)}
                            />
                            <p>{tag.value}</p>
                        </div>
                    </DropdownMenuItem>
                ))}
            </DropdownMenuContent>
        </DropdownMenu>
    );
}
