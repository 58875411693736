import { Card } from "@/component/shadcn/ui/card";
import { ScrollArea } from "@/component/shadcn/ui/scroll-area";
import type {
    GetTopicsResponse,
    GetUserResponse,
    Query,
} from "@/interfaces/serverData";
import { memo, useCallback, useEffect, useMemo, useState } from "react";

import { IssuesListHeaderCard } from "./IssuesListHeaderCard";

import { HeaderType, statuses } from "./constants";

import FilterBar from "./FilterBar";

import IssuesListCard from "./IssuesListCard";

import { arraysAreEqual } from "@/utilities/methods";
import { VariableSizeList } from "react-window";

const areEqual = (prevProps: IssuesListProps, nextProps: IssuesListProps) => {
    return (
        arraysAreEqual(prevProps.issues, nextProps.issues) &&
        arraysAreEqual(prevProps.topics, nextProps.topics) &&
        prevProps.topicsMap === nextProps.topicsMap &&
        prevProps.userID === nextProps.userID &&
        arraysAreEqual(prevProps.users, nextProps.users) &&
        prevProps.fromInbox === nextProps.fromInbox
    );
};

interface IssuesListProps {
    issues: Query[];
    topics: { color: string; label: string; value: string }[];
    topicsMap: Map<string, GetTopicsResponse>;
    userID: string;
    users: GetUserResponse[];
    fromInbox: boolean;
}

function IssuesList({
    issues,
    topics,
    topicsMap,
    userID,
    users,
    fromInbox,
}: IssuesListProps) {
    const [filteredIssues, setFilteredIssues] = useState<Query[]>(issues);
    const [isExpanded, setResponseExpanded] = useState<Map<string, boolean>>(
        new Map([
            ["Breaching", true],
            ["NeedsResponse", true],
            ["Open", true],
            ["New", true],
            ["Closed", true],
        ]),
    );
    const priorityOrder = [
        "Breaching",
        "NeedsResponse",
        "Open",
        "New",
        "Closed",
    ];
    const [headerIndices, setHeaderIndices] = useState<number[]>([]);
    const [listItems, setListItems] = useState<
        { key: string; content: JSX.Element }[]
    >([]);

    const updateExpanded = useCallback((type: string, value: boolean) => {
        setResponseExpanded((prevMap) => {
            const newMap = new Map(prevMap);
            newMap.set(type, value);
            return newMap;
        });
    }, []);

    const sortedStatuses = useMemo(() => {
        return statuses.sort((a, b) => {
            return (
                priorityOrder.indexOf(a.value) - priorityOrder.indexOf(b.value)
            );
        });
    }, [statuses, priorityOrder]);

    // Calculate height for each section
    const headerHeight = 35;
    const itemSize = 80;

    const getItemSize = useCallback(
        (index: number) => {
            return headerIndices.includes(index) ? headerHeight : itemSize;
        },
        [headerIndices],
    );

    useEffect(() => {
        const newHeaderIndices: number[] = [];
        let index = 0;

        // biome-ignore lint/complexity/noForEach: <explanation>
        sortedStatuses.forEach((status) => {
            const issuesForStatus: Query[] =
                filteredIssues.filter(
                    (issue) => issue.ticket_status === status.value,
                ) ?? [];
            newHeaderIndices.push(index);
            index +=
                1 + (isExpanded.get(status.value) ? issuesForStatus.length : 0);
        });

        setHeaderIndices(newHeaderIndices);
    }, [isExpanded, sortedStatuses, filteredIssues]);

    useEffect(() => {
        let index = 0;
        const newHeaderIndices: number[] = [];
        const newListItems: { key: string; content: JSX.Element }[] = [];

        for (const status of sortedStatuses) {
            const issuesForStatus: Query[] =
                filteredIssues.filter(
                    (issue) => issue.ticket_status === status.value,
                ) ?? [];
            // Add the header card for the current status
            newListItems.push({
                key: `header-${status.value}`,
                content: (
                    <IssuesListHeaderCard
                        key={`header-${status.value}`}
                        title={status.label}
                        value={status.value}
                        header_type={HeaderType.Status}
                        isExpanded={isExpanded}
                        updateExpanded={updateExpanded}
                    />
                ),
            });
            newHeaderIndices.push(index);
            index += 1;

            if (isExpanded.get(status.value)) {
                // Add the issue cards for the current status
                for (const issue of issuesForStatus) {
                    newListItems.push({
                        key: issue.id,
                        content: (
                            <IssuesListCard
                                key={issue.id}
                                issue={issue}
                                topics={topics}
                                topicsMap={topicsMap}
                                userID={userID}
                                users={users}
                                fromInbox={fromInbox}
                            />
                        ),
                    });
                }
                index += issuesForStatus.length;
            }
        }
        // setOverallList(newList);
        setHeaderIndices(newHeaderIndices);
        setListItems(newListItems);
    }, [
        isExpanded,
        sortedStatuses,
        filteredIssues,
        updateExpanded,
        fromInbox,
        topics,
        topicsMap,
        users,
        userID,
    ]);

    return (
        <Card className="m-2 rounded-sm h-screen w-full flex flex-col">
            <div>
                <FilterBar
                    issues={issues}
                    filteredIssues={filteredIssues}
                    setFilteredIssues={setFilteredIssues}
                    topics={topics}
                    users={users}
                />
            </div>
            <ScrollArea className="h-full w-full">
                <VariableSizeList
                    key={headerIndices.join("-")}
                    width={"100%"}
                    height={window.innerHeight}
                    itemCount={listItems.length}
                    itemSize={getItemSize}
                >
                    {({ index, style }) => {
                        const item = listItems[index];
                        return (
                            <div style={style} key={item.key}>
                                {item.content}
                            </div>
                        );
                    }}
                </VariableSizeList>
            </ScrollArea>
        </Card>
    );
}

export default memo(IssuesList, areEqual);
