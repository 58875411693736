import { Input } from "@/component/shadcn/ui/input";
import type { GetUserResponse, Query } from "@/interfaces/serverData";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import FilterDropdown from "./FilterDropdown";
import { arraysAreEqual } from "@/utilities/methods";
import { Filter } from "./Filter";

interface FilterBarProps {
    issues: Query[];
    filteredIssues: Query[];
    setFilteredIssues: React.Dispatch<React.SetStateAction<Query[]>>;
    topics: { color: string; label: string; value: string }[];
    users: GetUserResponse[];
}

function FilterBar({
    issues,
    filteredIssues,
    setFilteredIssues,
    topics,
    users,
}: FilterBarProps) {
    const [filters, setFilters] = useState<Map<string, Set<string>>>(new Map());
    const [searchQuery, setSearchQuery] = useState("");

    const handleFilterSelect = useCallback(
        (type: string, value: string) => () => {
            const newFilters = new Map(filters);
            if (newFilters.has(type)) {
                const currValues = new Set(newFilters.get(type));
                // Value is already in filter, so remove it
                if (currValues?.has(value)) {
                    currValues.delete(value);
                    // Remove the whole filter if there are no more values
                    if (currValues.size === 0) {
                        newFilters.delete(type);
                    } else {
                        newFilters.set(type, currValues);
                    }
                } else {
                    // Add the value since it's not in the filter yet
                    currValues.add(value);
                    newFilters.set(type, currValues);
                }
            } else {
                newFilters.set(type, new Set([value]));
            }
            setFilters(newFilters);
        },
        [filters],
    );
    const memoizedFilters = useMemo(() => filters, [filters]);

    // Sets the parent's filtered issues based on the filters and search
    useEffect(() => {
        let newFilteredIssues = issues;
        const entries = Array.from(filters.entries());
        for (const [type, values] of entries) {
            newFilteredIssues = newFilteredIssues.filter((issue) =>
                shouldInclude(type, values, issue),
            );
        }
        if (searchQuery !== "") {
            newFilteredIssues = newFilteredIssues.filter(
                (issue) =>
                    (issue.title ?? "")
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                    (issue.query ?? "")
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                    `${issue.ticket_identifier}-${issue.ticket_number}`
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()),
            );
        }
        if (!arraysAreEqual(newFilteredIssues, filteredIssues)) {
            setFilteredIssues(newFilteredIssues);
        }
    }, [filteredIssues, setFilteredIssues, issues, searchQuery, filters]);

    function shouldInclude(type: string, values: Set<string>, issue: Query) {
        switch (type) {
            case "Source": {
                return issue.source && values.has(issue.source);
            }
            case "Tag": {
                return values.has(issue.bot_category);
            }
            case "Topic": {
                for (const topic of issue.topic) {
                    if (values.has(topic)) {
                        return true; // At least one topic is in the filter set
                    }
                }
                return false;
            }
            case "Status": {
                return values.has(issue.ticket_status);
            }
            case "Assignee": {
                return (
                    (!issue.assignee_user_id && values.has("noAssignee")) ||
                    (issue.assignee_user_id &&
                        values.has(issue.assignee_user_id))
                );
            }
        }
    }

    return (
        <div className="flex flex-col gap-1 m-1 py-1 bg-muted">
            <div className="flex items-center gap-0 mx-5 mt-2">
                <MagnifyingGlassIcon className="w-4 h-4 text-gray-700" />
                <Input
                    type="text"
                    placeholder={"Search..."}
                    value={searchQuery}
                    onChange={(event) => setSearchQuery(event.target.value)}
                    className="w-full p-1.5 rounded-md text-xs border-none shadow-none focus:outline-none focus:border-transparent"
                />
            </div>
            <div className="flex items-center gap-2 mx-4 mt-0">
                {Array.from(filters.entries()).map(([type, values]) => (
                    <div key={type}>
                        <Filter
                            type={type}
                            values={values}
                            filters={memoizedFilters}
                            setFilters={setFilters}
                            handleItemSelect={handleFilterSelect}
                            topics={topics}
                            users={users}
                        />
                    </div>
                ))}
                <FilterDropdown
                    filters={memoizedFilters}
                    filterOptions={[
                        "Assignee",
                        "Status",
                        "Source",
                        "Tag",
                        "Topic",
                    ]}
                    handleItemSelect={handleFilterSelect}
                    topics={topics}
                    users={users}
                />
            </div>
        </div>
    );
}

export default memo(FilterBar);
