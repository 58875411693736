import type { UserResponse } from "@/interfaces/serverData";
import type { ComponentProps } from "react";
interface AvatarProps extends ComponentProps<"div"> {
    user: UserResponse;
}

export function Avatar({ user, className, ...props }: AvatarProps) {
    function getUserName(user: UserResponse) {
        return user.name ?? user.username ?? "Anonymous";
    }
    return (
        <div className={"lb-avatar w-6 h-6"} {...props}>
            {user.avatar && (
                <img
                    className="lb-avatar-image"
                    src={user.avatar}
                    alt={getUserName(user)}
                />
            )}
            {user.name && user.name.length !== 0 ? (
                <span className="lb-avatar-fallback" aria-hidden>
                    {getUserName(user)[0]}
                </span>
            ) : (
                <span className="lb-avatar-fallback" aria-hidden>
                    A
                </span>
            )}
        </div>
    );
}
