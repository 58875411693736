import { createTheme, styled } from "@mui/material";

export const assemblyTheme = createTheme({
    palette: {
        mode: "light",
        background: {
            default: "#ffffff"//"#fbfbfb",
        },
    },

    typography: {
        fontFamily: "Inter",
        // fontFamily: "'IBM Plex Sans', sans-serif"
    },
});

export const GlobalStyle = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.default, // Use the theme's background color
    fontFamily: theme.typography.fontFamily,
    minHeight: "100vh", // Ensure it covers full viewport height
    width: "100vw", // Ensure it covers full viewport width
}));
