import { Button } from "@/component/shadcn/ui/button";
import {
    Command,
    CommandEmpty,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/component/shadcn/ui/command";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    GetUserResponse,
    Query,
    TicketStatus,
    TicketTag,
    TicketTopics,
} from "@/interfaces/serverData";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import FilterDropdownElement from "./FilterDropdownElement";

interface IssueActionsProps<TData> {
    issueState: Query;
    updateIssueState: (newState: Partial<Query>) => void;
    topics: { color: string; label: string; value: string }[];
    userID: string;
    users: GetUserResponse[];
}

export function IssueActions<TData extends Query>({
    issueState,
    updateIssueState,
    topics,
    userID,
    users,
}: IssueActionsProps<TData>) {
    const api = useApi();
    const filters = new Map<string, Set<string>>();
    filters.set("Tag", new Set([issueState.bot_category]));
    filters.set("Topic", new Set(issueState.topic));
    filters.set("Status", new Set([issueState.ticket_status]));
    if (issueState.assignee_user_id) {
        filters.set("Assignee", new Set([issueState.assignee_user_id]));
    }

    const dropdownOptions = ["Assignee", "Status", "Tag", "Topic"];

    const saveIssue = (type: string, value: string) => () => {
        switch (type) {
            case "Tag":
                return saveTag(value);
            case "Topic":
                return toggleTopicSelection(value);
            case "Status":
                return saveStatus(value);
            case "Assignee":
                return saveAssignee(value);
            default:
                return null;
        }
    };

    function saveTag(tag: string) {
        const requestData: TicketTag = {
            id: issueState.id,
            tag: tag,
            source: "Web",
            user_id: userID,
        };
        api.patch(URLS.serverUrl + API.saveTicket, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                updateIssueState({ bot_category: tag });
                console.log(`Updated tag to ${tag} successfully`);
            } else {
                console.log("Call to update tag failed");
            }
        });
    }

    const toggleTopicSelection = (topic: string) => {
        const isSelected = issueState.topic?.includes(topic);
        let newTopicsSelected: string[];
        let addedTopics: string[] = [];
        let deletedTopics: string[] = [];

        if (isSelected) {
            newTopicsSelected =
                issueState.topic?.filter((t) => t !== topic) || [];
            deletedTopics = [topic];
        } else {
            newTopicsSelected = [...(issueState.topic || []), topic];
            addedTopics = [topic];
        }
        updateIssueState({ topic: newTopicsSelected });
        saveTopics(addedTopics, deletedTopics);
    };

    function saveTopics(addedTopics: string[], deletedTopics: string[]) {
        const requestData: TicketTopics = {
            id: issueState.id,
            added_topics: addedTopics,
            deleted_topics: deletedTopics,
            source: "Web",
            user_id: userID,
        };
        api.patch(URLS.serverUrl + API.saveTicket, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                console.log(
                    `Added topics ${addedTopics} and deleted topics ${deletedTopics} successfully`,
                );
            } else {
                console.log("Call to update topics failed");
            }
        });
    }

    function saveStatus(status: string) {
        const requestData: TicketStatus = {
            id: issueState.id,
            status: status,
            source: "Web",
            user_id: userID,
        };
        api.patch(URLS.serverUrl + API.saveTicket, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                updateIssueState({ ticket_status: status });
                console.log(`Updated status to ${status} successfully`);
            } else {
                console.log("Call to update status failed");
            }
        });
    }

    function saveAssignee(assigneeID: string) {
        const requestData = {
            id: issueState.id,
            source: "Web",
            user_id: userID,
            assignee_user_id: assigneeID === "noAssignee" ? "" : assigneeID,
        };
        api.patch(URLS.serverUrl + API.saveTicket, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                updateIssueState({ assignee_user_id: assigneeID });
                console.log(`Updated assignee to ${assigneeID} successfully`);
            } else {
                console.log("Call to update assignee failed");
            }
        });
    }

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button
                    variant="ghost"
                    className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
                >
                    <DotsHorizontalIcon className="h-4 w-4" />
                    <span className="sr-only">Open menu</span>
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
                align="end"
                className="w-[150px] max-h-60 p-0 bg-muted rounded-md overflow-y-auto"
                onClick={(event) => event.stopPropagation()}
            >
                <Command className="text-xs pb-1 border-none shadow-none">
                    <CommandInput placeholder="Update..." className="px-1" />
                    <CommandList>
                        <CommandEmpty className="text-xs px-4 py-2">
                            No results found.
                        </CommandEmpty>
                        {dropdownOptions.map((type) => (
                            <CommandItem key={type}>
                                <DropdownMenuSub>
                                    <DropdownMenuSubTrigger className="text-xs rounded-md hover:bg-muted text-gray-700 hover:text-gray-950 w-full px-3 -my-0.5 py-1.5 text-semibold">
                                        {type}
                                    </DropdownMenuSubTrigger>
                                    <DropdownMenuSubContent className="w-[300px] max-h-60 overflow-y-auto shadow-none border-none">
                                        <FilterDropdownElement
                                            key={type}
                                            type={type}
                                            filters={filters}
                                            handleItemSelect={saveIssue}
                                            topics={topics}
                                            users={users}
                                        />
                                    </DropdownMenuSubContent>
                                </DropdownMenuSub>
                            </CommandItem>
                        ))}
                    </CommandList>
                </Command>
            </DropdownMenuContent>
        </DropdownMenu>
    );
}
