import { Button, Dialog } from "@radix-ui/themes";
import type React from "react";

interface PopupProps {
    isDialogOpen: boolean;
    closeDialog: () => void;
    description: string;
    isButton: boolean;
    buttonText?: string;
    title: string;
    onButtonClick?: () => void;
    clickOutOf: boolean; // lets a user click out of dialog.
}

// reusable dialog component to use when errors happen!
const DialogComponentHandler: React.FC<PopupProps> = ({
    isDialogOpen,
    description,
    closeDialog,
    isButton,
    buttonText,
    title,
    onButtonClick,
    clickOutOf,
}) => {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const handlePointerDownOutside = (event: any) => {
        if (!clickOutOf) {
            event.preventDefault();
        }
    };
    return (
        <Dialog.Root open={isDialogOpen} onOpenChange={(open) => closeDialog()}>
            <Dialog.Content
                onEscapeKeyDown={handlePointerDownOutside}
                onPointerDownOutside={handlePointerDownOutside}
            >
                <div>
                    <Dialog.Title>{title}</Dialog.Title>
                    <Dialog.Description>{description}</Dialog.Description>
                    {isButton && (
                        <Button onClick={onButtonClick} mt="30px">
                            {buttonText}
                        </Button>
                    )}
                </div>
            </Dialog.Content>
        </Dialog.Root>
    );
};

export default DialogComponentHandler;
