import { Card } from "@/component/shadcn/ui/card";
import { ScrollArea } from "@/component/shadcn/ui/scroll-area";
import type { GetTopicsResponse, Insight } from "@/interfaces/serverData";
import { memo, useCallback, useEffect, useMemo, useState } from "react";

import { VariableSizeList } from "react-window";
import InsightsListCard from "./InsightsListCard";
import { InsightsListHeaderCard } from "./InsightsListHeaderCard";
import { statuses } from "./constants";
import FilterBar from "./FilterBar";
import { BookmarkFilledIcon } from "@radix-ui/react-icons";
import { arraysAreEqual } from "@/utilities/methods";

const areEqual = (
    prevProps: SavedInsightsListProps,
    nextProps: SavedInsightsListProps,
) => {
    return (
        arraysAreEqual(prevProps.savedInsights, nextProps.savedInsights) &&
        prevProps.userID === nextProps.userID &&
        prevProps.handleSaveAIGeneratedInsight ===
            nextProps.handleSaveAIGeneratedInsight &&
        prevProps.topicsMap === nextProps.topicsMap &&
        prevProps.generatedIsOpen === nextProps.generatedIsOpen
    );
};

interface SavedInsightsListProps {
    savedInsights: Insight[];
    userID: string;
    handleSaveAIGeneratedInsight: (insight: Insight) => Promise<void>;
    topicsMap: Map<string, GetTopicsResponse>;
    generatedIsOpen: boolean;
}

function SavedInsightsList({
    savedInsights,
    userID,
    handleSaveAIGeneratedInsight,
    topicsMap,
    generatedIsOpen,
}: SavedInsightsListProps) {
    const [filteredInsights, setFilteredInsights] =
        useState<Insight[]>(savedInsights);
    const [isExpanded, setResponseExpanded] = useState<Map<string, boolean>>(
        new Map([
            ["New", true],
            ["Open", true],
            ["Closed", true],
        ]),
    );
    const priorityOrder = ["New", "Open", "Closed"];
    const [headerIndices, setHeaderIndices] = useState<number[]>([]);
    const [listItems, setListItems] = useState<
        { key: string; content: JSX.Element }[]
    >([]);

    const updateExpanded = useCallback((type: string, value: boolean) => {
        setResponseExpanded((prevMap) => {
            const newMap = new Map(prevMap);
            newMap.set(type, value);
            return newMap;
        });
    }, []);

    const sortedStatuses = useMemo(() => {
        return statuses.sort((a, b) => {
            return (
                priorityOrder.indexOf(a.value) - priorityOrder.indexOf(b.value)
            );
        });
    }, [statuses, priorityOrder]);

    // Calculate height for each section
    const headerHeight = 35;
    const itemSize = 60;

    const getItemSize = useCallback(
        (index: number) => {
            return headerIndices.includes(index) ? headerHeight : itemSize;
        },
        [headerIndices],
    );

    useEffect(() => {
        const newHeaderIndices: number[] = [];
        let index = 0;

        // biome-ignore lint/complexity/noForEach: <explanation>
        sortedStatuses.forEach((status) => {
            const insightsForStatus: Insight[] =
                filteredInsights.filter(
                    (insight) => insight.status === status.value,
                ) ?? [];
            newHeaderIndices.push(index);
            index +=
                1 +
                (isExpanded.get(status.value) ? insightsForStatus.length : 0);
        });

        setHeaderIndices(newHeaderIndices);
    }, [isExpanded, sortedStatuses, filteredInsights]);

    useEffect(() => {
        let index = 0;
        const newHeaderIndices: number[] = [];
        const newListItems: { key: string; content: JSX.Element }[] = [];

        for (const status of sortedStatuses) {
            const insightForStatus: Insight[] =
                filteredInsights.filter(
                    (insight) => insight.status === status.value,
                ) ?? [];
            // Add the header card for the current status
            newListItems.push({
                key: `header-${status.value}`,
                content: (
                    <InsightsListHeaderCard
                        key={`header-${status.value}`}
                        title={status.label}
                        value={status.value}
                        isExpanded={isExpanded}
                        updateExpanded={updateExpanded}
                    />
                ),
            });
            newHeaderIndices.push(index);
            index += 1;

            if (isExpanded.get(status.value)) {
                // Add the insight cards for the current status
                for (const insight of insightForStatus) {
                    const saved = savedInsights.includes(insight);
                    newListItems.push({
                        key: insight.id,
                        content: (
                            <InsightsListCard
                                key={insight.id}
                                insight={insight}
                                userID={userID}
                                handleSaveAIGeneratedInsight={
                                    handleSaveAIGeneratedInsight
                                }
                                saved={saved}
                                topicsMap={topicsMap}
                            />
                        ),
                    });
                }
                index += insightForStatus.length;
            }
        }
        setHeaderIndices(newHeaderIndices);
        setListItems(newListItems);
    }, [isExpanded, sortedStatuses, filteredInsights, updateExpanded, userID]);

    return (
        <Card className="m-2 rounded-sm w-full flex flex-col relative">
            <div className="flex items-center gap-1 px-7 py-2.5 pt-4 text-[15px] font-semibold">
                Saved Insights
                <BookmarkFilledIcon className="h-6 w-6 text-muted-foreground p-1" />
            </div>
            <div>
                <FilterBar
                    insights={savedInsights}
                    filteredInsights={filteredInsights}
                    setFilteredInsights={setFilteredInsights}
                />
            </div>

            <ScrollArea className="h-full w-full">
                <VariableSizeList
                    key={headerIndices.join("-")}
                    width={"100%"}
                    height={
                        generatedIsOpen
                            ? window.innerHeight * 0.48
                            : window.innerHeight * 0.63
                    }
                    itemCount={listItems.length}
                    itemSize={getItemSize}
                >
                    {({ index, style }) => {
                        const item = listItems[index];
                        return (
                            <div style={style} key={item.key}>
                                {item.content}
                            </div>
                        );
                    }}
                </VariableSizeList>
            </ScrollArea>
        </Card>
    );
}

export default memo(SavedInsightsList, areEqual);
