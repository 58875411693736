import {
    DiscordLogoIcon,
    GitHubLogoIcon,
    PaperPlaneIcon,
} from "@radix-ui/react-icons";
import { ReactComponent as ConfluenceSvg } from "../../../images/integrations/confluence.svg";
import { ReactComponent as GmailSvg } from "../../../images/integrations/gmail.svg";
import { ReactComponent as JiraSvg } from "../../../images/integrations/jira.svg";
import { ReactComponent as LinearSvg } from "../../../images/integrations/linear1.svg";
import { ReactComponent as NotionSvg } from "../../../images/integrations/notion.svg";
import { ReactComponent as PRSvg } from "../../../images/integrations/pr.svg";
import { ReactComponent as SlackSvg } from "../../../images/integrations/slack.svg";
import { ReactComponent as WebSvg } from "../../../images/integrations/web.svg";
import { integrations } from "../constant";

export const integrationMappingToSvg = new Map<string, React.ElementType>([
    [integrations.Jira, JiraSvg],
    [integrations.Linear, LinearSvg],
    [integrations.Google, GmailSvg],
    ["Code", GitHubLogoIcon],
    ["Pull Requests", PRSvg],
    [integrations.Slack, SlackSvg],
    [integrations.Notion, NotionSvg],
    ["Web Documentation", WebSvg],
    [integrations.Discord, DiscordLogoIcon],
    ["default", SlackSvg],
    ["Chat Widget", PaperPlaneIcon],
    [integrations.Confluence, ConfluenceSvg],
]);

// from backend
export const integrationBackEndDataMappingToSvg = new Map<
    string,
    React.ElementType
>([
    [integrations.Jira, JiraSvg],
    [integrations.Linear, LinearSvg],
    [integrations.PullRequest, PRSvg],
    [integrations.Slack, SlackSvg],
    [integrations.Google, GmailSvg],
    [integrations.Gmail, GmailSvg],
    [integrations.Notion, NotionSvg],
    [integrations.Web, WebSvg],
    [integrations.Discord, DiscordLogoIcon],
    [integrations.GitHubTicket, GitHubLogoIcon],
    [integrations.ChatWidget, PaperPlaneIcon],
    [integrations.Confluence, ConfluenceSvg],
]);
