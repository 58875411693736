import { Box, Grid, Typography } from "@mui/material";
import { Button, Flex, Progress, Text } from "@radix-ui/themes";
import type React from "react";
import {
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useState,
} from "react";
import { useNavigate } from "react-router-dom";
import MultiSelectSearchableDropdown from "../../component/MultiSelectDropdown";
import { API, ASSEMBLY_COOKIES, URLS, pages } from "../../constant";
import { StyledBox } from "../../design/Box";
import { ErrorChip } from "../../design/Chip";
import { useApi } from "../../interfaces/api";
import type {
    IndexDataRequest,
    ScopeResponse,
} from "../../interfaces/serverData";
import NavBarComponent from "../../sharedPages/NavBar";
import { getCookie } from "../../utilities/CookieManagement";
import {
    type integrationInfo,
    integrations,
    integrationsList,
} from "./constant";

const GitHubIndexingPage = () => {
    const api = useApi();

    const [channels, setChannels] = useState<ScopeResponse[]>([]);

    enum loadingTypes {
        loading = 0,
        loaded = 1,
        error = 2,
    }
    const [loading, setLoading] = useState(loadingTypes.loading);

    const navigate = useNavigate();

    const dropdownStyle: React.CSSProperties = {
        position: "relative", // Establishes a new positioning context
        paddingRight: 15,
        maxHeight: "200px", // Adjust this value based on your item height to fit 5 items
        overflowY: "auto",
    };

    const [startIndexing, setStartIndexing] = useState<boolean>(false);

    const [jobList, setJobList] = useState<string[]>([]);

    const [isDisabledButton, setIsDisabledButton] = useState<boolean>(true);

    const [isSubmit, setIsSubmit] = useState<boolean>(false);

    const [channelsSelected, setChannelsSelected] = useState<string[]>([
        "Select",
    ]);

    const [integrationType, setIntegrationType] = useState("");

    const initialIntegrationInfo: integrationInfo = useMemo(
        () => ({
            title: "",
            description: "",
            buttonInfo: "",
        }),
        [],
    );

    const [info, setInfo] = useState<integrationInfo>(initialIntegrationInfo);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const tempInfo: integrationInfo = {
            title: "",
            description: "",
            buttonInfo: "",
        };

        switch (integrationType) {
            case "code":
                tempInfo.title = "Confirm GitHub Organization";
                tempInfo.description =
                    "Confirm your organization below that you'd like to give us access to.";
                tempInfo.buttonInfo = "Select Organization";
                break;
            case "GitHubTicket":
                tempInfo.title = "Confirm GitHub Organization";
                tempInfo.description =
                    "Confirm your organization below that you'd like to give us access to.";
                tempInfo.buttonInfo = "Select Organization";
                break;
        }
        setInfo(tempInfo);
    }, [integrationType, initialIntegrationInfo]);

    useLayoutEffect(() => {
        const integration = integrationsList.get(window.location.pathname);
        if (integration === undefined) {
            navigate("/*");
        }

        const queryString = window.location.search;

        const i = integration ? integration.integrationType : "";
        setIntegrationType((prev) => i);
        const urlParams = new URLSearchParams(queryString);

        if (integration?.integrationType === integrations.Notion) {
            setIsSubmit(true); // already go straight to indexing page for notion only (since we don't need to select channels)
        } else if (
            integration?.integrationType !== integrations.Code &&
            integration?.integrationType !== integrations.GitHubTicket
        ) {
            const stateParam = urlParams.get("state");
            if (stateParam !== integration?.integrationCode) {
                navigate("/*");
            }
        }
        let installationIdParam = "";
        if (integration?.integrationType === integrations.GitHubTicket) {
            installationIdParam = urlParams.get("installation_id") ?? "";
        }

        const codeParam = urlParams.get("code");
        if (codeParam === undefined || codeParam === null || codeParam === "") {
            navigate("/*");
        }

        const cookie = getCookie(ASSEMBLY_COOKIES.jira_url);

        const requestData = {
            type: integration?.integrationType,
            info: {
                code: codeParam,
                url: cookie,
                installationID: installationIdParam,
            },
        };

        const serverUrl = URLS.serverUrl ? URLS.serverUrl : "";
        api.post(serverUrl + API.authenticateIntegration, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                const channelData = res.data.data;
                setLoading(loadingTypes.loaded);
                setChannels(channelData);
            })
            .catch((res) => {
                setLoading(loadingTypes.error);
                console.log(res);
            });
    }, [navigate]); //only run once

    function handleChannelSelect(channels: string[]) {
        setChannelsSelected(channels);
        if (channels?.length === 0) {
            setIsDisabledButton(true);
        } else {
            setIsDisabledButton(false);
        }
    }

    const navigateToIntegrations = useCallback(async () => {
        navigate("/admin/manage_integrations", { replace: true });
    }, [navigate]);

    function handleSubmitClick() {
        handleSubmit();
    }

    function handleSubmit(intType?: string) {
        const myIntegrationType = intType ? intType : integrationType;
        const channelRequest: ScopeResponse[] = []; // contains channel ids for back-end
        const newPayload: IndexDataRequest[] = [];

        // biome-ignore lint/complexity/noForEach: <explanation>
        channels.forEach((channel) => {
            let finalizedString = "";
            finalizedString = `${channel.owner}`;
            if (channelsSelected.includes(finalizedString)) {
                channelRequest.push(channel);
                newPayload.push({
                    server: "https://api.github.com/",
                    owner: channel.owner!,
                    ref_name: channel.ref_name!,
                    name: channel.name,
                    is_reindex: false,
                    key: "",
                });
            }
        });

        const requestData = {
            names: newPayload,
            is_reindex: false,
            type: myIntegrationType,
            index_first_time: true,
        };

        api.post(URLS.serverUrl + API.index, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then(async (res) => {
            if (res.status === 200) {
                setIsSubmit(true);

                await new Promise((resolve) => setTimeout(resolve, 4000));
                setStartIndexing(true);
            } else {
            }
        });
    }

    const fetchProgress = useCallback(async () => {
        navigateToIntegrations();
    }, [navigateToIntegrations]);

    useEffect(() => {
        if (startIndexing) {
            fetchProgress();
        }
    }, [startIndexing, fetchProgress]);

    return (
        <div>
            <NavBarComponent state={pages.index} />

            {isSubmit ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "calc(100vh - 85px)",
                    }}
                >
                    <Box>
                        <Flex direction="column" gap="2">
                            <Text
                                size={{
                                    sm: "3",
                                    initial: "4",
                                    md: "4",
                                    xl: "5",
                                }}
                            >
                                Connecting...
                            </Text>

                            <Progress duration="2s" />
                        </Flex>
                    </Box>
                </div>
            ) : (
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ minHeight: "80vh" }}
                >
                    <StyledBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        textAlign="center"
                    >
                        <Typography variant="h5" sx={{ pb: 1 }}>
                            <b>{info.title}</b>
                        </Typography>
                        <Typography variant="subtitle1" sx={{ pb: 4 }}>
                            {info.description}
                        </Typography>

                        <Grid item>
                            {loading === loadingTypes.loading && (
                                <Button
                                    size="3"
                                    style={{ paddingLeft: "150px" }}
                                    loading
                                    variant="soft"
                                />
                            )}
                            {loading === loadingTypes.loaded && (
                                <MultiSelectSearchableDropdown
                                    dropdownStyle={dropdownStyle}
                                    items={channels.map((channel) => ({
                                        label: channel.name,
                                        disabled: false,
                                    }))}
                                    onItemClick={handleChannelSelect}
                                    menuText={""}
                                />
                            )}
                            {loading === loadingTypes.error && (
                                <ErrorChip
                                    label={
                                        "Oops! Looks like something's wrong. Try again, or notify us!"
                                    }
                                />
                            )}
                        </Grid>
                    </StyledBox>

                    <Grid item xs={3} sx={{ pt: 8 }}>
                        <Box
                            sx={{ position: "relative", width: "fit-content" }}
                        >
                            <Button
                                size="3"
                                onClick={handleSubmitClick}
                                disabled={isDisabledButton}
                            >
                                {info.buttonInfo}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default GitHubIndexingPage;
