import {
    CheckCircledIcon,
    CircleIcon,
    StopwatchIcon,
} from "@radix-ui/react-icons";

export const statuses = [
    {
        label: "New",
        value: "New",
        icon: CircleIcon,
    },
    {
        label: "Open",
        value: "Open",
        icon: StopwatchIcon,
    },
    {
        label: "Closed",
        value: "Closed",
        icon: CheckCircledIcon,
    },
];

export function findStatus(statusStr: string) {
    return statuses.find((status) => status.value === statusStr);
}
