"use client";

import { Button } from "@/component/shadcn/ui/button";
import { Input } from "@/component/shadcn/ui/input";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/component/shadcn/ui/popover";
import { cn } from "@/lib/utils";
import type { TagsRowState } from "@/pages/WorkspacePreferences/DataTable/constants";
import { DotFilledIcon } from "@radix-ui/react-icons";

export function GradientPicker({
    id,
    background,
    setBackground,
    className,
}: {
    id: string;
    background: string;
    setBackground: (id: string, rowState: Partial<TagsRowState>) => void;
    className?: string;
}) {
    const solids = [
        "#A9DAED",
        "#9CE0D0",
        "#C2DA91",
        "#F3D768",
        "#F3D673",
        "#FFC182",
        "#E4CDB7",

        "#B2DDB5",
        "#ADDDC0",
        "#C3E9D7",
        "#B8BCBA",
        "#7DCEDC",

        "#ACD8FC",
        "#C1D0FF",
        "#CBCDFF",
        "#EFBFDD",
        "#FDBDAF",
    ];

    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button
                    variant={"outline"}
                    className={cn(
                        "h-3 w-3 px-2",
                        !background && "text-muted-foreground",
                        className,
                    )}
                >
                    <DotFilledIcon
                        color={background}
                        style={{ transform: "scale(1.8)" }}
                    />
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-36">
                <div className="flex flex-row flex-wrap gap-1 mt-0">
                    {solids.map((s) => (
                        <div
                            key={s}
                            style={{ background: s }}
                            className="rounded-md h-6 w-6 cursor-pointer active:scale-105"
                            onClick={() =>
                                setBackground(id, { colorSelected: s })
                            }
                        />
                    ))}
                </div>

                <Input
                    id="custom"
                    value={background}
                    className="col-span-2 h-8 mt-4"
                    onChange={(e) =>
                        setBackground(id, {
                            colorSelected: e.currentTarget.value,
                        })
                    }
                />
            </PopoverContent>
        </Popover>
    );
}
