import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MenuDropdownComponent from "../component/MenuDropdownComponent";
import { pages } from "../constant";
import logo from "../images/logo.png";

function NavBarComponent({ state = pages.login }) {
    const navigate = useNavigate();
    const handleLogoClick = () => {
        navigate("/"); // Navigate to homepage
    };
    return (
        <Grid
            item
            container
            spacing={0}
            direction="row"
            sx={{ justifyContent: "space-between", p: 2, pr: 3, mt: 0.5 }}
        >
            <Grid item xs={1}>
                {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
                <img
                    src={logo}
                    alt="Assembly."
                    style={{ width: "10rem", height: "auto" }}
                    onClick={handleLogoClick}
                />
            </Grid>

            <Grid item xs={0}>
                <MenuDropdownComponent state={state} />
            </Grid>
        </Grid>
    );
}

export default NavBarComponent;
