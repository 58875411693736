import type { GetTopicsResponse, Ticket } from "@/interfaces/serverData";
import { memo, useEffect, useState } from "react";

import InsightsSection from "@/Ticket/InsightsSection";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import { Dialog } from "@radix-ui/themes";
import { InsightDisplay } from "./InsightDisplay";

interface SavedInsightDialogPopupProps {
    ticketWithInsight: Ticket;
}

function SavedInsightDialogPopup({
    ticketWithInsight,
}: SavedInsightDialogPopupProps) {
    const [topicsMap, setTopicsMap] = useState<Map<string, GetTopicsResponse>>(
        new Map(),
    );

    const api = useApi();

    useEffect(() => {
        api.get(URLS.serverUrl + API.getTopics, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    const topics: GetTopicsResponse[] = res.data.data;

                    const newTopics: {
                        color: string;
                        label: string;
                        value: string;
                    }[] = [];

                    const map = new Map<string, GetTopicsResponse>();

                    for (const topic of topics) {
                        newTopics.push({
                            color: topic.color ?? "#9B9EF0",
                            label: topic.topic_name,
                            value: topic.topic_name,
                        });
                        map.set(topic.topic_name, topic);
                    }
                    setTopicsMap(map);
                }
            })
            .catch(() => {
                console.log("Did not grab topics from db successfully");
            });
    }, [api]);

    const [popUpHeight, setPopUpHeight] = useState<number>(
        window.innerHeight * 0.9,
    );

    const sidebarLabels = "font-medium text-xs text-gray9";

    return (
        <Dialog.Root>
            <Dialog.Trigger>
                <button type="button" className="w-full">
                    <InsightsSection
                        issue={ticketWithInsight}
                        sidebarLabels={sidebarLabels}
                        showLinkInsight={false}
                    />
                </button>
            </Dialog.Trigger>

            <Dialog.Content style={{ maxHeight: `${popUpHeight}px` }}>
                <Dialog.Description
                    size="2"
                    mb="4"
                    className="pt-3 z-50 -mb-20"
                >
                    <InsightDisplay
                        insight={ticketWithInsight.insights[0]}
                        userID={""}
                        topicsMap={topicsMap}
                        insightSelectedIsSaved={true}
                    />
                </Dialog.Description>
            </Dialog.Content>
        </Dialog.Root>
    );
}

export default memo(SavedInsightDialogPopup);
