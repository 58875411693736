import type {
    CodeResponse,
    ConfluenceResponse,
    JiraResponse,
    LinearResponse,
    NodeResponse,
    NotionResponse,
    PRResponse,
    SlackResponse,
    WebResponse,
} from "../../interfaces/serverData";
import { integrations } from "../../pages/Admin/constant";

function getSlackText(slackNode: SlackResponse | null): string {
    if (slackNode != null) {
        const date = new Date(Number.parseFloat(slackNode.sent) * 1000);
        const humanReadableDate = date.toDateString();
        return `${slackNode.channel_name} ${humanReadableDate}`; // add channel name
    } else {
        return "Slack Message";
    }
}

function getText(text: string): string {
    if (text.length > 20) {
        // biome-ignore lint/style/noParameterAssign: <explanation>
        text = `${text.slice(0, 20)}...`;
    }

    return text;
}

export function getPRText(prNode: PRResponse | null): string {
    if (prNode != null && prNode.head !== "") {
        return prNode.head;
    } else {
        return "PR";
    }
}

export function getJiraText(jiraNode: JiraResponse | null): string {
    if (jiraNode != null && jiraNode.key !== "") {
        return getText(jiraNode.key);
    } else {
        return "Jira Ticket";
    }
}

export function getLinearText(linearNode: LinearResponse | null): string {
    if (linearNode != null && linearNode.key !== "") {
        return getText(linearNode.key);
    } else {
        return "Linear Ticket";
    }
}

export function getNotionPage(notionNode: NotionResponse | null): string {
    if (notionNode != null && notionNode.page_title !== "") {
        const splitText = notionNode.page_title.split("/");
        const pageTitle = splitText[splitText.length - 1];
        return getText(pageTitle);
    } else {
        return "Notion Page";
    }
}

export function getConfluenceText(
    confluenceNode: ConfluenceResponse | null,
): string {
    if (confluenceNode != null && confluenceNode.title !== "") {
        return getText(confluenceNode.title);
    } else {
        return "Confluence Page";
    }
}

export function getWebPage(webNode: WebResponse | null): string {
    if (webNode != null && webNode.key !== "") {
        return getText(webNode.key);
    } else {
        return "Web Page";
    }
}

export function createLink(
    inputFilePath: string,
    inputOwner: string,
    inputRepo: string,
    location: string,
    inputBranch: string,
): string {
    const parts = location.split(",");

    const startLine = Number.parseInt(parts[0], 10);
    const endLine = Number.parseInt(parts[1], 10);
    let githubLink = "https://github.com/";
    const firstSlashIndex = inputFilePath.indexOf("/");
    const restOfText = inputFilePath.substring(firstSlashIndex);

    // Formatting the string
    githubLink += `${inputOwner}/${inputRepo}/blob/${inputBranch}/${restOfText}#L${startLine}-L${endLine}`;

    return githubLink;
}

export function getCodeText(codeNode: CodeResponse | null): string {
    if (codeNode != null) {
        return getText(codeNode.filepath);
    } else {
        return "Code";
    }
}

export function getNodesText(node: NodeResponse | null): string {
    if (node != null) {
        if (node.kind === integrations.Slack) {
            return getSlackText(node as SlackResponse);
        } else if (node.kind === integrations.PullRequest) {
            return getPRText(node as PRResponse);
        } else if (node.kind === integrations.Jira) {
            return getJiraText(node as JiraResponse);
        } else if (node.kind === integrations.Linear) {
            return getLinearText(node as LinearResponse);
        } else if (node.kind === integrations.Notion) {
            return getNotionPage(node as NotionResponse);
        } else if (node.kind === integrations.Web) {
            return getWebPage(node as WebResponse);
        } else if (node.kind === integrations.Code) {
            return getCodeText(node as CodeResponse);
        } else if (node.kind === integrations.Confluence) {
            return getConfluenceText(node as ConfluenceResponse);
        } else {
            return "";
        }
        // TODO - add more cases here when we get more integrations
    } else {
        return "";
    }
}
