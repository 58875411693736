import { Button } from "@/component/shadcn/ui/button";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/component/shadcn/ui/dialog";
import { Input } from "@/component/shadcn/ui/input";
import { loadingTypes } from "@/constant";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { GetTopicsResponse } from "@/interfaces/serverData";
import { PlusIcon } from "@radix-ui/react-icons";
import { Box, Callout, Skeleton, Text } from "@radix-ui/themes";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { z } from "zod";
import { generateTagsColumns } from "./DataTable/columns";
import type { TagsRowState } from "./DataTable/constants";
import { TagsDataTable } from "./DataTable/tags-data-table";

import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/component/shadcn/ui/form";
import { GradientPicker } from "@/design/GradientPicker";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
const formSchema = z.object({
    topicName: z
        .string()
        .min(2, { message: "The topic name must be at least 2 characters." })
        .max(70, { message: "The topic name must be at most 70 characters." }),
    topicDescription: z.string().max(100, {
        message: "The topic name must be at most 100 characters.",
    }),
    color: z.string(),
});

const Tags = () => {
    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const [loadingState, setLoadingState] = useState<number>(
        loadingTypes.loading,
    );
    const api = useApi();
    const defaultBadgeColor = "#9B9EF0";

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            topicName: "",
            topicDescription: "",
            color: defaultBadgeColor,
        },
    });

    function onSubmit(values: z.infer<typeof formSchema>) {
        const requestData = {
            name: values.topicName,
            description: values.topicDescription,
            color: rowState.get("dialog")?.colorSelected ?? defaultBadgeColor,
        };
        api.post(URLS.serverUrl + API.addTopic, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                }
            })
            .catch((res) => {
                console.log("failed to get repository");
                setLoadingState(2);
            });
    }

    const [rowState, setRowState] = useState<Map<string, TagsRowState>>(
        new Map(),
    );
    const { data = [] } = useQuery({
        queryKey: ["topics"],
        queryFn: () => fetchTopics(),
        refetchInterval: 10000, // refetch every 10 secs
        refetchOnWindowFocus: true,
    });

    const updateRowState = (id: string, newState: Partial<TagsRowState>) => {
        setRowState((prevState) => {
            const currentState = prevState.get(id) || {
                colorSelected: "",
                tagSelected: "",
            };
            return new Map(prevState).set(id, {
                ...currentState,
                ...newState,
            });
        });
    };

    const fetchTopics = async (): Promise<GetTopicsResponse[]> => {
        const response = await api.get(URLS.serverUrl + API.getTopics, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        if (response.status === 200) {
            return response.data.data;
        }
        setLoadingState(2);
        return [];
    };

    useEffect(() => {
        if (data) {
            const newRowState = new Map<string, TagsRowState>();
            for (let i = 0; i < data.length; i++) {
                const query: GetTopicsResponse = data[i];
                newRowState.set(String(i), {
                    tagSelected: query.topic_name,
                    colorSelected: query.color ?? defaultBadgeColor,
                });
            }
            newRowState.set("dialog", {
                tagSelected: "",
                colorSelected: defaultBadgeColor,
            });
            setRowState(newRowState);
            setLoadingState(1);
        }
    }, [data]);

    const saveTag = (id: string, rowStateInfo: Partial<TagsRowState>) => {
        const requestData = {
            id: id,
            color: rowStateInfo.colorSelected,
        };
        api.post(URLS.serverUrl + API.editTopic, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    updateRowState(res.data.data.id, {
                        colorSelected: res.data.data.color,
                        tagSelected: res.data.data.tag,
                    });
                }
            })
            .catch((res) => {
                console.log("failed to get repository");
                setLoadingState(2);
            });
    };

    return (
        <div>
            <Box mt={"5%"} ml={"20%"} mr={"20%"}>
                <h2 className="text-2xl font-semibold">Tags</h2>
                <p className="text-sm text-gray11">Manage your tags</p>

                {loadingState === loadingTypes.loading && (
                    <Skeleton>
                        <Text>
                            {[...Array(2)].map((_, index) => (
                                <Text key={null}>{loremIpsum}</Text>
                            ))}
                        </Text>
                    </Skeleton>
                )}
                {loadingState === loadingTypes.error && (
                    <Callout.Root size="1" variant="outline" color="red">
                        <Callout.Text>
                            Sorry, something's wrong! Please notify us at
                            support@askassembly.app.
                        </Callout.Text>
                    </Callout.Root>
                )}
                {loadingState === loadingTypes.loaded && (
                    <div className="flex flex-col items-end gap-2">
                        <Dialog>
                            <DialogTrigger asChild>
                                <Button
                                    className="shadow-md outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm"
                                    size="sm"
                                    variant="outline"
                                >
                                    Add Tag
                                    <PlusIcon />
                                </Button>
                            </DialogTrigger>
                            <DialogContent className="inset-0 z-50 flex items-center justify-center p-5 bg-black bg-opacity-50 max-h-full overflow-auto">
                                <div className="bg-white shadow-lg rounded-md p-7 w-1/2 overflow-auto relative">
                                    <div className="ml-10 mr-10">
                                        <DialogHeader className="justify-left text-left items-left pb-5">
                                            <DialogTitle>
                                                Add A Topic
                                            </DialogTitle>
                                            <DialogDescription>
                                                Add a new topic with a
                                                description. We'll use this as a
                                                way to tag incoming customer
                                                support requests.
                                            </DialogDescription>
                                        </DialogHeader>

                                        <Form {...form}>
                                            <form
                                                onSubmit={form.handleSubmit(
                                                    onSubmit,
                                                )}
                                                className="space-y-8"
                                            >
                                                <FormField
                                                    control={form.control}
                                                    name="topicName"
                                                    render={({ field }) => (
                                                        <FormItem>
                                                            <div className="grid grid-cols-4 items-center gap-4">
                                                                <FormLabel htmlFor="topicName">
                                                                    Topic Name
                                                                </FormLabel>
                                                                <FormControl>
                                                                    <Input
                                                                        id="topicName"
                                                                        className="col-span-3"
                                                                        {...field}
                                                                    />
                                                                </FormControl>
                                                                <FormMessage className="text-xs" />
                                                            </div>
                                                        </FormItem>
                                                    )}
                                                />
                                                <FormField
                                                    control={form.control}
                                                    name="topicDescription"
                                                    render={({ field }) => (
                                                        <FormItem>
                                                            <div className="grid grid-cols-4 items-center gap-4">
                                                                <FormLabel htmlFor="topicDescription">
                                                                    Topic
                                                                    Description
                                                                </FormLabel>
                                                                <FormControl>
                                                                    <Input
                                                                        id="topicDescription"
                                                                        className="col-span-3"
                                                                        {...field}
                                                                    />
                                                                </FormControl>
                                                            </div>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )}
                                                />
                                                <FormField
                                                    control={form.control}
                                                    name="color"
                                                    render={({ field }) => (
                                                        <FormItem>
                                                            <div className="grid grid-cols-4 items-center gap-4">
                                                                <FormLabel>
                                                                    Color
                                                                </FormLabel>
                                                                <FormControl>
                                                                    <GradientPicker
                                                                        id={
                                                                            "dialog"
                                                                        }
                                                                        background={
                                                                            rowState.get(
                                                                                "dialog",
                                                                            )
                                                                                ?.colorSelected ??
                                                                            defaultBadgeColor
                                                                        }
                                                                        setBackground={
                                                                            updateRowState
                                                                        }
                                                                    />
                                                                </FormControl>
                                                            </div>
                                                        </FormItem>
                                                    )}
                                                />
                                                <DialogFooter className="justify-end text-end items-end">
                                                    <Button
                                                        className="bg-iris9"
                                                        type="submit"
                                                    >
                                                        Save
                                                    </Button>
                                                </DialogFooter>
                                            </form>
                                        </Form>
                                    </div>
                                    {/* <div className="grid gap-4 py-4">
                                        <div className="grid grid-cols-4 items-center gap-4">
                                            <Label
                                                htmlFor="topic_name"
                                                className="text-right"
                                            >
                                                Topic Name
                                            </Label>
                                            <Input
                                                id="topic_name"
                                                value={dialogTopicName}
                                                className="col-span-3"
                                                onChange={(event) => {
                                                    setDialogTopicName(
                                                        event.target.value,
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div className="grid grid-cols-4 items-center gap-4 text-xs">
                                            <Label
                                                htmlFor="description"
                                                className="text-right"
                                            >
                                                Topic Description
                                            </Label>
                                            <Input
                                                id="description"
                                                value={dialogDescriptionName}
                                                className="col-span-3"
                                                onChange={(event) =>
                                                    setDialogDescriptionName(
                                                        event.target.value,
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="grid grid-cols-4 items-center gap-4">
                                            <Label
                                                htmlFor="color"
                                                className="text-right"
                                            >
                                                Color
                                            </Label>
                                            <GradientPicker
                                                id={"dialog"}
                                                background={
                                                    rowState.get("dialog")
                                                        ?.colorSelected ??
                                                    defaultBadgeColor
                                                }
                                                setBackground={updateRowState}
                                            />
                                        </div>
                                    </div> */}
                                </div>
                            </DialogContent>
                        </Dialog>
                        <TagsDataTable
                            data={data}
                            columns={generateTagsColumns(rowState, saveTag)}
                        />
                    </div>
                )}
            </Box>
        </div>
    );
};

export default Tags;
