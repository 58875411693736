import type React from "react";
import type { FilterMetadata } from "./SideBar/SideBarComponent";

import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { Box, Flex, IconButton, Select, TextField } from "@radix-ui/themes";

// Assuming this interface is defined in your project
interface SearchBarProps {
    dropdown: Map<string, FilterMetadata>;
    searchFn: (searchInput: string) => void;
    currentSearchTerm: string;
    onSearchTermChange: (newTerm: string) => void;
    handleBarToggle: (type: string) => void;
    filterInput?: string;
}

const SearchBar = ({
    dropdown,
    searchFn,
    currentSearchTerm,
    onSearchTermChange,
    handleBarToggle,
    filterInput,
}: SearchBarProps) => {
    const handleSearch = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        searchFn(currentSearchTerm);
    };

    return (
        <form
            onSubmit={handleSearch}
            style={{
                alignItems: "center",
                marginTop: "35px",
                marginBottom: "5px",
            }}
        >
            <Flex direction="row" gap="3">
                {filterInput && (
                    <Select.Root
                        size="3"
                        defaultValue="Code"
                        onValueChange={handleBarToggle}
                    >
                        <Select.Trigger variant="soft" />
                        <Select.Content>
                            {Array.from(dropdown.entries()).map(
                                ([item, _]: [string, FilterMetadata]) => {
                                    return (
                                        <Select.Item key={item} value={item}>
                                            {item}
                                        </Select.Item>
                                    );
                                },
                            )}
                        </Select.Content>
                    </Select.Root>
                )}

                <Box style={{ width: "600px" }}>
                    <TextField.Root
                        size="3"
                        radius="small"
                        placeholder="Ask Assembly anything ..."
                        value={currentSearchTerm}
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                        ) =>
                            onSearchTermChange(
                                (event.currentTarget as HTMLInputElement).value,
                            )
                        }
                    />
                </Box>
                <IconButton size="3" variant="soft">
                    <MagnifyingGlassIcon width="22" height="22" />
                </IconButton>
            </Flex>
        </form>
    );
};

export default SearchBar;
