import { Button } from "@/component/shadcn/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { Insight, InsightData, Query } from "@/interfaces/serverData";
import {
    QuestionMarkCircledIcon,
    TriangleDownIcon,
} from "@radix-ui/react-icons";
import { Badge } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { findStatus, statuses } from "./constants";

interface InsightStatusDropdownProps<TData> {
    insight: Insight;
    updateInsightState: (newState: Partial<Insight>) => void;
}

export function InsightStatusDropdown<TData extends Query>({
    insight,
    updateInsightState,
}: InsightStatusDropdownProps<TData>) {
    const api = useApi();
    const initialStatus = statuses.find(
        (status) => status.value === insight.status,
    );
    const [statusSelected, setStatusSelected] = useState<string>(
        initialStatus?.value ?? "Unknown",
    );
    const fullStatusSelected = findStatus(statusSelected);

    function saveStatus(value: string) {
        const requestData: InsightData = {
            existing_insight_id: insight.id,
            status: value,
        };
        api.post(URLS.serverUrl + API.saveInsight, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                setStatusSelected(value);
                updateInsightState({ status: value });
                console.log(`Updated insight status to ${value} successfully`);
            } else {
                console.log("Call to update insight status failed");
            }
        });
    }

    useEffect(() => {
        const initialStatus = statuses.find(
            (status) => status.value === insight.status,
        );
        setStatusSelected(initialStatus?.value ?? "Unknown");
    }, [insight.status]);

    return (
        <div className="text-sm flex items-center px-1 pt-1">
            <DropdownMenu>
                <DropdownMenuTrigger asChild className="hover:bg-muted">
                    <Button variant="ghost" className="flex p-1.5 py-0.5">
                        <Badge
                            color={"gray"}
                            size="2"
                            radius="full"
                            variant="outline"
                            className="py-0 hover:opacity-70  hover:text-opacity-70 transition-opacity duration-300 text-gray11"
                        >
                            {fullStatusSelected ? (
                                <fullStatusSelected.icon
                                    style={{
                                        minWidth: "17px",
                                        minHeight: "30px",
                                    }}
                                />
                            ) : (
                                <QuestionMarkCircledIcon
                                    style={{
                                        minWidth: "17px",
                                        minHeight: "30px",
                                    }}
                                />
                            )}
                            <span className="text-xs font-normal ">
                                {fullStatusSelected
                                    ? fullStatusSelected.label
                                    : "Unknown"}
                            </span>
                            <TriangleDownIcon className="h-3 w-3" />
                        </Badge>
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                    side="bottom"
                    align="end"
                    className="w-[160px] p-2"
                >
                    {statuses.map((status) => (
                        <DropdownMenuItem
                            key={status.value}
                            className="py-1 hover:bg-muted cursor-pointer"
                            onSelect={() => saveStatus(status.value)}
                        >
                            <status.icon className="mr-2 w-4 h-4" />
                            <span>{status.label}</span>
                        </DropdownMenuItem>
                    ))}
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    );
}
