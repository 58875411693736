import { loadingTypes } from "@/constant";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { OrgInfoResponse } from "@/interfaces/serverData";
import {
    Badge,
    Box,
    Button,
    Callout,
    Card,
    Flex,
    Skeleton,
    Text,
} from "@radix-ui/themes";
import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as NotionSvg } from "../../../images/integrations/notion.svg";
import type { metadata } from "../IntegrationsManagementPage";
import IntegrationHeader from "./IntegrationHeader";

const NotionIntegration = () => {
    function handleReindex(integrationType: string, integrationUrl?: string) {
        if (integrationType === undefined || integrationType === "") {
            return;
        }

        const requestData = {
            type: integrationType,
            is_reindex: true,
        };

        api.post(URLS.serverUrl + API.indexIntegration, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then(async (res) => {
            if (res.status === 200) {
            } else {
                // todo - need to handle this case.
            }
        });
        navigate("/admin/integrations/reindex");
    }
    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const [refreshDisabled, setRefreshDisabled] = useState<boolean>(true);

    const [additionalMetadata, setAdditionalMetadata] = useState<metadata>({
        scopes: [],
        indexedOn: "",
    });

    const [loadingState, setLoadingState] = useState<number>(
        loadingTypes.loading,
    );
    const api = useApi();

    const [integrationEnabled, setIntegrationEnabled] =
        useState<boolean>(false);

    useEffect(() => {
        const requestData = {
            types: ["Notion"],
        };
        api.post(URLS.serverUrl + API.getItemsByOrgID, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                const temp: metadata = { scopes: [], indexedOn: "" };

                if (res.status === 200) {
                    if (res.data.data) {
                        const orgInfo: OrgInfoResponse = res.data.data;

                        if (orgInfo.Notion?.scopes) {
                            setIntegrationEnabled(true);
                            for (const repo of orgInfo.Notion.scopes) {
                                temp.scopes.push(repo.name);
                            }

                            if (orgInfo.Notion.date) {
                                const date: Date = parseISO(
                                    orgInfo.Notion.date,
                                );
                                const humanReadableDate = format(
                                    date,
                                    "MMMM dd, yyyy hh:mm a",
                                );
                                temp.indexedOn = humanReadableDate;

                                const currentTime: Date = new Date();

                                const difference: number =
                                    currentTime.getTime() - date.getTime();
                                const differenceInHours: number =
                                    difference / (1000 * 60 * 60);

                                const hasNotBeen24Hours: boolean =
                                    differenceInHours <= 24;
                                setRefreshDisabled(hasNotBeen24Hours);
                            }
                        }

                        setAdditionalMetadata(temp);
                        setLoadingState(1);
                    }
                } else {
                    console.log("failed to get results");
                    setLoadingState(2);
                }
            })
            .catch((res) => {
                console.log("failed to get repository");
                setLoadingState(2);
            });
    }, [api]);

    const navigate = useNavigate();

    const notionContinue = () => {
        const client_id = process.env.REACT_APP_NOTION_CLIENT_ID;
        const redirect_url = `${process.env.REACT_APP_CALLBACK_URL}admin/integrations/notion`;
        const notion_url = `https://api.notion.com/v1/oauth/authorize?client_id=${client_id}&response_type=code&owner=user&redirect_uri=${redirect_url}`;
        window.open(notion_url, "_self");
    };

    return (
        <div className="bg-gray-500">
            <Box mt={"5%"} ml={"28%"} mr={"28%"}>
                <Flex direction={"column"} align={"start"} gap="6">
                    <IntegrationHeader
                        integrationType="Notion"
                        description="Automatically search through and answer questions about your Notion knowledge base"
                        SvgIcon={NotionSvg}
                    />
                    <Flex style={{ width: "100%", justifyContent: "flex-end" }}>
                        {loadingState === loadingTypes.loaded &&
                        !integrationEnabled ? (
                            <Button onClick={notionContinue}>Enable</Button>
                        ) : (
                            <Button disabled={true}>Enabled</Button>
                        )}
                    </Flex>
                </Flex>
                {loadingState === loadingTypes.loading && (
                    <Skeleton>
                        <Text>
                            {[...Array(2)].map((_, index) => (
                                <Text key={null}>{loremIpsum}</Text>
                            ))}
                        </Text>
                    </Skeleton>
                )}
                {loadingState === loadingTypes.error && (
                    <Callout.Root size="1" variant="outline" color="red">
                        <Callout.Text>
                            Sorry, something's wrong! Please notify us at
                            support@askassembly.app.
                        </Callout.Text>
                    </Callout.Root>
                )}
                {loadingState === loadingTypes.loaded && integrationEnabled && (
                    <Flex direction={"column"} gap="2" mb="2">
                        <Text size={"2"}>Channels Selected: </Text>
                        <Card style={{ padding: "20px" }}>
                            <Flex
                                direction="row"
                                gap="3"
                                align="center"
                                wrap="wrap"
                            >
                                {additionalMetadata.scopes.map((item) => (
                                    <Badge key={item}>{item}</Badge>
                                ))}{" "}
                            </Flex>
                        </Card>
                        <Flex
                            direction="column"
                            style={{
                                justifyContent: "flex-end",
                                alignItems: "end",
                            }}
                            gap="3"
                            mb="5"
                        >
                            <Flex direction={"row"}>
                                <Text size={"1"}>
                                    Last Updated: {
                                        additionalMetadata.indexedOn
                                    }{" "}
                                </Text>
                            </Flex>

                            <Button
                                disabled={refreshDisabled}
                                onClick={() => {
                                    handleReindex("Notion");
                                }}
                            >
                                Refresh
                            </Button>
                        </Flex>
                    </Flex>
                )}
            </Box>
        </div>
    );
};

export default NotionIntegration;
