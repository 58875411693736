import {
    Box,
    Button,
    Callout,
    Card,
    Dialog,
    Flex,
    Heading,
    Select,
    Separator,
    Skeleton,
    Text,
} from "@radix-ui/themes";
import { useEffect, useRef, useState } from "react";
import { loadingTypes } from "../../../constant";
import { useApi } from "../../../interfaces/api";

import "../toast_styles.css";
import { Cross1Icon, PlusIcon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";
import { API, URLS } from "../../../constant";
import type { ScopeResponse } from "../../../interfaces/serverData";
import { useSearch } from "../../SearchContext";

interface ConnectorProps {
    saveSettings: (
        connectionInput: string,
        connectionMetadata: ScopeResponse[],
    ) => void;
    enabledIntegrations: string[];
    botType: string;
    description: string;
}

const Shortcuts = (props: ConnectorProps) => {
    const api = useApi();

    const timerRef = useRef(0);

    const { searchBarFilters, loading } = useSearch();

    const [loadingState, setLoadingState] = useState<number>(
        loadingTypes.loading,
    ); // 0: loading, 1: loaded, 2: error

    const [loadingDropdown, setLoadingDropdown] = useState<number>(
        loadingTypes.loading,
    );

    const [integrations, setIntegrations] = useState<string[]>([]);
    const [selectedIntegration, setSelectedIntegration] = useState<string>(
        integrations[0],
    );

    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        return () => clearTimeout(timerRef.current);
    }, []);

    const navigate = useNavigate();
    function save() {
        setModalOpen(false);
        const integrationMap = new Map<string, string>([
            ["GitHub Ticket", "GitHubTicket"],
            ["Linear Ticket", "Linear"],
            ["Jira Ticket", "Jira"],
        ]);

        const mapping = integrationMap.get(selectedIntegration);
        if (mapping !== undefined) {
            if (props.enabledIntegrations.includes(mapping)) {
                props.saveSettings(mapping, [selectedSourceMetadata]);
            } else {
                navigate(-1);
            }
        } else {
            navigate(-1);
        }
    }

    const [selectedSourceMetadata, setSelectedSourceMetadata] =
        useState<ScopeResponse>({ key: "", name: "" });
    function updateSelectedSourceMetadata(value: string) {
        const selectedItem: ScopeResponse = JSON.parse(value);
        setSelectedSourceMetadata(selectedItem);
    }

    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const [sources, setSources] = useState<ScopeResponse[]>([]);

    useEffect(() => {
        if (!modalOpen) return;
        const integrationMap = new Map<string, string>([
            ["GitHubTicket", "GitHub Ticket"],
            ["Linear", "Linear Ticket"],
            ["Jira", "Jira Ticket"],
        ]);

        const integrationsList: string[] = [];
        for (const integration of props.enabledIntegrations) {
            const mapping = integrationMap.get(integration);
            if (mapping !== undefined) {
                integrationsList.push(mapping);
            }
        }
        setIntegrations(integrationsList);
        if (integrationsList.length > 0) {
            setSelectedIntegration(integrationsList[0]);
        }

        setLoadingState(loadingTypes.loaded);
    }, [props.enabledIntegrations, modalOpen]);

    useEffect(() => {
        const integrationMap = new Map<string, string>([
            ["GitHub Ticket", "GitHubTicket"],
            ["Linear Ticket", "Linear"],
            ["Jira Ticket", "Jira"],
        ]);
        setLoadingDropdown(loadingTypes.loading);
        const mapping = integrationMap.get(selectedIntegration);
        if (mapping) {
            const requestData = {
                type: mapping,
            };
            api.post(URLS.serverUrl + API.getScopes, requestData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    const dataItems: ScopeResponse[] = res.data.data;
                    setSources(dataItems);
                    if (dataItems.length > 0) {
                        setSelectedSourceMetadata(dataItems[0]);
                    }
                    setLoadingDropdown(loadingTypes.loaded);
                })
                .catch((res) => {
                    setLoadingDropdown(loadingTypes.error);
                });
        }
    }, [selectedIntegration, api]);

    return (
        <div>
            <Dialog.Root open={modalOpen} onOpenChange={setModalOpen}>
                <Dialog.Trigger>
                    <Button
                        style={{ width: "100%" }}
                        variant={"ghost"}
                        size="1"
                    >
                        <PlusIcon />
                    </Button>
                </Dialog.Trigger>
                <Dialog.Content
                    style={{
                        width: "fit-content",
                        maxWidth: "450px",
                        paddingBottom: "30px",
                        paddingRight: "30px",
                        paddingLeft: "30px",
                    }}
                >
                    <Flex direction="column" gap="1">
                        <Flex direction="column">
                            <Flex
                                direction="row"
                                justify={"between"}
                                align={"center"}
                            >
                                <Heading size="3" weight={"medium"}>
                                    Create Shortcuts from {props.botType}
                                </Heading>
                                <Button
                                    variant="ghost"
                                    onClick={() => {
                                        setModalOpen(false);
                                    }}
                                >
                                    <Cross1Icon />
                                </Button>
                            </Flex>
                            <Separator
                                size="4"
                                my="4"
                                style={{ width: "100%" }}
                            />
                        </Flex>

                        {loadingState === loadingTypes.loading && (
                            <Card style={{ flex: 1, padding: "20px" }}>
                                <Skeleton>
                                    <Text>{loremIpsum}</Text>
                                </Skeleton>
                            </Card>
                        )}
                        {loadingState === loadingTypes.error && (
                            <Callout.Root
                                size="1"
                                variant="outline"
                                color="red"
                            >
                                <Callout.Text>
                                    Sorry, something's wrong! Please notify us
                                    at support@askassembly.app.
                                </Callout.Text>
                            </Callout.Root>
                        )}
                        {loadingState === loadingTypes.loaded && (
                            <Flex direction={"column"} gap="2" align="start">
                                <Text style={{ marginBottom: "15px" }} size="2">
                                    {props.description}
                                </Text>

                                <Text size="2" weight={"medium"}>
                                    Integration:
                                </Text>

                                {props.enabledIntegrations.length > 0 ? (
                                    <Select.Root
                                        onValueChange={setSelectedIntegration}
                                        defaultValue={integrations[0]}
                                        size={"2"}
                                    >
                                        <Select.Trigger
                                            variant="surface"
                                            style={{
                                                marginBottom: "15px",
                                                width: "100%",
                                            }}
                                        />
                                        <Select.Content>
                                            {integrations.map((integration) => {
                                                return (
                                                    <Select.Item
                                                        key={integration}
                                                        value={integration}
                                                    >
                                                        {integration}
                                                    </Select.Item>
                                                );
                                            })}
                                        </Select.Content>
                                    </Select.Root>
                                ) : (
                                    <Text color="red" size="1">
                                        Oops! Looks like you'll need to set up
                                        your GitHub, Jira, or Linear integration
                                        first to use the bot.
                                    </Text>
                                )}
                                {loadingDropdown === loadingTypes.loading && (
                                    <Skeleton>
                                        <Flex align="center" direction="column">
                                            <Text size="3" weight={"medium"}>
                                                Select where to create a ticket:
                                            </Text>
                                            <Box height="3vh" width="40vh" />
                                        </Flex>
                                    </Skeleton>
                                )}
                                {loadingDropdown === loadingTypes.loaded && (
                                    <Flex
                                        direction={"column"}
                                        gap="2"
                                        align="start"
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        <Text size="2" weight={"medium"}>
                                            Select where to create a ticket:
                                        </Text>
                                        <Select.Root
                                            onValueChange={
                                                updateSelectedSourceMetadata
                                            }
                                            defaultValue={JSON.stringify(
                                                sources[0],
                                            )}
                                            size={"2"}
                                        >
                                            <Select.Trigger
                                                variant="surface"
                                                style={{
                                                    marginBottom: "15px",
                                                    width: "100%",
                                                }}
                                            />
                                            <Select.Content>
                                                {sources.map((channel) => {
                                                    const valueItem =
                                                        channel.name;
                                                    return (
                                                        <Select.Item
                                                            key={valueItem}
                                                            value={JSON.stringify(
                                                                channel,
                                                            )}
                                                        >
                                                            {valueItem}
                                                        </Select.Item>
                                                    );
                                                })}
                                            </Select.Content>
                                        </Select.Root>
                                    </Flex>
                                )}

                                {loadingDropdown === loadingTypes.error && (
                                    <Skeleton>
                                        <Select.Root />
                                    </Skeleton>
                                )}

                                <Button
                                    style={{
                                        width: "auto",
                                        alignSelf: "end",
                                    }}
                                    onClick={save}
                                    disabled={
                                        loadingDropdown === loadingTypes.error
                                    }
                                >
                                    {props.enabledIntegrations.length > 0
                                        ? "Add"
                                        : "Go to Manage Integrations"}
                                </Button>
                            </Flex>
                        )}
                    </Flex>
                </Dialog.Content>
            </Dialog.Root>
        </div>
    );
};

export default Shortcuts;
