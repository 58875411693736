// DialogContext.tsx
import type React from "react";
import { type ReactNode, createContext, useContext, useState } from "react";

interface DialogContextProps {
    isDialogOpen: boolean;
    openDialog: () => void;
    closeDialog: () => void;
}

const DialogContext = createContext<DialogContextProps | undefined>(undefined);

export const useDialog = (): DialogContextProps => {
    const context = useContext(DialogContext);
    if (!context) {
        throw new Error("useDialog must be used within a DialogProvider");
    }
    return context;
};

interface DialogProviderProps {
    children: ReactNode;
}

export const DialogProvider: React.FC<DialogProviderProps> = ({ children }) => {
    const [isDialogOpen, setDialogOpen] = useState<boolean>(false);

    const openDialog = () => setDialogOpen(true);
    const closeDialog = () => setDialogOpen(false);

    return (
        <DialogContext.Provider
            value={{ isDialogOpen, openDialog, closeDialog }}
        >
            {children}
        </DialogContext.Provider>
    );
};
