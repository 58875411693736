import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/component/shadcn/ui/table";
import { loadingTypes } from "@/constant";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { OrgInfoResponse, ScopeResponse } from "@/interfaces/serverData";
import { ClipboardCopyIcon, PaperPlaneIcon } from "@radix-ui/react-icons";
import {
    Box,
    Button,
    Callout,
    Card,
    Code,
    Flex,
    Separator,
    Skeleton,
    Text,
} from "@radix-ui/themes";
import { useEffect, useState } from "react";
import IntegrationHeader from "./IntegrationHeader";

const ChatWidgetIntegration = () => {
    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const [apiKeyInfo, setApiKeyInfo] = useState<ScopeResponse>({
        key: "",
        name: "",
    });

    // function convertUnixTimestampToReadableDate(timestamp: number): string {
    //     if (timestamp === 0) {
    //         return "";
    //     }
    //     const date = new Date(timestamp * 1000);
    //     return date.toLocaleString();
    // }

    const [loadingState, setLoadingState] = useState<number>(
        loadingTypes.loading,
    );
    const api = useApi();

    const [integrationEnabled, setIntegrationEnabled] =
        useState<boolean>(false);

    useEffect(() => {
        const requestData = {
            types: ["ChatWidget"],
        };
        api.post(URLS.serverUrl + API.getItemsByOrgID, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.data) {
                        const orgInfo: OrgInfoResponse = res.data.data;
                        if (orgInfo.ChatWidget) {
                            setIntegrationEnabled(true);

                            const requestData = {
                                type: "ChatWidget",
                            };
                            api.post(
                                URLS.serverUrl + API.getScopes,
                                requestData,
                                {
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                },
                            )
                                .then((res) => {
                                    if (res.status === 200) {
                                        if (res.data.data) {
                                            setApiKeyInfo(res.data.data[0]);
                                            setLoadingState(1);
                                        }
                                    }
                                })
                                .catch((res) => {
                                    setLoadingState(2);
                                });
                        }
                        setLoadingState(1);
                    }
                } else {
                    console.log("failed to get results");
                    setLoadingState(2);
                }
            })
            .catch((res) => {
                console.log("failed to get repository");
                setLoadingState(2);
            });
    }, [api]);

    const chatwidgetContinue = async () => {
        const serverUrl = URLS.serverUrl ? URLS.serverUrl : "";
        const requestData = {
            type: "ChatWidget",
        };
        api.post(serverUrl + API.authenticateIntegration, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                window.location.reload();
            })
            .catch((res) => {
                setLoadingState(2);
            });
    };

    const [showApiKey, setShowApiKey] = useState(false);

    const toggleApiKeyVisibility = () => {
        setShowApiKey(!showApiKey);
    };

    return (
        <div className="bg-gray-500">
            <Box
                mt={"5%"}
                ml={{ lg: "24%", md: "18%", sm: "15%" }}
                mr={{ lg: "24%", md: "18%", sm: "15%" }}
            >
                <Flex direction={"column"} align={"start"} gap="6" mb="4">
                    <IntegrationHeader
                        integrationType="Chat Widget"
                        description="Turn your content into an AI search and support copilot with in-app embedded chat."
                        SvgIcon={PaperPlaneIcon}
                    />
                    <Flex style={{ width: "100%", justifyContent: "flex-end" }}>
                        {loadingState === loadingTypes.loaded &&
                        !integrationEnabled ? (
                            <Button onClick={chatwidgetContinue}>Enable</Button>
                        ) : (
                            <Flex gap="2" direction="column">
                                <Button disabled={true}>Enabled</Button>
                            </Flex>
                        )}
                    </Flex>
                </Flex>
                {loadingState === loadingTypes.loading && (
                    <Skeleton>
                        <Text>
                            {[...Array(2)].map((_, index) => (
                                <Text key={null}>{loremIpsum}</Text>
                            ))}
                        </Text>
                    </Skeleton>
                )}
                {loadingState === loadingTypes.error && (
                    <Callout.Root size="1" variant="outline" color="red">
                        <Callout.Text>
                            Sorry, something's wrong! Please notify us at
                            support@askassembly.app.
                        </Callout.Text>
                    </Callout.Root>
                )}
                {loadingState === loadingTypes.loaded && integrationEnabled && (
                    <Flex direction={"column"} gap="2" mb="5">
                        <Card
                            style={{
                                paddingRight: "0px",
                                paddingLeft: "0px",
                            }}
                        >
                            <Flex
                                direction={"column"}
                                gap="2"
                                mb="2"
                                justify={"between"}
                            >
                                <Flex
                                    direction={"row"}
                                    justify={"between"}
                                    align={"start"}
                                    style={{
                                        paddingLeft: "20px",
                                        paddingRight: "20px",
                                        paddingTop: "5px",
                                        paddingBottom: "5px",
                                    }}
                                >
                                    <Flex direction={"column"} maxWidth={"75%"}>
                                        <Text size={"2"}>API Key</Text>
                                        <Text size={"1"} color="gray">
                                            Use this API Key to set up your chat
                                            widget.
                                        </Text>
                                    </Flex>
                                </Flex>
                                {apiKeyInfo.key !== "" && (
                                    <Separator
                                        size={"4"}
                                        mt="1"
                                        mb="1"
                                        style={{ width: "100%" }}
                                    />
                                )}
                                {apiKeyInfo.key !== "" && (
                                    <Flex
                                        style={{
                                            paddingRight: "20px",
                                            paddingLeft: "20px",
                                        }}
                                        direction="row"
                                        gap="3"
                                        align="center"
                                        wrap="wrap"
                                    >
                                        <Table className="min-w-full leading-normal">
                                            <TableHeader>
                                                <TableRow>
                                                    <TableHead className="w-[100px]">
                                                        API Key
                                                    </TableHead>
                                                    <TableHead>
                                                        Visibility
                                                    </TableHead>
                                                    <TableHead>Copy</TableHead>
                                                </TableRow>
                                            </TableHeader>
                                            <TableBody>
                                                <TableRow key={apiKeyInfo.key}>
                                                    <TableCell className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                        <p className="text-xs">
                                                            {showApiKey
                                                                ? `${apiKeyInfo.key.substring(0, 45)}*****`
                                                                : "*".repeat(
                                                                      70,
                                                                  )}
                                                        </p>
                                                    </TableCell>
                                                    <TableCell className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                        <Button
                                                            size="1"
                                                            radius="medium"
                                                            variant="soft"
                                                            onClick={
                                                                toggleApiKeyVisibility
                                                            }
                                                        >
                                                            {showApiKey
                                                                ? "Hide"
                                                                : "Show"}
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                                        <Button
                                                            size="1"
                                                            radius="medium"
                                                            variant="soft"
                                                            onClick={() =>
                                                                navigator.clipboard.writeText(
                                                                    apiKeyInfo.key,
                                                                )
                                                            }
                                                        >
                                                            <ClipboardCopyIcon />
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Flex>
                                )}
                            </Flex>
                        </Card>
                    </Flex>
                )}

                {loadingState === loadingTypes.loaded && integrationEnabled && (
                    <Flex direction={"column"} gap="2" mb="2">
                        <Card
                            style={{
                                paddingRight: "0px",
                                paddingLeft: "0px",
                            }}
                        >
                            <Flex
                                direction={"column"}
                                gap="2"
                                mb="2"
                                justify={"between"}
                            >
                                <Flex
                                    direction={"row"}
                                    justify={"between"}
                                    align={"start"}
                                    style={{
                                        paddingLeft: "20px",
                                        paddingRight: "20px",
                                        paddingTop: "5px",
                                        paddingBottom: "5px",
                                    }}
                                >
                                    <Flex direction={"column"} maxWidth={"75%"}>
                                        <Text size={"2"}>
                                            Instructions to add Chat Widget
                                        </Text>
                                    </Flex>
                                </Flex>

                                <Flex
                                    style={{
                                        paddingRight: "20px",
                                        paddingLeft: "20px",
                                    }}
                                    direction="column"
                                    gap="3"
                                    align="start"
                                    wrap="wrap"
                                >
                                    <Text size="1">
                                        Add the code below to your{" "}
                                        <Code>index.html</Code>
                                        file.
                                    </Text>
                                    <Code size="2">
                                        {`<script src="https://assembly-ai.github.io/index.js"></script>`}
                                        <br />
                                        {`<link href="https://assembly-ai.github.io/index.css" rel="stylesheet" type="text/css">`}
                                    </Code>
                                    <Text size="1">
                                        If you don't use Tailwind css, add the
                                        following script.
                                    </Text>
                                    <Code size="2">
                                        {` <script src="https://cdn.tailwindcss.com"></script>`}
                                    </Code>
                                    <Text size="1" style={{ width: "100%" }}>
                                        You'll need to set up configurations for
                                        this component. Replace the api key with
                                        the key above.
                                    </Text>

                                    <Code size="2" style={{ width: "100%" }}>
                                        {`<script>
                                                window.AssemblyAIConfig = { 
                                                    questions: ["question 1", "question 2"],
                                                    title: "Assembly",
                                                    url: "<LOGO_URL>",
                                                    api_key: "<API_KEY>",
                                                };
                                            </script>`
                                            .split("\n") // Split the string into lines
                                            .map((line, index) => (
                                                <div key={line}>
                                                    {line}
                                                    <br />
                                                </div>
                                            ))}
                                    </Code>
                                </Flex>
                            </Flex>
                        </Card>
                    </Flex>
                )}
            </Box>
        </div>
    );
};

export default ChatWidgetIntegration;
