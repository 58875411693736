import { useEffect } from "react";

const Redirect = () => {
    useEffect(() => {
        // Replace with your paid domain URL
        window.location.href = "https://www.dashboard.askassembly.app/";
    }, []);

    return null;
};

export default Redirect;
