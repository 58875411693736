import { Button } from "@/component/shadcn/ui/button";
import {
    Command,
    CommandGroup,
    CommandItem,
    CommandList,
} from "@/component/shadcn/ui/command";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    HistoryResponse,
    Query,
    Ticket,
    TicketTopics,
} from "@/interfaces/serverData";
import type { GetTopicsResponse } from "@/interfaces/serverData";
import { cn } from "@/lib/utils";
import { DotFilledIcon, PlusIcon } from "@radix-ui/react-icons";
import { Badge, Callout, Skeleton, Text } from "@radix-ui/themes";
import { CheckIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { TicketTagDropdown } from "./TicketTagDropdown";
import type {
    QueryObserverResult,
    RefetchOptions,
} from "@tanstack/react-query";

interface TicketTopicsDropdownProps<TData> {
    ticket: Ticket;
    userID: string;
    refetchThreadData: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<HistoryResponse[], Error>>;
}

export function TicketTopicsDropdown<TData extends Query>({
    ticket,
    userID,
    refetchThreadData,
}: TicketTopicsDropdownProps<TData>) {
    const api = useApi();
    const [topicsSelected, setTopicsSelected] = useState<string[]>(
        ticket.topic || [],
    );
    const [topics, setTopics] = useState<GetTopicsResponse[]>([]);
    const [topicsMap, setTopicsMap] = useState(new Map());
    const [loadingState, setLoadingState] = useState<number>(0); // 0: loading, 1: loaded, 2: error

    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.";

    // Grab the list of topics for the org
    useEffect(() => {
        api.get(URLS.serverUrl + API.getTopics, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    const topics: GetTopicsResponse[] = res.data.data;

                    const map = new Map<string, GetTopicsResponse>();

                    for (const topic of topics) {
                        map.set(topic.topic_name, topic);
                    }
                    setTopicsMap(map);
                    setTopics(topics);
                    setLoadingState(1);
                } else {
                    setLoadingState(2);
                }
            })
            .catch(() => {
                console.log("Did not grab topics from db successfully");
                setLoadingState(2);
            });
    }, [api]);

    function saveTopics(newTopicsSelected: string[]) {
        const requestData: TicketTopics = {
            id: ticket.id,
            added_topics: newTopicsSelected.filter(
                (t) => !topicsSelected.includes(t),
            ),
            deleted_topics: topicsSelected.filter(
                (t) => !newTopicsSelected.includes(t),
            ),
            source: "Web",
            user_id: userID,
        };
        api.patch(URLS.serverUrl + API.saveTicket, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                refetchThreadData();
                console.log(`Updated topics ${newTopicsSelected} successfully`);
            } else {
                console.log("Call to update topics failed");
            }
        });
    }

    const toggleTopicSelection = (topic: GetTopicsResponse) => {
        const isSelected = topicsSelected.includes(topic.topic_name);
        const newTopicsSelected = isSelected
            ? topicsSelected.filter((t) => t !== topic.topic_name)
            : [...topicsSelected, topic.topic_name];

        setTopicsSelected(newTopicsSelected);
        saveTopics(newTopicsSelected);
    };

    return (
        <div className="text-sm flex items-center">
            {loadingState === 0 && (
                <div>
                    <Skeleton>
                        <Text>
                            {[...Array(6)].map((_, index) => (
                                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                <Text key={index}>{loremIpsum}</Text>
                            ))}
                        </Text>
                    </Skeleton>
                </div>
            )}
            {loadingState === 1 && topics && (
                <div className="w-full inherit">
                    <div className="flex flex-wrap justify-start p-1.5 data-[state=open]:bg-muted">
                        <TicketTagDropdown ticket={ticket} userID={userID} />
                        {topicsSelected.map((topic) => (
                            <Badge
                                color="gray"
                                size="2"
                                radius="full"
                                variant="outline"
                                className="m-1"
                                key={topic}
                            >
                                <div className="flex flex-row items-center">
                                    <DotFilledIcon
                                        color={
                                            topicsMap.get(topic)?.color &&
                                            topicsMap.get(topic)?.color !== ""
                                                ? topicsMap.get(topic)?.color
                                                : "#9B9EF0"
                                        }
                                        style={{ transform: "scale(1.8)" }}
                                    />
                                    <p className="pl-0.3">{topic}</p>
                                </div>
                            </Badge>
                        ))}
                        <DropdownMenu>
                            <DropdownMenuTrigger
                                asChild
                                className="hover:bg-muted"
                            >
                                <Button
                                    variant="ghost"
                                    className="flex flex-wrap justify-start p-1.5 gap-2 data-[state=open]:bg-muted"
                                >
                                    <PlusIcon />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent
                                align="end"
                                className="max-h-60 overflow-y-auto p-2"
                            >
                                <Command>
                                    <CommandList>
                                        <CommandGroup>
                                            {topics.map((topic) => {
                                                const isSelected =
                                                    topicsSelected.includes(
                                                        topic.topic_name,
                                                    );
                                                return (
                                                    <CommandItem
                                                        key={topic.id}
                                                        onSelect={() =>
                                                            toggleTopicSelection(
                                                                topic,
                                                            )
                                                        }
                                                        className="py-1 hover:bg-secondary cursor-pointer flex flex-row items-center"
                                                    >
                                                        <div
                                                            className={cn(
                                                                "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                                                                isSelected
                                                                    ? "bg-primary text-primary-foreground"
                                                                    : "opacity-50",
                                                            )}
                                                        >
                                                            {isSelected && (
                                                                <CheckIcon className="h-4 w-4" />
                                                            )}
                                                        </div>
                                                        <p>
                                                            {topic.topic_name}
                                                        </p>
                                                    </CommandItem>
                                                );
                                            })}
                                        </CommandGroup>
                                    </CommandList>
                                </Command>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </div>
                </div>
            )}
            {loadingState === 2 && (
                <Callout.Root size="1" variant="outline" color="red">
                    <Callout.Text>
                        Sorry, something's wrong with loading the topic
                        selections! Please notify us at support@askassembly.app.
                    </Callout.Text>
                </Callout.Root>
            )}
        </div>
    );
}
