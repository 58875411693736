import { Theme } from "@radix-ui/themes";
import "@radix-ui/themes/styles.css";
import { PostHogProvider } from "posthog-js/react";
import type React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./output.css";
import { AuthProvider } from "@propelauth/react";
import { DialogProvider } from "./DialogContext";
import Redirect from "./pages/RedirectPage";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);

const options = {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

interface PostHogProviderWrapperProps {
    children: React.ReactNode;
}

const local_host = "http://127.0.0.1:3000";
const render_url = "https://assembly-web-app.onrender.com";
const PostHogProviderWrapper: React.FC<PostHogProviderWrapperProps> = ({
    children,
}) => {
    if (window.location.host === local_host) return <>{children}</>;
    return (
        <PostHogProvider
            apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
            options={options}
        >
            {children}
        </PostHogProvider>
    );
};

if (window.location.hostname === render_url) {
    root.render(<Redirect />);
} else {
    root.render(
        // <React.StrictMode>
        <AuthProvider authUrl={process.env.REACT_APP_AUTH_URL!}>
            <Theme accentColor="iris">
                <BrowserRouter>
                    <PostHogProviderWrapper>
                        <DialogProvider>
                            <App />
                        </DialogProvider>
                    </PostHogProviderWrapper>
                </BrowserRouter>
            </Theme>
        </AuthProvider>,
        // </React.StrictMode>
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
