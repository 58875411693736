import { GradientPicker } from "@/design/GradientPicker";
import type {
    GetUserResponse,
    GetTopicsResponse,
} from "@/interfaces/serverData";
import { AvatarIcon, CaretUpIcon } from "@radix-ui/react-icons";
import { Badge } from "@radix-ui/themes";
import type { badgePropDefs } from "@radix-ui/themes/dist/cjs/components/badge.props";
import type { ColumnDef } from "@tanstack/react-table";
import {
    type MembersRowState,
    type TagsRowState,
    queryToLabel,
} from "./constants";
import { DataTableColumnHeader } from "./data-table-column-header";
import { DataTableRowActions } from "./data-table-row-actions";
export type BadgeColor = (typeof badgePropDefs.color.values)[number];

export function generateTagsColumns(
    rowState: Map<string, TagsRowState>,
    saveTag: (id: string, newState: Partial<TagsRowState>) => void,
): ColumnDef<GetTopicsResponse>[] {
    const columns: ColumnDef<GetTopicsResponse>[] = [
        {
            accessorKey: "id",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title="ID"
                    className="hidden w-0"
                />
            ),
            cell: ({ row }) => {
                return <div className="hidden w-0">{row.original.id}</div>;
            },
            size: 0,
        },
        {
            accessorKey: "tag",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={queryToLabel.tag}
                />
            ),
            cell: ({ row }) => {
                let color = "#9B9EF0";

                if (
                    rowState.get(row.id) !== undefined &&
                    rowState.get(row.id)?.colorSelected !== ""
                ) {
                    // biome-ignore lint/style/noNonNullAssertion: <explanation>
                    color = rowState.get(row.id)!.colorSelected;
                }

                return (
                    <div className="text-xs flex flex-row items-center gap-3">
                        <GradientPicker
                            id={row.original.id}
                            background={color}
                            setBackground={saveTag}
                        />
                        <div className="flex flex-row items-center">
                            <p className="pl-0.3">{row.original.topic_name}</p>
                        </div>
                    </div>
                );
            },

            filterFn: (row, id, value) => {
                // Return true to include all rows
                if (!value || value.length === 0) {
                    return true;
                }
                const query = row.original.topic_name;
                return query.toLowerCase().includes(value.toLowerCase());
            },
            sortingFn: (rowA, rowB, columnId) => {
                return rowA.original.topic_name.localeCompare(
                    rowB.original.topic_name,
                );
            },
            enableSorting: true,
            size: 300,
        },
        {
            accessorKey: "description",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={queryToLabel.description}
                />
            ),
            cell: ({ row }) => {
                const description: string = row.original.description ?? "";

                return <span className="px-1 text-xs">{description}</span>;
            },
            filterFn: (row, id, value) => {
                return value.includes(row.original.description);
            },
            enableSorting: true,
            enableResizing: true,
            size: 600,
        },
        {
            accessorKey: "count",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={queryToLabel.count}
                />
            ),
            cell: ({ row }) => {
                return (
                    <div className="flex flex-end justify-end float-right pr-2">
                        <Badge
                            color="gray"
                            size="1"
                            radius="full"
                            className="my-1"
                        >
                            <CaretUpIcon />{" "}
                            {row.original.bug_topic_count +
                                row.original.feature_topic_count +
                                row.original.query_topic_count +
                                row.original.unknown_topic_count}
                        </Badge>
                    </div>
                );
            },
            sortingFn: (rowA, rowB, columnId) => {
                const totalA: number =
                    Number.parseInt(rowA.original.bug_topic_count) +
                    Number.parseInt(rowA.original.feature_topic_count) +
                    Number.parseInt(rowA.original.query_topic_count) +
                    Number.parseInt(rowA.original.unknown_topic_count);
                const totalB =
                    Number.parseInt(rowB.original.bug_topic_count) +
                    Number.parseInt(rowB.original.feature_topic_count) +
                    Number.parseInt(rowB.original.query_topic_count) +
                    Number.parseInt(rowB.original.unknown_topic_count);
                return totalA - totalB;
            },
            enableSorting: true,
            size: 100,
        },
    ];
    return columns;
}

export function generateMembersColumns(
    rowState: Map<string, MembersRowState>,
    updateRowState: (id: string, newState: Partial<MembersRowState>) => void,
): ColumnDef<GetUserResponse>[] {
    const columns: ColumnDef<GetUserResponse>[] = [
        {
            accessorKey: "id",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title="ID"
                    className="hidden w-0"
                />
            ),
            cell: ({ row }) => {
                return (
                    <div className="hidden w-0">{rowState.get(row.id)?.id}</div>
                );
            },
            size: 0,
        },
        {
            accessorKey: "name",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={queryToLabel.name}
                    className="text-left"
                />
            ),
            cell: ({ row }) => {
                return (
                    <div className="px-3 py-2 text-sm flex items-center gap-3">
                        <div className={"lb-avatar w-6 h-6 rounded-md"}>
                            {rowState.get(row.id)?.picture_url ? (
                                <img
                                    className="lb-avatar-image"
                                    src={rowState.get(row.id)?.picture_url}
                                    alt={rowState.get(row.id)?.name}
                                />
                            ) : (
                                <AvatarIcon className="w-6 h-6" />
                            )}
                        </div>
                        <div className="flex flex-col">
                            {rowState.get(row.id)?.name}
                            <div className="text-muted-foreground text-xs">
                                {rowState.get(row.id)?.email}
                            </div>
                        </div>
                    </div>
                );
            },
            filterFn: (row, id, value) => {
                // No filter applied, include all rows
                if (!value || value.length === 0) {
                    return true;
                }
                const nameMatches =
                    rowState
                        .get(row.id)
                        ?.name.toLowerCase()
                        .includes(value.toLowerCase()) ?? false;
                const emailMatches =
                    rowState
                        .get(row.id)
                        ?.email.toLowerCase()
                        .includes(value.toLowerCase()) ?? false;
                return nameMatches || emailMatches;
            },
            enableSorting: true,
            enableResizing: true,
            size: 700,
        },
        {
            accessorKey: "role",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={queryToLabel.role}
                />
            ),
            cell: ({ row }) => {
                return (
                    <span className="px-3 text-sm">
                        {rowState.get(row.id)?.role}
                    </span>
                );
            },
            filterFn: (row, id, value) => {
                return value.includes(rowState.get(row.id)?.role);
            },
            enableSorting: true,
            enableResizing: true,
            size: 300,
        },
        {
            id: "actions",
            cell: ({ row }) => (
                <DataTableRowActions
                    id={row.id}
                    rowState={rowState.get(row.id)}
                    updateRowState={updateRowState}
                />
            ),
        },
    ];
    return columns;
}
