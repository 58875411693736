import type { HeaderType } from "@/IssuesTable/constants";
import { Button } from "@/component/shadcn/ui/button";
import { Card, CardContent } from "@/component/shadcn/ui/card";
import { getAnnouncementStatusIcon } from "@/utilities/methods";
import {
    CaretDownIcon,
    CaretUpIcon,
    QuestionMarkCircledIcon,
} from "@radix-ui/react-icons";
interface IssuesListHeaderCardProps {
    title: string;
    value: string;
    header_type: HeaderType;
    isExpanded: Map<string, boolean>;
    updateExpanded?: (type: string, value: boolean) => void;
}

export function AnnouncementsListHeaderCard({
    title: label,
    value,
    header_type,
    isExpanded,
    updateExpanded,
}: IssuesListHeaderCardProps) {
    // TODO: make this based on the header_type, not just the status
    const Icon = getAnnouncementStatusIcon(value);
    return (
        <Card className="py-2 px-6 border-l-transparent border-r-transparent border-b-transparent bg-secondary">
            <CardContent className="flex items-center gap-2 text-[13px] p-0">
                {Icon ? (
                    <Icon className="ml-0.5 w-3.5 h-3.5" />
                ) : (
                    <QuestionMarkCircledIcon className="ml-0.5 w-3.5 h-3.5" />
                )}
                {label}
                <Button
                    type="submit"
                    variant="ghost"
                    onClick={() =>
                        updateExpanded
                            ? updateExpanded(value, !isExpanded.get(value))
                            : () => {}
                    }
                    className="p-1 m-0 hover:bg-transparent hover:text-current"
                >
                    {isExpanded.get(value) ? (
                        <CaretDownIcon className="w-3 h-3 -mx-1.5" />
                    ) : (
                        <CaretUpIcon className="w-3 h-3 -mx-1.5" />
                    )}
                </Button>
            </CardContent>
        </Card>
    );
}
