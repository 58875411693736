import { Button, Callout, Flex, Separator } from "@radix-ui/themes";
import axios, { type CancelTokenSource } from "axios";
import { usePostHog } from "posthog-js/react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CardComponentV2 from "../component/Cards/CardComponentV2";
import IntegrationsLookupComponent from "../component/Cards/IntegrationsLookupComponent";
import FirstSetupDialog from "../component/FirstSetupDialog";
import SearchBar from "../component/SearchBarComponent";
import type { FilterMetadata } from "../component/SideBar/SideBarComponent";
import { API, URLS } from "../constant";
import CardLoading from "../design/CardLoading";
import { useApi } from "../interfaces/api";
import type {
    DescribeResponse,
    DescribeResponseIntegration,
    ScopeResponse,
} from "../interfaces/serverData";
import type {
    SearchResponse,
    SearchResponseIntegration,
} from "../interfaces/serverData";
import { integrationMappingToRequest } from "./Admin/constant";
import { useSearch } from "./SearchContext";
interface SearchProps {
    // searchBarFilters: Map<string, FilterMetadata>;
    // setSearchBarFilters: React.Dispatch<
    //     React.SetStateAction<Map<string, FilterMetadata>>
    // >;
    isAdmin: boolean;
}

const SearchPage: React.FC<SearchProps> = ({ isAdmin }) => {
    const { searchBarFilters, setSearchBarFilters, loading } = useSearch();

    const api = useApi();

    const navigate = useNavigate(); // Hook to navigate
    const handleLogoClick = () => {
        navigate("/"); // Navigate to homepage
    };
    const [isSearchInitiated, setIsSearchInitiated] = useState(false);
    const [searchQuery, setSearchQuery] = useState<string>("");

    const [noResultsFound, setNoResultsFound] = useState<string>("");
    const [isloadedData, setIsLoadedData] = useState(0);
    // 0 means nothing is loaded, 1 is search response, 2 is bad response, 3 is search integration response
    const [respData, setRespData] = useState<SearchResponse[]>([]);
    const [respIntegrationData, setRespIntegrationData] = useState<
        SearchResponseIntegration[]
    >([]);

    const [searchResults, setSearchResults] = useState<DescribeResponse[]>([]);
    const [searchResultsIntegration, setSearchResultsIntegration] = useState<
        DescribeResponseIntegration[]
    >([]);

    const [cancelTokenSource, setCancelTokenSource] =
        useState<CancelTokenSource | null>(null);

    const [organizationName, setOrganizationName] = useState<string>("");

    const [showMore, setShowMore] = useState(false);

    const [showMoreLoadingScreen, setShowMoreLoadingScreen] = useState(false);
    const posthog = usePostHog();

    const [searchTerm, setSearchTerm] = useState("");

    const [filterInput, setFilterInput] = useState<string[]>(["Code"]);
    const [searchFilterForDescribe, setSearchFilterForDescribe] = useState<
        string[]
    >(["Code"]);

    function getItems(type: string) {
        const metadata = searchBarFilters.get(type);
        if (metadata) {
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
            const finalFilters: any[] = [];
            const granularFilters = metadata.granularFilters;
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
            granularFilters?.forEach((enabled: boolean, key: any) => {
                if (enabled) {
                    finalFilters.push(key);
                }
            });

            return finalFilters;
        }
        return [];
    }

    function getActiveFilters() {
        const enabledFilters: string[] = [];
        searchBarFilters.forEach((metadata: FilterMetadata, key: string) => {
            if (metadata?.enabled) {
                enabledFilters.push(key);
            }
        });
        return enabledFilters;
    }

    function handleBarToggle(type: string) {
        const temp = new Map(searchBarFilters);
        const metadata = temp.get(type);

        temp.forEach((metadata: FilterMetadata, key: string) => {
            if (key !== type) {
                metadata.enabled = false;
            }
        });

        if (metadata !== undefined) {
            metadata.enabled = !metadata.enabled;
            temp.set(type, metadata);
        }

        setSearchBarFilters(temp);
        setFilterInput([type]);
    }

    const handleSearchTermChange = (newTerm: string) => {
        setSearchTerm(newTerm);
    };

    function search(searchInput: string) {
        setIsSearchInitiated(true);
        setShowMore(false);
        setShowMoreLoadingScreen(false);
        setSearchResults((prev) => []);
        setSearchResultsIntegration((prev) => []);
        setRespData((prev) => []);
        setRespIntegrationData((prev) => []);

        if (searchInput.length === 0) {
            setNoResultsFound("Please enter something to search!");
            return;
        }
        setNoResultsFound("");

        if (cancelTokenSource) {
            cancelTokenSource.cancel(
                "Previous request cancelled because a new request was made.",
            );
        }

        const newCancelToken = axios.CancelToken.source();
        setCancelTokenSource((prev) => newCancelToken);

        setIsLoadedData(2); // loading screen

        interface Filter {
            source: string;
            in_items: string[];
        }

        const reqData: {
            query: string;
            filters: Filter[];
        } = {
            query: searchInput,
            filters: [],
        };

        let sourceType = "code";
        const myFilters: string[] = getActiveFilters();
        switch (
            myFilters[0] // TODO - change for multi filter support
        ) {
            case "Code": {
                sourceType = "code";
                const items: ScopeResponse[] = getItems("Code");
                const keys: string[] = [];
                for (const channel of items) {
                    keys.push(channel.key);
                }
                reqData.filters = [
                    {
                        source: sourceType,
                        in_items: keys,
                    },
                ];
                break;
            }
            case "Pull Requests": {
                sourceType = "PullRequest";
                const items: ScopeResponse[] = getItems("Pull Requests");
                const keys: string[] = [];
                for (const channel of items) {
                    keys.push(channel.key);
                }
                reqData.filters = [
                    {
                        source: sourceType,
                        in_items: keys,
                    },
                ];
                break;
            }
            case "Slack": {
                sourceType = myFilters[0];
                const items: ScopeResponse[] = getItems(sourceType);
                const keys: string[] = [];
                for (const channel of items) {
                    keys.push(channel.key);
                }

                reqData.filters = [
                    {
                        source: sourceType,
                        in_items: keys,
                    },
                ];
                break;
            }
            default: {
                sourceType = myFilters[0];
                const updatedSourceType =
                    integrationMappingToRequest.get(sourceType);
                reqData.filters = [
                    {
                        source: updatedSourceType!,
                        in_items: getItems(sourceType),
                    },
                ];
                break;
            }
        }
        posthog?.capture("search", {
            login_type: searchInput,
        });

        api.post(URLS.serverUrl + API.search, reqData, {
            headers: {
                "Content-Type": "application/json",
            },
            cancelToken: newCancelToken.token,
        })
            .then((res) => {
                if (res.status === 200) {
                    setSearchFilterForDescribe(myFilters);
                    if (
                        res.data === undefined ||
                        res.data === null ||
                        res.data.data === undefined ||
                        res.data.data === null
                    ) {
                        setNoResultsFound(
                            "No results found. Try searching for something else.",
                        );
                        posthog?.capture("no_results_found");
                        setIsLoadedData(0); // bad response
                        return;
                    } else if (res.data.data && res.data.data.length === 0) {
                        setNoResultsFound(
                            "No results found. Try searching for something else.",
                        );
                        posthog?.capture("no_results_found");
                        setIsLoadedData(0); // bad response
                        return;
                    } else if (res.data.data) {
                        let values = res.data.data;
                        values = values.filter((val: any) => {
                            return val !== null;
                        });
                        if (values.length > 5) {
                            values = values.splice(0, 5);
                        }
                        switch (sourceType) {
                            case "code":
                                setRespData(values);
                                break;
                            default:
                                setRespIntegrationData(values);
                                break;
                        }

                        setSearchQuery(searchInput);
                        posthog?.capture("results_found");
                    }

                    // setIsLoadedData(1);
                    // getSummaries();
                } else {
                    setNoResultsFound(
                        "Sorry, something's wrong! Please notify us at support@askassembly.app.",
                    );
                    setIsLoadedData(0);
                    return;
                }
            })
            .catch((res) => {
                if (axios.isCancel(res)) {
                    return;
                } else {
                    setNoResultsFound(
                        "Sorry, something's wrong! Please notify us at support@askassembly.app.",
                    );
                    setIsLoadedData(0);
                    return;
                }
            });
    }

    const updateSearchData = useCallback(
        async (idBatch: string[], showMoreEnabled: boolean) => {
            const objects = [];
            for (const id of idBatch) {
                const elem = {
                    id: id,
                    source: "code",
                };
                objects.push(elem);
            }
            const requestData = {
                objects: objects,
                query: searchQuery,
            };
            await api
                .post(URLS.serverUrl + API.describe, requestData, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    cancelToken: cancelTokenSource?.token,
                })
                .then((summaryRes) => {
                    setShowMoreLoadingScreen(false);
                    const summaryResponse: DescribeResponse =
                        summaryRes.data.data;

                    setSearchResults((prev) => [...prev, summaryResponse]);
                    if (showMoreEnabled) {
                        setShowMore(true);
                    }
                })
                .catch((res) => {
                    if (axios.isCancel(res)) {
                        setShowMoreLoadingScreen(false);
                        return;
                    } else {
                        setNoResultsFound(
                            "Sorry, something's wrong! Please notify us at support@askassembly.app.",
                        );
                    }
                });
        },
        [searchQuery, cancelTokenSource],
    );

    const getSummaries = useCallback(async () => {
        if (respData.length === 0) {
            return;
        }
        const idList: string[] = respData.map(
            (item: SearchResponse) => item.id,
        );
        if (idList.length === 0) {
            return;
        }

        // only do first PROMPT
        let showMoreVal = false;
        if (idList.length > 1) {
            showMoreVal = true;
        }
        await updateSearchData(idList.slice(0, 1), showMoreVal);
        setIsLoadedData(1);
    }, [respData, updateSearchData]);

    const updateIntegrationData = useCallback(
        async (
            idBatch: string[],
            showMoreEnabled: boolean,
            myFilterInput: string[],
        ) => {
            const objects = [];
            for (const id of idBatch) {
                const elem = {
                    id: id,
                    source: integrationMappingToRequest.get(myFilterInput[0]),
                };
                objects.push(elem);
            }
            const requestData = {
                objects: objects,
                query: searchQuery,
            };

            await api
                .post(URLS.serverUrl + API.describe, requestData, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    cancelToken: cancelTokenSource?.token,
                })
                .then((summaryRes) => {
                    setShowMoreLoadingScreen(false);
                    const summaryResponse: DescribeResponseIntegration =
                        summaryRes.data.data;
                    setSearchResultsIntegration((prev) => [
                        ...prev,
                        summaryResponse,
                    ]);

                    if (showMoreEnabled) {
                        setShowMore(true);
                    }
                    setIsLoadedData(3);
                })
                .catch((res) => {
                    if (axios.isCancel(res)) {
                        setShowMoreLoadingScreen(false);
                        return;
                    } else {
                        setNoResultsFound(
                            "Sorry, something's wrong! Please notify us at support@askassembly.app.",
                        );
                        // setIsLoadedData(0);
                    }
                });
        },
        [searchQuery, cancelTokenSource],
    );

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    const getIntegrationSummaries = useCallback(async () => {
        if (respIntegrationData.length === 0) {
            return;
        }

        const idList: string[] = respIntegrationData.map(
            (item: SearchResponseIntegration) => item.id,
        );
        if (idList.length === 0) {
            return;
        }

        // only do first PROMPT

        if (idList.length > 5) {
            await updateIntegrationData(
                idList.slice(0, 5),
                true,
                searchFilterForDescribe,
            );
        } else {
            await updateIntegrationData(idList, false, searchFilterForDescribe);
        }
    }, [respIntegrationData, updateIntegrationData]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        getSummaries();
    }, [respData, getSummaries]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        getIntegrationSummaries();
    }, [respIntegrationData, getIntegrationSummaries]);

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const openDialog = () => setIsDialogOpen(true);
    const closeDialog = () => setIsDialogOpen(false);
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (loading) {
            if (searchBarFilters && searchBarFilters.size === 0) {
                openDialog();
            } else {
                closeDialog();
            }
        }
    }, [searchBarFilters, loading]);

    function createLink(
        inputFilePath: string,
        inputOwner: string,
        inputRepo: string,
        startLine: number,
        endLine: number,
        inputBranch: string,
    ): string {
        let githubLink = "https://github.com/";
        const firstSlashIndex = inputFilePath.indexOf("/");
        const restOfText = inputFilePath.substring(firstSlashIndex);

        // Formatting the string
        githubLink += `${inputOwner}/${inputRepo}/blob/${inputBranch}/${restOfText}#L${startLine}-L${endLine}`;

        return githubLink;
    }

    const handleExternalLink = (url: string) => {
        window.open(url, "_blank");
    };

    const showMoreOptions = async () => {
        setShowMore(false);
        setShowMoreLoadingScreen(true);

        if (respIntegrationData.length > 5) {
            let index = 0;
            const idList: string[] = respIntegrationData
                .slice(5)
                .map((item: SearchResponseIntegration) => item.id);
            if (idList.length === 0) {
                return;
            }
            do {
                setShowMoreLoadingScreen(true);
                await updateIntegrationData(
                    [idList[index]],
                    false,
                    searchFilterForDescribe,
                );
                index++;
            } while (index < idList.length);
        } else if (respData.length > 1) {
            const idList: string[] = respData
                .slice(1)
                .map((item: SearchResponse) => item.id);
            if (idList.length === 0) {
                return;
            }

            for (const id of idList) {
                setShowMoreLoadingScreen(true);
                await updateSearchData([id], false);
            }
        }
    };

    return (
        <div className="bg-gray-500">
            {loading && (
                <FirstSetupDialog
                    isDialogOpen={isDialogOpen}
                    closeDialog={closeDialog}
                    isAdmin={isAdmin}
                />
            )}
            <Flex direction="column" style={{ alignItems: "center" }} gap="6">
                <SearchBar
                    dropdown={searchBarFilters}
                    searchFn={search}
                    currentSearchTerm={searchTerm}
                    onSearchTermChange={handleSearchTermChange}
                    handleBarToggle={handleBarToggle}
                />

                <Separator orientation="horizontal" style={{ width: "100%" }} />

                {noResultsFound !== "" && (
                    <Callout.Root
                        size="1"
                        variant="outline"
                        color="red"
                        style={{ marginTop: "80px" }}
                    >
                        <Callout.Text>{noResultsFound}</Callout.Text>
                    </Callout.Root>
                )}
                {isloadedData === 2 && <CardLoading />}
                {isloadedData === 1 &&
                    searchResults.map((i: DescribeResponse, index: number) => {
                        const nodes = i.nodes;
                        if (
                            nodes === null ||
                            nodes === undefined ||
                            nodes.length === 0
                        ) {
                            return <div></div>;
                        }
                        let bestAnswer = false;
                        if (index === 0) {
                            bestAnswer = true;
                        } else {
                            bestAnswer = false;
                        }
                        const node = nodes[0];

                        let githubBlame = undefined;
                        if (
                            i.githubblame !== null &&
                            i.githubblame !== undefined &&
                            i.githubblame.length > 0
                        ) {
                            githubBlame = i.githubblame[0];
                        }
                        return (
                            <div>
                                <CardComponentV2
                                    githubblame={githubBlame}
                                    summary={i.summary}
                                    node={node}
                                    bestAnswer={bestAnswer}
                                />
                            </div>
                        );
                    })}
                {isloadedData === 3 &&
                    searchResultsIntegration.map(
                        (i: DescribeResponseIntegration, index: number) => {
                            if (index === 0) {
                                return (
                                    <div>
                                        <IntegrationsLookupComponent
                                            summary={i.summary}
                                            nodes={i.nodes}
                                            bestAnswer={true}
                                        />
                                    </div>
                                );
                            } else {
                                return (
                                    <div>
                                        <IntegrationsLookupComponent
                                            summary={i.summary}
                                            nodes={i.nodes}
                                            bestAnswer={false}
                                        />
                                    </div>
                                );
                            }
                        },
                    )}
                {showMore && (
                    <Button
                        onClick={() => {
                            showMoreOptions();
                        }}
                    >
                        Show More
                    </Button>
                )}

                {showMoreLoadingScreen && <CardLoading />}
            </Flex>
        </div>
    );
};

export default SearchPage;
