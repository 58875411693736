import { AnnouncementsSection } from "@/Ticket/AnnouncementsSection";
import { ExternalIssuesSection } from "@/Ticket/ExternalIssuesSection";
import { Card, CardContent } from "@/component/shadcn/ui/card";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    GetTopicsResponse,
    GetUserResponse,
    Insight,
    Query,
    RelatedIssue,
} from "@/interfaces/serverData";
import { getColorLight, processInsightMetadata } from "@/utilities/methods";
import {
    BookmarkIcon,
    CalendarIcon,
    ComponentBooleanIcon,
    DotFilledIcon,
} from "@radix-ui/react-icons";
import { Badge, DataList } from "@radix-ui/themes";
import { useEffect, useMemo, useState } from "react";
import SparklesIcon from "../images/icons8-sparkles-48.png";
import { EditableDescriptionInput } from "./EditableDescriptionInput";
import { EditableTitleInput } from "./EditableTitleInput";
import { InsightStatusDropdown } from "./InsightStatusDropdown";
import { RelatedIssuesDropdown } from "./RelatedIssuesDropdown";

const RelatedIssueCard = (
    relatedIssue: RelatedIssue,
    insightSelectedIsSaved: boolean,
    issueSelected: string | undefined,
    setIssueSelected?: React.Dispatch<React.SetStateAction<string | undefined>>,
) => {
    const isSelected = issueSelected === relatedIssue.org_specific_id;
    const handleClick = (id: string) => {
        return () => {
            if (insightSelectedIsSaved && setIssueSelected) {
                setIssueSelected(id);
            } else {
                const url = `/issue/${id}`;
                window.open(url, "_blank");
            }
        };
    };

    return (
        <Card
            className={`p-2 py-0 m-1 rounded ${isSelected ? "bg-sidebarBorder" : "hover:bg-muted"}`}
        >
            <CardContent className="p-1">
                <button
                    className="flex flex-col gap-1.5 text-xs bg-transparent border-none p-0 cursor-pointer"
                    onClick={handleClick(relatedIssue.org_specific_id)}
                    type="button"
                >
                    <div className="flex items-center gap-2 truncate max-w-[445px]">
                        <Badge
                            color="iris"
                            size="1"
                            radius="full"
                            className="px-2 py-1 rounded-xl text-xs m-0"
                        >
                            {relatedIssue.org_specific_id}
                        </Badge>
                        {relatedIssue.title
                            ? relatedIssue.title
                            : relatedIssue.query}
                    </div>
                    {!insightSelectedIsSaved && (
                        <div className="text-xs text-muted-foreground px-1 overflow-hidden whitespace-normal break-words max-w-[445px]">
                            {relatedIssue.query}
                        </div>
                    )}
                </button>
            </CardContent>
        </Card>
    );
};

interface InsightDisplayProps {
    insight: Insight;
    userID: string;
    insightSelectedIsSaved: boolean;
    topicsMap: Map<string, GetTopicsResponse>;
    issueSelected?: string | undefined;
    setIssueSelected?: React.Dispatch<React.SetStateAction<string | undefined>>;
    updateInsightState?: (newState: Partial<Insight>) => void;
    handleSaveAIGeneratedInsight?: (insight: Insight) => Promise<void>;
    issues?: Query[];
}

export function InsightDisplay({
    insight,
    updateInsightState,
    insightSelectedIsSaved,
    handleSaveAIGeneratedInsight,
    issues,
    userID,
    issueSelected,
    setIssueSelected,
    topicsMap,
}: InsightDisplayProps) {
    const api = useApi();
    const [isAIGenerated, topic, category] = insight
        ? processInsightMetadata(insight)
        : [false, "", ""];
    const [isSavingInsight, setIsSavingInsight] = useState(false);
    const [userInfo, setUserInfo] = useState<GetUserResponse>();
    const [pictureURL, setPictureURL] = useState<string>("");

    const sidebarLabels = "font-semibold p-1.5 text-sm mb-0 pb-0";

    const handleSave = async () => {
        if (insight && handleSaveAIGeneratedInsight) {
            setIsSavingInsight(true);
            try {
                await handleSaveAIGeneratedInsight(insight);
                console.log("Insight saved and data refetched");
            } finally {
                setIsSavingInsight(false);
            }
        }
    };

    const created_date = useMemo(() => {
        try {
            const d = new Date(insight.created_at);
            return d.toLocaleDateString();
        } catch (err) {
            console.log(
                `Could not convert ticket's created at timestamp ${insight.created_at} to a valid date, so using the original timestamp format. Error: ${err}`,
            );
            return insight.created_at;
        }
    }, [insight.created_at]);

    const updated_date = useMemo(() => {
        try {
            const d = new Date(insight.updated_at);
            return d.toLocaleDateString();
        } catch (err) {
            console.log(
                `Could not convert ticket's created at timestamp ${insight.updated_at} to a valid date, so using the original timestamp format. Error: ${err}`,
            );
            return insight.updated_at;
        }
    }, [insight.updated_at]);

    useEffect(() => {
        if (insight?.user_id) {
            api.post(
                URLS.serverUrl + API.getUser,
                {
                    user_id: insight?.user_id,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            )
                .then((res) => {
                    if (res.status === 200) {
                        setUserInfo(res.data.data);
                        if (res.data.data.logo !== "") {
                            setPictureURL(res.data.data.picture_url);
                        }
                    }
                })
                .catch((res) => {
                    console.log("Could not grab username");
                });
        }
    }, [api, insight]);

    return (
        <div className="flex flex-1 flex-col rounded-md p-4 h-full overflow-y-auto mb-20">
            {!insightSelectedIsSaved && (
                <div className="mx-3 py-2 px-3 text-xs flex items-center gap-1 bg-secondary rounded-md">
                    Click the bookmark icon to save and edit this AI Generated
                    Insight
                    <button
                        type="button"
                        className="pr-2 hover:bg-secondary rounded-md"
                        onClick={handleSave}
                        disabled={isSavingInsight}
                    >
                        <BookmarkIcon className="h-4 w-4 text-muted-foreground" />
                    </button>
                </div>
            )}
            <div className="flex flex-col items-start p-4">
                <div className="text-lg font-semibold p-1 w-full">
                    {insightSelectedIsSaved && updateInsightState ? (
                        <EditableTitleInput
                            insight={insight}
                            updateInsightState={updateInsightState}
                        />
                    ) : (
                        <div className="flex items-center gap-1">
                            {insight.title}
                            <img
                                src={SparklesIcon}
                                alt=""
                                className="h-7 w-7 rounded p-1"
                            />
                        </div>
                    )}
                </div>
                <div className="text-sm text-muted-foreground p-1 w-full">
                    {insightSelectedIsSaved && updateInsightState ? (
                        <EditableDescriptionInput
                            insight={insight}
                            updateInsightState={updateInsightState}
                        />
                    ) : (
                        insight.description
                    )}
                </div>
            </div>
            <div className="flex flex-col items-start gap-0 px-4 pb-2">
                <div className="font-semibold p-1.5 text-sm">Properties</div>
                <DataList.Root size="1" className="p-1.5">
                    {insightSelectedIsSaved && updateInsightState && (
                        <DataList.Item align="center">
                            <DataList.Label className="text-xs w-4">
                                Status
                            </DataList.Label>
                            <DataList.Value>
                                <InsightStatusDropdown
                                    insight={insight}
                                    updateInsightState={updateInsightState}
                                />
                            </DataList.Value>
                        </DataList.Item>
                    )}
                    {insightSelectedIsSaved && userInfo && (
                        <DataList.Item align="center">
                            <DataList.Label className="text-xs w-4">
                                Created By
                            </DataList.Label>
                            <DataList.Value>
                                <div className="flex items-center text-xs">
                                    <Badge
                                        color={"gray"}
                                        size="2"
                                        radius="full"
                                        variant="outline"
                                        className="mx-2.5 my-1.5"
                                    >
                                        <div
                                            className={
                                                "lb-avatar rounded-lg w-6 h-5"
                                            }
                                        >
                                            {pictureURL && (
                                                <img
                                                    className="lb-avatar-image"
                                                    src={pictureURL}
                                                    alt={`${userInfo.first_name} ${userInfo.last_name}`}
                                                />
                                            )}
                                            <span>
                                                {`${userInfo.first_name} ${userInfo.last_name}`}
                                            </span>
                                        </div>
                                        <div className="lb-comment-author text-xs font-medium">
                                            {`${userInfo.first_name} ${userInfo.last_name}`}
                                        </div>
                                    </Badge>
                                </div>
                            </DataList.Value>
                        </DataList.Item>
                    )}
                    {(category || topic) && (
                        <DataList.Item align="center">
                            <DataList.Label className="text-xs w-4">
                                Created From
                            </DataList.Label>
                            <DataList.Value>
                                <div className="text-xs flex items-center px-2.5">
                                    {category &&
                                        category.toLowerCase() !==
                                            "unknown" && (
                                            <Badge
                                                color={"gray"}
                                                size="2"
                                                radius="full"
                                                variant="outline"
                                                className="mx-2.5 my-1.5"
                                            >
                                                <div className="flex flex-row items-center">
                                                    <ComponentBooleanIcon
                                                        color={getColorLight(
                                                            category,
                                                        )}
                                                    />
                                                    <p className="pl-0.5">
                                                        {category}
                                                    </p>
                                                </div>
                                            </Badge>
                                        )}
                                    {topic && (
                                        <Badge
                                            color="gray"
                                            size="2"
                                            radius="full"
                                            variant="outline"
                                            className="my-1.5"
                                            key={topic}
                                        >
                                            <div className="flex flex-row items-center">
                                                <DotFilledIcon
                                                    color={
                                                        topicsMap.get(topic)
                                                            ?.color &&
                                                        topicsMap.get(topic)
                                                            ?.color !== ""
                                                            ? topicsMap.get(
                                                                  topic,
                                                              )?.color
                                                            : "#9B9EF0"
                                                    }
                                                    style={{
                                                        transform: "scale(1.8)",
                                                    }}
                                                />

                                                <p className="pl-0.3">
                                                    {topic}
                                                </p>
                                            </div>
                                        </Badge>
                                    )}
                                </div>
                            </DataList.Value>
                        </DataList.Item>
                    )}
                    {insightSelectedIsSaved && (
                        <DataList.Item align="center">
                            <DataList.Label className="text-xs w-4">
                                Created
                            </DataList.Label>
                            <DataList.Value>
                                <div className="flex flex-col gap-1 mx-2.5 my-0.5">
                                    <div className="text-xs flex items-center">
                                        <CalendarIcon
                                            style={{
                                                width: "20px",
                                                height: "30px",
                                            }}
                                        />
                                        <span className="px-1">
                                            {created_date}
                                        </span>
                                    </div>
                                </div>
                            </DataList.Value>
                        </DataList.Item>
                    )}
                    {insightSelectedIsSaved && (
                        <DataList.Item align="center">
                            <DataList.Label className="text-xs w-4">
                                Updated
                            </DataList.Label>
                            <DataList.Value>
                                <div className="flex flex-col gap-1 mx-2.5 my-0.5">
                                    <div className="text-xs flex items-center">
                                        <CalendarIcon
                                            style={{
                                                width: "20px",
                                                height: "30px",
                                            }}
                                        />
                                        <span className="px-1">
                                            {updated_date}
                                        </span>
                                    </div>
                                </div>
                            </DataList.Value>
                        </DataList.Item>
                    )}
                </DataList.Root>
            </div>
            {insightSelectedIsSaved && (
                <div className="p-4">
                    <ExternalIssuesSection
                        insight={insight}
                        updateInsightState={updateInsightState}
                        userID={userID}
                        sidebarLabels={sidebarLabels}
                    />
                </div>
            )}
            {insightSelectedIsSaved && (
                <div className="p-4">
                    <AnnouncementsSection
                        insight={insight}
                        updateInsightState={updateInsightState}
                        userID={userID}
                        sidebarLabels={sidebarLabels}
                    />
                </div>
            )}
            <div className="p-4">
                {insightSelectedIsSaved && updateInsightState ? (
                    <RelatedIssuesDropdown
                        insight={insight}
                        updateInsightState={updateInsightState}
                        issues={issues ?? []}
                    />
                ) : (
                    <div className="flex flex-row items-center">
                        <div className="font-semibold p-1.5 text-md">
                            Related Issues
                        </div>
                        <Badge
                            color="gray"
                            size="2"
                            radius="full"
                            variant="soft"
                            className="m-0.5"
                        >
                            <div className="flex flex-row items-center">
                                <p className="pl-0.5">{insight.count}</p>
                            </div>
                        </Badge>
                    </div>
                )}

                {insight.related_issues?.map((relatedIssue) =>
                    RelatedIssueCard(
                        relatedIssue,
                        insightSelectedIsSaved,
                        issueSelected,
                        setIssueSelected,
                    ),
                )}
            </div>
        </div>
    );
}
