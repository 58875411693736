import Clock from "@/component/Timer";

import { Avatar } from "@/Ticket/Avatar";
import { MyUser } from "@/Ticket/User";
import { Card, CardContent } from "@/component/shadcn/ui/card";
import type {
    GetTopicsResponse,
    GetUserResponse,
    IconEntry,
    Query,
} from "@/interfaces/serverData";
import { integrationBackEndDataMappingToSvg } from "@/pages/Admin/Integrations/constant";
import {
    arraysAreEqual,
    cleanText,
    getColorLight,
    getExternalIssueIcon,
    getHtmlStringFromReactContent,
} from "@/utilities/methods";
import {
    AvatarIcon,
    ComponentBooleanIcon,
    DotFilledIcon,
} from "@radix-ui/react-icons";
import { Badge } from "@radix-ui/themes";
import parse from "html-react-parser";
import debounce from "lodash/debounce";
import React, {
    useEffect,
    useMemo,
    useState,
    useCallback,
    memo,
    Suspense,
} from "react";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import { toHTML } from "slack-markdown";
import { IssueActions } from "./IssueActions";

const areEqual = (
    prevProps: IssuesListCardProps,
    nextProps: IssuesListCardProps,
) => {
    return (
        prevProps.issue === nextProps.issue &&
        arraysAreEqual(prevProps.topics, nextProps.topics) &&
        prevProps.topicsMap === nextProps.topicsMap &&
        prevProps.userID === nextProps.userID &&
        arraysAreEqual(prevProps.users, nextProps.users) &&
        prevProps.fromInbox === nextProps.fromInbox
    );
};

interface IssuesListCardProps {
    issue: Query;
    topics: { color: string; label: string; value: string }[];
    topicsMap: Map<string, GetTopicsResponse>;
    userID: string;
    users: GetUserResponse[];
    fromInbox: boolean;
}

function IssuesListCard({
    issue,
    topics,
    topicsMap,
    userID,
    users,
    fromInbox,
}: IssuesListCardProps) {
    const navigate = useNavigate();
    const [issueState, setIssueState] = useState<Query>(issue);

    const foundUser: GetUserResponse | undefined = useMemo(
        () => users.find((user) => user.id === issueState.assignee_user_id),
        [issueState.assignee_user_id, users],
    );
    const pictureURL = foundUser?.picture_url ?? "";
    const userName = `${foundUser?.first_name} ${foundUser?.last_name}`;

    const SourceSvgImage: React.ElementType | undefined = useMemo(
        () =>
            integrationBackEndDataMappingToSvg.get(
                issueState.source ?? "Unknown",
            ),
        [issueState.source],
    );

    const externalIssuesIcons = useMemo(() => {
        const icons = new Set<IconEntry>();
        // biome-ignore lint/complexity/noForEach: <explanation>
        issueState.external_issues?.forEach((url) => {
            icons.add({
                Component: getExternalIssueIcon(url),
                props: {
                    width: 20,
                    height: 20,
                    style: { marginLeft: "2", marginRight: "2" },
                },
            });
        });
        return icons;
    }, [issueState.external_issues]);

    const date: string = useMemo(() => {
        let updatedDate = new Date(issueState.updated_at);

        if (Number.isNaN(updatedDate.getTime()) || !issueState.updated_at) {
            updatedDate = new Date();
        }

        const today = new Date();

        const isToday =
            updatedDate.getDate() === today.getDate() &&
            updatedDate.getMonth() === today.getMonth() &&
            updatedDate.getFullYear() === today.getFullYear();

        if (isToday) {
            const userLocale = navigator.language || "en-US";
            return updatedDate.toLocaleTimeString(userLocale, {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
            });
        }

        // Otherwise, return the standard date format
        const userLocale = navigator.language || "en-US";
        return updatedDate.toLocaleDateString(userLocale, {
            month: "short",
            day: "numeric",
        });
    }, [issueState.updated_at]);

    const [visibleTopics, setVisibleTopics] = useState<
        { label: string; value: string }[]
    >([]);
    const [overflowTopics, setOverflowTopics] = useState<Map<string, string[]>>(
        new Map(),
    );
    const [showTagText, setShowTagText] = useState<boolean>(true);
    const [titleMaxWidth, setTitleMaxWidth] = useState(
        window.innerWidth * 0.35,
    );

    // Calculate topic dimensions
    const measureTopicWidth = useMemo(() => {
        const measurementElement = document.createElement("div");
        measurementElement.style.position = "absolute";
        measurementElement.style.visibility = "hidden";
        measurementElement.style.whiteSpace = "nowrap";
        measurementElement.style.padding = "0";
        document.body.appendChild(measurementElement);

        return (text: string): number => {
            measurementElement.innerText = text;
            return measurementElement.offsetWidth;
        };
    }, []);
    const updateDimensions = useCallback(() => {
        const topicWidths = issueState.topic.map(measureTopicWidth);
        const maxVisibleWidth = window.innerWidth * 0.1; // 1% of screen width
        let totalWidth = 0;
        const topicsToShow = [];
        const overflow: Map<string, string[]> = new Map();

        for (let i = 0; i < topicWidths.length; i++) {
            let topicColor =
                topicsMap.get(issueState.topic[i])?.color ?? "#9B9EF0";
            if (topicColor === "") {
                topicColor = "#9B9EF0";
            }
            if (totalWidth + topicWidths[i] <= maxVisibleWidth) {
                topicsToShow.push({
                    value: issueState.topic[i],
                    label: topicColor,
                });
                totalWidth += topicWidths[i];
            } else {
                if (overflow.has(topicColor)) {
                    const curr = overflow.get(topicColor) || [];
                    overflow.set(topicColor, [...curr, issueState.topic[i]]);
                } else {
                    overflow.set(topicColor, [issueState.topic[i]]);
                }
            }
        }
        setVisibleTopics(topicsToShow);
        setOverflowTopics(overflow);
        setShowTagText(window.innerWidth * 0.03 > 50);
        setTitleMaxWidth(window.innerWidth * 0.4);
    }, [issueState.topic, topicsMap]);

    // Memoize debounced updateDimensions
    const debouncedUpdateDimensions = useMemo(
        () => debounce(updateDimensions, 200),
        [updateDimensions],
    );

    useEffect(() => {
        updateDimensions();
    }, [issueState.topic]);

    const handleRowClick = (id: string) => {
        const from = fromInbox ? "inbox" : "issues";
        navigate(`/issue/${id}`, { state: { from: from } });
    };

    const updateIssueState = (newState: Partial<Query>) => {
        setIssueState((prevState) => ({
            ...prevState,
            ...newState,
        }));
        if (newState.bot_category) {
            issue.bot_category = newState.bot_category;
        }
        if (newState.topic) {
            issue.topic = newState.topic.sort();
        }
        if (newState.ticket_status) {
            issue.ticket_status = newState.ticket_status;
        }
        if (newState.assignee_user_id) {
            issue.assignee_user_id = newState.assignee_user_id;
        }
    };

    return (
        <Card
            className="py-3 px-6 border-l-transparent border-r-transparent border-b-transparent hover:bg-muted rounded w-full h-20"
            onClick={() =>
                handleRowClick(
                    `${issueState.ticket_identifier}-${issueState.ticket_number}`,
                )
            }
        >
            <CardContent className="p-0">
                <button
                    className="text-xs bg-transparent border-none p-0 cursor-pointer w-full"
                    type="button"
                >
                    <div
                        className={`flex items-center justify-between w-full gap-1.5 ${issueState.user_info.id === "" && "pt-2"}`}
                    >
                        <div className="flex items-center gap-3">
                            {issueState.user_info.id !== "" && (
                                <div className="lb-comment-details">
                                    <Suspense
                                        fallback={
                                            <div className="relative aspect-square w-8 flex-none animate-pulse rounded-full bg-gray-100" />
                                        }
                                    >
                                        <Avatar user={issueState.user_info} />
                                    </Suspense>
                                </div>
                            )}
                            <div
                                className="flex flex-col gap-0.5"
                                style={{ maxWidth: titleMaxWidth }}
                            >
                                <div className="flex items-center gap-2">
                                    {issueState.user_info.id !== "" ? (
                                        <div className="lb-comment-details-labels">
                                            <MyUser
                                                user={issueState.user_info}
                                                className="lb-comment-author text-sm font-semibold"
                                            />
                                        </div>
                                    ) : (
                                        <div className="text-sm font-semibold overflow-hidden whitespace-nowrap text-ellipsis">
                                            {issueState.title?.trim() ||
                                                issueState.query}
                                        </div>
                                    )}
                                    <div className="flex-shrink-0 text-xs text-muted-foreground">
                                        {`${issueState.ticket_identifier}-${issueState.ticket_number}`}
                                    </div>
                                </div>
                                {issueState.user_info.id !== "" && (
                                    <div className="text-xs overflow-hidden whitespace-nowrap text-ellipsis">
                                        {issueState.title?.trim() ||
                                            issueState.query}
                                    </div>
                                )}
                                {issueState.source !== "Slack" ? (
                                    <div className="text-xs text-muted-foreground overflow-hidden whitespace-nowrap text-ellipsis">
                                        <ReactMarkdown
                                            remarkPlugins={[remarkGfm]}
                                            rehypePlugins={[rehypeRaw]}
                                        >
                                            {cleanText(issueState.query ?? "")}
                                        </ReactMarkdown>
                                    </div>
                                ) : (
                                    <div className="text-xs text-muted-foreground overflow-hidden whitespace-nowrap text-ellipsis">
                                        <ReactMarkdown
                                            remarkPlugins={[remarkGfm]}
                                            rehypePlugins={[rehypeRaw]}
                                        >
                                            {getHtmlStringFromReactContent(
                                                parse(
                                                    toHTML(
                                                        cleanText(
                                                            issueState.query ??
                                                                "",
                                                        ),
                                                    ),
                                                ),
                                            )}
                                        </ReactMarkdown>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="flex items-center justify-end">
                            {issue.breaching !== "" &&
                                issue.breaching !== undefined &&
                                issue.breaching !== null && (
                                    <Clock
                                        targetTime={issue.breaching}
                                        variant="soft"
                                    />
                                )}
                            <div className="relative flex-none">
                                <div className="flex items-center gap-1 mx-1">
                                    {visibleTopics.map((topic) => (
                                        <Badge
                                            color="gray"
                                            size="2"
                                            radius="full"
                                            variant="outline"
                                            className="m-0.5"
                                            key={topic.value}
                                        >
                                            <div className="flex flex-row items-center">
                                                <DotFilledIcon
                                                    color={
                                                        topic.label !== ""
                                                            ? topic.label
                                                            : "#9B9EF0"
                                                    }
                                                    style={{
                                                        transform: "scale(1.8)",
                                                    }}
                                                />
                                                <p className="pl-0.3">
                                                    {topic.value}
                                                </p>
                                            </div>
                                        </Badge>
                                    ))}
                                    {overflowTopics.size > 0 &&
                                        Array.from(
                                            overflowTopics.entries(),
                                        ).map(([label, topics]) => {
                                            return (
                                                <Badge
                                                    color="gray"
                                                    size="2"
                                                    radius="full"
                                                    variant="outline"
                                                    className="m-0.5"
                                                    key={label}
                                                >
                                                    <div className="flex flex-row items-center">
                                                        <DotFilledIcon
                                                            color={
                                                                label ??
                                                                "#9B9EF0"
                                                            }
                                                            style={{
                                                                transform:
                                                                    "scale(1.8)",
                                                            }}
                                                        />
                                                        <div className="flex items-center pl-0.3">
                                                            +{topics.length}
                                                        </div>
                                                    </div>
                                                </Badge>
                                            );
                                        })}
                                </div>
                            </div>

                            <Badge
                                color="gray"
                                size="2"
                                radius="full"
                                variant="outline"
                                className="m-0.5"
                            >
                                <div className="flex flex-row items-center">
                                    <ComponentBooleanIcon
                                        color={getColorLight(
                                            issueState.bot_category,
                                        )}
                                    />
                                    {showTagText && (
                                        <p className="pl-0.5">
                                            {issueState.bot_category}
                                        </p>
                                    )}
                                </div>
                            </Badge>
                            <div className="mx-1.5 text-xs w-15">{date}</div>
                            {SourceSvgImage && (
                                <SourceSvgImage className="w-5 h-5 mr-1" />
                            )}
                            {Array.from(externalIssuesIcons).map((icon) =>
                                React.createElement(icon.Component, icon.props),
                            )}
                            {issueState.assignee_user_id &&
                            issueState.assignee_user_id !== "noAssignee" ? (
                                <div className="lb-avatar rounded-lg w-6 h-6 mx-1">
                                    {pictureURL && (
                                        <img
                                            className="lb-avatar-image"
                                            src={pictureURL}
                                            alt={userName}
                                        />
                                    )}
                                    <span>{userName ?? ""}</span>
                                </div>
                            ) : (
                                <AvatarIcon className="w-6 h-6 mx-1" />
                            )}
                            <div className="w-5">
                                <IssueActions
                                    issueState={issueState}
                                    updateIssueState={updateIssueState}
                                    topics={topics}
                                    userID={userID}
                                    users={users}
                                />
                            </div>
                        </div>
                    </div>
                </button>
            </CardContent>
        </Card>
    );
}

export default memo(IssuesListCard, areEqual);
