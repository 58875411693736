import { Button } from "@/component/shadcn/ui/button";
import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/component/shadcn/ui/form";
import { TrashIcon } from "@radix-ui/react-icons";
import { Separator } from "@radix-ui/themes";
import { Badge } from "@radix-ui/themes";

import type { FieldArrayWithId, UseFormReturn } from "react-hook-form";
import type { BroadcastInfo } from "./AnnouncementsEditingPage";

interface AnnouncementsBulkReplyProps {
    fields: FieldArrayWithId<
        {
            customerGroupMetadata: {
                customer_group: string;
            }[];
            sourceMetadata: {
                source: string;
            }[];
            broadcastName: string;
        },
        "sourceMetadata",
        "id"
    >[];
    fieldsState: BroadcastInfo[];
    form: UseFormReturn<
        {
            broadcastName: string;
            sourceMetadata: {
                source: string;
            }[];
            customerGroupMetadata: {
                customer_group: string;
            }[];
        },
        any,
        undefined
    >;
    handleBroadcastChange: (index: number, newBroadcast: string) => void;
    filteredBroadcasts: { label: string; value: string; color: string }[];
    removeSource: (index: number) => void;
    setFieldsState: (
        state: BroadcastInfo[] | ((prev: BroadcastInfo[]) => BroadcastInfo[]),
    ) => void;
}

export const AnnouncementsBulkReplies: React.FC<
    AnnouncementsBulkReplyProps
> = ({
    fields,
    fieldsState,
    form,
    handleBroadcastChange,
    filteredBroadcasts,
    removeSource,
    setFieldsState,
}) => {
    return (
        <>
            <div>
                {fields.length !== 0 && (
                    <FormLabel className="pb-0 mb-0 text-xs">
                        Accounts
                    </FormLabel>
                )}
                {fields.map((item, index) => {
                    const IconComponent = fieldsState[index]?.icon;

                    return (
                        <FormField
                            key={item.id}
                            control={form.control}
                            name={"sourceMetadata"}
                            render={({ field }) => (
                                <>
                                    <FormItem className="flex flex-row justify-between items-start">
                                        <div className="flex flex-col gap-y-5">
                                            <FormControl>
                                                <div className="flex flex-col items-start gap-3">
                                                    <div className="flex items-stretch flex-row gap-5 ">
                                                        <Badge
                                                            color={"gray"}
                                                            size="2"
                                                            radius="full"
                                                            variant="outline"
                                                            className="m-0.5 my-1 px-2 text-xs rounded-xl"
                                                        >
                                                            <div className="flex flex-row items-center">
                                                                <>
                                                                    {IconComponent !==
                                                                        undefined && (
                                                                        <IconComponent className="w-4 h-4" />
                                                                    )}
                                                                    <p className="pl-1 pr-1">
                                                                        {
                                                                            fieldsState[
                                                                                index
                                                                            ]
                                                                                .selectedBroadcast
                                                                        }
                                                                    </p>
                                                                </>
                                                            </div>
                                                        </Badge>
                                                    </div>

                                                    <div className="pl-3 flex flex-wrap flex-row gap-2">
                                                        {fieldsState[
                                                            index
                                                        ]?.selectedChannels?.map(
                                                            (framework) => {
                                                                return (
                                                                    <Badge
                                                                        key={
                                                                            framework.name
                                                                        }
                                                                        color="iris"
                                                                    >
                                                                        <div className="flex flex-row items-center justify-center p-1">
                                                                            <p className="text-xs">
                                                                                {
                                                                                    framework.name
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    </Badge>
                                                                );
                                                            },
                                                        )}
                                                    </div>
                                                </div>
                                            </FormControl>
                                            <FormMessage className="text-xs" />
                                        </div>
                                        <Button
                                            type="button"
                                            variant="outline"
                                            size="sm"
                                            className="mt-0"
                                            onClick={() => {
                                                removeSource(index);
                                            }}
                                        >
                                            <TrashIcon />
                                        </Button>
                                    </FormItem>
                                    <Separator size="4" className="mt-4 mb-4" />
                                </>
                            )}
                        />
                    );
                })}
            </div>
        </>
    );
};
