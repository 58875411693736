import { Card, CardContent } from "@/component/shadcn/ui/card";
import { API, URLS, loadingTypes } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { ScopeResponse } from "@/interfaces/serverData";
import { CrossCircledIcon, Pencil2Icon } from "@radix-ui/react-icons";
import {
    Badge,
    Button,
    DataList,
    Dialog,
    Flex,
    Select,
    Skeleton,
    Switch,
    Text,
} from "@radix-ui/themes";
import type React from "react";
import { useEffect, useState } from "react";
import { ReactComponent as SlackSvg } from "../../images/integrations/slack.svg";

interface SlackAlertsProps {
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
    setToastOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setToastText: React.Dispatch<React.SetStateAction<string>>;
    setToastSymbol: React.Dispatch<React.SetStateAction<React.ElementType>>;
    timerRef: React.MutableRefObject<number>;
}

const SlackAlerts: React.FC<SlackAlertsProps> = ({
    isOpen,
    onOpenChange,
    setToastOpen,
    setToastSymbol,
    setToastText,
    timerRef,
}) => {
    const [channelOptions, setChannelOptions] = useState<ScopeResponse[]>([]);
    const [slackChannel, setSlackChannel] = useState<string | undefined>(
        undefined,
    );

    const [slackEnabled, setSlackEnabled] = useState<boolean>(false);
    const [savedSlackEnabledValue, setSavedSlackEnabledValue] =
        useState<boolean>(false);

    const [savedSlackChannel, setSavedSlackChannel] = useState<
        string | undefined
    >(undefined);
    const [loading, setLoading] = useState<loadingTypes>(loadingTypes.loading);

    const api = useApi();
    useEffect(() => {
        const serverUrl = URLS.serverUrl ? URLS.serverUrl : "";
        const requestData = {
            type: "Slack",
        };
        api.post(serverUrl + API.getScopes, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                const channelData = res.data.data;
                setChannelOptions(channelData);
            })
            .catch((res) => {
                setLoading(loadingTypes.error);
                console.log(res);
            });

        api.get(`${URLS.serverUrl}${API.getNotifications}/Slack`, {
            headers: { Accept: "application/json" },
        })
            .then((slackRes) => {
                if (slackRes.status === 200) {
                    console.log(slackRes);
                    const slackResData = slackRes.data.data;
                    setSlackEnabled(slackResData.enabled);
                    setSavedSlackEnabledValue(slackResData.enabled);
                    setSlackChannel(slackResData.channel.name);
                    setSavedSlackChannel(slackResData.channel.name);
                    setLoading(loadingTypes.loaded);
                } else {
                    setLoading(loadingTypes.error);
                }
            })
            .catch((error) => {
                console.error("Error checking Slack integration:", error);
                setLoading(loadingTypes.error);
            });
    }, [api, setSlackEnabled]);

    function saveSettings() {
        const serverUrl = URLS.serverUrl ? URLS.serverUrl : "";
        const requestData = {
            enabled: slackEnabled,
            channel: {
                name: slackChannel,
            },
        };
        api.post(
            `${serverUrl}${API.saveNotificationSettings}/Slack`,
            requestData,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        )
            .then((res) => {
                if (res.status === 200) {
                    const slackResData = res.data.data;
                    setSlackEnabled(slackResData.enabled);
                    setSavedSlackEnabledValue(slackResData.enabled);
                    setSavedSlackChannel(slackResData.channel.name);
                    setLoading(loadingTypes.loaded);
                    setToastText("Updated Settings!");
                } else {
                    setToastText(
                        "Oops! Something's wrong. Please try again at a later time.",
                    );
                    setToastSymbol(CrossCircledIcon);
                }
            })
            .catch((res) => {
                setLoading(loadingTypes.error);
                setToastText(
                    "Oops! Something's wrong. Please try again at a later time.",
                );
                setToastSymbol(CrossCircledIcon);
            })
            .finally(() => {
                setToastOpen(false);
                console.log("reached here");
                window.clearTimeout(timerRef.current);
                timerRef.current = window.setTimeout(() => {
                    console.log("setting open to true");
                    setToastOpen(true);
                }, 100);
            });
    }

    return (
        <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
            <Card className="pt-6 pb-6 px-6">
                <CardContent>
                    <div className="flex flex-col gap-6">
                        <div className="flex flex-row items-center justify-between">
                            <div className="flex flex-row items-center gap-1">
                                <SlackSvg className="max-h-5 max-w-5" />
                                <h2 className="text-md font-medium">
                                    Slack Notifications
                                </h2>
                            </div>

                            <Dialog.Trigger className="self-end justify-self-end">
                                <Button className="iris text-white">
                                    <Pencil2Icon />
                                    Edit
                                </Button>
                            </Dialog.Trigger>
                        </div>
                        {savedSlackEnabledValue && (
                            <Card className="mx-6">
                                <CardContent className="py-6 px-6 ">
                                    <DataList.Root>
                                        <DataList.Item align="center">
                                            <DataList.Label minWidth="88px">
                                                Status
                                            </DataList.Label>
                                            <DataList.Value>
                                                <Badge
                                                    color="jade"
                                                    variant="soft"
                                                    radius="full"
                                                >
                                                    Enabled
                                                </Badge>
                                            </DataList.Value>
                                        </DataList.Item>
                                        <DataList.Item align="center">
                                            <DataList.Label minWidth="88px">
                                                Notification Channel
                                            </DataList.Label>
                                            <DataList.Value>
                                                <p className="text-iris11">
                                                    {`#${savedSlackChannel}`}
                                                </p>
                                            </DataList.Value>
                                        </DataList.Item>
                                    </DataList.Root>
                                </CardContent>
                            </Card>
                        )}
                    </div>
                </CardContent>
            </Card>

            <Dialog.Content style={{ maxWidth: 450 }}>
                <Dialog.Title>Slack Notification Settings</Dialog.Title>
                <Flex direction="column" gap="4">
                    <Flex align="center" justify="between">
                        <Text>Enable Slack Notifications</Text>
                        <Switch
                            checked={slackEnabled}
                            onCheckedChange={setSlackEnabled}
                        />
                    </Flex>
                    {slackEnabled && loading === loadingTypes.loading && (
                        <Skeleton>
                            <Select.Root>
                                <Select.Trigger
                                    className="w-full"
                                    placeholder="Select Slack channel"
                                />
                                <Select.Content />
                            </Select.Root>
                        </Skeleton>
                    )}

                    {slackEnabled && loading === loadingTypes.loaded && (
                        <Select.Root
                            value={slackChannel}
                            onValueChange={setSlackChannel}
                        >
                            <Select.Trigger
                                className="w-full"
                                placeholder="Select Slack channel"
                            />
                            <Select.Content>
                                {channelOptions.map((channel) => (
                                    <Select.Item
                                        key={channel.key}
                                        value={channel.name}
                                    >
                                        #{channel.name}
                                    </Select.Item>
                                ))}
                            </Select.Content>
                        </Select.Root>
                    )}

                    {loading === loadingTypes.loaded && (
                        <Flex justify="end" gap="3" mt="4">
                            <Dialog.Close>
                                <Button
                                    className="iris text-white"
                                    onClick={saveSettings}
                                >
                                    Save
                                </Button>
                            </Dialog.Close>
                        </Flex>
                    )}
                </Flex>
            </Dialog.Content>
        </Dialog.Root>
    );
};

export default SlackAlerts;
