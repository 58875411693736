import { InsightDisplay } from "@/Insights/InsightDisplay";
import TicketDisplay from "@/Insights/TicketDisplay";
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbSeparator,
} from "@/component/shadcn/ui/breadcrumb";
import { Button } from "@/component/shadcn/ui/button";
import {
    ResizableHandle,
    ResizablePanel,
    ResizablePanelGroup,
} from "@/component/shadcn/ui/resizable";
import {
    TooltipContent,
    TooltipProvider,
    Tooltip as TooltipRoot,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    GetTopicsResponse,
    HistoryResponse,
    Insight,
    QueriesWithPaginationResponse,
    Ticket,
} from "@/interfaces/serverData";
import { CopyIcon, Link2Icon } from "@radix-ui/react-icons";
import {
    Box,
    Callout,
    Flex,
    Separator,
    Skeleton,
    Text,
} from "@radix-ui/themes";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";

interface InsightDisplayPageProps {
    id: string;
    userID: string;
}

const InsightDisplayPage: React.FC<InsightDisplayPageProps> = ({
    id,
    userID,
}) => {
    const api = useApi();
    const [loadingPageState, setLoadingPageState] = useState<number>(0); // 0: loading, 1: loaded, 2: error
    const [tooltipOpen, setTooltipOpen] = useState<boolean>(false); // State for tooltip visibility
    const [issueSelected, setIssueSelected] = useState<string>();
    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";
    const [loadingTicketState, setLoadingTicketState] = useState<number>(0); // 0: loading, 1: loaded, 2: error
    const [loadingThreadState, setLoadingThreadState] = useState<number>(0);
    const [topicsMap, setTopicsMap] = useState<Map<string, GetTopicsResponse>>(
        new Map(),
    );
    const [loadingTopicsState, setLoadingTopicsState] = useState<number>(0);
    const [insightState, setInsightState] = useState<Insight>();
    const updateInsight: (newState: Partial<Insight>) => void = (newState) => {
        setInsightState((prevState) => {
            if (prevState === undefined) {
                return { ...newState } as Insight;
            }
            return { ...prevState, ...newState };
        });
    };

    const { data: insightData, refetch } = useQuery({
        queryKey: ["insight", id],
        queryFn: () => fetchInsight(),
        refetchInterval: 10000, // refetch every 10 secs
        refetchOnWindowFocus: true,
    });
    const fetchInsight = async (): Promise<Insight | null> => {
        try {
            const response = await api.get(
                `${URLS.serverUrl}${API.getInsightInfo}/${id}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                },
            );
            if (response.status === 200) {
                setLoadingPageState(1); // Set state to loaded
                return response.data.data;
            } else {
                setLoadingPageState(2); // Set state to error
                return null;
            }
        } catch (error) {
            setLoadingPageState(2); // Set state to error in case of an exception
            return null;
        }
    };
    useEffect(() => {
        if (insightData) {
            setInsightState(insightData);
        }
    }, [insightData]);

    const fetchQueries = async ({
        pageParam = 0,
    }: { pageParam?: number }): Promise<QueriesWithPaginationResponse> => {
        try {
            const response = await api.get(
                URLS.serverUrl + API.queriesWithPagination,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    params: {
                        limit: 1000,
                        offset: pageParam,
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            return { data: [], has_next_page: false, next_cursor: 0 };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return { data: [], has_next_page: false, next_cursor: 0 };
        }
    };

    const {
        data: issuesData,
        error,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        status,
    } = useInfiniteQuery({
        queryKey: ["queries"],
        queryFn: fetchQueries,
        getNextPageParam: (lastPage) => {
            if (lastPage.has_next_page) {
                return lastPage.next_cursor;
            }
            return undefined; // No more pages
        },
        initialPageParam: 0,
        refetchInterval: 30000,
        refetchOnWindowFocus: true,
    });
    const combinedIssuesData =
        issuesData && Array.isArray(issuesData.pages)
            ? issuesData.pages
                  .filter((page) => page !== null && page !== undefined)
                  .flatMap((page) =>
                      Array.isArray(page.data)
                          ? page.data.filter(
                                (item) => item !== null && item !== undefined,
                            )
                          : [],
                  ) // Filter out null or undefined items in page.data
            : [];

    const { data: ticketData, refetch: refetchTicketData } = useQuery({
        queryKey: ["ticket", issueSelected ?? "", userID],
        queryFn: () => fetchTicket(),
        refetchInterval: 10000, // refetch every 10 secs
        refetchOnWindowFocus: true,
    });
    const { data: threadData = [], refetch: refetchThreadData } = useQuery({
        queryKey: ["ticket_thread", issueSelected ?? ""],
        queryFn: () => fetchTicketThread(),
        refetchInterval: 10000, // refetch every 10 secs
        refetchOnWindowFocus: true,
    });
    const fetchTicket = async (): Promise<Ticket | null> => {
        if (!issueSelected) return null;
        const response = await api.get(
            `${URLS.serverUrl}${API.getTicketInfo}/${issueSelected ?? ""}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            },
        );
        if (response.status === 200) {
            const ticketData: Ticket = response.data.data;
            setLoadingTicketState(1);
            return ticketData;
        } else {
            setLoadingTicketState(2);
            return null;
        }
    };
    const fetchTicketThread = async (): Promise<HistoryResponse[]> => {
        if (!issueSelected) return [];
        const response = await api.post(
            URLS.serverUrl + API.getHistoryRecords,
            {
                identifier: issueSelected ?? "",
                parent_id: "",
                latest_timestamp: "",
            },
        );
        if (response.status === 200) {
            setLoadingThreadState(1);
            return response.data.data;
        } else {
            return [];
        }
    };

    // Grab the list of topics for the org
    useEffect(() => {
        api.get(URLS.serverUrl + API.getTopics, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    const topics: GetTopicsResponse[] = res.data.data;

                    const newTopics: {
                        color: string;
                        label: string;
                        value: string;
                    }[] = [];

                    const map = new Map<string, GetTopicsResponse>();

                    for (const topic of topics) {
                        newTopics.push({
                            color: topic.color ?? "#9B9EF0",
                            label: topic.topic_name,
                            value: topic.topic_name,
                        });
                        map.set(topic.topic_name, topic);
                    }
                    setTopicsMap(map);
                    setLoadingTopicsState(1);
                }
            })
            .catch(() => {
                console.log("Did not grab topics from db successfully");
            });
    }, [api]);

    const copyTitle = useCallback((): void => {
        navigator.clipboard.writeText(insightState?.title ?? "");
        setTooltipOpen(false);
    }, [insightState]);

    const copyLink = useCallback((): void => {
        navigator.clipboard.writeText(
            `${process.env.REACT_APP_CALLBACK_URL}insight/${id}`,
        );
        setTooltipOpen(false);
    }, [id]);

    return (
        <Flex direction="column" align="center" justify="center">
            <Box
                height="100%" // Ensure it takes full height of its container
                width="100%"
                pl="100"
            >
                <div className="h-screen flex-1 flex-col space-y-8 pb-8">
                    <div className="flex flex-row items-center pl-4 mt-4">
                        <Breadcrumb>
                            <BreadcrumbList className="flex items-center pl-2">
                                <BreadcrumbItem>
                                    <BreadcrumbLink href="/insights">
                                        Product Insights
                                    </BreadcrumbLink>
                                </BreadcrumbItem>
                                <BreadcrumbSeparator className="mx-2" />
                                {loadingPageState === 1 && (
                                    <TooltipProvider>
                                        <TooltipRoot
                                            open={tooltipOpen}
                                            onOpenChange={setTooltipOpen}
                                        >
                                            <TooltipTrigger asChild>
                                                <BreadcrumbItem
                                                    onMouseEnter={() =>
                                                        setTooltipOpen(true)
                                                    }
                                                >
                                                    <BreadcrumbLink className="hover:underline">
                                                        {insightState?.title}
                                                    </BreadcrumbLink>
                                                </BreadcrumbItem>
                                            </TooltipTrigger>
                                            <TooltipContent className="bg-white text-black p-4 rounded shadow-lg">
                                                <div className="flex flex-col gap-2">
                                                    <Button
                                                        size="sm"
                                                        variant="ghost"
                                                        onClick={copyTitle}
                                                        className="flex items-center gap-2 text-black"
                                                    >
                                                        <CopyIcon className="h-4 w-4" />{" "}
                                                        Copy Title
                                                    </Button>
                                                    <Button
                                                        size="sm"
                                                        variant="ghost"
                                                        onClick={copyLink}
                                                        className="flex items-center gap-2 text-black"
                                                    >
                                                        <Link2Icon className="h-4 w-4" />{" "}
                                                        Copy Link
                                                    </Button>
                                                </div>
                                            </TooltipContent>
                                        </TooltipRoot>
                                    </TooltipProvider>
                                )}
                            </BreadcrumbList>
                        </Breadcrumb>
                    </div>
                    <Separator
                        size="4"
                        style={{
                            marginTop: "20px",
                            marginBottom: "0px",
                            paddingBottom: "0px",
                        }}
                    />
                    {loadingPageState === 0 && (
                        <div>
                            <Skeleton>
                                <Text>
                                    {[...Array(6)].map((_, index) => (
                                        // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                        <Text key={index}>{loremIpsum}</Text>
                                    ))}
                                </Text>
                            </Skeleton>
                        </div>
                    )}
                    {loadingPageState === 1 &&
                        loadingTopicsState === 1 &&
                        insightState && (
                            <div style={{ marginTop: "0px" }}>
                                <TooltipProvider delayDuration={0}>
                                    <ResizablePanelGroup
                                        direction="horizontal"
                                        onLayout={(sizes: number[]) => {
                                            document.cookie = `react-resizable-panels:layout=${JSON.stringify(
                                                sizes,
                                            )}`;
                                        }}
                                        className="h-fit items-stretch relative z-10"
                                    >
                                        <ResizablePanel
                                            defaultSize={
                                                issueSelected !== undefined
                                                    ? window.innerWidth * 0.6
                                                    : window.innerWidth
                                            }
                                            key="insightInfo"
                                            className="flex flex-col h-screen ml-5 overflow-y-scroll"
                                        >
                                            <InsightDisplay
                                                insight={insightState}
                                                userID={userID}
                                                insightSelectedIsSaved={true}
                                                updateInsightState={
                                                    updateInsight
                                                }
                                                topicsMap={topicsMap}
                                                issueSelected={issueSelected}
                                                setIssueSelected={
                                                    setIssueSelected
                                                }
                                                issues={combinedIssuesData}
                                            />
                                        </ResizablePanel>
                                        {issueSelected !== undefined && (
                                            <>
                                                <ResizableHandle className="bg-sidebarBorder" />
                                                <ResizablePanel
                                                    defaultSize={
                                                        window.innerWidth * 0.4
                                                    }
                                                    key="ticketInfo"
                                                    className="flex flex-col h-full"
                                                >
                                                    {ticketData && (
                                                        <TicketDisplay
                                                            analytics={
                                                                ticketData
                                                            }
                                                            userID={userID}
                                                            ai_response=""
                                                            threadData={
                                                                threadData
                                                            }
                                                            loadingTicketState={
                                                                loadingTicketState
                                                            }
                                                            loadingThreadState={
                                                                loadingThreadState
                                                            }
                                                            refetchTicketData={
                                                                refetchTicketData
                                                            }
                                                            refetchThreadData={
                                                                refetchThreadData
                                                            }
                                                        />
                                                    )}
                                                </ResizablePanel>
                                            </>
                                        )}
                                    </ResizablePanelGroup>
                                </TooltipProvider>
                            </div>
                        )}
                    {loadingPageState === 2 && (
                        <Callout.Root size="1" variant="outline" color="red">
                            <Callout.Text>
                                Sorry, something's wrong! Please notify us at
                                support@askassembly.app.
                            </Callout.Text>
                        </Callout.Root>
                    )}
                </div>
            </Box>
        </Flex>
    );
};
export default InsightDisplayPage;
