import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getRoot, $isParagraphNode } from "lexical";
import { useEffect } from "react";

import { LoaderCircleIcon, SendHorizonalIcon } from "lucide-react";
import { Button } from "../shadcn/ui/button";

import { CLEAR_EDITOR_COMMAND } from "lexical";

export default function ActionsPlugin({
    setIsSendDisabled,
    isSendDisabled,
    loading,
    handleSubmit,
}: {
    setIsSendDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    isSendDisabled: boolean;
    loading: boolean;
    handleSubmit: () => void;
}): JSX.Element {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        return editor.registerUpdateListener(
            ({ dirtyElements, prevEditorState, tags }) => {
                editor.getEditorState().read(() => {
                    const root = $getRoot();
                    const children = root.getChildren();

                    if (children.length > 1) {
                        setIsSendDisabled(true);
                    } else {
                        if ($isParagraphNode(children[0])) {
                            const paragraphChildren = children[0].getChildren();
                            const isParagraphEmpty = paragraphChildren.every(
                                (child) => {
                                    return !child.getTextContent().trim();
                                },
                            );
                            setIsSendDisabled(!isParagraphEmpty);
                        } else {
                            setIsSendDisabled(false);
                        }
                    }
                });
            },
        );
    }, [editor, setIsSendDisabled]);

    return (
        <div className="mr-3 mt-auto ml-auto">
            <Button
                disabled={!isSendDisabled}
                className="px-2 py-2 disabled:opacity-50 bg-[#5B5BD6]"
                onClick={() => {
                    editor.getEditorState().read(() => {
                        handleSubmit();
                        editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
                    });
                }}
                type="submit"
            >
                {loading ? (
                    <LoaderCircleIcon className="h-4 w-4 animate-spin" />
                ) : (
                    <SendHorizonalIcon className="h-4 w-4" />
                )}
            </Button>
        </div>
    );
}
