import { Grid, LinearProgress } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API, URLS, pages } from "../../constant";
import { StyledBox } from "../../design/Box";
import { ReactComponent as SlackSvg } from "../../images/integrations/slack.svg";
import { useApi } from "../../interfaces/api";
import NavBarComponent from "../../sharedPages/NavBar";
import { integrationsList } from "./constant";

import { Em, Flex, Text } from "@radix-ui/themes";

const BotPage = () => {
    const navigate = useNavigate();
    const api = useApi();
    const [integration, setIntegration] = useState<string>("");

    const navigateToIntegrations = useCallback(async () => {
        navigate(`/admin/manage_integrations/${integration}`, {
            replace: true,
        });
    }, [navigate, integration]);

    const [startIndexing, setStartIndexing] = useState<boolean>(false);

    useEffect(() => {
        const integration = integrationsList.get(window.location.pathname);
        if (integration === undefined) {
            navigate("/*");
        }

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        const guildParam = urlParams.get("guild_id");
        if (guildParam === undefined || guildParam === "") {
            navigate("/*");
        }

        const codeParam = urlParams.get("code");
        if (codeParam === undefined || codeParam === "") {
            navigate("/*");
        }

        setIntegration(integration?.integrationType ?? "");

        const requestData = {
            type: integration?.integrationType,
            info: {
                code: codeParam,
                scope: guildParam,
            },
        };

        const serverUrl = URLS.serverUrl ? URLS.serverUrl : "";
        api.post(serverUrl + API.authenticateIntegration, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                setStartIndexing(true);
                console.log("successfully authenticated and added bot");
            })
            .catch((res) => {
                setStartIndexing(true);
                console.log("unable to authenticate and add bot");
            });
    }, [navigate]); //only run once

    const fetchProgress = useCallback(async () => {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        navigateToIntegrations();
    }, [navigateToIntegrations]);

    useEffect(() => {
        if (startIndexing) {
            fetchProgress();
        }
    }, [startIndexing, fetchProgress]);

    return (
        <div className="bg-gray-500">
            <NavBarComponent state={pages.search} />

            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{ minHeight: "80vh" }}
            >
                <StyledBox>
                    <Flex direction="column" gap="2">
                        <Text
                            size={{
                                sm: "4",
                                initial: "5",
                                md: "5",
                                xl: "6",
                            }}
                            weight="bold"
                        >
                            <b>
                                <SlackSvg
                                    style={{
                                        width: "20px",
                                        height: "20px",
                                        paddingRight: "20px",
                                    }}
                                />
                                Installing the discord bot!
                            </b>
                        </Text>
                        <Text
                            size={{
                                sm: "3",
                                initial: "4",
                                md: "4",
                                xl: "4",
                            }}
                        >
                            <Em>Assembling...</Em>
                        </Text>

                        <LinearProgress />
                    </Flex>
                </StyledBox>
            </Grid>
        </div>
    );
};

export default BotPage;
