import { Component } from "react";

import { Badge } from "@radix-ui/themes";

import { ExclamationTriangleIcon, LapTimerIcon } from "@radix-ui/react-icons";

import type { badgePropDefs } from "@radix-ui/themes/dist/cjs/components/badge.props";

export type BadgeType = (typeof badgePropDefs.variant.values)[number];

interface ClockProps {
    targetTime: string;
    variant: BadgeType;
}

interface ClockState {
    fixDate: Date;
    diff: number;
}

class Clock extends Component<ClockProps, ClockState> {
    intervalId: NodeJS.Timeout | null = null;

    constructor(props: ClockProps) {
        super(props);

        const { targetTime } = this.props;
        const fixDate = new Date(targetTime);
        const currDate = new Date();

        this.state = {
            fixDate,
            diff: fixDate.getTime() - currDate.getTime(),
        };
    }

    componentDidMount() {
        this.intervalId = setInterval(() => this.tick(), 1000);
    }

    componentWillUnmount() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    tick() {
        this.setState((prevState) => ({
            diff: prevState.fixDate.getTime() - new Date().getTime(),
        }));
    }

    getDisplayTime() {
        const { diff } = this.state;

        if (diff === 0) {
            return "now";
        } else if (diff < -100) {
            return "breached!";
        }

        const hours = Math.floor(diff / (60 * 60 * 1000));
        const mins = Math.floor((diff % (60 * 60 * 1000)) / (60 * 1000));
        const secs = Math.floor((diff % (60 * 1000)) / 1000);

        if (hours > 0) {
            return `${hours} hrs ${mins} mins`;
        } else if (mins > 0) {
            return `${mins} mins`;
        } else if (secs > 30) {
            return "< 1 min";
        } else {
            return "< 30 secs";
        }
    }

    render() {
        const { diff } = this.state;
        const displayTime = this.getDisplayTime();

        if (diff < 100) {
            return (
                <Badge
                    color="red"
                    size="2"
                    radius="full"
                    variant={this.props.variant}
                    className="py-1.5"
                >
                    <ExclamationTriangleIcon />{" "}
                    {/* Different symbol for breached status */}
                    <span>{displayTime}</span>
                </Badge>
            );
        } else {
            // If not breached, show the default badge
            return (
                <Badge
                    color="gray"
                    size="2"
                    radius="full"
                    className="bg-gray py-1.5"
                >
                    <LapTimerIcon />
                    <span>{displayTime}</span>
                </Badge>
            );
        }
    }
}

export default Clock;
