import { HeaderType } from "@/IssuesTable/constants";
import { Card } from "@/component/shadcn/ui/card";
import { ScrollArea } from "@/component/shadcn/ui/scroll-area";
import type { Announcement, GetUserResponse } from "@/interfaces/serverData";
import { CheckCircledIcon } from "@radix-ui/react-icons";
import * as Toast from "@radix-ui/react-toast";
import { useState } from "react";
import { AnnouncementsListCard } from "./AnnouncementsListCard";
import { AnnouncementsListHeaderCard } from "./AnnouncementsListHeaderCard";
import { AnnouncementsState, statuses } from "./constants";

interface AnnouncementsTableProps {
    announcements: Announcement[];
    userID: string;
    users: GetUserResponse[];
    forceUpdate: () => void;
}

export function AnnouncementsTable({
    announcements,
    userID,
    users,
    forceUpdate,
}: AnnouncementsTableProps) {
    const [filteredAnnouncements, setFilteredAnnouncements] =
        useState<Announcement[]>(announcements);

    const [toastText, setToastText] = useState<string>("Saved draft");
    const [open, setOpen] = useState<boolean>(false);
    const [ToastSymbol, setToastSymbol] =
        useState<React.ElementType>(CheckCircledIcon);

    const [isExpanded, setResponseExpanded] = useState<Map<string, boolean>>(
        new Map([
            [AnnouncementsState.draft, true],
            [AnnouncementsState.sent, true],
        ]),
    );
    const updateExpanded = (type: string, value: boolean) => {
        setResponseExpanded((prevMap) => {
            const newMap = new Map(prevMap);
            newMap.set(type, value);
            return newMap;
        });
    };
    const priorityOrder = [AnnouncementsState.draft, AnnouncementsState.sent];
    return (
        <Card className="m-2 rounded-sm h-screen w-full flex flex-col">
            {/* <div>
                <FilterBar
                    issues={issues}
                    setFilteredAnnouncements={setFilteredAnnouncements}
                    topics={topics}
                    users={users}
                />
            </div> */}
            <ScrollArea className="h-full w-full">
                {statuses
                    .sort((a, b) => {
                        return (
                            priorityOrder.indexOf(a.value) -
                            priorityOrder.indexOf(b.value)
                        );
                    })
                    .map((status) => (
                        <div key={status.value}>
                            <AnnouncementsListHeaderCard
                                title={status.label}
                                value={status.value}
                                header_type={HeaderType.Status}
                                isExpanded={isExpanded}
                                updateExpanded={updateExpanded}
                            />
                            {isExpanded.get(status.value) &&
                                filteredAnnouncements
                                    ?.filter(
                                        (announcement) =>
                                            announcement?.status ===
                                            status.value,
                                    )
                                    .map((announcement) => (
                                        <AnnouncementsListCard
                                            key={announcement.id}
                                            announcement={announcement}
                                            userID={userID}
                                            users={users}
                                            setToastSymbol={setToastSymbol}
                                            setToastText={setToastText}
                                            setOpen={setOpen}
                                            forceUpdate={forceUpdate}
                                        />
                                    ))}
                        </div>
                    ))}
            </ScrollArea>
            <Toast.Provider swipeDirection="right">
                <Toast.Root
                    className="ToastRoot"
                    open={open}
                    onOpenChange={setOpen}
                >
                    <Toast.Title className="ToastTitle">
                        <div className="flex flex-row items-center">
                            <ToastSymbol color="green" />
                            {toastText}
                        </div>
                    </Toast.Title>
                </Toast.Root>
                <Toast.Viewport className="ToastViewport" />
            </Toast.Provider>
        </Card>
    );
}
