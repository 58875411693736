// response type from /auth server call
export interface RepositoryDataResponse {
    default_branch: string;
    name: string;
    owner: string;
    server: string;
    url: string;
    visability: number;
    org_id: string;
}

export interface OrgDataResponse {
    id: string;
    name: string;
    url: string;
    server: string;
}

// request type to /index server call
export interface IndexDataRequest {
    server: string;
    owner: string;
    ref_name: string;
    name: string;
    key: string;
    is_reindex: boolean;
}

// search_natural

export interface SearchResponse {
    id: string;
    org_id: string;
    // githubblame?: { [line_number: string]: GitHubBlameResponse };
    // nodes?: NodeResponse[][];
}

interface GitHubBlameResponseByLine {
    [line_number: string]: GitHubBlameResponse;
}

export interface DescribeResponse {
    summary: string;
    ids: string[];
    nodes?: CodeResponse[];
    githubblame?: GitHubBlameResponseByLine[];
}

export interface SearchResponseIntegration {
    id: string;
    channel_id: string;
    channel_name: string;
    org_id: string;
    message: string;
    sent: string;
    url: string;
    kind: string;
}

export interface DescribeResponseIntegration {
    summary: string;
    ids: string[];
    nodes?: SearchResponseIntegration[];
}
export interface GitHubBlameResponse {
    Id: string;
    Name: string;
    CommittedDate: string;
    Message: string;
    PRsResponsible: GitHubBlamePRResponse[];
}

export interface GitHubBlamePRResponse {
    Number: number;
    Url: string;
    MergedAt: string;
}

export interface FilterResponse {
    name: string;
    id: string;
}

export interface NodeResponse {
    kind: string; // 'jira' or 'pr' or 'code', etc.
    url: string;
    org_id: string;
}

export const NodeResponseKinds = {
    jira: "Jira",
    pr: "PullRequest",
    linear: "Linear",
    slack: "Slack",
    notion: "Notion",
    web: "Web",
    code: "code",
    githubTicket: "GitHubTicket",
    confluence: "Confluence",
};

export interface CodeResponse extends NodeResponse {
    signature: string;
    filepath: string;
    server: string;
    owner: string;
    repo: string;
    body: string;
    branch: string;
    hash: string;
    location: string;
}

export interface PRResponse extends NodeResponse {
    pr_number: number;
    merged_at: string;
    head: string;
}

export interface JiraResponse extends NodeResponse {
    pr_number: number;
    key: string;
    summary: string;
    status: string;
    content: string;
    url: string;
    jira_created_at: string;
    jira_updated_at: string;
}

export interface ConfluenceResponse extends NodeResponse {
    todo: string;
}

export interface LinearResponse extends NodeResponse {
    linear_created_at: string;
    linear_updated_at: string;
    summary: string;
    key: string;
}

export interface SlackResponse extends NodeResponse {
    channel_name: string;
    channel_id: string;
    url: string;
    sent: string;
}

export interface NotionResponse extends NodeResponse {
    page_id: string;
    page_title: string;
}

export interface WebResponse extends NodeResponse {
    key: string; // page title
}

export interface ConfluenceResponse extends NodeResponse {
    page_id: string;
    title: string;
}

export interface ScopeResponse {
    key: string;
    name: string;
    owner?: string;
    ref_name?: string;
    server?: string;
    repository_id?: string;
    source_specific_id?: string;
}

export interface GeneralNotificationSettings {
    new_needs_response: boolean;
    updated_needs_response: boolean;
    sla_breaching_notification: boolean;
    sla_notification: boolean;
}

export interface SlackNotificationSettings {
    channelKey?: string;
}

export interface BotMetadata {
    bot_type: string;
    auto_escalate?: boolean;
    preview_message?: boolean;
    auto_respond?: boolean;
    channels_selected?: ScopeResponse[];
    confidence_enabled?: boolean;
    icon_url?: string;
    responding_enabled?: boolean;
    asm_ticket_channels?: ScopeResponse[];
    index_code?: boolean;
}

export interface BotConnection {
    bot_type: string;
    connection_source: string;
    connection_channels: ScopeResponse[];
    bot_channels?: ScopeResponse[];
    operation: string;
}

export interface IntegrationMetadataResponse {
    scopes: ScopeResponse[];
    date: string;
    slack_team_id?: string;
    token_scopes?: string[];
}

export interface QueriesResponse {
    data: Query[];
}

export interface AnalyticsResponse {
    count: number;
    resolved: number;
    escalated: number;
    categories: Map<string, number>;
}

export interface OrgInfoResponse {
    Code?: IntegrationMetadataResponse;
    Slack?: IntegrationMetadataResponse;
    Linear?: IntegrationMetadataResponse;
    Jira?: IntegrationMetadataResponse;
    Google?: IntegrationMetadataResponse;
    PullRequest?: IntegrationMetadataResponse;
    Notion?: IntegrationMetadataResponse;
    Web?: IntegrationMetadataResponse;
    Discord?: IntegrationMetadataResponse;
    GitHubTicket?: IntegrationMetadataResponse;
    ChatWidget?: IntegrationMetadataResponse;
    Confluence?: IntegrationMetadataResponse;
}

export interface GetApiKeysResponse {
    created_at: number;
    key: string;
}

export interface ConnectionMetadata {
    connection: string;
    scopes: ScopeResponse[];
}
export interface GetConnectionsResponse {
    channels: ScopeResponse[];
    connected_integrations: ConnectionMetadata;
}

export interface TicketTag {
    id: string;
    tag: string;
    source: string;
    user_id: string;
}

export interface Assignee {
    id: string;
    source: string;
    user_id: string;
    assignee_user_id: string;
}

export interface TicketTopics {
    id: string;
    added_topics: string[];
    deleted_topics: string[];
    source: string;
    user_id: string;
}

export interface TicketStatus {
    id: string;
    status: string;
    source: string;
    user_id: string;
}

export interface TopicCount {
    topic: TopicInfo;
    count: string;
}

export interface RelatedIssue {
    id: string;
    org_specific_id: string;
    title: string;
    query: string;
}

export interface Question {
    summary: string;
    topic: string;
    category: string;
    count: number;
    related_questions: RelatedIssue[];
}

export interface Insight {
    id: string;
    created_at: string;
    updated_at: string;
    title: string;
    description: string;
    user_id: string;
    status: string;
    related_issues: RelatedIssue[];
    count: number;
    metadata: string;
    external_issues: ExternalIssues[];
    announcements: Announcement[];
}

export interface ExternalIssues {
    id: string;
    parent_id: string;
    url: string;
}

export interface Reaction {
    name: string;
    users: string[];
    count: number;
}

export interface Message {
    id: string;
    text: string;
    user_id: string;
    ts: string;
    reactions: Reaction[];
}

export type UserResponse = {
    id: string;
    name: string;
    username?: string;
    email?: string;
    url?: string;
    avatar?: string;
};

export type Query = {
    id: string;
    ticket_identifier: string;
    ticket_number: number;
    number: number;
    title: string;
    query: string;
    created_at: string;
    updated_at: string;
    bot_category: string;
    source?: string;
    topic: string[];
    ticket_status: string;
    url: string;
    source_specific_id: string;
    ai_response?: string;
    internal_note?: string;
    assignee_user_id?: string;
    external_issues: string[];
    breaching?: string;
    user_info: UserResponse;
    assembly_responded: boolean;
};

export interface Ticket {
    id: string;
    ticket_identifier: string;
    number: number;
    created_at: string;
    updated_at: string;
    query: string;
    title: string;
    bot_category: string;
    source: string;
    topic: string[];
    ticket_status: string;
    url: string;
    source_specific_id: string;
    ai_response: string;
    internal_note: string;
    assignee_user_id: string;
    external_issues: ExternalIssues[];
    channel_name: string;
    breaching?: string;
    insights: Insight[]; // Returning abridged version, no related_issues, orgID, and userID
}

export interface GetThreadInfoResponse {
    thread: Message[];
    users: { [key: string]: UserResponse };
}

export interface HistoryResponse {
    id: string;
    timestamp: string;
    type: string;
    content: string;
    source: string;
    metadata: string;
    user_data: UserResponse;
}

export interface PublishMessage {
    message: string;
    source_specific_id: string;
    source: string;
    user: string;
    message_title?: string;
}

export interface InsightData {
    existing_insight_id?: string;
    title?: string;
    description?: string;
    user_id?: string;
    status?: string;
    related_issues?: string[];
    metadata?: string;
}

export interface SaveInsightResponse {
    id: string;
}

export interface SimilarIssuesResponse {
    id_to_score: { [id: string]: number };
    issues: Query[];
}

export interface GetUserResponse {
    id: string;
    email: string;
    username: string;
    first_name: string;
    last_name: string;
    picture_url: string;
    user_role: string;
}

export interface IconEntry {
    Component: React.ElementType;
    props?: React.SVGProps<SVGElement>;
}

interface TicketComment {
    user: string;
    comment: string;
}

export interface CreateTicketPayload {
    query: TicketComment[];
    title: string;
    source: string;
    user: string;
    question: string;
    source_specific_id: string;
}

export interface CreateTicketFromUIPayload {
    title: string;
    message: string;
    user: string;
    status: string;
    assignee_user_id: string;
    tag: string;
    topic: string[];
}

export interface CreateTicketFromUIResponse {
    id: string;
    org_specific_id: string;
}

export enum PublishTicketType {
    Issue = "Issue",
    Insight = "Insight",
}

export interface PublishTicketPayload {
    connection: string;
    title: string;
    source: string;
    user: string;
    question: string;
    url: string;
    comments: TicketComment[];
    system_id: string;
    system_type: PublishTicketType;
    destination?: ScopeResponse;
}

export interface PublishTicketResponse {
    urls: string[];
}

export interface QueriesWithPaginationResponse {
    data: Query[];
    has_next_page: boolean;
    next_cursor: number;
}

export interface GetTopicsResponse {
    id: string;
    org_id: string;
    topic_name: string;
    bug_topic_count: string;
    feature_topic_count: string;
    query_topic_count: string;
    unknown_topic_count: string;
    created_at?: string;
    description?: string;
    color?: string;
}

export interface InsightsWithPaginationResponse {
    data: Insight[];
    has_next_page: boolean;
    next_cursor: number;
}

export interface InsightsResponse {
    pages: {
        data: Insight[];
    }[];
}
export interface SLAResponse {
    id: string;
    enabled: boolean;
    channels: ScopeResponse[]; // Adjust type as necessary
    breach_target: number;
    breach_target_unit: string;
    notification?: number;
    notification_time_unit?: string;
    notification_enabled: boolean;
    owner_id: string;
    created_at?: string;
    updated_at?: string;
    source: string;
}

export interface SLACreatePayload {
    operation: string;
    enabled: boolean;
    channels: ScopeResponse[];
    breach_target: number;
    breach_target_unit: string;
    notification?: number;
    notification_time_unit?: string;
    source: string;
    id?: string;
}

export interface SLADeletePayload {
    id: string;
    operation: string;
}

export interface CustomerGroup {
    id?: string;
    created_at: string;
    updated_at: string;
    group_name: string;
    description: string;
    user_id: string;
    metadata: string;
    editable: boolean;
    integration: string;
}

export interface CustomerGroupCreatePayload {
    operation: string;
    metadata: string;
    id?: string;
    group_name: string;
    description: string;
}

export interface CustomerGroupDeletePayload {
    id: string;
    operation: string;
}

export interface Announcement {
    id: string;
    created_at: string;
    updated_at: string;
    name: string;
    content: string;
    status: string;
    user_id: string;
    metadata: string;
    customer_groups: ScopeResponse[];
    announcement_type: string;
    insight_id: string;
    recommended_answer: string;
}

export interface AnnouncementsWithPaginationResponse {
    data: Announcement[];
    has_next_page: boolean;
    next_cursor: number;
}

export interface AnnouncementsResponse {
    pages: {
        data: Announcement[];
    }[];
}

export interface TopicInfo {
    ID: string;
    Name: string;
}

export interface InsightFilters {
    topics?: TopicInfo[];
    tags?: string[];
    statuses?: string[];
    sources?: string[];
    assignee_user_ids?: string[];
    oldest_created?: string;
    latest_created?: string;
    oldest_updated?: string;
    latest_updated?: string;
    exclude_existing_ri?: boolean;
}

export interface ErrorResponse {
    details?: string;
}
