import { Button } from "@/component/shadcn/ui/button";
import { Input } from "@/component/shadcn/ui/input";
import { Cross2Icon } from "@radix-ui/react-icons";
import { Flex } from "@radix-ui/themes";
import type { Table } from "@tanstack/react-table";
import { DataTableViewOptions } from "./data-table-view-options";

interface DataTableToolbarProps<TData> {
    table: Table<TData>;
    placeholder: string;
    column: string;
}

export function DataTableToolbar<TData>({
    table,
    placeholder,
    column,
}: DataTableToolbarProps<TData>) {
    const isFiltered =
        table
            .getState()
            .columnFilters.filter((filter) => filter.id !== "ticket_status")
            .length > 0;

    return (
        <div>
            <Flex direction="row" align="center" gap="2">
                <Input
                    placeholder={placeholder}
                    value={
                        (table.getColumn(column)?.getFilterValue() as string) ??
                        ""
                    }
                    onChange={(event) =>
                        table
                            .getColumn(column)
                            ?.setFilterValue(event.target.value)
                    }
                    className="h-8 w-[250px] lg:w-[350px] px-2"
                />

                {isFiltered && (
                    <Button
                        variant="ghost"
                        onClick={() => table.resetColumnFilters()}
                        className="h-8 px-2 lg:px-3 py-1 flex"
                    >
                        <Cross2Icon className="mr-2 h-4 w-4" />
                        Reset
                    </Button>
                )}
                <DataTableViewOptions table={table} />
            </Flex>
        </div>
    );
}
