import { Input } from "@/component/shadcn/ui/input";

import type { Insight } from "@/interfaces/serverData";
import { arraysAreEqual } from "@/utilities/methods";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { memo, useEffect, useState } from "react";

interface FilterBarProps {
    insights: Insight[];
    filteredInsights: Insight[];
    setFilteredInsights: React.Dispatch<React.SetStateAction<Insight[]>>;
}

function FilterBar({
    insights,
    filteredInsights,
    setFilteredInsights,
}: FilterBarProps) {
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        let newFilteredInsights = insights;
        if (searchQuery !== "") {
            newFilteredInsights = newFilteredInsights.filter(
                (insight) =>
                    (insight.title ?? "")
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                    (insight.description ?? "")
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()),
            );
        }
        if (!arraysAreEqual(newFilteredInsights, filteredInsights)) {
            setFilteredInsights(newFilteredInsights);
        }
    }, [insights, filteredInsights, setFilteredInsights, searchQuery]);

    return (
        <div className="flex flex-col gap-1 m-1 py-1 bg-muted">
            <div className="flex items-center gap-0 mx-5 mt-2">
                <MagnifyingGlassIcon className="w-4 h-4 text-gray-700" />
                <Input
                    type="text"
                    placeholder={"Search..."}
                    value={searchQuery}
                    onChange={(event) => setSearchQuery(event.target.value)}
                    className="w-full p-1.5 rounded-md text-xs border-none shadow-none focus:outline-none focus:border-transparent"
                />
            </div>
        </div>
    );
}

export default memo(FilterBar);
