import { useOthers, useSelf } from "@liveblocks/react/suspense";
import styles from "./Avatars.module.css";

export function Avatars() {
    const users = useOthers();
    const currentUser = useSelf();

    return (
        <div className={styles.avatars}>
            {users.map(({ connectionId, info }, index) => {
                return (
                    <Avatar
                        key={connectionId}
                        picture={
                            info?.avatar ??
                            `https://liveblocks.io/avatars/avatar-${index + 1}.png`
                        }
                        name={info?.name ?? "Anonymous"}
                    />
                );
            })}

            {currentUser && (
                <div className="relative ml-8 first:ml-0">
                    <Avatar
                        picture={
                            currentUser.info?.avatar ??
                            "https://liveblocks.io/avatars/avatar-1.png"
                        }
                        name={currentUser.info?.name ?? "Anonymous"}
                    />
                </div>
            )}
        </div>
    );
}

export function Avatar({ picture, name }: { picture: string; name: string }) {
    return (
        <div className={styles.avatar} data-tooltip={name}>
            <img
                src={picture}
                className={styles.avatar_picture}
                data-tooltip={name}
            />
        </div>
    );
}
