import { useLogoutFunction } from "@propelauth/react";
import { Button, Dialog } from "@radix-ui/themes";
// UnauthorizedDialog.tsx
import type React from "react";
import { useDialog } from "../DialogContext";

const UnauthorizedDialog: React.FC = () => {
    const { isDialogOpen, closeDialog } = useDialog();
    const logout = useLogoutFunction();
    const closeDialogAndRedirect = async () => {
        closeDialog();
        await logout(false);
    };
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const handlePointerDownOutside = (event: any) => {
        event.preventDefault();
    };
    return (
        <Dialog.Root open={isDialogOpen} onOpenChange={(open) => closeDialog()}>
            <Dialog.Content
                onEscapeKeyDown={handlePointerDownOutside}
                onPointerDownOutside={handlePointerDownOutside}
            >
                <Dialog.Title>Your session has expired</Dialog.Title>
                <Dialog.Description
                    color="gray"
                    style={{ paddingBottom: "30px" }}
                >
                    Please log in again to continue using the app.
                </Dialog.Description>
                <Button onClick={closeDialogAndRedirect}>Login Again</Button>
            </Dialog.Content>
        </Dialog.Root>
    );
};

export default UnauthorizedDialog;
