import { AlertDialog, Button, Flex } from "@radix-ui/themes";
import type React from "react";
import { useNavigate } from "react-router-dom";

interface PopupProps {
    isDialogOpen: boolean;
    closeDialog: () => void;
    isAdmin: boolean;
}

const FirstSetupDialog: React.FC<PopupProps> = ({
    isDialogOpen,
    // openDialog,
    closeDialog,
    isAdmin,
}) => {
    const navigate = useNavigate();
    const closeDialogAndRedirect = async () => {
        navigate("/admin/manage_integrations");
    };
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const handlePointerDownOutside = (event: any) => {
        event.preventDefault();
    };
    return (
        <AlertDialog.Root
            open={isDialogOpen}
            onOpenChange={(open) => closeDialog()}
        >
            <AlertDialog.Content onEscapeKeyDown={handlePointerDownOutside}>
                {isAdmin ? (
                    <div>
                        <AlertDialog.Title>Glad You're Here!</AlertDialog.Title>
                        <AlertDialog.Description>
                            Navigate to <b>Manage Integrations</b> to connect
                            Assembly to any integration you'd like!
                        </AlertDialog.Description>
                        <Flex gap="3" mt="4" justify="end">
                            <AlertDialog.Action>
                                <Button
                                    onClick={closeDialogAndRedirect}
                                    variant="solid"
                                    color="iris"
                                >
                                    Let's go!
                                </Button>
                            </AlertDialog.Action>
                        </Flex>
                    </div>
                ) : (
                    <div>
                        <AlertDialog.Title>Welcome!</AlertDialog.Title>
                        <AlertDialog.Description>
                            Please contact Admin to set up an integration with
                            Assembly! If you are an Admin, please contact us at
                            support@askassembly.app or reach out to us via
                            Slack.
                        </AlertDialog.Description>
                    </div>
                )}
            </AlertDialog.Content>
        </AlertDialog.Root>
    );
};

export default FirstSetupDialog;
