import { Skeleton } from "@radix-ui/themes";

import { Text } from "@radix-ui/themes";

export function Loading() {
    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    return (
        <div>
            <Skeleton>
                <Text>{loremIpsum}</Text>
            </Skeleton>
        </div>
    );
}
