export const queryToLabel: Record<string, string> = {
    id: "Id",
    tag: "Tag",
    description: "Description",
    created_at: "Timestamp",
    count: "Count",
    name: "Member",
    role: "Role",
};

export const tags = [
    {
        value: "Bug",
        color: "red",
    },
    {
        value: "Feature",
        color: "blue",
    },
    {
        value: "Query",
        color: "green",
    },
    {
        value: "Unknown",
        color: "gray",
    },
];

export type TagsRowState = {
    colorSelected: string;
    tagSelected: string;
};

export type MembersRowState = {
    id: string;
    name: string;
    email: string;
    username: string;
    picture_url: string;
    role: string;
};
