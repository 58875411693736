import { useState } from "react";
import URLCustomPopup from "../../../component/UrlPopupComponent";

interface IntegrationProps {
    popupOpen: boolean;
    setPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
    index: (integrationType: string, jiraUrl: string) => void; // separate function for jira, due to addition of URL
}

export const JiraCardComponent = (props: IntegrationProps) => {
    const [jiraURL, setJiraURL] = useState("");

    const [invalidUrl, setInvalidUrl] = useState<boolean>(false);

    const handleJiraURLChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setJiraURL((prev) => event.target.value);
        setInvalidUrl(
            !(
                event.target.value.endsWith(".atlassian.net/") &&
                event.target.value.startsWith("https://")
            ) && event.target.value.length !== 0,
        );
    };

    return (
        <URLCustomPopup
            open={props.popupOpen}
            text="Enter Jira cloud or installation URL"
            suggestText="https://<your-domain>.atlassian.net/"
            onClickConfirm={() => {
                props.index("Jira", jiraURL);
            }}
            onCancel={() => {
                props.setPopupOpen(false);
            }}
            jiraUrl={jiraURL}
            setJiraUrl={handleJiraURLChange}
            invalidUrl={invalidUrl}
        />
    );
};
