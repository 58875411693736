import { sources, statuses, tags } from "@/IssuesTable/constants";
import { API, URLS } from "@/constant";
import type {
    GetUserResponse,
    Insight,
    InsightData,
    Query,
    ScopeResponse,
} from "@/interfaces/serverData";
import { integrationBackEndDataMappingToSvg } from "@/pages/Admin/Integrations/constant";

import {
    CheckCircledIcon,
    EnvelopeOpenIcon,
    ExclamationTriangleIcon,
    LapTimerIcon,
    QuestionMarkCircledIcon,
    ReaderIcon,
    StopwatchIcon,
} from "@radix-ui/react-icons";
import type { badgePropDefs } from "@radix-ui/themes/dist/cjs/components/badge.props";
import type { QueryClient } from "@tanstack/react-query";
import type { AxiosInstance } from "axios";
import _ from "lodash";
import { CircleIcon } from "lucide-react";
import ReactDOMServer from "react-dom/server";

export type BadgeColor = (typeof badgePropDefs.color.values)[number];
export function getColor(input: string): BadgeColor {
    switch (input) {
        case "Query":
            return "green";
        case "Feature":
            return "blue";
        case "Bug":
            return "red";
        default:
            return "gray";
    }
}

export function getColorLight(input: string): string {
    switch (input) {
        case "Query":
            return "#5BB98B";
        case "Feature":
            return "#5EB1EF";
        case "Bug":
            return "#EB8E90";
        default:
            return "#BBBBBB";
    }
}

export function getTopicColor(input: string): BadgeColor {
    switch (input) {
        case "autoresponder setup":
            return "green";
        case "channel activity":
            return "pink";
        default:
            return "iris";
    }
}

export function processInsightMetadata(
    insight: Insight,
): [boolean, string, string] {
    if (insight.metadata) {
        const metadata = new Map(Object.entries(JSON.parse(insight.metadata)));
        const isAIGenerated =
            metadata.has("ai_generated") &&
            Boolean(metadata.get("ai_generated"));
        const topicGeneratedFrom: string =
            (metadata.get("topic_generated_from") as string) ?? "";
        const categoryGeneratedFrom: string =
            (metadata.get("category_generated_from") as string) ?? "";
        return [isAIGenerated, topicGeneratedFrom, categoryGeneratedFrom];
    }
    return [false, "", ""];
}

export async function saveAIGeneratedInsight(
    insight: Insight,
    queryClient: QueryClient,
    userID: string,
    api: AxiosInstance,
) {
    const requestData: InsightData = {
        title: insight.title,
        description: insight.description,
        user_id: userID,
        related_issues: insight.related_issues.map((ri) => ri.id),
        metadata: insight.metadata,
    };

    try {
        const res = await api.post(
            URLS.serverUrl + API.saveInsight,
            requestData,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        );

        if (res.status === 200) {
            console.log(`Saved insight id ${res.data.data} successfully`);
            await queryClient.refetchQueries({
                queryKey: ["insights"],
                exact: true,
            });
            return res.data.data;
        } else {
            console.log("Call to update status failed");
            return "";
        }
    } catch (error) {
        console.error("Error saving insight:", error);
        return "";
    }
}

export function getExternalIssueIcon(url: string): React.ElementType {
    const icon = url.toLowerCase().includes("linear")
        ? integrationBackEndDataMappingToSvg.get("Linear")
        : url.toLowerCase().includes("github")
          ? integrationBackEndDataMappingToSvg.get("GitHubTicket")
          : url.toLowerCase().includes("atlassian")
            ? integrationBackEndDataMappingToSvg.get("Jira")
            : undefined;

    return icon || ReaderIcon;
}

export function getExternalIssueText(url: string): string {
    const text = url.toLowerCase().includes("linear")
        ? "Linear"
        : url.toLowerCase().includes("github")
          ? "GitHub"
          : url.toLowerCase().includes("atlassian")
            ? "Jira"
            : "";
    return text;
}

export const areQueriesEqual = (query1: Query, query2: Query): boolean => {
    return (
        query1.id === query2.id ||
        query1.title === query2.title ||
        query1.query === query2.query
    );
};

export const addToCustomSet = (
    query: Query,
    set: Set<Query>,
    compareFn: (q1: Query, q2: Query) => boolean,
) => {
    if (
        !Array.from(set).some((existingQuery) =>
            compareFn(existingQuery, query),
        )
    ) {
        set.add(query);
    }
};

export function getPlural(type: string) {
    switch (type) {
        case "Source": {
            return "sources";
        }
        case "Broadcast": {
            return "broadcasts";
        }
        case "Tag": {
            return "tags";
        }
        case "Topic": {
            return "topics";
        }
        case "Status": {
            return "statuses";
        }
        case "Assignee": {
            return "assignees";
        }
    }
}

export function getOptions(
    type: string,
    topics: { color: string; label: string; value: string }[],
    users: GetUserResponse[],
    channels?: ScopeResponse[],
    organizationSources?: string[],
) {
    switch (type) {
        case "Source": {
            if (organizationSources !== undefined) {
                return sources.filter((broadcast) =>
                    organizationSources.includes(broadcast.value),
                );
            } else {
                return sources;
            }
        }
        case "Channel": {
            debugger;
            return channels?.map((channel) => ({
                label: channel.name,
                value: channel.key,
                color: "",
            }));
        }
        case "Tag": {
            return tags;
        }
        case "Topic": {
            return topics;
        }
        case "Status": {
            return statuses;
        }
        case "Assignee": {
            const options = users.map((user) => ({
                label: `${user.first_name} ${user.last_name}`,
                value: user.id,
                color: "",
            }));
            options.push({
                color: "",
                label: "No Assignee",
                value: "noAssignee",
            });
            return options;
        }
    }
}

export function getStatusIcon(status: string) {
    switch (status) {
        case "New": {
            return CircleIcon;
        }
        case "Open": {
            return StopwatchIcon;
        }
        case "NeedsResponse": {
            return LapTimerIcon;
        }
        case "Breaching": {
            return ExclamationTriangleIcon;
        }
        case "Closed": {
            return CheckCircledIcon;
        }
        default: {
            return QuestionMarkCircledIcon;
        }
    }
}

export function getAnnouncementStatusIcon(status: string) {
    switch (status) {
        case "Draft": {
            return ReaderIcon;
        }
        case "Sent": {
            return EnvelopeOpenIcon;
        }
        default: {
            return QuestionMarkCircledIcon;
        }
    }
}

export function arraysAreEqual<T>(arr1: T[], arr2: T[]): boolean {
    return _.isEqual(arr1, arr2);
}

export function formatUsernames(content: string) {
    const regex = /<@([^>]+)>/g;

    return content.replace(regex, (match, username) => {
        return `<span class="mention">@${username}</span>`;
    });
}

export function cleanText(text: string): string {
    // Replace HTML tags with an empty string
    const htmlTagRegex = /<[^>]*>/g;
    let cleanedText = text.replace(htmlTagRegex, "");

    // Remove images
    cleanedText = cleanedText.replace(/!\[.*?\]\(.*?\)/g, "");
    // Remove headers
    cleanedText = cleanedText.replace(/^#.*$/gm, "");
    // Remove blockquotes
    cleanedText = cleanedText.replace(/^> .*/gm, "");

    // Replace newlines with a single space and trim leading/trailing spaces
    return cleanedText.replace(/(\r\n|\n|\r)/gm, " ").trim();
}

export function getHtmlStringFromReactContent(
    reactElement: React.ReactNode,
): string {
    return ReactDOMServer.renderToString(reactElement);
}
