import { statuses } from "@/IssuesTable/constants";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    HistoryResponse,
    IconEntry,
    Query,
    Ticket,
    TicketStatus,
} from "@/interfaces/serverData";
import { getStatusIcon } from "@/utilities/methods";
import {
    QuestionMarkCircledIcon,
    TriangleDownIcon,
} from "@radix-ui/react-icons";
import { Badge } from "@radix-ui/themes";
import type {
    QueryObserverResult,
    RefetchOptions,
} from "@tanstack/react-query";
import React from "react";
import { useEffect, useState } from "react";

interface TicketStatusDropdownProps<TData> {
    ticket: Ticket;
    userID: string;
    refetchThreadData: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<HistoryResponse[], Error>>;
}

export function TicketStatusDropdown<TData extends Query>({
    ticket,
    userID,
    refetchThreadData,
}: TicketStatusDropdownProps<TData>) {
    const api = useApi();
    const initialStatus = statuses.find(
        (status) => status.value === ticket.ticket_status,
    );
    const [statusSelected, setStatusSelected] = useState<string>(
        initialStatus?.value ?? "Unknown",
    );

    useEffect(() => {
        setStatusSelected(initialStatus?.value ?? "Unknown");
    }, [initialStatus]);

    function saveStatus(value: string) {
        const requestData: TicketStatus = {
            id: ticket.id,
            status: value,
            source: "Web",
            user_id: userID,
        };
        api.patch(URLS.serverUrl + API.saveTicket, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                setStatusSelected(value);
                refetchThreadData();
                console.log(`Updated status to ${value} successfully`);
            } else {
                console.log("Call to update status failed");
            }
        });
    }

    const selectedStatus = statuses.find(
        (status) => status.value === statusSelected,
    );
    const Icon = getStatusIcon(selectedStatus?.value ?? "Unknown");
    const statusesInfo: { label: string; value: string; icon: IconEntry }[] =
        [];
    for (const option of statuses) {
        const IconComponent = getStatusIcon(option.value ?? "Unknown");
        const iconEntry = {
            Component: IconComponent,
            props: {
                width: 15,
                height: 15,
                style: { marginRight: "8" },
            },
        };
        statusesInfo.push({
            label: option.label,
            value: option.value,
            icon: iconEntry,
        });
    }

    return (
        <div className="text-xs flex items-center">
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <Badge
                        color={"gray"}
                        size="2"
                        radius="full"
                        variant="outline"
                        className="py-0 hover:opacity-70  hover:text-opacity-70 transition-opacity duration-300 text-gray11"
                    >
                        {selectedStatus ? (
                            <Icon
                                style={{
                                    minWidth: "17px",
                                    minHeight: "30px",
                                }}
                            />
                        ) : (
                            <QuestionMarkCircledIcon
                                style={{
                                    minWidth: "17px",
                                    minHeight: "30px",
                                }}
                            />
                        )}
                        <span className="text-xs font-normal ">
                            {selectedStatus ? selectedStatus.label : "Unknown"}
                        </span>
                        <TriangleDownIcon className="h-3 w-3" />
                    </Badge>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                    side="bottom"
                    align="end"
                    className="w-[160px] p-2"
                >
                    {statusesInfo.map((status) => (
                        <DropdownMenuItem
                            key={status.value}
                            className="py-1 hover:bg-muted cursor-pointer"
                            onSelect={() => saveStatus(status.value)}
                        >
                            {React.createElement(
                                status.icon.Component,
                                status.icon.props,
                            )}
                            {status.label}
                        </DropdownMenuItem>
                    ))}
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    );
}
