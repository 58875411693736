import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    TextField,
} from "@mui/material";
import type React from "react";
import { useEffect, useState } from "react";

interface PopupProps {
    open: boolean;
    text: string;
    suggestText: string;
    onClickConfirm: () => void;
    onCancel: () => void;
    jiraUrl: string;
    setJiraUrl: (event: React.ChangeEvent<HTMLInputElement>) => void;
    invalidUrl: boolean;
}

const URLCustomPopup: React.FC<PopupProps> = ({
    open,
    text,
    suggestText,
    onClickConfirm,
    onCancel,
    jiraUrl,
    setJiraUrl,
    invalidUrl,
}) => {
    useEffect(() => {
        if (!invalidUrl && jiraUrl.length > 0) {
            setDisabledButton(false);
        } else {
            setDisabledButton(true);
        }
    }, [invalidUrl, jiraUrl]);

    const [disabledButton, setDisabledButton] = useState<boolean>(true);

    return (
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{text}</DialogTitle>

            <TextField
                sx={{ ml: 2, mr: 2 }}
                value={jiraUrl}
                onChange={setJiraUrl}
                id="outlined-basic"
                label={suggestText}
                variant="outlined"
                size="small"
                error={invalidUrl}
            />

            <DialogActions>
                <Button
                    onClick={onClickConfirm}
                    disabled={disabledButton}
                    color="primary"
                >
                    Activate
                </Button>
                <Button onClick={onCancel} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default URLCustomPopup;
