import { useEffect, useState } from "react";
import { API, URLS, pages } from "../../constant";
import { clearAllCookies } from "../../utilities/CookieManagement";

import { ChevronDownIcon } from "@radix-ui/react-icons";

import { useApi } from "@/interfaces/api";
import { useLogoutFunction } from "@propelauth/react";
import { Button } from "@radix-ui/themes";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "../shadcn/ui/dropdown-menu";

import { useNavigate } from "react-router-dom";

interface MenuDropdownProps {
    state: string;
    organizationName: string;
    collapsed: boolean;
}
export default function MenuDropdownComponent({
    state,
    organizationName,
    collapsed,
}: MenuDropdownProps) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    // Default is the assembly logo
    const [logoUrl, setLogoUrl] = useState(
        "https://bookface-images.s3.amazonaws.com/small_logos/e776eb370bd36ebbc8087ded38ebcb435bf3d4c4.png",
    );

    const logout = useLogoutFunction();
    const toggleDropdown = () => setIsOpen((prev) => !prev);
    const closeDropdown = () => setIsOpen(false);
    const handleOpenChange = (open: boolean) => {
        setIsOpen(open);
    };

    useEffect(() => {
        switch (state) {
            case pages.login:
                break;
            case pages.index:
                setIsLoggedIn(true);
                break;
            case pages.search:
                setIsLoggedIn(true);
                break;
            default:
                break;
            // Default case
        }
    }, [state]);

    const api = useApi();
    useEffect(() => {
        api.get(URLS.serverUrl + API.getOrg, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.data.logo !== "") {
                        setLogoUrl(res.data.data.logo);
                    }
                }
            })
            .catch((res) => {
                console.log("Could not grab logo, using default");
            });
    }, [api]);

    const onLogout = async () => {
        clearAllCookies();
        await logout(false);
    };
    const navigate = useNavigate();

    const clickWorkspacePreferences = async () => {
        navigate("/preferences/profile");
    };

    const clickDashboard = async () => {
        navigate("/inbox");
    };

    return (
        <div className="pt-3 pb-6 pl-3 w-full">
            <DropdownMenu
                dir={"ltr"}
                open={isOpen}
                onOpenChange={handleOpenChange}
            >
                <DropdownMenuTrigger>
                    <Button
                        variant="soft"
                        size="2"
                        className={`flex items-center text-sm px-2 ${
                            isOpen ? "bg-sidebarBorder" : "bg-muted"
                        }`}
                        onClick={toggleDropdown}
                    >
                        <img
                            src={logoUrl}
                            alt={organizationName}
                            className="w-5 h-5 rounded-sm"
                        />
                        <div className={`${collapsed ? "hidden" : "block"}`}>
                            {organizationName}
                        </div>
                        <ChevronDownIcon className="w-3 h-3 text-primary" />
                    </Button>
                </DropdownMenuTrigger>
                {isLoggedIn && (
                    <DropdownMenuContent side="bottom" className="w-40 ml-4 ">
                        <DropdownMenuItem
                            onClick={() => {
                                onLogout();
                                closeDropdown();
                            }}
                            className="py-1 hover:bg-muted cursor-pointer px-3 text-xs"
                        >
                            Logout
                        </DropdownMenuItem>
                        <DropdownMenuSeparator className="h-0.5" />
                        <DropdownMenuItem
                            onClick={() => {
                                clickWorkspacePreferences();
                                closeDropdown();
                            }}
                            className="py-1 hover:bg-muted cursor-pointer px-3 text-xs"
                        >
                            Preferences
                        </DropdownMenuItem>
                        <DropdownMenuItem
                            onClick={() => {
                                clickDashboard();
                                closeDropdown();
                            }}
                            className="py-1 hover:bg-muted cursor-pointer px-3 text-xs"
                        >
                            Dashboard
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                )}
            </DropdownMenu>
        </div>
    );
}
