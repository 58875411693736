import { Button } from "@/component/shadcn/ui/button";
import { Textarea } from "@/component/shadcn/ui/textarea";

import { Card } from "@/component/shadcn/ui/card";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/component/shadcn/ui/dialog";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import { Pencil2Icon } from "@radix-ui/react-icons";
import type React from "react";
import { useState } from "react";
import ReactMarkdown from "react-markdown";

interface InternalNotesProps {
    ticketId: string;
    internalNote?: string;
    source: string;
    userId: string;
    sidebarLabels: string;
}

const InternalNotes: React.FC<InternalNotesProps> = ({
    ticketId,
    internalNote,
    source,
    userId,
    sidebarLabels,
}) => {
    const [notes, setNotes] = useState(internalNote ?? "");
    const [savedNotes, setSavedNotes] = useState(internalNote ?? "");
    const [isEditing, setIsEditing] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const api = useApi();

    const saveNotes = async () => {
        const requestData = {
            id: ticketId,
            internal_note: notes,
            source: "Web", // internal system data
            user_id: userId,
        };
        try {
            const response = await api.patch(
                URLS.serverUrl + API.saveTicket,
                requestData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            );
            if (response.status === 200) {
                setSavedNotes(notes);
                setIsEditing(false);
                console.log("Notes updated successfully");
            } else {
                console.log("Failed to update notes");
            }
        } catch (error) {
            console.error("Error saving notes:", error);
        }
    };

    return (
        <div>
            <div className="flex flex-row justify-between items-center w-full pb-2">
                <div className={sidebarLabels}>Internal Notes</div>
                <Dialog>
                    <DialogTrigger asChild>
                        <Button
                            onClick={() => setIsEditing(true)}
                            className="h-5 flex flex-wrap gap-2 justify-start px-2 py-0 data-[state=open]:bg-muted"
                            size="sm"
                            variant={"outline"}
                        >
                            Edit
                            <Pencil2Icon />
                        </Button>
                    </DialogTrigger>
                    <DialogContent className="inset-0 z-50 flex items-center justify-center p-5 bg-black bg-opacity-50 max-h-full overflow-auto">
                        <div className="bg-white shadow-lg rounded-md p-7 w-1/2 overflow-auto relative">
                            <DialogHeader>
                                <DialogTitle className="text-md pb-3">
                                    Edit Notes
                                </DialogTitle>
                            </DialogHeader>
                            <Textarea
                                placeholder="Type your internal notes here... (Markdown is supported)"
                                value={notes}
                                onChange={(e) => setNotes(e.target.value)}
                                className="min-h-[100px] mb-4 w-full"
                            />
                            <div className="flex justify-end space-x-2">
                                <DialogClose asChild>
                                    <Button
                                        variant="outline"
                                        onClick={() => {
                                            setIsEditing(false);
                                            setNotes(savedNotes);
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </DialogClose>
                                <DialogClose>
                                    <Button onClick={saveNotes}>
                                        Save Notes
                                    </Button>
                                </DialogClose>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>

            {savedNotes.length !== 0 && (
                <Card className="p-2 m-2">
                    <div className="prose max-w-none text-xs">
                        <ReactMarkdown>{savedNotes}</ReactMarkdown>
                    </div>
                </Card>
            )}
        </div>
    );
};

export default InternalNotes;
