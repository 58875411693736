import { useState } from "react";
import { EXTERNAL_LINKS } from "../../constant";
import { Popover, PopoverContent, PopoverTrigger } from "../shadcn/ui/popover";
import {
    ChatBubbleIcon,
    FileTextIcon,
    QuestionMarkIcon,
    TrashIcon,
} from "@radix-ui/react-icons";
import { IconButton } from "@radix-ui/themes";
import {
    AlertDialog,
    AlertDialogContent,
    AlertDialogTrigger,
} from "../shadcn/ui/alert-dialog";
import { Button } from "../shadcn/ui/button";

export default function BallPopover() {
    const [openAlertDialog, setOpenAlertDialog] = useState(false);

    const toFeedback = () => {
        window.open(EXTERNAL_LINKS.contact);
    };

    const toPrivacyPolicy = () => {
        window.open(EXTERNAL_LINKS.privacy_policy);
    };

    return (
        <Popover>
            <PopoverTrigger>
                <IconButton
                    variant="outline"
                    size="2"
                    className="rounded-full ml-2 mb-2"
                >
                    <QuestionMarkIcon />
                </IconButton>
            </PopoverTrigger>
            <PopoverContent className="p-2 mt-2 ml-2 w-max">
                <div className="flex flex-col gap-1">
                    <AlertDialog
                        open={openAlertDialog}
                        onOpenChange={setOpenAlertDialog}
                    >
                        <AlertDialogTrigger asChild>
                            <Button
                                variant="ghost"
                                size="sm"
                                className="flex items-center justify-start"
                            >
                                <TrashIcon className="mr-2" />
                                Delete Data
                            </Button>
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                            <p>
                                Reach out to support@askassembly.app, and we'll
                                get this started for you!
                            </p>
                            <div className="mt-4 flex justify-end">
                                <Button
                                    onClick={() => setOpenAlertDialog(false)}
                                >
                                    Close
                                </Button>
                            </div>
                        </AlertDialogContent>
                    </AlertDialog>
                    <Button
                        onClick={toFeedback}
                        variant="ghost"
                        size="sm"
                        className="flex items-center justify-start"
                    >
                        <ChatBubbleIcon className="mr-2" />
                        Feedback
                    </Button>
                    <Button
                        onClick={toPrivacyPolicy}
                        variant="ghost"
                        size="sm"
                        className="flex items-center justify-start"
                    >
                        <FileTextIcon className="mr-2" />
                        Privacy Policy
                    </Button>
                </div>
            </PopoverContent>
        </Popover>
    );
}
