import { pages } from "@/constant";
import { GroupIcon, IdCardIcon, ThickArrowRightIcon } from "@radix-ui/react-icons";
import {
    BellIcon,
    BookmarkIcon,
    PersonIcon,
    TimerIcon,
} from "@radix-ui/react-icons";
import { NavigationMenu } from "@radix-ui/react-navigation-menu";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../shadcn/ui/button";
import {
    NavigationMenuItem,
    NavigationMenuList,
} from "../shadcn/ui/navigation-menu";
import MenuDropdownComponent from "./MenuDropdownComponent";
import BallPopover from "./BallPopover";

export type FilterMetadata = {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    granularFilters: Map<any, boolean>;
    enabled: boolean;
};

export const NAVBAR_PREFERENCES_ITEMS = {
    profile: "profile",
    tags: "tags",
    alerts: "alerts",
    members: "members",
    sla: "sla",
    customergroups: "customergroups",
    integrations: "integrations",
    workflows: "workflows",
};
interface SideBarProps {
    organizationName: string;
    filters?: Map<string, FilterMetadata>;
    activeButton: string;
    setActiveButton: (newTerm: string) => void;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    handleToggle?: (type: string, value: any) => () => void;
    isAdmin: boolean;
    width: number;
}

export const drawerWidth = "250px";

export default function WorkspaceSideBarComponent(props: SideBarProps) {
    const [selectedItem, setSelectedItem] = useState(props.activeButton);

    useEffect(() => {
        const path = window.location.pathname;
        if (path.includes("/preferences/profile")) {
            setSelectedItem(NAVBAR_PREFERENCES_ITEMS.profile);
        } else if (path.includes("/preferences/tags")) {
            setSelectedItem(NAVBAR_PREFERENCES_ITEMS.tags);
        } else if (path.includes("/preferences/alerts")) {
            setSelectedItem(NAVBAR_PREFERENCES_ITEMS.alerts);
        } else if (path.includes("/preferences/members")) {
            setSelectedItem(NAVBAR_PREFERENCES_ITEMS.members);
        } else if (path.includes("/preferences/sla")) {
            setSelectedItem(NAVBAR_PREFERENCES_ITEMS.sla);
        } else if (path.includes("/preferences/customergroups")) {
            setSelectedItem(NAVBAR_PREFERENCES_ITEMS.customergroups);
        }
    }, [window.location.pathname]);

    const handleMenuClick = (buttonName: string) => {
        // You can call the respective function here
        props.setActiveButton(buttonName);
        setSelectedItem(buttonName);
        if (buttonName === NAVBAR_PREFERENCES_ITEMS.profile) {
            navigate("/preferences/profile");
        } else if (buttonName === NAVBAR_PREFERENCES_ITEMS.tags) {
            navigate("/preferences/tags");
        } else if (buttonName === NAVBAR_PREFERENCES_ITEMS.alerts) {
            navigate("/preferences/alerts");
        } else if (buttonName === NAVBAR_PREFERENCES_ITEMS.members) {
            navigate("/preferences/members");
        } else if (buttonName === NAVBAR_PREFERENCES_ITEMS.sla) {
            navigate("/preferences/sla");
        } else if (buttonName === NAVBAR_PREFERENCES_ITEMS.customergroups) {
            navigate("/preferences/customergroups");
        } else if (buttonName === NAVBAR_PREFERENCES_ITEMS.workflows) {
            navigate("/preferences/workflows");
        }
    };

    const collapsed = props.width < 10;
    const navigate = useNavigate();

    return (
        <div
            className={`relative transition-all duration-300 min-w-[15px]${collapsed ? "w-15" : "w-50"
                }`}
        >
            <MenuDropdownComponent
                organizationName={props.organizationName}
                state={pages.search}
                collapsed={collapsed}
            />

            <NavigationMenu
                className="flex flex-col space-y-2"
                orientation="vertical"
            >
                <div
                    className={`text-xs px-5 font-bold text-gray-500 ${collapsed ? "hidden" : "block"
                        }`}
                >
                    My Account
                </div>
                <NavigationMenuList className="flex flex-col items-center w-full">
                    <NavigationMenuItem className="w-full">
                        <div className="w-full px-3">
                            <Button
                                variant="ghost"
                                size="sm"
                                onClick={() =>
                                    handleMenuClick(
                                        NAVBAR_PREFERENCES_ITEMS.profile,
                                    )
                                }
                                className={`flex justify-center items-center text-left w-full box-border ${selectedItem ===
                                    NAVBAR_PREFERENCES_ITEMS.profile
                                    ? "bg-secondary text-gray-950"
                                    : "text-gray-700 hover:text-gray-950 hover:bg-gray-100"
                                    }`}
                            >
                                <PersonIcon />
                                <div
                                    className={`flex-1 ml-2 ${collapsed ? "hidden" : "block"
                                        }`}
                                >
                                    Profile
                                </div>
                            </Button>
                        </div>
                    </NavigationMenuItem>
                </NavigationMenuList>
                {props.isAdmin && (
                    <>
                        <div
                            className={`text-xs px-5 font-bold text-gray-500 ${collapsed ? "hidden" : "block"
                                }`}
                        >
                            Preferences
                        </div>
                        <NavigationMenuList className="flex flex-col items-center w-full">
                            <NavigationMenuItem className="w-full">
                                <div className="w-full px-3">
                                    <Button
                                        variant="ghost"
                                        size="sm"
                                        onClick={() =>
                                            handleMenuClick(
                                                NAVBAR_PREFERENCES_ITEMS.tags,
                                            )
                                        }
                                        className={`flex justify-center items-center text-left w-full box-border ${selectedItem ===
                                            NAVBAR_PREFERENCES_ITEMS.tags
                                            ? "bg-secondary text-gray-950"
                                            : "text-gray-700 hover:text-gray-950 hover:bg-gray-100"
                                            }`}
                                    >
                                        <BookmarkIcon />
                                        <div
                                            className={`flex-1 ml-2 ${collapsed ? "hidden" : "block"
                                                }`}
                                        >
                                            Tags
                                        </div>
                                    </Button>
                                </div>
                            </NavigationMenuItem>
                            <NavigationMenuItem className="w-full">
                                <div className="w-full px-3">
                                    <Button
                                        variant="ghost"
                                        size="sm"
                                        onClick={() =>
                                            handleMenuClick(
                                                NAVBAR_PREFERENCES_ITEMS.alerts,
                                            )
                                        }
                                        className={`flex justify-center items-center text-left w-full box-border ${selectedItem ===
                                            NAVBAR_PREFERENCES_ITEMS.alerts
                                            ? "bg-secondary text-gray-950"
                                            : "text-gray-700 hover:text-gray-950 hover:bg-gray-100"
                                            }`}
                                    >
                                        <BellIcon />
                                        <div
                                            className={`flex-1 ml-2 ${collapsed ? "hidden" : "block"
                                                }`}
                                        >
                                            Alerts
                                        </div>
                                    </Button>
                                </div>
                            </NavigationMenuItem>
                            <NavigationMenuItem className="w-full">
                                <div className="w-full px-3">
                                    <Button
                                        variant="ghost"
                                        size="sm"
                                        onClick={() =>
                                            handleMenuClick(
                                                NAVBAR_PREFERENCES_ITEMS.members,
                                            )
                                        }
                                        className={`flex justify-center items-center text-left w-full box-border ${selectedItem ===
                                            NAVBAR_PREFERENCES_ITEMS.members
                                            ? "bg-secondary text-gray-950"
                                            : "text-gray-700 hover:text-gray-950 hover:bg-gray-100"
                                            }`}
                                    >
                                        <IdCardIcon />
                                        <div
                                            className={`flex-1 ml-2 ${collapsed ? "hidden" : "block"
                                                }`}
                                        >
                                            Members
                                        </div>
                                    </Button>
                                </div>
                            </NavigationMenuItem>
                            <NavigationMenuItem className="w-full">
                                <div className="w-full px-3">
                                    <Button
                                        variant="ghost"
                                        size="sm"
                                        onClick={() =>
                                            handleMenuClick(
                                                NAVBAR_PREFERENCES_ITEMS.sla,
                                            )
                                        }
                                        className={`flex justify-center items-center text-left w-full box-border ${selectedItem ===
                                            NAVBAR_PREFERENCES_ITEMS.sla
                                            ? "bg-secondary text-gray-950"
                                            : "text-gray-700 hover:text-gray-950 hover:bg-gray-100"
                                            }`}
                                    >
                                        <TimerIcon />
                                        <div
                                            className={`flex-1 ml-2 ${collapsed ? "hidden" : "block"
                                                }`}
                                        >
                                            SLAs
                                        </div>
                                    </Button>
                                </div>
                            </NavigationMenuItem>
                            <NavigationMenuItem className="w-full">
                                <div className="w-full px-3">
                                    <Button
                                        variant="ghost"
                                        size="sm"
                                        onClick={() =>
                                            handleMenuClick(
                                                NAVBAR_PREFERENCES_ITEMS.workflows,
                                            )
                                        }
                                        className={`flex justify-center items-center text-left w-full box-border ${selectedItem ===
                                            NAVBAR_PREFERENCES_ITEMS.workflows
                                            ? "bg-secondary text-gray-950"
                                            : "text-gray-700 hover:text-gray-950 hover:bg-gray-100"
                                            }`}
                                    >
                                        <ThickArrowRightIcon />
                                        <div
                                            className={`flex-1 ml-2 ${collapsed ? "hidden" : "block"
                                                }`}
                                        >
                                            Workflows
                                        </div>
                                    </Button>
                                </div>
                            </NavigationMenuItem>
                            <NavigationMenuItem className="w-full">
                                <div className="w-full px-3">
                                    <Button
                                        variant="ghost"
                                        size="sm"
                                        onClick={() =>
                                            handleMenuClick(
                                                NAVBAR_PREFERENCES_ITEMS.customergroups,
                                            )
                                        }
                                        className={`flex justify-center items-center text-left w-full box-border ${selectedItem ===
                                            NAVBAR_PREFERENCES_ITEMS.customergroups
                                            ? "bg-secondary text-gray-950"
                                            : "text-gray-700 hover:text-gray-950 hover:bg-gray-100"
                                            }`}
                                    >
                                        <GroupIcon />
                                        <div
                                            className={`flex-1 ml-2 ${collapsed ? "hidden" : "block"
                                                }`}
                                        >
                                            Customer Groups
                                        </div>
                                    </Button>
                                </div>
                            </NavigationMenuItem>
                        </NavigationMenuList>
                    </>
                )}
            </NavigationMenu>
            <div className="fixed bottom-0 left-0 p-4">
                <BallPopover />
            </div>
        </div>
    );
}
