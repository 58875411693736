import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";

import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";

import { AIResponsePlugin } from "./AIResponsePlugin";

import { $convertToMarkdownString, TRANSFORMERS } from "@lexical/markdown";

import { LexicalComposer } from "@lexical/react/LexicalComposer";

import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";

import { ContentEditable } from "@lexical/react/LexicalContentEditable";

import { HeadingNode, QuoteNode } from "@lexical/rich-text";

import { ListItemNode, ListNode } from "@lexical/list";

import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { LineBreakNode, ParagraphNode } from "lexical";
import { ExampleTheme } from "../../component/textEditor/Theme";
import Toolbar from "../../component/textEditor/Toolbar";
import ActionsPlugin from "./ActionsPlugin";
import EnterCommand from "./EnterCommand";
import { EmojiNode } from "./nodes/EmojiNode";

interface EditorProps {
    className?: string;
    handleSubmit: (markdwn: string) => void;
    enableAIResponse: boolean;
    ai_response: string;
    isToggled?: boolean;
    setIsToggled?: React.Dispatch<React.SetStateAction<boolean>>;
    aiResponseButton?: boolean;
    setIsSendDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    isSendDisabled: boolean;
    loading?: boolean;
    setKey?: React.Dispatch<React.SetStateAction<number>>;
}

export function Editor({
    className,
    handleSubmit,
    enableAIResponse,
    ai_response,
    isToggled,
    setIsToggled = () => {},
    aiResponseButton,
    isSendDisabled,
    setIsSendDisabled,
    setKey = () => {},
    loading = false,
}: EditorProps) {
    const initialConfig = {
        namespace: "MyEditor",
        theme: ExampleTheme,
        onError: (error: unknown) => {
            console.error(error);
            throw error;
        },
        nodes: [
            HeadingNode,
            QuoteNode,
            ListNode,
            ListItemNode,
            EmojiNode,
            LineBreakNode,
            ParagraphNode,
        ],
    };

    return (
        <LexicalComposer initialConfig={initialConfig}>
            <div
                className={`relative flex flex-row justify-between w-full pb-2 gap-[50px] ${className || ""}`}
            >
                <div className="text-sm relative flex flex-col flex-1 gap-1 rounded-lg transition-all">
                    <Toolbar />
                    {enableAIResponse && (
                        <AIResponsePlugin
                            ai_response={ai_response}
                            isToggled={isToggled ?? false}
                            setIsSendDisabled={setIsSendDisabled}
                            setIsToggled={setIsToggled}
                            aiResponseButton={aiResponseButton ?? false}
                            setKey={setKey}
                        />
                    )}
                    <div className="max-h-32 overflow-scroll">
                        <RichTextPlugin
                            contentEditable={<ContentEditable />}
                            placeholder={<div />}
                            ErrorBoundary={LexicalErrorBoundary}
                        />
                    </div>
                    <HistoryPlugin />
                    <AutoFocusPlugin />
                    <ClearEditorPlugin />
                    {/* <MarkdownShortcutPlugin transformers={TRANSFORMERS} /> */}
                    <EnterCommand
                        onSubmit={() => {
                            const mrkdwn =
                                $convertToMarkdownString(TRANSFORMERS);
                            handleSubmit(mrkdwn);
                        }}
                    />
                    <ListPlugin />
                    <ActionsPlugin
                        isSendDisabled={isSendDisabled}
                        setIsSendDisabled={setIsSendDisabled}
                        loading={loading}
                        handleSubmit={() => {
                            const mrkdwn =
                                $convertToMarkdownString(TRANSFORMERS);
                            handleSubmit(mrkdwn);
                        }}
                    />
                </div>
            </div>
        </LexicalComposer>
    );
}
