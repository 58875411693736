import { MagicWandIcon } from "@radix-ui/react-icons";
import { Box, Button, Card, Flex, Heading } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import type { CodeBlockPropsV2 } from "../../interfaces/SearchBlock";
import type { CodeResponse } from "../../interfaces/serverData";
import CodeSnippet from "../CodeSnippetComponent";
import Typewriter from "../TypeWriterComponent";
import { createLink, getNodesText } from "./cardUtils";

function CardComponentV2(props: CodeBlockPropsV2) {
    const [node, setNode] = useState<CodeResponse>();
    const [startLine, setStartLine] = useState<number>(0);
    const [endLine, setEndLine] = useState<number>(0);

    useEffect(() => {
        if (props.node && Object.values(props.node)) {
            setNode(props.node);
            const parts = props.node.location.split(",");

            const s = Number.parseInt(parts[0], 10);
            setStartLine(s);
            const e = Number.parseInt(parts[1], 10);
            setEndLine(e);
        }
    }, [props.node]);

    function openLink(link: string) {
        window.open(link, "_blank");
    }

    return (
        <Box p={"4"} style={{ width: "900px", margin: "auto" }}>
            <Card size="3">
                <Flex direction={"column"} gap="2">
                    {props.bestAnswer && (
                        <Box
                            style={{
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                            }}
                        >
                            <MagicWandIcon />
                            <Heading
                                size="3"
                                style={{ paddingLeft: "1%" }}
                                color="iris"
                            >
                                Best Answer
                            </Heading>
                        </Box>
                    )}
                    <Typewriter text={props.summary} />

                    {node !== undefined && (
                        <div>
                            <CodeSnippet
                                link={createLink(
                                    node!.filepath,
                                    node!.owner,
                                    node!.repo,
                                    node!.location,
                                    node!.branch,
                                )}
                                repo={node!.repo}
                                filePath={node!.filepath}
                                startLine={startLine}
                                endLine={endLine}
                                codeBlock={node!.body}
                                summary={props.summary}
                                githubblame={props.githubblame}
                                owner={node!.owner}
                                nodes={props.node}
                                bestAnswer={props.bestAnswer}
                            />
                            <Box
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                }}
                            >
                                <Flex gap="4" wrap={"wrap"}>
                                    <Button
                                        size="2"
                                        variant="soft"
                                        onClick={() => {
                                            openLink(
                                                createLink(
                                                    node.filepath,
                                                    node.owner,
                                                    node.repo,
                                                    node.location,
                                                    node.branch,
                                                ) ?? "",
                                            );
                                        }}
                                    >
                                        {getNodesText(node)}
                                    </Button>
                                </Flex>
                            </Box>
                        </div>
                    )}
                </Flex>
            </Card>
        </Box>
    );
}

export default CardComponentV2;
