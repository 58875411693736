export const queryToLabel: Record<string, string> = {
    id: "Id",
    title: "Title",
    created_at: "Created",
    bot_category: "Tag",
    source: "Source",
    topic: "Topics",
    ticket_status: "Status",
};

export const sources = [
    {
        label: "Slack",
        value: "Slack",
    },
    {
        label: "Discord",
        value: "Discord",
    },
    {
        label: "Chat Widget",
        value: "ChatWidget",
    },
    {
        label: "Gmail",
        value: "Gmail",
    },
    {
        label: "Web",
        value: "Web",
    },
];

export const AnnouncementsState = {
    draft: "Draft",
    sent: "Sent",
    delete: "Delete",
};

export const AnnouncementsType = {
    broadcast: "Broadcast",
    replyInThread: "ReplyInThread",
};

export const AnnouncementsTypeMap: { [key: string]: string } = {
    [AnnouncementsType.replyInThread]: "Reply In Thread",
    [AnnouncementsType.broadcast]: "Broadcast",
};

export const statuses = [
    {
        label: AnnouncementsState.draft,
        value: AnnouncementsState.draft,
    },
    {
        label: AnnouncementsState.sent,
        value: AnnouncementsState.sent,
    },
];

export type RowState = {
    tagSelected: string;
    topicsSelected: string[];
    statusSelected: string;
};
